import format from 'date-fns/format';

import { type BrandJourneyReportSnakeCase } from '@isi/interfaces/brand-report.interface';
import { mapKeysToCamel } from '@isi/network/helpers/params/map-keys-to-camel.function';

enum BrandJourneyReportType {
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export default class BrandJourneyReport {
  static dateFormat = 'do MMM yyyy';

  id!: number;
  filename!: string;
  downloadPath!: string;
  startDate!: Date;
  formattedStartDate!: string;
  endDate!: Date;
  formattedEndDate!: string;
  type!: BrandJourneyReportType;

  constructor({ start_date, end_date, ...rest }: BrandJourneyReportSnakeCase) {
    Object.assign(this, mapKeysToCamel(rest));

    this.setDates(start_date, end_date);
  }

  private setDates = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const formattedStartDate = format(startDate, BrandJourneyReport.dateFormat);
    const formattedEndDate = format(endDate, BrandJourneyReport.dateFormat);

    this.startDate = startDate;
    this.endDate = endDate;
    this.formattedStartDate = formattedStartDate;
    this.formattedEndDate = formattedEndDate;
  };

  get titleString() {
    return `${this.type} ${this.formattedStartDate} - ${this.formattedEndDate}`;
  }
}
