import { css, Global } from '@emotion/react';
import { observer } from 'mobx-react';

import markOTBoldFont from '@isi/core/resources/fonts/MarkOT-Bold.ttf';
import markOTFont from '@isi/core/resources/fonts/MarkOT.ttf';

import useStoreContext from '@isi/hooks/useStoreContext';

import LiveChat from '@isi/components/common/LiveChat.component';
import Navbar from '@isi/components/common/navbar.component';
import Notification from '@isi/components/common/notification.component';
import SideNav from '@isi/components/common/side-nav.component';
import SpinnerWrapper from '@isi/components/common/SpinnerWrapper.component';
import Routes from '@isi/components/Routes';

const InStoreInterface = () => {
  const {
    authStore: { loggedIn },
    storeStore: { getStoreConfig },
  } = useStoreContext();

  return (
    <>
      <Global
        styles={css`
          @import 'https://use.fontawesome.com/releases/v5.6.3/css/all.css';

          @font-face {
            font-family: 'MarkOT-Bold';
            src: url('${markOTBoldFont}') format('truetype');
          }
          @font-face {
            font-family: 'MarkOT';
            src: url('${markOTFont}') format('truetype');
          }

          html,
          body {
            background-color: white;
            margin: 0;
            font-family: MarkOT, helvetica, arial, sans-serif;
            font-weight: normal;
          }

          button {
            -webkit-appearance: none;
            outline: none;
          }

          .bold {
            font-family: MarkOT-Bold, helvetica, arial, sans-serif;
          }

          .overflow-hidden {
            overflow: hidden;
          }

          a[disabled] {
            pointer-events: none;
            opacity: 0.41;
          }
        `}
      />

      <SideNav>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 78px auto 1fr;
            grid-template-areas:
              'header'
              'notification'
              'main';
            height: 100vh;
          `}
        >
          <Navbar buttonMode={loggedIn} />
          <Notification />
          {!getStoreConfig && loggedIn && <SpinnerWrapper />}
          <LiveChat />
          <Routes />
        </div>
      </SideNav>
    </>
  );
};

export default observer(InStoreInterface);
