import { css } from '@emotion/react';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Component } from 'react';

import { OrderStatus } from '@isi/enums/order-status.enum';
import { transformFormatDateWithStore } from '@isi/helpers/dateLocalisation';
import { productModeToString } from '@isi/helpers/product-mode-to-string.function';
import { type IDashboardOrder } from '@isi/interfaces/dashboard-order.interface';
import { rootStore } from '@isi/stores/root.store';

import { IconButton } from '@isi/components/common/icon-button.component';
import { PrintButton } from '@isi/components/common/print-button.component';

interface IOrderRow {
  order: IDashboardOrder;
  expanded: boolean;
}

@observer
export default class OrderRow extends Component<IOrderRow, {}> {
  private buttonAction() {
    return this.props.expanded ? rootStore.dashboardStore.clearCurrentOrder() : this.expandOrder();
  }

  private icon() {
    return this.props.expanded ? faMinus : faPlus;
  }

  private expandOrder() {
    rootStore.dashboardStore.setCurrentOrder(this.props.order);
  }

  public render(): JSX.Element {
    const { order } = this.props;

    const {
      storeStore: { getStoreConfig, storeCurrencyCode: currencyCode, storeLocale: locale },
    } = rootStore;

    const gridTemplateColumns = getStoreConfig?.warehouseToDoor
      ? 'repeat(7, 125px) 50px'
      : '160px repeat(8, 125px) 50px';

    return (
      <div
        role='row'
        css={css`
          background: #f7f7f7;
          width: 100%;
        `}
      >
        <div
          css={css`
            margin: auto;
            width: fit-content;
            font-size: 14px;
            background-color: #f7f7f7;
            padding-top: 20px;
            padding-bottom: 18px;
            padding-right: 10px;
            border-bottom: 1px solid #e1e1e1;
            display: grid;
            grid-template-columns: ${gridTemplateColumns};
          `}
        >
          {!getStoreConfig?.warehouseToDoor && (
            <>
              <div role='cell'>
                {transformFormatDateWithStore(order.pickUpTime, getStoreConfig?.utcOffsetSeconds, 'ddd, Do MMM YY')}
              </div>
              <div role='cell'>
                {transformFormatDateWithStore(order.pickUpTime, getStoreConfig?.utcOffsetSeconds, 'LT')}
              </div>
            </>
          )}
          <div
            role='cell'
            css={css`
              padding-right: 10px;
            `}
          >
            {order.id}
            {order.afternoonCollection ? ' 🕒' : ''}
          </div>
          <div
            role='cell'
            css={css`
              padding-right: 10px;
            `}
          >
            {!order.externalState.includes('Return') &&
              ![OrderStatus.Completed, OrderStatus.Cancelled].includes(order.status) && (
                <PrintButton
                  orderId={order.id}
                  height={24}
                  width={24}
                  additionalIconStyles={`
                  margin: auto;
                `}
                />
              )}
          </div>
          {!getStoreConfig?.warehouseToDoor && (
            <div
              role='cell'
              css={css`
                padding-right: 10px;
              `}
            >
              {order.pickupCode}
            </div>
          )}
          {getStoreConfig?.warehouseToDoor && (
            <div
              role='cell'
              css={css`
                padding-right: 10px;
              `}
            >
              {moment.utc(order.startDatetime).format('Do MMMM')}
              <br />
              {order.slotTimesAsString}
            </div>
          )}
          <div
            role='cell'
            css={css`
              padding-right: 10px;
            `}
          >
            {order.storeOrderNumber}
          </div>
          <div role='cell'>
            <div
              css={css`
                width: fit-content;
                max-width: 102px;
              `}
            >
              {productModeToString(order.productMode)}
            </div>
          </div>
          <div
            role='cell'
            css={css`
              padding-right: 10px;
            `}
          >
            {order.externalState}
          </div>
          <div role='cell'>
            {Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode }).format(order.orderTotal)}
          </div>
          <div role='cell'>
            <IconButton
              ariaLabel='Show order info'
              callback={() => {
                this.buttonAction();
              }}
              iconClass={this.icon()}
              disabled={false}
              height={12}
              width={12}
              additionalIconStyles={`
                margin: auto;
              `}
            />
          </div>
        </div>
      </div>
    );
  }
}
