import { Environments } from '@isi/enums/environments';
import { GetCookie } from '@isi/helpers/cookies';

export function originUrl(): string {
  return window.location.origin;
}

export function apiUrl(): string {
  const env = GetCookie.environment();
  switch (env) {
    case Environments.Next:
      if (process.env.HEROKU_APP_NAME) {
        return `https://api.${process.env.HEROKU_APP_NAME}.toshi.co`;
      }
      return 'https://next.api.toshi.co';
    case Environments.Staging:
      return 'https://staging.api.toshi.co';
    case Environments.Production:
      return 'https://api.toshi.co';
    case Environments.Development:
      return 'https://api.toshi.test';
    case Environments.Test:
      return 'http://api.lvh.me:3000';
    default:
      return 'https://api.toshi.test';
  }
}

export function socketUrl(): string {
  const env = GetCookie.environment();

  switch (env) {
    case Environments.Next:
      if (process.env.HEROKU_APP_NAME) {
        return `wss://sockets.${process.env.HEROKU_APP_NAME}.toshi.co/cable`;
      }
      return 'wss://next.sockets.toshi.co/cable';
    case Environments.Staging:
      return 'wss://staging.sockets.toshi.co/cable';
    case Environments.Production:
      return 'wss://sockets.toshi.co/cable';
    case Environments.Development:
      return 'wss://sockets.toshi.test/cable';
    case Environments.Test:
      return 'ws://sockets.lvh.me:3000/cable';
    default:
      return 'wss://sockets.toshi.test/cable';
  }
}
