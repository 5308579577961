import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { Service } from '@isi/enums/service.enum';
import { PackageValidationField } from '@isi/interfaces/package-validation-error.interface';
import { ProductRowValidationField } from '@isi/interfaces/product-validation-error.interface';
import { type ISelectedProduct } from '@isi/interfaces/selected-product.interface';
import { rootStore } from '@isi/stores/root.store';

import Toggle from '@isi/components/abstractions/Toggle';
import { Container } from '@isi/components/common/container.component';
import Modal from '@isi/components/common/modal.component';
import { DropOffProductRow } from '@isi/components/products/add-services/add-services-table/drop-off-product-row.component';
import { DropOffTableHeader } from '@isi/components/products/add-services/add-services-table/drop-off-table-header.component';
import { NumberOfBags } from '@isi/components/products/add-services/add-services-table/number-of-bags.component';
import { WaitAndTryProductRow } from '@isi/components/products/add-services/add-services-table/wait-and-try-product-row.component';
import { WaitAndTryTableHeader } from '@isi/components/products/add-services/add-services-table/wait-and-try-table-header.component';
import DropOffWarningModalContent from '@isi/components/products/add-services/drop-off-warning-modal-content.compontent';
import { type ServicesToggle, ServicesToggles } from '@isi/components/products/add-services/services-toggle.component';

interface IAddServicesProps {
  onProductsToDeleteChange: (amountToDelete: number) => void;
  isGiftingOrder: boolean;
  isDropOffOrder: boolean;
  isServiceOrder: boolean;
}

interface IAddServicesState {
  pageSelected: ServicesToggles;
  fittingAndPinningAllSelected: boolean;
  perfectFitAllSelected: boolean;
  inspireMeAllSelected: boolean;
  paymentRequiredAllSelected: boolean;
  productsToDelete: IWaitAndTryRowRef[];
  showModal: boolean;
}

interface IWaitAndTryRowRef {
  productId: number;
  productSizeId: number;
  rowRef: WaitAndTryProductRow | DropOffProductRow | null | undefined;
}

@observer
class AddServices extends Component<IAddServicesProps, IAddServicesState> {
  public productHeaderRef: WaitAndTryTableHeader | DropOffTableHeader | null = null;
  public productRowRefs: IWaitAndTryRowRef[] = [];
  private servicesToggle: ServicesToggle | null = null;
  constructor(props: IAddServicesProps) {
    super(props);
    this.state = {
      pageSelected: this.props.isServiceOrder ? ServicesToggles.WaitAndTry : ServicesToggles.DropOff,
      productsToDelete: [],
      fittingAndPinningAllSelected: false,
      perfectFitAllSelected: false,
      inspireMeAllSelected: false,
      paymentRequiredAllSelected: false,
      showModal: false,
    };
  }

  componentDidMount() {
    if (this.showWaitAndTry() || rootStore.orderStore.getIsServicedOrder === true) {
      rootStore.orderStore.unsetDropOffOnly();
    } else {
      rootStore.orderStore.setDropOffOnly();
    }
  }

  public renderContent = (): JSX.Element => {
    if (this.state.pageSelected === ServicesToggles.WaitAndTry || rootStore.orderStore.getIsServicedOrder === true) {
      return (
        <>
          <table
            css={css`
              width: 100%;
              font-size: 10px;
              border-collapse: collapse;
            `}
          >
            <thead>
              <WaitAndTryTableHeader
                ref={(ref) => {
                  if (ref) this.productHeaderRef = ref;
                }}
                onDeleteAllCheckboxClick={(selected) => {
                  this.toggleAllProductsForDeletion(selected);
                }}
                onToggleServiceForAllProducts={(service) => {
                  this.toggleServiceForAllProducts(service);
                }}
              />
            </thead>
            <tbody>{this.renderWaitAndTryRows()}</tbody>
          </table>
          {this.renderNumberOfBags()}
          {!this.props.isGiftingOrder && this.renderSecurityCodeToggle()}
        </>
      );
    }
    return (
      <>
        <table
          css={css`
            width: 100%;
            font-size: 10px;
            border-collapse: collapse;
          `}
        >
          <thead>
            <DropOffTableHeader
              ref={(ref) => {
                if (ref) this.productHeaderRef = ref;
              }}
              onDeleteAllCheckboxClick={(selected) => {
                this.toggleAllProductsForDeletion(selected);
              }}
            />
          </thead>
          <tbody>{this.renderDropOffRows()}</tbody>
        </table>
        {!this.props.isGiftingOrder && this.renderConsignmentDropoffToggle()}
        {!this.props.isGiftingOrder && this.renderSecurityCodeToggle()}
      </>
    );
  };

  public renderSecurityCodeToggle = (): JSX.Element | null =>
    rootStore.storeStore.getStoreConfig?.enableSecurityCodeFeature ? (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 24px;
          gap: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            width: 55%;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              gap: 10px;
              border: 2px solid #cccccc;
              border-radius: 30px;
              background-color: white;
              padding: 10px 20px;
              font-size: 16px;
              width: 100%;
              max-height: 30px;
            `}
          >
            <label htmlFor='security-code-toggle'>Require a security code for delivery?</label>
            <Toggle
              icons={{
                unchecked: null,
              }}
              id='security-code-toggle'
              onChange={rootStore.orderStore.toggleUseSecurityCodes}
              checked={rootStore.orderStore.useSecurityCodes}
            />
          </div>
        </div>
        <div
          css={css`
            width: 45%;
            text-align: start;
            line-height: 1;
          `}
        >
          <small
            css={css`
              font-size: 0.7em;
            `}
          >
            <em>
              We will send a 4 digit security code as an email and text message to the client, and won&apos;t hand over
              the order until the code is confirmed.
            </em>
          </small>
        </div>
      </div>
    ) : null;

  public renderConsignmentDropoffToggle = (): JSX.Element | null =>
    rootStore.storeStore.getStoreConfig?.consignmentDropoffEnabled ? (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 24px;
          gap: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            width: 55%;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              gap: 10px;
              border: 2px solid #cccccc;
              border-radius: 30px;
              background-color: white;
              padding: 10px 20px;
              font-size: 16px;
              width: 100%;
              max-height: 30px;
            `}
          >
            <label htmlFor='consignment-dropoff-toggle'>Is this a consignment order?</label>
            <Toggle
              icons={{
                unchecked: null,
              }}
              id='consignment-dropoff-toggle'
              onChange={rootStore.orderStore.toggleConsignmentDropoff}
              checked={rootStore.orderStore.consignment}
            />
          </div>
        </div>
        <div
          css={css`
            width: 45%;
            text-align: start;
            line-height: 1;
          `}
        >
          <small
            css={css`
              font-size: 0.7em;
            `}
          >
            <em>
              For consignment orders, payment is taken after delivery. If the client wishes to return any items at a
              later date, please use the return feature associated to the order.
            </em>
          </small>
        </div>
      </div>
    ) : null;

  public renderWaitAndTryRows = (): JSX.Element[] => {
    rootStore.productStore.setValidationForAllProductRows();
    if (rootStore.storeStore.getStoreConfig?.consignmentServicedEnabled) {
      rootStore.orderStore.setConsignmentServiced();
    }
    const rows: JSX.Element[] = [];
    this.productRowRefs = [];
    for (const product of rootStore.productStore.sortedSelectedProductsByAdditionalSize) {
      const { id } = product;
      const { productSizeId, sku, mainSize, quantity, services, colour, taxRate } = product;
      const size = rootStore.productStore.getProductSize(id, productSizeId);
      const price = rootStore.productStore.getSelectedProductPrice(id);
      const errors = rootStore.productStore.getValidationErrorsForProduct(id, productSizeId);
      rows.push(
        <WaitAndTryProductRow
          ref={(productRowRef) => {
            this.productRowRefs.push({
              productId: id,
              productSizeId,
              rowRef: productRowRef,
            });
          }}
          sku={sku}
          key={`product-services-row-${id}-${productSizeId}`}
          isMainRow={mainSize}
          product={product}
          name={product.name}
          sizeId={productSizeId}
          size={size}
          price={price}
          quantity={quantity}
          services={services}
          colour={colour}
          taxRate={taxRate}
          onItemToDeleteSelectChange={() => this.toggleProductForDeletion(id, productSizeId)}
          onDuplicateClick={() => this.duplicateRowWithBlankSize(product)}
          onSizeChange={(newSize: string) => this.onSizeChange(id, productSizeId, newSize, this.props.isDropOffOrder)}
          onPriceChange={(newPrice: number) => this.onPriceChange(id, productSizeId, newPrice)}
          onQuantityChange={(quantity: number) =>
            this.onQuantityChange(id, productSizeId, quantity, this.props.isDropOffOrder)
          }
          onServiceChange={(selected: boolean, service: Service) =>
            this.toggleServiceforProduct(selected, product, productSizeId, service)
          }
          errors={errors}
        />,
      );
    }
    return rows;
  };

  public renderNumberOfBags = (): JSX.Element => {
    const packageCount = rootStore.orderStore.getPackageCount;
    const packageSize = rootStore.orderStore.getPackageSize;
    const errors = rootStore.orderStore.getValidationErrorsForPackage();

    return (
      <NumberOfBags
        onPackageSizeChange={this.onPackageSizeChange}
        onPackageCountChange={this.onPackageCountChange}
        packageSize={packageSize}
        packageCount={packageCount}
        errors={errors}
      />
    );
  };

  public renderDropOffRows = (): JSX.Element[] => {
    rootStore.productStore.setValidationForAllProductRows();

    this.productRowRefs = [];

    // return rows;

    return rootStore.productStore.sortedSelectedProductsByAdditionalSize.map((product) => {
      const { id, productSizeId, colour } = product;
      const size = rootStore.productStore.getProductSize(id, productSizeId);
      const price = rootStore.productStore.getSelectedProductPrice(id);
      const packageCount = rootStore.orderStore.getPackageCount;
      const errors = rootStore.productStore.getValidationErrorsForProduct(id, productSizeId);
      return (
        <DropOffProductRow
          ref={(productRowRef) => {
            this.productRowRefs.push({
              productId: id,
              productSizeId,
              rowRef: productRowRef,
            });
          }}
          key={`product-services-row-${id}-${productSizeId}`}
          product={rootStore.productStore.getProduct(id)}
          name={product.name}
          colour={colour}
          quantity={packageCount}
          size={size}
          price={price}
          onItemToDeleteSelectChange={() => this.toggleProductForDeletion(id, productSizeId)}
          onSizeChange={(newSize: string) => this.onSizeChange(id, productSizeId, newSize, this.props.isDropOffOrder)}
          onPriceChange={(newPrice: number) => this.onPriceChange(id, productSizeId, newPrice)}
          onQuantityChange={(packageCount: number) => this.onPackageCountChange(packageCount)}
          errors={errors}
        />
      );
    });
  };

  public duplicateRowWithBlankSize = (product: ISelectedProduct) => {
    const canCreateRow: boolean = !rootStore.productStore.checkIfProductHasABlankSize(product.id);
    if (canCreateRow) {
      rootStore.productStore.createBlankProductSize(product);
    }
  };

  private showWaitAndTry = (): boolean => {
    if (this.props.isGiftingOrder) {
      return false;
    }
    if (this.props.isDropOffOrder) {
      return false;
    }

    return true;
  };

  public updateSelectedServiceState = () => {
    if (!this.showWaitAndTry()) {
      return;
    }
    if (this.state.pageSelected === ServicesToggles.DropOff) {
      this.showWaitAndTryScreen();
      return;
    }

    const hasServices = rootStore.productStore.hasSelectedAdditionalSizes || rootStore.productStore.hasSelectedServices;
    if (hasServices && this.state.pageSelected === ServicesToggles.WaitAndTry) {
      this.setState({ showModal: true });
      this.servicesToggle?.forceWaitAndTrySelection();
      return;
    }
    this.showDropOffScreen();
  };

  public toggleProductForDeletion = (productId: number, productSizeId: number) => {
    this.productHeaderRef?.forceUnselectDeleteCheckbox();
    if (this.checkProductIsSelectedForDeletion(productId, productSizeId)) {
      this.removeProductFromSelectedForDeletion(productId, productSizeId);
      return;
    }

    const rowRef = this.getRowRef(productId, productSizeId);

    this.addProductToSelectedForDeletion(productId, productSizeId, rowRef);
  };

  public addProductToSelectedForDeletion = (
    productId: number,
    productSizeId: number,
    rowRef: WaitAndTryProductRow | DropOffProductRow | null | undefined,
  ) => {
    const { productsToDelete } = this.state;
    const productForDeletion: IWaitAndTryRowRef = { productId, productSizeId, rowRef };
    this.setState((prev) => ({ productsToDelete: [...prev.productsToDelete, productForDeletion] }));
    this.props.onProductsToDeleteChange(productsToDelete.length);
  };

  public removeProductFromSelectedForDeletion = (productId: number, productSizeId: number) => {
    const { productsToDelete } = this.state;
    let indexOfProduct = 0;
    for (const [i, element] of productsToDelete.entries()) {
      const matchesProduct = element.productId === productId && element.productSizeId === productSizeId;
      if (matchesProduct) {
        indexOfProduct = i;
      }
    }
    productsToDelete.splice(indexOfProduct, 1);
    this.setState({ productsToDelete });
    this.props.onProductsToDeleteChange(productsToDelete.length);
  };

  public checkProductIsSelectedForDeletion = (productId: number, productSizeId: number): boolean => {
    for (let i = 0; i < this.state.productsToDelete.length; i++) {
      const currentProduct = this.state.productsToDelete[i];
      const productIsSelectedForDeletion =
        currentProduct.productId === productId && currentProduct.productSizeId === productSizeId;

      if (productIsSelectedForDeletion) {
        return true;
      }
    }
    return false;
  };

  public selectAllProductsForDeletion = () => {
    const productsToDelete: IWaitAndTryRowRef[] = [];
    this.productRowRefs.forEach((productRow: IWaitAndTryRowRef) => {
      if (productRow.rowRef !== null) {
        productsToDelete.push(productRow);
        productRow.rowRef?.forceDeleteSelect();
      }
    });
    this.setState({ productsToDelete }, () => {
      this.props.onProductsToDeleteChange(this.state.productsToDelete.length);
    });
  };

  public unselectAllProductsForDeletion = () => {
    this.productRowRefs.forEach((productRow: IWaitAndTryRowRef) => {
      productRow.rowRef?.forceDeleteDeselect();
    });
    this.setState({ productsToDelete: [] });
    this.productHeaderRef?.forceUnselectDeleteCheckbox();
    this.props.onProductsToDeleteChange(0);
  };

  public deleteSelectedProducts = () => {
    for (const product of this.state.productsToDelete) {
      rootStore.productStore.removeFromSelectedProducts(product.productId, product.productSizeId);
    }
    this.productHeaderRef?.forceUnselectDeleteCheckbox();
    this.setState({ productsToDelete: [] });
  };

  private showDropOffScreen = () => {
    rootStore.orderStore.setDropOffOnly();
    this.servicesToggle?.forceDropOffSelection();
    this.setState({ pageSelected: ServicesToggles.DropOff, showModal: false });
    this.unselectAllProductsForDeletion();
  };

  private showWaitAndTryScreen = () => {
    this.setState({ pageSelected: ServicesToggles.WaitAndTry });
    rootStore.orderStore.unsetDropOffOnly();
  };

  private getRowRef = (
    productId: number,
    productSizeId: number,
  ): WaitAndTryProductRow | DropOffProductRow | undefined | null => {
    return this.productRowRefs.find((row) => row.productId === productId && row.productSizeId === productSizeId)
      ?.rowRef;
  };

  private toggleAllProductsForDeletion = (selected: boolean) => {
    if (selected) {
      this.selectAllProductsForDeletion();
      return;
    }
    this.unselectAllProductsForDeletion();
  };

  private toggleServiceforProduct = (
    selected: boolean,
    product: ISelectedProduct,
    sizeId: number,
    service: Service,
  ) => {
    if (selected) {
      rootStore.productStore.selectServiceForProduct(product, sizeId, service);

      this.selectServiceForUpDownSizeServices(product, service);
      return;
    }
    rootStore.productStore.deselectServiceForProduct(product, sizeId, service);
    this.deselectServiceForUpDownSizeServices(product, service);
  };

  private deselectServiceForUpDownSizeServices = (product: ISelectedProduct, service: Service) => {
    product.upDownSizeIds?.forEach((sizeId: number) => {
      const row = this.findProductRow(product.id, sizeId) as WaitAndTryProductRow;
      if (row.props.product) {
        row.props.product.services = product.services;
        this.forceDeselectProductRowForService(service, row);
      }
    });
  };

  private selectServiceForUpDownSizeServices = (product: ISelectedProduct, service: Service) => {
    product.upDownSizeIds?.forEach((sizeId: number) => {
      const row = this.findProductRow(product.id, sizeId) as WaitAndTryProductRow;
      if (row.props.product) {
        row.props.product.services = product.services;
        this.forceSelectProductRowForService(service, row);
      }
    });
  };

  private findProductRow = (
    productId: number,
    productSizeId: number,
  ): WaitAndTryProductRow | DropOffProductRow | null | undefined => {
    for (let i = 0; i < this.productRowRefs.length; i++) {
      const match =
        this.productRowRefs[i].productId === productId &&
        this.productRowRefs[i].productSizeId === productSizeId &&
        this.productRowRefs[i].rowRef !== null;

      if (match) {
        return this.productRowRefs[i].rowRef;
      }
    }
    return null;
  };

  private onSizeChange = (productId: number, sizeId: number, newSize: string, dropOff: boolean) => {
    rootStore.productStore.updateProductSize(productId, sizeId, newSize);
    rootStore.productStore.setValidationForProductRowField(productId, sizeId, newSize, ProductRowValidationField.size);
    if (dropOff) {
      rootStore.orderStore.setPackageSize(newSize);
    }
  };

  private onPriceChange = (productId: number, sizeId: number, _newPrice: number) => {
    const newPrice = parseFloat(_newPrice.toFixed(2));
    rootStore.productStore.updateSelectedProductPrice(productId, newPrice);
    rootStore.productStore.setValidationForProductRowField(
      productId,
      sizeId,
      newPrice,
      ProductRowValidationField.price,
    );
  };

  private onQuantityChange = (productId: number, sizeId: number, newQuantity: number, dropOff: boolean) => {
    rootStore.productStore.updateProductQuantity(productId, sizeId, newQuantity);
    rootStore.productStore.setValidationForProductRowField(
      productId,
      sizeId,
      newQuantity,
      ProductRowValidationField.quantity,
    );
    if (dropOff) {
      rootStore.orderStore.setPackageCount(newQuantity);
    }
  };

  private onPackageSizeChange = (packageSize: string) => {
    rootStore.orderStore.setPackageSize(packageSize);
    rootStore.orderStore.setValidationForPackage(packageSize, PackageValidationField.size);
  };

  private onPackageCountChange = (packageCount: number) => {
    rootStore.orderStore.setPackageCount(packageCount);
    rootStore.orderStore.setValidationForPackage(packageCount, PackageValidationField.count);
  };

  private toggleServiceForAllProducts = (service: Service) => {
    switch (service) {
      case Service.FittingPinning: {
        if (this.state.fittingAndPinningAllSelected) {
          this.setState({ fittingAndPinningAllSelected: false });
          rootStore.productStore.deselectServiceForAllProducts(service);
          this.forceDeselectAllProductsForService(service);
          break;
        }
        this.setState({ fittingAndPinningAllSelected: true });
        rootStore.productStore.selectServiceForAllProducts(service);
        this.forceSelectAllProductsForService(service);
        break;
      }
      case Service.InspireMe: {
        if (this.state.inspireMeAllSelected) {
          this.setState({ inspireMeAllSelected: false });
          rootStore.productStore.deselectServiceForAllProducts(service);
          this.forceDeselectAllProductsForService(service);
          break;
        }
        this.setState({ inspireMeAllSelected: true, paymentRequiredAllSelected: true });
        rootStore.productStore.selectServiceForAllProducts(service);
        rootStore.productStore.selectServiceForAllProducts(Service.PaymentRequired);
        this.forceSelectAllProductsForService(service);
        break;
      }
      case Service.PerfectFit: {
        if (this.state.perfectFitAllSelected) {
          this.setState({ perfectFitAllSelected: false });
          rootStore.productStore.deselectServiceForAllProducts(service);
          this.forceDeselectAllProductsForService(service);
          break;
        }
        this.setState({ perfectFitAllSelected: true });
        rootStore.productStore.selectServiceForAllProducts(service);
        this.forceSelectAllProductsForService(service);
        break;
      }
      case Service.PaymentRequired: {
        if (this.state.paymentRequiredAllSelected) {
          this.setState({ paymentRequiredAllSelected: false });
          rootStore.productStore.deselectServiceForAllProducts(service);
          this.forceDeselectAllProductsForService(service);
          break;
        }
        this.setState({ paymentRequiredAllSelected: true });
        rootStore.productStore.selectServiceForAllProducts(service);
        this.forceSelectAllProductsForService(service);
        break;
      }
      default:
        break;
    }
  };

  private forceSelectProductRowForService = (service: Service, row: WaitAndTryProductRow) => {
    switch (service) {
      case Service.FittingPinning:
        row.forceFittingAndPinningSelect();
        break;
      case Service.InspireMe:
        row.forceInspireMeSelect();
        break;
      case Service.PerfectFit:
        row.forcePerfectFitSelect();
        break;
      case Service.PaymentRequired:
        row.forcePaymentRequiredSelect();
        break;
      default:
        break;
    }
  };

  private forceDeselectProductRowForService = (service: Service, row: WaitAndTryProductRow) => {
    switch (service) {
      case Service.FittingPinning:
        row.forceFittingAndPinningDeselect();
        break;
      case Service.InspireMe:
        row.forceInspireMeDeselect();
        row.forcePaymentRequiredDeselect();
        break;
      case Service.PerfectFit:
        row.forcePerfectFitDeselect();
        break;
      case Service.PaymentRequired:
        row.forcePaymentRequiredDeselect();
        break;
      default:
        break;
    }
  };

  private forceSelectAllProductsForService = (service: Service) => {
    for (const product of this.productRowRefs) {
      const ref: WaitAndTryProductRow | null = product.rowRef as WaitAndTryProductRow | null;
      if (ref) {
        this.forceSelectProductRowForService(service, ref);
      }
    }
  };

  private forceDeselectAllProductsForService = (service: Service) => {
    for (const product of this.productRowRefs) {
      const ref: WaitAndTryProductRow | null = product.rowRef as WaitAndTryProductRow | null;
      if (ref) {
        this.forceDeselectProductRowForService(service, ref);
      }
    }
  };

  private renderModal = () => {
    return this.state.showModal ? (
      <Modal backgroundClicked={() => this.setState({ showModal: false })}>
        <DropOffWarningModalContent
          cancelClicked={() => this.setState({ showModal: false })}
          dropOffClicked={() => this.showDropOffScreen()}
        />
      </Modal>
    ) : undefined;
  };

  public render(): JSX.Element {
    return (
      <div>
        {this.renderModal()}
        <Container
          width='calc(100% - 40px)'
          additionalStyles={`
            max-width: 730px;
            margin-left: 20px;
            margin: auto;
            margin-bottom: 150px;
            padding-top: 26px;
            padding-bottom: 36px;
            position: relative;
            text-align: center;
          `}
        >
          {this.renderContent()}
        </Container>
      </div>
    );
  }
}

export { AddServices };
