import { css } from '@emotion/react';
import { differenceInSeconds, format } from 'date-fns';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from 'react-spinner-material';
import { useInterval } from 'usehooks-ts';

import clock from '@isi/assets/icons/clock.svg';
import giftIconLarge from '@isi/assets/icons/gift-large-icon.svg';
import pencil from '@isi/assets/icons/pencil.svg';
import person from '@isi/assets/icons/person.svg';
import trash from '@isi/assets/icons/trash.svg';
import { transformDateWithStore } from '@isi/helpers/dateLocalisation';
import { rootStore } from '@isi/stores/root.store';

import useStoreContext from '@isi/hooks/useStoreContext';

import { H4 } from '@isi/components/common/h4.component';
import { H5 } from '@isi/components/common/h5.component';
import { GiftingConsumer } from '@isi/components/common/orderGiftingContext';
import { ContentType } from '@isi/components/create_order/ContentType.enum';

interface IClientDateTimeCompleteProps {
  contentType: ContentType;
  dashboard: boolean;
}

const OrderTimeOut = observer(() => {
  const {
    dashboardStore: { getOrderTimeoutTimeStamp, setOrderTimeoutTimeStamp },
    orderStore: { clearDeliverySlot },
  } = useStoreContext();

  const [formattedTimeRemaining, setFormattedTimeRemaining] = useState<string>();
  const [timedOut, setTimedOut] = useState<boolean>();

  useEffect(() => {
    if (timedOut) {
      setOrderTimeoutTimeStamp();
      clearDeliverySlot();
    }
  }, [clearDeliverySlot, setOrderTimeoutTimeStamp, timedOut]);

  useInterval(
    () => {
      if (getOrderTimeoutTimeStamp) {
        const now = new Date();

        const difference = differenceInSeconds(getOrderTimeoutTimeStamp, now);

        const minutes = Math.floor(difference / 60);
        const seconds = difference % 60;

        const formattedTime = format(new Date().setMinutes(minutes, seconds), 'mm:ss');

        setFormattedTimeRemaining(formattedTime);

        if (difference <= 0) {
          setTimedOut(true);
        }
      }
    },
    timedOut ? null : 1000,
  );

  return (
    <div
      css={css`
        margin-top: 10px;
        color: #800000;
      `}
    >
      {formattedTimeRemaining ? (
        <>
          Time left to place order <b>{formattedTimeRemaining}</b>
        </>
      ) : (
        'Calculating...'
      )}
    </div>
  );
});

@observer
export default class ClientDateTimeComplete extends Component<IClientDateTimeCompleteProps, {}> {
  constructor(props: IClientDateTimeCompleteProps) {
    super(props);
    rootStore.dashboardStore.clearCurrentDeliverySlot();

    if (props.dashboard) rootStore.dashboardStore.loadjourneyFromCurrentOrder();
  }

  public mainBoxImage = (contentType: ContentType): any => {
    if (contentType === ContentType.gifting) {
      return giftIconLarge;
    }

    if (contentType === ContentType.customer) {
      return person;
    }

    return clock;
  };

  public renderCompleteClientDateTimeContent = (type: ContentType): JSX.Element => {
    const customerMode = type === ContentType.customer || type === ContentType.gifting;
    const mainImage = this.mainBoxImage(type);

    return (
      <div
        css={css`
          padding: 25px 20px;
        `}
      >
        <div
          css={css`
            position: relative;
            margin-bottom: 10px;
          `}
        >
          {this.renderImg(mainImage)}
          <H4
            bold
            additionalStyles={`
              display: inline;
              ${type === ContentType.gifting ? 'margin-left: 30px;' : 'margin-left: 38px;'}
              vertical-align: super;
            `}
          >
            <GiftingConsumer>
              {({ isGiftingOrder }) => {
                if (type === ContentType.gifting) {
                  return 'Recipient';
                }
                if (type === ContentType.customer) {
                  if (isGiftingOrder) {
                    return 'Sender';
                  }
                  return 'Client';
                }
                return 'Date & Time';
              }}
            </GiftingConsumer>
          </H4>
          {this.props.dashboard ? null : this.renderLinks()}
        </div>
        <div
          css={css`
            font-family: MarkOT;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            ${customerMode ? 'margin-left: 59px;' : 'margin-left: 71px;'}
          `}
        >
          {this.renderContentText(type)}
        </div>
      </div>
    );
  };

  formatZone = (input: string) => {
    const zone = input.replace(/_/g, ' ');

    return ` - ${zone.slice(3)}`;
  };

  private renderCustomerContentText = (type: ContentType): JSX.Element | undefined => {
    const customer = rootStore.customerStore.customerValue;
    const address = rootStore.customerStore.addressValue;
    const order = rootStore.dashboardStore.getCurrentOrder;

    const isCompletedGiftedOrder = this.props.dashboard && rootStore.dashboardStore.getCurrentOrder?.giftedOrder;
    if (customer) {
      return type === ContentType.dateTime ? (
        rootStore.orderStore.getUnscheduledOrder ? (
          this.displayUnscheduledOrder()
        ) : (
          this.displayDeliverySlot()
        )
      ) : (
        <>
          <div className='fullstorymask'>
            {customer.title} {customer.firstName} {customer.lastName}
          </div>
          <div className='fullstorymask'>{customer.email}</div>
          {customer.contactNumber && <div className='fullstorymask'>{customer.contactNumber}</div>}
          <GiftingConsumer>
            {({ isGiftingOrder }) => {
              if (isGiftingOrder || isCompletedGiftedOrder) {
                return null;
              }

              return (
                <div>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <H5 bold additionalStyles='margin-bottom: 0; margin-top: 5px;'>
                      Address
                      {order?.endAddressZone ? (
                        <span
                          css={css`
                            text-transform: capitalize;
                          `}
                        >
                          {this.formatZone(order.endAddressZone)}
                        </span>
                      ) : null}
                    </H5>
                  </div>
                  {address?.houseName && <div className='fullstorymask'>{address?.houseName}</div>}
                  <div className='fullstorymask'>{address?.line1}</div>
                  <div className='fullstorymask'>{address?.line2}</div>
                  <div className='fullstorymask'>{address?.cityTown}</div>
                  <div className='fullstorymask'>{address?.postcodeZipcode}</div>
                </div>
              );
            }}
          </GiftingConsumer>
        </>
      );
    }
    return undefined;
  };

  private renderGifteeContentText = (): JSX.Element | undefined => {
    const giftee = rootStore.customerStore.gifteeValue;

    return giftee ? (
      <>
        <div className='fullstorymask'>
          {giftee.title} {giftee.firstName} {giftee.lastName}
        </div>
        <div className='fullstorymask'>{giftee.email}</div>
        {giftee.contactNumber && <div className='fullstorymask'>{giftee.contactNumber}</div>}
        {rootStore.orderStore.getGiftingNote && (
          <div
            css={css`
              width: 100%;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            <span
              css={css`
                font-family: MarkOT-Bold, helvetica, arial, sans-serif;
                margin-right: 5px;
              `}
            >
              <span
                className='fullstorymask'
                css={css`
                  font-family: MarkOT-Bold, helvetica, arial, sans-serif;
                  margin-right: 5px;
                `}
              >
                Gifting note:
              </span>
              <div className='fullstorymask'>{rootStore.orderStore.getGiftingNote}</div>
            </span>
          </div>
        )}
      </>
    ) : undefined;
  };

  public renderContentText = (type: ContentType): JSX.Element | undefined => {
    const isGiftingRecipient = this.props.contentType === ContentType.gifting;

    if (isGiftingRecipient) {
      return this.renderGifteeContentText();
    }
    return this.renderCustomerContentText(type);
  };

  public renderImg = (img: any, height?: number, additionalStyles?: string): JSX.Element => (
    <img
      src={img.toString()}
      css={css`
        height: ${height || '33'}px;
        display: inline;
        ${additionalStyles}
      `}
    />
  );

  private displayUnscheduledOrder(): JSX.Element {
    return (
      <div
        css={css`
          max-width: 320px;
        `}
      >
        Customer will select their desired delivery slot at a later date via the TOSHI Booker.
      </div>
    );
  }

  private displayDeliverySlot(): JSX.Element | undefined {
    let startDatetime: Date | string | undefined;
    const order = rootStore.dashboardStore.getCurrentOrder;
    if (this.props.dashboard) {
      if (rootStore.dashboardStore.getCurrentJourney !== undefined) {
        startDatetime = transformDateWithStore(
          rootStore.dashboardStore.getCurrentJourney.startDatetime,
          rootStore.storeStore.getStoreConfig?.utcOffsetSeconds,
        );
      }
    } else if (rootStore.orderStore.orderValue.scheduledDate) {
      // We don't transform this because it's just a date and a timeslot, not a datetime that's
      // come from the server
      startDatetime = new Date(rootStore.orderStore.orderValue.scheduledDate).toUTCString();
    }
    if (startDatetime) {
      return (
        <>
          <div>{moment.utc(startDatetime).format('dddd, Do')}</div>
          <div>{moment.utc(startDatetime).format('MMMM YYYY')}</div>
          <div>{this.displayDeliverySlotTime()}</div>
          {order ? '' : <OrderTimeOut />}
        </>
      );
    }

    return undefined;
  }

  private displayDeliverySlotTime(): string | undefined | JSX.Element {
    if (this.props.dashboard) {
      const formattedSlot = rootStore.dashboardStore.currentOrderDeliverySlotString;
      if (formattedSlot === '') return <Spinner radius={20} color='#202020' stroke={2} visible />;
      return `Between ${formattedSlot}`;
    }
    return `Between ${rootStore.orderStore.getSelectedDeliverySlotOption?.display}`;
  }

  renderLinks = (): JSX.Element => {
    if (this.props.contentType === ContentType.dateTime) {
      return this.renderDateTimeLink();
    }
    return this.renderCustomerLinks();
  };

  renderDateTimeLink = (): JSX.Element => (
    <Link
      to='/date_time'
      css={css`
        padding: 0;
        display: inline;
        position: absolute;
        right: 0;
        top: 20px;
      `}
    >
      {this.renderImg(pencil, 20)}
    </Link>
  );

  renderCustomerLinks = (): JSX.Element => {
    const isGiftingRecipient = this.props.contentType === ContentType.gifting;
    const customerId = isGiftingRecipient
      ? rootStore.customerStore.gifteeValue?.id
      : rootStore.customerStore.customerValue?.id;
    const clearInfo = isGiftingRecipient
      ? () => rootStore.customerStore.clearGifteeInfo()
      : () => rootStore.customerStore.clearCustomerInfo();
    const editLinkAriaLabel = isGiftingRecipient ? 'Edit recipient information' : 'Edit date and time information';

    return (
      <div>
        <GiftingConsumer>
          {({ isGiftingOrder }) => (
            <Link
              aria-label={editLinkAriaLabel}
              to={
                isGiftingRecipient
                  ? `/customers/gifting/${customerId}/edit`
                  : `/customers/${customerId}/edit?isGiftingOrder=${isGiftingOrder}`
              }
              css={css`
                padding: 0;
                display: inline;
                position: absolute;
                right: 0;
                top: 20px;
              `}
            >
              {this.renderImg(pencil, 20)}
            </Link>
          )}
        </GiftingConsumer>
        <button
          onClick={clearInfo}
          css={css`
            border: none;
            background-color: transparent;
            position: absolute;
            right: -7px;
            top: 70px;
            &:hover {
              cursor: pointer;
            }
          `}
          type='button'
        >
          {this.renderImg(trash, 20)}
        </button>
      </div>
    );
  };

  public render(): JSX.Element {
    return this.renderCompleteClientDateTimeContent(this.props.contentType);
  }
}
export { ClientDateTimeComplete, IClientDateTimeCompleteProps };
