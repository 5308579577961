import axios from 'axios';

import { apiUrl } from '@isi/config/Url';

export interface IGetFileStoreUrlResponse {
  data: {
    url: string;
    fileName: string;
  };
  status: number;
}

export interface IGetFileStoreUrlResponseSnakeCase {
  data: {
    url: string;
    fileName: string;
  };
  status: number;
}

export const getFileStoreUrl = async (): Promise<IGetFileStoreUrlResponseSnakeCase> => {
  const url = `${apiUrl()}/v2/aws/csv_filestore_url`;
  return axios.get(url);
};
