import { css } from '@emotion/react';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import { Component } from 'react';
import { type RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import giftLargeIcon from '@isi/assets/icons/gift-large-icon.svg';
import { ContactPreferences } from '@isi/enums/contact_preferences.enum';
import { type FieldTypePreservingOmit } from '@isi/helpers/fieldPreservingOmit';
import { type ICustomerFieldsTouched, type ICustomerFormState } from '@isi/interfaces/customer-form-state.interface';
import { type Giftee, type ICustomer } from '@isi/interfaces/customer.interface';
import { type INewCustomerErrors } from '@isi/interfaces/new-customer-errors.interface';
import { type SearchPrediction } from '@isi/network/addresses/address-autocomplete-options.function';
import { type INewAddressData, type INewCustomerData } from '@isi/network/customers/create-customer.function';
import {
  getCustomer,
  type GetRecipientResponse,
  type IGetCustomerResponse,
} from '@isi/network/customers/get-customer.function';
import { mapKeysToCamel } from '@isi/network/helpers/params/map-keys-to-camel.function';
import { rootStore } from '@isi/stores/root.store';

import AddressEntryInput from '@isi/components/common/address-entry-input.component';
import { Button, ButtonSize } from '@isi/components/common/button.component';
import { Checkbox } from '@isi/components/common/checkbox.component';
import { Container } from '@isi/components/common/container.component';
import { Dropdown } from '@isi/components/common/dropdown.component';
import { H1 } from '@isi/components/common/h1.component';
import { H3 } from '@isi/components/common/h3.component';
import { H4 } from '@isi/components/common/h4.component';
import { Input } from '@isi/components/common/input.component';
import { Label } from '@isi/components/common/label.component';
import { PhoneInput } from '@isi/components/common/phone-input.component';
import { type ISelectValue, Select } from '@isi/components/common/select.component';
import SpinnerWrapper from '@isi/components/common/SpinnerWrapper.component';
import { TextArea } from '@isi/components/common/textarea.component';
import { blankState } from '@isi/components/customers/blank-slate.function';

interface ICustomerProps extends RouteComponentProps<ICustomerFormRouteParams> {
  edit?: boolean;
}

interface ICustomerFormRouteParams {
  customer_id: string;
}

type CustomerFormState = {
  customerId?: string | null;
  customer: INewCustomerData;
  // We want to use most of INewAddressData, but we don't want country in state.
  // The regular Omit from typescript doesnt preserve property types when the type has an index signature ([key: foo]: bar)
  // so we make our own one. See https://github.com/microsoft/TypeScript/issues/49656
  address: FieldTypePreservingOmit<INewAddressData, 'country'>;
  touched: ICustomerFieldsTouched;
  postcodeEligible: boolean;
  displayPostcodeEligibilityError: boolean;
  displayAllErrors: boolean;
  phoneNumberFocused: boolean;
  manualAddressEntry: boolean;
  loading: boolean;
  errors: INewCustomerErrors;
  giftingNote?: string;
  isGiftRecipient: boolean;
  clientAwareOfServiceCoverage?: boolean;
};

@observer
class CustomerForm extends Component<ICustomerProps, CustomerFormState> {
  private isGiftRecipient = this.props.history.location?.pathname?.includes('/gifting');

  private contactPreferenceValues = [
    {
      value: ContactPreferences.SMS,
      display: 'SMS',
    },
    {
      value: ContactPreferences.EMAIL,
      display: 'Email',
    },
    {
      value: ContactPreferences.BOTH,
      display: 'Email & SMS',
    },
  ];

  private debouncedHandleCheck = debounce(
    async (postcodeValue: string) => {
      if (postcodeValue.trim() === '') {
        return;
      }
      const postcodeEligible = await rootStore.customerStore.checkPostcodeEligibility(postcodeValue);
      this.setState({
        postcodeEligible,
        displayPostcodeEligibilityError: !postcodeEligible,
      });
    },
    500,
    { leading: false, trailing: true },
  );

  private titleSelectValues: ISelectValue[] = [
    {
      value: 'Mr.',
      display: 'Mr.',
      disabled: false,
    },
    {
      value: 'Mrs.',
      display: 'Mrs.',
      disabled: false,
    },
    {
      value: 'Ms.',
      display: 'Ms.',
      disabled: false,
    },
    {
      value: 'Miss.',
      display: 'Miss.',
      disabled: false,
    },
    {
      value: 'Mx.',
      display: 'Mx.',
      disabled: false,
    },
    {
      value: 'Dr.',
      display: 'Dr.',
      disabled: false,
    },
  ];

  private validEmailRegex: RegExp =
    /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

  constructor(props: ICustomerProps) {
    super(props);

    const loading = this.props.edit;
    this.state = {
      ...this.generateBlankState(loading),
      isGiftRecipient: this.isGiftRecipient,
      clientAwareOfServiceCoverage: this.isGiftRecipient ? false : undefined,
    };
  }

  componentDidMount() {
    this.setupCustomer().then(() => {
      this.validate();
    });
  }

  componentDidUpdate(prevProps: ICustomerProps, prevState: ICustomerFormState) {
    if (
      JSON.stringify(prevState.address) !== JSON.stringify(this.state.address) ||
      JSON.stringify(prevState.customer) !== JSON.stringify(this.state.customer) ||
      JSON.stringify(prevState.clientAwareOfServiceCoverage) !== JSON.stringify(this.state.clientAwareOfServiceCoverage)
    ) {
      this.validate();
    }
    if (prevProps.history.location?.pathname !== this.props.history.location?.pathname) {
      this.setState({
        isGiftRecipient: this.props.history.location?.pathname?.includes('/gifting'),
      });
    }
  }

  private async handleSubmit() {
    if (this.errorsPresent()) {
      this.setState({ displayAllErrors: true });
      return;
    }

    // The TOSHI API Customer Resolution service decides whether to create a new recipient or update
    // an existing one
    let recipientCreated: {
      success: boolean;
      error?: string;
    };

    if (this.orderIsGifting() || this.state.isGiftRecipient) {
      if (this.state.giftingNote) {
        rootStore.orderStore.setGiftingNote(this.state.giftingNote);
      }
      recipientCreated = await rootStore.customerStore.createGiftingCustomer(
        this.state.customer,
        this.state.isGiftRecipient,
      );
    } else {
      const country = rootStore.storeStore.getStoreConfig?.storeCountry ?? '';
      const address: INewAddressData = {
        ...this.state.address,
        country,
      };

      recipientCreated = await rootStore.customerStore.createCustomer(this.state.customer, address);
    }

    if (recipientCreated.success) {
      this.props.history.push('/');
    } else if (recipientCreated.error) {
      this.setState((prev) => ({
        ...prev,
        errors: {
          ...prev.errors,
          contactNumber: String(recipientCreated.error),
        },
      }));
    }
  }

  private handleGiftingNoteChange(value: string): void {
    this.setState(
      (prev) => ({
        ...prev,
        giftingNote: value,
        touched: {
          ...prev.touched,
          giftingNote: true,
        },
      }),
      this.validate,
    );
  }

  private handleCustomerNumberChange(value: string, country: string): void {
    this.setState((prev) => ({
      ...prev,
      customer: {
        ...prev.customer,
        contactNumber: value,
        contactNumberCountry: country,
      },
      touched: { ...prev.touched, contactNumber: true },
    }));
    this.validate();
  }

  private handleCustomerAwareServiceCoverageChange(value: boolean): void {
    this.setState((prev) => ({
      ...prev,
      clientAwareOfServiceCoverage: value,
      touched: {
        ...prev.touched,
        clientAwareOfServiceCoverage: true,
      },
    }));
  }

  private async setupCustomer() {
    if (this.props.edit || rootStore.customerStore.customerAddressValue?.id === 0) {
      const customerId = rootStore.customerStore.customerValue?.id || this.props.match.params.customer_id;
      const gifteeId = rootStore.customerStore.gifteeValue?.id || '';
      if (this.state.isGiftRecipient && rootStore.customerStore.gifteeValue) {
        await this.loadGiftee(gifteeId);
      } else if (!this.state.isGiftRecipient) {
        await this.loadCustomer(customerId);
      }
    }
  }

  private handleCustomerChange = (key: string, value: string | number | boolean): void => {
    this.setState(
      (prev) => ({
        ...prev,
        customer: {
          ...prev.customer,
          [key]: value,
        },
        touched: { ...prev.touched, [key]: true },
      }),
      this.validate,
    );
  };

  public setAddressFromSearchPrediction = (prediction: SearchPrediction): void => {
    const address = prediction.address_components;
    this.setState((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        houseName: '',
        line1: address.address_line_1 || '',
        line2: address.address_line_2 || '',
        cityTown: address.town || '',
        postcodeZipcode: address.postcode || '',
      },
      touched: {
        ...prev.touched,
        line1: true,
        line2: true,
        cityTown: true,
        postcodeZipcode: true,
      },
    }));

    this.toggleManualAddressEntry();
    this.debouncedHandleCheck(address.postcode || '');
  };

  private setStateFromGiftee({
    title,
    firstName,
    lastName,
    email,
    guest,
    contactNumber,
    contactNumberCountry,
    contactPreferences,
  }: GetRecipientResponse): void {
    this.setState({
      customer: {
        title: title || '',
        firstName,
        lastName,
        email,
        guest,
        contactNumber,
        contactNumberCountry,
        contactPreferences,
      },
      loading: false,
    });
  }

  private setStateFromCustomer(customer: IGetCustomerResponse): void {
    const {
      lastName,
      title,
      firstName,
      email,
      guest,
      contactNumber,
      contactNumberCountry,
      contactPreferences,
      lastAddress: { houseName, line1, line2, cityTown, postcodeZipcode },
    } = customer;

    this.setState({
      customer: {
        title: title || '',
        firstName,
        lastName,
        email,
        guest,
        contactNumber,
        contactNumberCountry,
        contactPreferences,
      },
      address: {
        houseName: houseName || '',
        line1,
        line2: line2 || '',
        cityTown: cityTown || '',
        postcodeZipcode,
      },
      touched: {
        title: true,
        firstName: true,
        lastName: true,
        email: true,
        contactNumber: true,
        houseName: true,
        line1: true,
        line2: true,
        cityTown: true,
        postcodeZipcode: true,
        contactPreferences: true,
      },
      postcodeEligible: false,
      displayPostcodeEligibilityError: false,
      displayAllErrors: false,
      phoneNumberFocused: false,
      manualAddressEntry: true,
      loading: false,
    });
  }

  toggleManualAddressEntry = () => {
    this.setState((prev) => ({ ...prev, manualAddressEntry: !prev.manualAddressEntry }));
  };

  shouldDisplayError = (field: string) => {
    const hasError = this.state.errors[field];
    const shouldShow = this.state.touched[field] || this.state.displayAllErrors;
    return hasError && shouldShow ? this.state.errors[field] : '';
  };

  public handleCustomerContactPreferences = (preference: number) =>
    this.handleCustomerChange('contactPreferences', preference);

  private handleAddressChange = (key: string, value: string): void => {
    this.setState(
      (prev) => ({
        address: {
          ...prev.address,
          [key]: value,
        },
        touched: {
          ...prev.touched,
          [key]: true,
        },
      }),
      this.validate,
    );
  };

  private loadGiftee = async (id: string | number) => {
    const giftee = rootStore.customerStore.gifteeValue;

    if (giftee) {
      this.setState({
        ...this.loadRecipientBasicData(giftee),
        giftingNote: rootStore.orderStore.getGiftingNote,
      });
    } else if (id) {
      await getCustomer(id)
        .then((gifteeRes) => {
          const giftee = mapKeysToCamel(gifteeRes.data) as GetRecipientResponse;
          this.setStateFromGiftee(giftee);
        })
        .catch(() => {
          this.props.history.push('/');
        });
    }
  };

  private loadCustomer = async (id: string | number) => {
    const customer = rootStore.customerStore.customerValue;
    const address = rootStore.customerStore.customerAddressValue;

    const customerIdMatchesURL = customer?.id.toString() === this.props.match.params.customer_id;

    if (address && customerIdMatchesURL) {
      this.setState({
        address: {
          houseName: address.houseName || '',
          line1: address.line1,
          line2: address.line2 || '',
          cityTown: address.cityTown || '',
          postcodeZipcode: address.postcodeZipcode.toString(),
        },
        loading: false,
      });
      if (address.id === 0) {
        this.toggleManualAddressEntry();
      }
    }
    if (customer && customerIdMatchesURL) {
      this.setState({
        ...this.loadRecipientBasicData(customer),
        loading: false,
      });
    } else if (id) {
      await getCustomer(id)
        .then((customerRes) => {
          const customer = mapKeysToCamel(customerRes.data) as IGetCustomerResponse;
          this.setStateFromCustomer(customer);
        })
        .catch(() => {
          this.props.history.push('/');
        });
    }
  };

  private loadRecipientBasicData = (
    recipient: ICustomer | Giftee,
  ): { customerId: string; customer: INewCustomerData; loading: boolean } => ({
    customerId: recipient.id,
    customer: {
      title: recipient.title || '',
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      email: recipient.email,
      guest: recipient.guest,
      contactNumber: recipient.contactNumber,
      contactNumberCountry: recipient.contactNumberCountry || 'GB',
      contactPreferences: recipient.contactPreferences,
    },
    loading: false,
  });

  private generateBlankState = (loading?: boolean): ICustomerFormState => blankState(loading);

  private handlePostcodeEligibilityCheck = async (postcodeValue: string) => {
    this.setState((prev) => ({
      ...prev,
      touched: { ...prev.touched, postcodeZipcode: true },
      postcodeEligible: false,
      displayPostcodeEligibilityError: false,
      address: { ...prev.address, postcodeZipcode: postcodeValue },
    }));
    await this.debouncedHandleCheck(postcodeValue);
  };

  private validate = (): INewCustomerErrors => {
    const orderIsGifting = this.orderIsGifting();
    const skipAddressValidation = this.isGiftRecipient || orderIsGifting;
    const errors = {
      title: this.state.customer.title.length === 0 ? 'Please select a title.' : '',
      firstName: this.state.customer.firstName.length === 0 ? 'Please enter a name.' : '',
      lastName: this.state.customer.lastName.length === 0 ? 'Please enter a last name.' : '',
      email: this.validateEmail(),
      contactNumber: this.validateContactNumber(),
      contactNumberCountry:
        this.state.customer.contactNumberCountry?.length <= 0
          ? 'Please select a country for the given phone number'
          : '',
      line1: !skipAddressValidation && this.state.address.line1.length === 0 ? 'Please enter an address.' : '',
      cityTown: !skipAddressValidation && this.state.address.cityTown.length === 0 ? 'Please enter a city.' : '',
      postcodeZipcode: this.validatePostcode(skipAddressValidation),
      clientAwareOfServiceCoverage:
        this.isGiftRecipient && !this.state.clientAwareOfServiceCoverage
          ? 'Please confirm TOSHI service coverage.'
          : '',
    };
    if (JSON.stringify(errors) !== JSON.stringify(this.state.errors)) {
      this.setState({ errors });
    }

    return errors;
  };

  private validatePostcode(skipAddressValidation: boolean): string {
    if (skipAddressValidation) {
      return '';
    }
    if (this.state.address.postcodeZipcode.length === 0) {
      return 'Please enter a postcode/zipcode.';
    }
    if (this.state.displayPostcodeEligibilityError) {
      return 'Postcode/Zipcode not eligible.';
    }
    return '';
  }

  private errorsPresent(): boolean {
    const errorsPresent = Object.keys(this.state.errors).some((x) => this.state.errors[x]);
    return errorsPresent;
  }

  private validateEmail(): string {
    if (this.state.customer.email.length === 0) {
      return 'Please enter an email.';
    }
    if (!this.validEmailRegex.test(this.state.customer.email)) {
      return 'Please enter a valid email.';
    }
    return '';
  }

  private validateContactNumber(): string {
    if (this.state.customer.contactNumber.length <= 5) {
      return 'Please enter a mobile number.';
    }
    return '';
  }

  private orderIsGifting(): boolean {
    return new URLSearchParams(this.props.history.location.search).get('isGiftingOrder') === 'true';
  }

  public render() {
    const { loading, customer, giftingNote, clientAwareOfServiceCoverage, errors, manualAddressEntry, address } =
      this.state;

    if (loading) return <SpinnerWrapper />;

    const title = this.isGiftRecipient ? 'GIFTING DETAILS' : 'CLIENT INFORMATION';
    const orderIsGifting = this.orderIsGifting();

    return (
      <div>
        <Container width='320px' additionalStyles='margin-top:73px;'>
          {this.isGiftRecipient && (
            <H1
              bold
              additionalStyles={`
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
            >
              <img
                css={css`
                  height: 1.5rem;
                  margin-right: 5px;
                `}
                src={giftLargeIcon}
                alt='Large gift icon'
              />
              Gift recipient
            </H1>
          )}
          <H1 additionalStyles='margin-bottom: 40px;'>{title}</H1>
          {!this.isGiftRecipient && (
            <H4 bold additionalStyles='letter-spacing: 1.3px; margin-bottom: 15px;'>
              ABOUT CLIENT
            </H4>
          )}
          {this.isGiftRecipient && (
            <div
              css={css`
                font-size: 14px;
                margin-bottom: 40px;
              `}
            >
              <strong>When the order is placed</strong>, the recipient will be sent an email and SMS with a gift
              message, asking them to schedule their delivery at a time and location that suits them.
            </div>
          )}
          <div
            css={css`
              margin-bottom: 10px;
              width: 203px;
            `}
          >
            <Select
              defaultValue={{
                value: customer.title,
                display: customer.title,
                disabled: false,
              }}
              id='selectTitle'
              label='Title*'
              values={this.titleSelectValues}
              disabled={false}
              placeholder='Please select a title'
              onChange={(value: string | number) => this.handleCustomerChange('title', value as string)}
              error={this.shouldDisplayError('title')}
            />
          </div>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <Input
              id='inputFirstName'
              labelText='First name*'
              value={customer.firstName}
              onClick={() => null}
              onChange={(value: string) => this.handleCustomerChange('firstName', value)}
              disabled={false}
              type='text'
              error={this.shouldDisplayError('firstName')}
              clearState={() => this.handleCustomerChange('firstName', '')}
            />
          </div>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <Input
              id='inputLastName'
              labelText='Last name*'
              value={customer.lastName}
              onClick={() => null}
              onChange={(value: string) => this.handleCustomerChange('lastName', value)}
              disabled={false}
              type='text'
              error={this.shouldDisplayError('lastName')}
              clearState={() => this.handleCustomerChange('lastName', '')}
            />
          </div>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <Input
              id='inputEmail'
              labelText='Email address*'
              value={customer.email}
              onClick={() => null}
              onChange={(value: string) => this.handleCustomerChange('email', value)}
              disabled={false}
              type='email'
              error={this.shouldDisplayError('email')}
              clearState={() => this.handleCustomerChange('email', '')}
            />
            {!this.isGiftRecipient && (
              <Checkbox
                onClick={(value) => this.handleCustomerChange('guest', !value)}
                checked={!customer.guest}
                additionalStyles='margin-top: 5px;'
              >
                {"Is this the client's email address?"}
              </Checkbox>
            )}
          </div>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <PhoneInput
              value={customer.contactNumber}
              labelText='Mobile number*'
              country={customer.contactNumberCountry || rootStore.storeStore.getStoreConfig?.storeCountryCode}
              preferredCountries={['gb', 'us']}
              onChange={(value: string, country: string) => {
                this.handleCustomerNumberChange(value, country);
              }}
              error={this.shouldDisplayError('contactNumber')}
            />
          </div>
          {!this.isGiftRecipient && (
            <>
              <Label text='Contact Preferences' />
              <Dropdown
                values={this.contactPreferenceValues}
                onChange={(value) => this.handleCustomerContactPreferences(Number(value))}
                selectedValue={String(customer.contactPreferences)}
                error={false}
              />
            </>
          )}
          {this.isGiftRecipient && (
            <>
              <div
                css={css`
                  margin-bottom: 10px;
                `}
              >
                <TextArea
                  id='gift-message'
                  labelText='Gift message (optional)'
                  placeholderText='e.g. Wishing you a very happy birthday'
                  value={giftingNote}
                  onChange={(val) => this.handleGiftingNoteChange(val)}
                  disabled={false}
                />
              </div>
              <div
                css={css`
                  font-size: 14px;
                  margin-top: 50px;
                  margin-bottom: 30px;
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                `}
              >
                <H3
                  bold
                  additionalStyles={`
                  text-transform: uppercase;
                  margin: 0;
                `}
                >
                  Recipient address
                </H3>
                <div>
                  Please confirm that the recipient&apos;s address is within
                  <strong
                    css={css`
                      font-weight: bold;
                      font-family: 'MarkOT-Bold', helvetica, arial, sans-serif;
                    `}
                  >
                    {' '}
                    London zones 1-6
                  </strong>
                </div>
                <div>
                  <Checkbox
                    id='clientAwareOfServiceCoverage'
                    checked={clientAwareOfServiceCoverage}
                    disabled={false}
                    onClick={() => {
                      this.handleCustomerAwareServiceCoverageChange(!clientAwareOfServiceCoverage);
                    }}
                  >
                    {"Recipient is aware of TOSHI's coverage area"}
                  </Checkbox>
                  {this.shouldDisplayError('clientAwareOfServiceCoverage') && (
                    <div
                      css={css`
                        color: #d20303;
                        margin-top: 10px;
                        font-size: 13px;
                        height: 13px;
                      `}
                    >
                      {errors.clientAwareOfServiceCoverage}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {!this.isGiftRecipient && !orderIsGifting && (
            <>
              <H4 bold additionalStyles='letter-spacing: 1.3px; margin-top: 27px; margin-bottom: 23px;'>
                CLIENT ADDRESS
              </H4>
              <AddressEntryInput
                manualAddressEntry={manualAddressEntry}
                handleAddressChange={this.handleAddressChange}
                handlePostcodeEligibilityCheck={this.handlePostcodeEligibilityCheck}
                setAddressFromSearchPrediction={this.setAddressFromSearchPrediction}
                shouldDisplayError={this.shouldDisplayError}
                toggleManualAddressEntry={this.toggleManualAddressEntry}
                address={address}
                isGiftRecipient={this.isGiftRecipient}
              />
            </>
          )}
        </Container>
        <Container width='100%' additionalStyles='height: 260px; margin-top: 28px;'>
          <div
            css={css`
              width: 320px;
              margin: 0 auto;
            `}
          >
            <Button
              id='submitForm'
              buttonSize={ButtonSize.large}
              onClick={() => {
                this.handleSubmit();
              }}
              disabled={false}
              additionalStyles='width: 320px;'
            >
              COMPLETE
            </Button>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(CustomerForm);
