import { createContext } from 'react';

export const GiftingContext = createContext({
  isGiftingOrder: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setIsGifting: (_isGifting: boolean) => {},
});

export const GiftingProvider = GiftingContext.Provider;
export const GiftingConsumer = GiftingContext.Consumer;
