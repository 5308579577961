import { css } from '@emotion/react';
import { Component, type ReactNode } from 'react';

import { rootStore } from '@isi/stores/root.store';

export interface IModalProps {
  children: ReactNode;
  backgroundClicked: () => void;
}

export default class Modal extends Component<IModalProps, {}> {
  public componentDidMount() {
    rootStore.uiStore.stopPageScroll();
  }

  public componentWillUnmount() {
    rootStore.uiStore.startPageScroll();
  }

  public backgroundClicked = (event: any) => {
    if (this.props.backgroundClicked && event.target.id === 'TOSHI-cancel-order-modal') {
      this.props.backgroundClicked();
    }
  };

  public render() {
    return (
      <div
        id='TOSHI-cancel-order-modal'
        onClick={(e) => this.backgroundClicked(e)}
        css={css`
          width: 100vw;
          height: 100vh;
          position: fixed;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          left: 0;
          z-index: 101;
          background-color: rgba(0, 0, 0, 0.6);
        `}
      >
        <div
          css={css`
            padding: 20px;
            position: relative;
            max-height: calc(100% - 40px);
            min-height: 315px;
            max-width: calc(100% - 40px);
            width: 384px;
            background-color: #ffffff;
          `}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
