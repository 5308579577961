import { css } from '@emotion/react';

import dropdownArrow from '@isi/assets/icons/dropdown-arrow.svg';

interface DropdownProps {
  values: DropdownValue[];
  onChange: (value: string) => void;
  selectedValue: string;
  error: boolean;
  className?: string;
  selectStyleOverride?: string;
  hidePleaseChoose?: boolean;
}

export interface DropdownValue {
  display: string;
  value: number | string;
}

const renderTextFromValue = (value: string): string => value.replace(/-/g, ' ');

export const Dropdown = ({
  values,
  onChange,
  selectedValue,
  error,
  className,
  hidePleaseChoose,
  selectStyleOverride,
}: DropdownProps): JSX.Element => {
  const showPleaseChoose = !hidePleaseChoose;

  return (
    <div>
      <select
        className={className}
        css={css(
          selectStyleOverride ||
            `
        border: solid 1px ${error ? 'red' : '#202020'};
        background-color: #ffffff;
        display: block;
        padding: 10px 10px;
        font-family: MarkOT;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        ${selectedValue === '' ? 'color: grey;' : 'color: #202020;'}
        border-radius: 0px;
        width: 100%;
        margin-bottom: 20px;
        appearance: none;
        background-position: calc(100% - 15px) 50%;
        background-repeat: no-repeat;
        text-transform: capitalize;
        background-image: url('${dropdownArrow}');
        background-size: 20px;`,
        )}
        onChange={(event) => onChange(event.target.value)}
        defaultValue={selectedValue}
      >
        {showPleaseChoose && (
          <option value='' disabled>
            Please choose...
          </option>
        )}
        {values.map((value, index) => (
          <option key={index} value={value.value}>
            {renderTextFromValue(value.display)}
          </option>
        ))}
      </select>
    </div>
  );
};
