import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';

import backArrow from '@isi/assets/icons/left-arrow.svg';
import { rootStore } from '@isi/stores/root.store';

import { Button, ButtonSize } from '@isi/components/common/button.component';
import { LinkButton } from '@isi/components/common/link-button.component';

interface IAddServicesNavContentProps {
  onBackClick: () => void;
  isServiceOrder: boolean;
}

@observer
export default class AddServicesNavContent extends Component<IAddServicesNavContentProps, {}> {
  public renderBackArrow = (): JSX.Element => {
    return (
      <img
        src={backArrow}
        css={css`
          height: 12px;
          margin-right: 7px;
          top: 10px;
          position: absolute;
        `}
        alt='back'
      />
    );
  };

  public render(): JSX.Element {
    return (
      <div>
        <div
          css={css`
            margin-top: 27px;
            @media (min-width: 1024px) {
              margin-top: 0;
              position: absolute;
              left: 0;
              top: 44px;
            }
          `}
        >
          {this.props.isServiceOrder === true ? (
            <LinkButton onClick={() => this.props.onBackClick()} disabled={false}>
              {this.renderBackArrow()}
              <div
                css={css`
                  display: inline-block;
                  position: absolute;
                  top: 2px;
                  left: 20px;
                  width: 180px;
                  text-decoration: underline;
                `}
              >
                Back to select products
              </div>
            </LinkButton>
          ) : (
            ''
          )}
        </div>
        <Button
          onClick={() => rootStore.orderStore.saveOrder()}
          disabled={!rootStore.productStore.areAllProductsValid}
          buttonSize={ButtonSize.large}
          linkTo='/'
          additionalStyles={`
            margin-top: 32px;
            @media (min-width: 1024px) {
              margin-top: 0;
              position: absolute;
              right: 0;
              top: 34px;
            }
            `}
        >
          Save and Review
        </Button>
      </div>
    );
  }
}
