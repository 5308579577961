export enum BrandUserRole {
  ChangePrices = 'change_prices',
  Refund = 'refund',
  Reporting = 'reporting',
  Booker = 'booker',
}

export type BrandUserType = { id: string; jwt: string; roles: BrandUserRole[] };

export default class BrandUser implements BrandUserType {
  id;
  jwt;
  roles;

  constructor({ id, jwt, roles }: BrandUserType) {
    this.id = id;
    this.jwt = jwt;
    this.roles = roles;
  }

  hasRole = (role: BrandUserRole) => this.roles.includes(role);
}
