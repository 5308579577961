const excludingTaxCalculation = (price: number, rate: number): string => {
  const calc = 1 + rate / 100;
  return (price / calc).toFixed(2);
};

const includingTaxCalculation = (price: number, rate: number): string => {
  const onePercent = price / 100;
  const tax = onePercent * rate;

  return (price + tax).toFixed(2);
};

export { excludingTaxCalculation, includingTaxCalculation };
