import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import LoadableMixin from '@shared/stores/mixins/loadable.mixin';

import {
  type PagyNetworkFunction,
  type PagyParams,
  type PagyResponseData,
  type PagyResponseHeaders,
} from '@isi/interfaces/pagy-pagination.inteface';
import { mapKeysToCamel } from '@isi/network/helpers/params/map-keys-to-camel.function';

export default abstract class PagyPagination<TResponse = {}, TTransformed = {}> extends LoadableMixin {
  private _items = observable.map<number, TTransformed[]>();

  constructor() {
    super();

    makeObservable<PagyPagination<TResponse, TTransformed>, '_items'>(this, {
      _items: observable,
      items: computed,
      setItems: action.bound,
      clearItems: action.bound,
      count: observable,
      page: observable,
      per: observable,
      setPaginationStatus: action.bound,
      fetchItems: action.bound,
    });
  }

  get items() {
    return this._items;
  }

  setItems(page: number, items: TTransformed[]) {
    this._items.set(page, items);
  }

  clearItems() {
    this._items = observable.map<number, TTransformed[]>();
  }

  count: PagyResponseData['count'] = 0;

  page: PagyResponseData['page'] = 1;

  per: PagyParams['per'] = 10;

  setPaginationStatus({ count, page }: PagyResponseHeaders) {
    this.count = Number(count);
    this.page = Number(page);
  }

  async fetchItems(_page: number = this.page, per: number = this.per) {
    let page = _page;
    if (per !== this.per || !this.items.get(page)) {
      if (per !== this.per) {
        page = 1;
      }
      this.per = per;
      this.state = 'pending';
      try {
        const { data, headers } = await this.load({ page, per });

        runInAction(() => {
          this.setPaginationStatus(mapKeysToCamel(headers as PagyResponseHeaders));
          const transformedItems = this.transformResponse(data);

          this.setItems(page, transformedItems);

          setTimeout(() => {
            this.state = 'done';
          }, 800);
        });
      } catch (error: any) {
        this.state = 'error';
        this.loadingError = error?.data?.message;
      }
    } else {
      this.page = page;
    }
  }

  // following functions are to be overwritten by mixin target:
  abstract load: PagyNetworkFunction<TResponse>;
  abstract transformResponse(responseItems: TResponse[]): TTransformed[];
}
