import axios from 'axios';

import { apiUrl } from '@isi/config/Url';
import { mapKeysToSnake } from '@isi/network/helpers/params/map-keys-to-snake.function';

export interface IConfirmOrderParams {
  brandCheckoutReference: string;
  brandOrderReference: string;
}

export interface IConfirmOrderResponse {
  order_id: string;
  result: boolean;
}
export const confirmOrder = (params: IConfirmOrderParams) => {
  const url = `${apiUrl()}/v2/order/confirm_store_order`;

  return axios.post<IConfirmOrderResponse>(url, mapKeysToSnake(params));
};
