import Bugsnag from '@bugsnag/js';
import { type AxiosResponse } from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';

import { NotificationLevel } from '@isi/enums/notificationLevel.enum';
import { randomString } from '@isi/helpers/random-string.function';
import { type IDashboardOrder, type IDashboardOrderItem } from '@isi/interfaces/dashboard-order.interface';
import { type IOrderProduct } from '@isi/interfaces/order-product.interface';
import { type IOrder } from '@isi/interfaces/order.interface';
import {
  type IPackageValidationError,
  PackageValidationField,
} from '@isi/interfaces/package-validation-error.interface';
import { type ISelectedProduct } from '@isi/interfaces/selected-product.interface';
import { mapKeysToCamel } from '@isi/network/helpers/params/map-keys-to-camel.function';
import {
  confirmOrder,
  type IConfirmOrderParams,
  type IConfirmOrderResponse,
} from '@isi/network/orders/confirm-order.function';
import {
  createGiftedOrder,
  createOrder,
  createUnscheduledOrder,
  type ICreateOrderParams,
  type ICreateUnscheduledOrderParams,
} from '@isi/network/orders/create-order.function';
import {
  type IAdditionalSize,
  type ILineItem,
  type ISetOrderItemsParams,
  setOrderItems,
  type UnscheduledOrderItem,
} from '@isi/network/orders/set-order-items.function';
import { getOrderItems, type IGetProductResponse } from '@isi/network/products/get-order-products.function';
import type { RootStore } from '@isi/stores/root.store';

import { type ISelectValue } from '@isi/components/common/select.component';

export class OrderStore {
  constructor(private readonly rootStore: RootStore) {
    makeObservable<
      OrderStore,
      | 'selectedDeliverySlotOption'
      | 'unscheduledOrder'
      | 'products'
      | 'brandCheckoutReference'
      | 'shadowOrderId'
      | 'isCreatingOrder'
      | 'isGiftedOrder'
      | 'isServicedOrder'
      | 'giftingNote'
      | 'order'
      | 'packageCount'
      | 'packageSize'
    >(this, {
      useSecurityCodes: observable,
      consignment: observable,
      dropOffOnly: observable,
      selectedDeliverySlotOption: observable,
      unscheduledOrder: observable,
      products: observable,
      brandCheckoutReference: observable,
      shadowOrderId: observable,
      isCreatingOrder: observable,
      isGiftedOrder: observable,
      giftingNote: observable,
      order: observable,
      packageCount: observable,
      packageSize: observable,
      getisCreatingOrder: computed,
      orderValue: computed,
      NumberOfProducts: computed,
      storeAssistantName: computed,
      storeAssistantPhone: computed,
      orderNotes: computed,
      storeOrderNumber: computed,
      orderProducts: computed,
      atLeastOneProductSet: computed,
      getSelectedDeliverySlotOption: computed,
      dateTimeDetailsComplete: computed,
      orderTotal: computed,
      paidToDate: computed,
      canPlaceOrder: computed,
      getIsGiftedOrder: computed,
      getGiftingNote: computed,
      getPackageCount: computed,
      getPackageSize: computed,
      setUseSecurityCodes: action.bound,
      toggleUseSecurityCodes: action.bound,
      toggleConsignmentDropoff: action.bound,
      setOrderDeliverySlotId: action.bound,
      setOrderScheduledDate: action.bound,
      setUnscheduledOrder: action.bound,
      unsetUnscheduledOrder: action.bound,
      setSelectedDeliverySlotOption: action.bound,
      setStoreAssistantName: action.bound,
      setStoreAssistantPhone: action.bound,
      setStoreOrderNumber: action.bound,
      setOrderNotes: action.bound,
      setIsGiftedOrder: action.bound,
      setGiftingNote: action.bound,
      clearDeliverySlot: action.bound,
      clearOrderInfo: action.bound,
      clearOrderServices: action.bound,
      clearGiftingInfo: action.bound,
      saveOrder: action.bound,
      setOrderServicesFromProduct: action.bound,
      setDropOffOnly: action.bound,
      unsetDropOffOnly: action.bound,
      createGiftedOrder: action.bound,
      createOrder: action.bound,
      setOrder: action.bound,
      setOrderProducts: action.bound,
      getOrderProducts: action.bound,
      isServicedOrder: observable,
      getIsServicedOrder: computed,
      setIsServicedOrder: action.bound,
      setPackageCount: action.bound,
      setPackageSize: action.bound,
      getValidationErrorsForPackage: action.bound,
      setErrorMessage: action.bound,
      setValidationForPackage: action.bound,
      // getIsServicedOrder: action.bound,
      // setIsServicedOrder: action.bound,
    });
  }

  // this is set by the storeStore upon fetching storeConfig
  useSecurityCodes: boolean = false;

  consignment: boolean = false;

  dropOffOnly: boolean = false;

  private selectedDeliverySlotOption: ISelectValue | null = null;

  private unscheduledOrder: boolean = false;

  private products: IOrderProduct[] = [];

  private brandCheckoutReference: string = '';

  private shadowOrderId: string | number | undefined = undefined;

  private isCreatingOrder: boolean = false;

  private isGiftedOrder: boolean = false;

  private isServicedOrder: boolean = false;

  private giftingNote: string | undefined = undefined;

  private order: IOrder = {
    id: '',
    storeId: -1,
    deliverySlotId: -1,
    storeAssistantName: '',
    storeAssistantPhone: '',
    scheduledDate: '',
    services: {
      waitAndTry: false,
      perfectFit: false,
      inspireMe: false,
      alterations: false,
      sizeUpSizeDown: false,
      consignment: false,
    },
    storeOrderNumber: '',
    notes: '',
    orderTotal: 0,
  };

  private packageCount: number = 1;

  private packageSize: string = '';

  private packageErrorMessage: IPackageValidationError = {
    countErrorMessage: '',
    sizeErrorMessage: 'Bag must have a size.',
  };

  get getisCreatingOrder(): boolean {
    return this.isCreatingOrder;
  }

  get getUnscheduledOrder(): boolean {
    return this.unscheduledOrder;
  }

  get orderValue(): IOrder {
    return this.order;
  }

  get NumberOfProducts(): number {
    return this.products.length;
  }

  get storeAssistantName(): string {
    return this.order.storeAssistantName;
  }

  get storeAssistantPhone(): string {
    return this.order.storeAssistantPhone;
  }

  get orderNotes(): string {
    return this.order.notes;
  }

  get storeOrderNumber(): string | null {
    return this.order.storeOrderNumber;
  }

  get orderProducts(): IOrderProduct[] {
    return this.products;
  }

  get atLeastOneProductSet(): boolean {
    return this.products.length > 0;
  }

  get getSelectedDeliverySlotOption(): ISelectValue | null {
    return this.selectedDeliverySlotOption;
  }

  get dateTimeDetailsComplete(): boolean {
    if ((this.order.scheduledDate && this.order.deliverySlotId) || this.unscheduledOrder) {
      return true;
    }

    return false;
  }

  get orderTotal(): number {
    let total: number = 0;
    for (const product of this.products) {
      if (product.mainSize) {
        total += product.price * product.quantity;
      }
    }

    return total;
  }

  get paidToDate(): number {
    let paidToDate = 0;

    for (const product of this.products) {
      if (!product.services.paymentRequired && product.mainSize) {
        paidToDate += product.price * product.quantity;
      }
    }

    return paidToDate;
  }

  get canPlaceOrder(): boolean {
    const customer = this.rootStore.customerStore.customerValue;
    const { addressEligible } = this.rootStore.customerStore;
    const storeConfig = this.rootStore.storeStore.getStoreConfig;

    const orderHasProducts = this.products.length > 0;
    const orderHasDeliverySlot = this.order.deliverySlotId !== -1 || this.unscheduledOrder;

    const addressInfoComplete = this.getIsGiftedOrder || addressEligible || this.unscheduledOrder;

    return (
      !!customer &&
      addressInfoComplete &&
      !!storeConfig &&
      orderHasProducts &&
      orderHasDeliverySlot &&
      !this.isCreatingOrder
    );
  }

  get getIsGiftedOrder(): boolean {
    return this.isGiftedOrder;
  }

  get getIsServicedOrder(): boolean {
    return this.isServicedOrder;
  }

  get getGiftingNote(): string | undefined {
    return this.giftingNote;
  }

  get getPackageCount(): number {
    return this.packageCount;
  }

  get getPackageSize(): string {
    return this.packageSize;
  }

  setUseSecurityCodes(state: boolean) {
    this.useSecurityCodes = state;
  }

  toggleUseSecurityCodes = (): void => {
    this.useSecurityCodes = !this.useSecurityCodes;
  };

  toggleConsignmentDropoff = (): void => {
    this.consignment = !this.consignment;
  };

  setConsignmentServiced = () => {
    this.consignment = true;
  };

  setOrderDeliverySlotId = (deliverySlotId: number): void => {
    this.order.deliverySlotId = deliverySlotId;
  };

  setUnscheduledOrder = () => {
    this.order.scheduledDate = null;
    this.unscheduledOrder = true;
    this.selectedDeliverySlotOption = null;
  };

  unsetUnscheduledOrder = () => {
    this.unscheduledOrder = false;
  };

  setOrderScheduledDate = (scheduledDate: string): void => {
    this.order.scheduledDate = scheduledDate;
  };

  setSelectedDeliverySlotOption = (selectedOption: ISelectValue | null): void => {
    this.selectedDeliverySlotOption = selectedOption;
  };

  setStoreAssistantName = (assistantName: string): void => {
    this.order.storeAssistantName = assistantName;
  };

  setStoreAssistantPhone = (storeAssistantPhone: string): void => {
    this.order.storeAssistantPhone = storeAssistantPhone;
  };

  setStoreOrderNumber = (storeOrderNumber: string): void => {
    this.order.storeOrderNumber = storeOrderNumber;
  };

  setOrderNotes = (orderNotes: string): void => {
    this.order.notes = orderNotes;
  };

  setIsGiftedOrder = (isGiftedOrder: boolean): void => {
    this.isGiftedOrder = isGiftedOrder;
  };

  setIsServicedOrder = (isServicedOrder: boolean): void => {
    this.isServicedOrder = isServicedOrder;
  };

  setGiftingNote = (giftingNote: string): void => {
    this.giftingNote = giftingNote;
  };

  setPackageCount = (packageCount: number): void => {
    this.packageCount = packageCount;
  };

  setPackageSize = (packageSize: string): void => {
    this.packageSize = packageSize;
  };

  getValidationErrorsForPackage(): string[] {
    const errors: string[] = [];
    for (const error of Object.values(this.packageErrorMessage)) {
      if (error !== '') {
        errors.push(error);
      }
    }
    return errors;
  }

  setErrorMessage = (value: string | number, field: PackageValidationField): void => {
    switch (field) {
      case PackageValidationField.size:
        // eslint-disable-next-line no-param-reassign
        this.packageErrorMessage.sizeErrorMessage = value === '' ? 'Bag must have a size.' : '';
        break;
      case PackageValidationField.count:
        this.packageErrorMessage.countErrorMessage = Number(value) < 1 ? 'Bag must have a quantity' : '';
        // eslint-disable-next-line no-param-reassign
        break;
      default:
        break;
    }
  };

  setValidationForPackage(value: string | number, field: PackageValidationField): void {
    this.setErrorMessage(value, field);
  }

  clearDeliverySlot = (): void => {
    this.order.deliverySlotId = null;
    this.order.scheduledDate = null;
    this.selectedDeliverySlotOption = null;
    this.unscheduledOrder = false;
  };

  clearOrderInfo = (): void => {
    this.order = {
      id: '',
      storeId: -1,
      storeAssistantName: '',
      storeAssistantPhone: '',
      scheduledDate: '',
      deliverySlotId: -1,
      services: {
        waitAndTry: false,
        perfectFit: false,
        inspireMe: false,
        alterations: false,
        sizeUpSizeDown: false,
        consignment: false,
      },
      storeOrderNumber: '',
      notes: '',
      orderTotal: 0,
    };

    this.rootStore.productStore.clearProductInfo();
    this.rootStore.customerStore.clearCustomerInfo();
    this.rootStore.customerStore.clearGifteeInfo();
    this.clearGiftingInfo();
    this.products = [];
    this.selectedDeliverySlotOption = null;
    this.dropOffOnly = false;
    this.shadowOrderId = undefined;
    this.brandCheckoutReference = randomString(12, 'TOSHI:');
    this.packageCount = 1;
    this.packageSize = '';
    this.packageErrorMessage = {
      countErrorMessage: '',
      sizeErrorMessage: 'Bag must have a size.',
    };
  };

  clearOrderServices = (): void => {
    this.order.services = {
      waitAndTry: false,
      perfectFit: false,
      inspireMe: false,
      alterations: false,
      sizeUpSizeDown: false,
      consignment: false,
    };
  };

  clearGiftingInfo = (): void => {
    this.giftingNote = undefined;
  };

  saveOrder = (): void => {
    this.clearOrderServices();

    if (this.dropOffOnly) {
      this.rootStore.productStore.clearAllServicesForAllProducts();
    }

    this.products = this.rootStore.productStore.getSelectedProducts.map((product: ISelectedProduct) => {
      const orderProduct: IOrderProduct = {
        productId: product.id,
        productSizeId: product.productSizeId,
        imageUrl: product.imageUrl,
        sku: product.sku,
        colour: product.colour,
        name: product.name,
        mainSize: product.mainSize,
        price: product.inputPrice,
        quantity: product.quantity,
        size: product.size,
        services: product.services,
        paidAt: product.paidAt,
        taxRate: product.taxRate,
      };
      this.setOrderServicesFromProduct(product);
      return orderProduct;
    });
  };

  setOrderServicesFromProduct = (product: ISelectedProduct): void => {
    this.order.services.alterations = this.order.services.alterations || product.services.perfectFit;
    this.order.services.perfectFit = this.order.services.perfectFit || product.services.fittingPinning;
    this.order.services.inspireMe = this.order.services.inspireMe || product.services.inspireMe;
    this.order.services.waitAndTry = !this.dropOffOnly;
    this.order.services.sizeUpSizeDown = this.order.services.sizeUpSizeDown || product.productSizeId > 1;
    this.order.services.consignment = this.consignment;
  };

  setDropOffOnly = (): void => {
    this.rootStore.productStore.removeAdditionalSelectedSizes();
    this.dropOffOnly = true;
  };

  unsetDropOffOnly = (): void => {
    this.dropOffOnly = false;
  };

  async createGiftedOrder(): Promise<{ orderId: string } | undefined> {
    const customer = this.rootStore.customerStore.customerValue;
    const giftee = this.rootStore.customerStore.gifteeValue;
    const storeConfig = this.rootStore.storeStore.getStoreConfig;
    const services = {
      alterations: false,
      inspireMe: false,
      kept: true,
      perfectFit: false,
      sizeUpSizeDown: false,
      waitAndTry: !this.dropOffOnly,
      consignment: false,
    };

    if (customer && giftee && storeConfig?.key) {
      const params = {
        customer_id: customer.id,
        first_name: customer.firstName,
        last_name: customer.lastName,
        contact_number: customer.contactNumber,
        email: customer.email,
        gift_recipient: {
          gift_recipient_id: giftee.id,
          first_name: giftee.firstName,
          last_name: giftee.lastName,
          email: giftee.email,
          contact_number: giftee.contactNumber,
        },
        store_id: storeConfig.storeId,
        wait_and_try: services.waitAndTry,
        up_down_size: services.sizeUpSizeDown,
        alteration: services.alterations,
        perfect_fit: services.perfectFit,
        inspire_me: services.inspireMe,
        consignment: this.consignment,
        product_mode: 'instore_interface',
        store_order_number: this.storeOrderNumber || this.brandCheckoutReference,
        total_due: this.orderTotal - this.paidToDate,
        paid_to_date: this.paidToDate,
        order_total: this.orderTotal,
        total_less_discount: this.orderTotal,
        notes: this.order.notes,
        store_assistant_name: this.storeAssistantName,
        store_assistant_phone: this.storeAssistantPhone,
        gift_message: this.giftingNote,
        use_security_codes: false,
      };

      const response = await createGiftedOrder(params);

      if (response.status === 200) {
        return mapKeysToCamel(response.data);
      }
    }
    return undefined;
  }

  async createUnscheduledOrder(): Promise<{ orderId: string } | undefined> {
    const customer = this.rootStore.customerStore.customerValue;
    const { services, notes } = this.order;
    const storeConfig = this.rootStore.storeStore.getStoreConfig;
    const address = this.rootStore.customerStore.addressValue;
    const unscheduled = this.unscheduledOrder;
    const lineItems = this.products;

    if (customer && address && storeConfig?.key && services && unscheduled && lineItems) {
      const orderItems = lineItems
        .map(
          (product: IOrderProduct) =>
            product.mainSize &&
            ({
              id: product.productId,
              sku: product.sku,
              quantity: product.quantity,
              input_price: product.price,
              name: product.name,
              image_url: product.imageUrl,
              product_url: '',
              bespoke: false,
              size: product.size,
              brand_product_id: product.productId,
              colour: product.colour,
              inspire_me: product.services.inspireMe,
              payment_required: product.services.paymentRequired === true,
              additional_sizes: this.constructAdditionalSizesParam(product),
            } as UnscheduledOrderItem),
        )
        .filter(Boolean);

      const params: ICreateUnscheduledOrderParams = {
        customer_id: customer.id,
        first_name: customer.firstName,
        last_name: customer.lastName,
        contact_number: customer.contactNumber,
        email: customer.email,
        address_id: address.id,
        house_name: address.houseName,
        address_line_1: address.line1,
        address_line_2: address.line2,
        town: address.cityTown,
        postcode: address.postcodeZipcode,
        country: address.country as string,
        store_id: storeConfig.storeId,
        wait_and_try: !this.dropOffOnly,
        up_down_size: !!services.sizeUpSizeDown,
        alteration: !!services.alterations,
        perfect_fit: !!services.perfectFit,
        inspire_me: !!services.inspireMe,
        consignment: this.consignment,
        store_order_number: this.storeOrderNumber || this.brandCheckoutReference,
        total_due: this.orderTotal - this.paidToDate,
        paid_to_date: this.paidToDate,
        order_total: this.orderTotal,
        total_less_discount: this.orderTotal,
        product_mode: 'instore_interface',
        notes,
        store_assistant_name: this.storeAssistantName,
        store_assistant_phone: this.storeAssistantPhone,
        use_security_codes: this.useSecurityCodes,
        order_items: orderItems,
        package_count: this.packageCount,
        package_size: this.packageSize,
      };

      try {
        const response = await createUnscheduledOrder(params);

        if (response.status === 201) {
          this.useSecurityCodes = false;
          this.consignment = false;
          this.orderCreatedSucces(response.data.order_id);
          return mapKeysToCamel(response.data);
        }
      } catch {
        this.orderCreatedFailure();
      }
    }
    return undefined;
  }

  async createOrder(): Promise<{ orderId: string } | void> {
    const customer = this.rootStore.customerStore.customerValue;
    const address = this.rootStore.customerStore.addressValue;
    const storeConfig = this.rootStore.storeStore.getStoreConfig;
    const { services, scheduledDate, deliverySlotId, notes } = this.order;
    if (customer && address && storeConfig?.key && services && scheduledDate && deliverySlotId) {
      const params: ICreateOrderParams = {
        customer_id: customer.id,
        first_name: customer.firstName,
        last_name: customer.lastName,
        contact_number: customer.contactNumber,
        email: customer.email,
        address_id: address.id,
        house_name: address.houseName,
        address_line_1: address.line1,
        address_line_2: address.line2,
        town: address.cityTown,
        postcode: address.postcodeZipcode,
        country: address.country as string,
        store_id: storeConfig.storeId,
        delivery_slot_id: deliverySlotId,
        wait_and_try: !this.dropOffOnly,
        up_down_size: !!services.sizeUpSizeDown,
        alteration: !!services.alterations,
        perfect_fit: !!services.perfectFit,
        inspire_me: !!services.inspireMe,
        consignment: this.consignment,
        scheduled_date: scheduledDate,
        store_order_number: this.storeOrderNumber || this.brandCheckoutReference,
        total_due: this.orderTotal - this.paidToDate,
        paid_to_date: this.paidToDate,
        order_total: this.orderTotal,
        total_less_discount: this.orderTotal,
        product_mode: 'instore_interface',
        notes,
        store_assistant_name: this.storeAssistantName,
        store_assistant_phone: this.storeAssistantPhone,
        use_security_codes: this.useSecurityCodes,
        package_count: this.packageCount,
        package_size: this.packageSize,
      };

      const response = await createOrder(params);

      if (response.status === 200) {
        this.useSecurityCodes = false;
        this.consignment = false;
        return mapKeysToCamel(response.data);
      }
    }
    return undefined;
  }

  setOrder = (order: IOrder): void => {
    this.order = order;
  };

  constructAdditionalSizesParam = (product: IOrderProduct): IAdditionalSize[] =>
    this.products
      .map(
        ({ productId, productSizeId, sku, size }) =>
          productId === product.productId && productSizeId !== product.productSizeId && { sku, size },
      )
      .filter(Boolean);

  setOrderItems = async (): Promise<void> => {
    const storeConfig = this.rootStore.storeStore.getStoreConfig;
    if (storeConfig?.storeId) {
      const orderId = this.shadowOrderId || 0;
      const lineItems = this.products
        .map(
          (product: IOrderProduct) =>
            product.mainSize &&
            this.shadowOrderId &&
            ({
              id: product.productId,
              sku: product.sku,
              quantity: product.quantity,
              inputPrice: product.price,
              name: product.name,
              imageUrl: product.imageUrl,
              productUrl: '',
              bespoke: false,
              size: product.size,
              brandProductId: product.productId,
              colour: product.colour,
              inspireMe: product.services.inspireMe,
              payment_required: product.services.paymentRequired === true,
              additional_sizes: this.constructAdditionalSizesParam(product),
            } as ILineItem),
        )
        .filter(Boolean);

      const params: ISetOrderItemsParams = {
        store_id: storeConfig.storeId,
        order_id: orderId,
        lineItems,
      };

      await setOrderItems(params);
    }
  };

  confirmOrder = async (): Promise<AxiosResponse<IConfirmOrderResponse> | undefined> => {
    const {
      storeStore: { getStoreConfig: storeConfig },
      orderStore: {
        orderValue: { storeOrderNumber },
      },
    } = this.rootStore;

    if (storeConfig) {
      // Checkout reference and order reference are the same
      // as there is not ecom order id to use for order reference
      const params: IConfirmOrderParams = {
        brandCheckoutReference: storeOrderNumber || this.brandCheckoutReference,
        brandOrderReference: storeOrderNumber || this.brandCheckoutReference,
      };
      return confirmOrder(params);
    }
    return undefined;
  };

  resetOrderCreationInfo = (): void => {
    this.isCreatingOrder = false;
    this.brandCheckoutReference = '';
  };

  orderCreatedSucces = (orderId: string): boolean => {
    this.resetOrderCreationInfo();
    this.clearOrderInfo();
    this.rootStore.uiStore.setNotification(
      {
        level: NotificationLevel.Success,
        message: 'The order has been successfully created.',
        orderId,
      },
      5000,
    );
    return true;
  };

  orderCreatedFailure = (): boolean => {
    this.rootStore.uiStore.setNotification(
      {
        level: NotificationLevel.Error,
        message: "Sorry, we're having trouble creating this order. Please try again.",
      },
      5000,
    );

    this.resetOrderCreationInfo();
    return false;
  };

  createSetItemsAndConfirmOrder = async (isGiftedOrder: boolean): Promise<boolean> => {
    this.brandCheckoutReference = randomString(12, 'TOSHI:');
    this.isCreatingOrder = true;
    try {
      const createOrderResponse = await (isGiftedOrder ? this.createGiftedOrder() : this.createOrder());
      if (createOrderResponse?.orderId) {
        this.shadowOrderId = createOrderResponse.orderId;
        await this.setOrderItems();
        const confirmOrderResponse = await this.confirmOrder();

        if (confirmOrderResponse?.status === 200) {
          this.orderCreatedSucces(confirmOrderResponse.data.order_id);
        }
      }
    } catch (error: any) {
      this.orderCreatedFailure();
      Bugsnag.notify(error);
    }

    this.resetOrderCreationInfo();
    return false;
  };

  public setOrderProducts = (order: IDashboardOrder): void => {
    const products: IOrderProduct[] = order.orderItems.map((item: IDashboardOrderItem) => ({
      productId: item.id,
      productSizeId: -1,
      sku: item.sku,
      mainSize: true,
      name: item.name,
      preTaxPriceCents: item.preTaxPriceCents,
      postTaxPriceCents: item.postTaxPriceCents,
      size: item.size,
      colour: item.colour,
      imageUrl: item.imageUrl,
      price: item.inputPrice,
      quantity: item.quantity,
      kept: !!item.kept,
      taxRate: item.taxRate,
      services: {
        inspireMe: !!item.inspireMeItem,
        fittingPinning: !!order.services.perfectFit,
        perfectFit: !!order.services.perfectFit,
        paymentRequired: item.paidAt === null,
      },
      paidAt: item.paidAt || undefined,
    }));

    this.products = products;
  };

  public getOrderProducts = async (order: IOrder): Promise<void> => {
    const params = { id: order.id };
    const response = await getOrderItems(params);

    const mappedProducts: IOrderProduct[] = response.data.map(
      (product: IGetProductResponse): IOrderProduct => ({
        ...mapKeysToCamel(product),
        productId: product.id,
        productSizeId: -1,
        mainSize: true,
        price: product.input_price,
        kept: Boolean(product.kept),
        taxRate: product.tax_rate,
        services: {
          inspireMe: Boolean(product.inspire_me),
          fittingPinning: Boolean(order.services.perfectFit),
          perfectFit: Boolean(order.services.perfectFit),
          paymentRequired: product.paid_at === null,
        },
      }),
    );

    this.products = mappedProducts;
  };
}
