import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { mqMaxMd, mqMinMd } from '@shared/helpers/styling/emotion-media-query.function';

import emailIcon from '@isi/assets/icons/email.svg';
import backArrowIcon from '@isi/assets/icons/left-arrow.svg';
import burgerMenuIcon from '@isi/assets/icons/left-menu.svg';
import phoneIcon from '@isi/assets/icons/phone.svg';
import { BrandUserRole } from '@isi/stores/models/brand-user.model';

import useAuthContext from '@isi/hooks/useAuthContext';
import useStoreContext from '@isi/hooks/useStoreContext';
import useToshiDetails from '@isi/hooks/useToshiDetails.function';

import { H1 } from '@isi/components/common/h1.component';
import { LinkButton } from '@isi/components/common/link-button.component';

interface NavBarProps {
  buttonMode?: boolean;
}

const tabletOnly = `${mqMinMd} {
  display: none;
};`;

const noTablet = `${mqMaxMd} {
  display: none;
};`;

const headerHeight = 78;

const NavLink = styled(LinkButton)`
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: 6px;
  white-space: pre;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.25rem;
  }
`;

const NavBar = (props: NavBarProps): JSX.Element => {
  const { user: brandUser, logOut } = useAuthContext();
  const {
    orderStore: { storeAssistantName: _storeAssistantName },
    uiStore: { toggleSideNav },
  } = useStoreContext();

  const storeAssistantName = useMemo(
    (): string | undefined => _storeAssistantName && _storeAssistantName.toUpperCase(),
    [_storeAssistantName],
  );

  const rightSideNavlinks = useMemo(
    () => [
      {
        path: '/',
        copy: 'HOME',
        show: brandUser?.hasRole(BrandUserRole.Booker),
      },
      {
        path: '/dashboard',
        copy: 'DASHBOARD',
        show: true,
      },
      {
        path: '/reports',
        copy: 'REPORTS',
        show: brandUser?.hasRole(BrandUserRole.Reporting),
      },
      {
        path: '/resources',
        copy: 'RESOURCES',
        show: true,
      },
    ],
    [brandUser],
  );

  const burgerMenuButton = (
    <button
      onClick={toggleSideNav}
      css={css`
        background: none;
        border: none;
        ${tabletOnly}
      `}
      type='button'
    >
      <img
        src={burgerMenuIcon.toString()}
        css={css`
          height: 15px;
        `}
        alt='toggle side navigation'
      />
    </button>
  );

  const logoutButton = (
    <NavLink onClick={logOut} disabled={false}>
      LOGOUT
    </NavLink>
  );

  const userSection = (
    // TODO: get user data from store
    <>
      <div
        className='fullstorymask'
        css={css`
          font-size: 12px;
          letter-spacing: 1px;
        `}
      >
        HI {storeAssistantName}
      </div>
      {logoutButton}
    </>
  );

  const backArrow = (
    <img
      src={backArrowIcon.toString()}
      css={css`
        height: 12px;
        margin-right: 7px;
      `}
    />
  );

  const backButton = (
    <LinkButton
      disabled={false}
      noUnderline
      additionalStyles={`
        font-size: 12px;
        letter-spacing: 1px;
        `}
    >
      {backArrow} BACK
    </LinkButton>
  );

  const renderLeftSection = () => {
    // TODO: choose between back button or logout section based on UI store
    // render back button or logout (with store name)
    // variable below is placeholder to control rendering
    const shouldRenderUserSection = true;

    return (
      <div
        css={css`
          grid-area: left;
          align-self: center;
          justify-self: flex-start;
          display: flex;
          gap: 1em;
          padding-left: 1em;
          letter-spacing: 1px;
        `}
      >
        <div
          css={css`
            ${noTablet}
          `}
        >
          {shouldRenderUserSection ? userSection : backButton}
        </div>
        {burgerMenuButton}
      </div>
    );
  };

  const [showContactDropdown, setShowContactDropdown] = useState(false);

  const { contactEmail, contactPhone } = useToshiDetails();

  const contactDropdown = showContactDropdown && (
    <div
      css={css`
        position: absolute;
        top: ${headerHeight}px;
        right: 5px;
        background-color: #eeeeee;
        padding: 20px;
        border-radius: 2px;
        display: grid;
        grid-template-columns: 15px 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
          'icons emails'
          'icons emails';

        align-items: center;
        gap: 1em;
        a {
          color: black;
          text-underline-offset: 0.25rem;
        }
      `}
    >
      <img
        src={emailIcon.toString()}
        css={css`
          height: 15px;
          display: inline-block;
        `}
      />

      <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
      <img
        src={phoneIcon.toString()}
        css={css`
          height: 15px;
          display: inline-block;
        `}
      />
      <a href={`tel:${contactPhone.number}`}>{contactPhone.formatted}</a>
    </div>
  );

  const contactButton = (
    <>
      <NavLink onClick={() => setShowContactDropdown((prev) => !prev)}>CONTACT</NavLink>
      {contactDropdown}
    </>
  );

  const rightSection = (
    <div
      css={css`
        grid-area: right;
        align-self: center;
        justify-self: flex-end;
        display: flex;
        gap: 1em;
        padding-right: 1em;
        ${noTablet}
      `}
    >
      {rightSideNavlinks.map(
        ({ path, copy, show }) =>
          show && (
            <NavLink key={path} to={path}>
              {copy}
            </NavLink>
          ),
      )}
      {contactButton}
    </div>
  );

  const logo = (
    <div
      css={css`
        text-align: center;
      `}
    >
      <H1
        bold
        additionalStyles={`
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 4.4px;
          text-align: center;
          font-size: 18px;
          width: fit-content;
          align-self: center;
          justify-self: center;
          width: 100%;
          margin: 0;
          `}
      >
        TOSHI
      </H1>
      <span
        css={css`
          color: black;
          font-size: 10px;
          @media (min-width: 1024px) {
            font-size: 13px;
          }
        `}
      >
        THE LUXURY OF CONVENIENCE
      </span>
    </div>
  );

  return (
    <nav
      css={css`
        grid-area: header;
        height: ${headerHeight}px;
        transition: all 0.3s ease;
        z-index: 100;
        background-color: #ffffff;
        border-bottom: solid 1px #d5d5d5;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas: ${props.buttonMode ? "'left center right'" : "'center center center'"};
        height: 100%;
      `}
    >
      {props.buttonMode && renderLeftSection()}

      <Link
        to='/'
        css={css`
          grid-area: center;
          text-decoration: none;
          align-self: center;
          justify-self: center;
        `}
      >
        {logo}
      </Link>

      {props.buttonMode && rightSection}
    </nav>
  );
};

export default observer(NavBar);
