import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';
import { type RouteComponentProps, type StaticContext } from 'react-router';

import { ProductPages } from '@isi/enums/product-pages.enum';
import { rootStore } from '@isi/stores/root.store';

import { H1 } from '@isi/components/common/h1.component';
import { AddServices } from '@isi/components/products/add-services/add-services.component';
import AddServicesNavContent from '@isi/components/products/bottom-navbar/add-services-nav-content.component';
import BottomNavbar from '@isi/components/products/bottom-navbar/bottom-navbar.component';
import { DeleteProductsNavContent } from '@isi/components/products/bottom-navbar/delete-products-nav-content.component';
import SelectProductsNavContent from '@isi/components/products/bottom-navbar/select-products-nav-content.component';
import { SelectProducts } from '@isi/components/products/select-products/select-products.component';

interface IProductsState {
  currentPage: ProductPages;
  productsToDelete: number;
  selectProductsPageButtonBold: boolean;
  addServicesPageButtonBold: boolean;
}

type ProductsProps = RouteComponentProps<
  {},
  StaticContext,
  { isGiftingOrder?: boolean; isServiceOrder?: boolean; edit?: boolean }
>;

@observer
class Products extends Component<ProductsProps, IProductsState> {
  public selectProductsPage: SelectProducts | null = null;
  public addServicesPage: AddServices | null = null;
  public DeleteProductsNav: DeleteProductsNavContent | null = null;

  constructor(props: ProductsProps) {
    super(props);
    this.state = {
      currentPage: ProductPages.SelectProducts,
      productsToDelete: 0,
      // eslint-disable-next-line react/no-unused-state
      selectProductsPageButtonBold: true,
      addServicesPageButtonBold: false,
    };
  }

  componentDidUpdate(): void {
    if (this.state.currentPage === ProductPages.AddServices && !this.state.addServicesPageButtonBold) {
      this.setState({
        addServicesPageButtonBold: true,
      });
    }
  }

  async componentDidMount(): Promise<void> {
    // if the serviced button is pressed the isServicedOrder state is passed as true
    // and this is stored in the orderStore (so we know we have a serviced order).
    if (Boolean(this.props.location?.state?.isServiceOrder) === true) {
      rootStore.orderStore.setIsServicedOrder(true);
    }
    if (!this.props.location?.state?.edit) rootStore.productStore.clearSelectedProducts();

    if (Boolean(this.props.location?.state?.isServiceOrder) === false) {
      if (!this.props.location?.state?.edit) {
        await rootStore.productStore.addDropOffProductItem();
      }
      this.setState({
        currentPage: ProductPages.AddServices,
        addServicesPageButtonBold: true,
      });
    }
  }

  private renderBottomNav = () => {
    const productsSelected: number = rootStore.productStore.getSelectedProducts.length;
    return productsSelected > 0 ? <BottomNavbar>{this.renderBottomNavContent()}</BottomNavbar> : null;
  };

  private renderBottomNavContent = () => {
    const onSelectProductsPage = this.state.currentPage === ProductPages.SelectProducts;
    const hasProductsToDeleteSelected = this.state.productsToDelete > 0;
    const onAddServicesPage = this.state.currentPage === ProductPages.AddServices;

    if (onSelectProductsPage) {
      return (
        <SelectProductsNavContent
          onClear={() => this.selectProductsPage?.clearProducts()}
          onAddServices={() => this.showAddServicesPage()}
        />
      );
    }
    if (hasProductsToDeleteSelected && onAddServicesPage) {
      return (
        <DeleteProductsNavContent
          ref={(ref) => {
            if (ref) this.DeleteProductsNav = ref;
          }}
          onDeleteClick={() => this.deleteProductsFromSelection()}
          onCancelClick={() => this.cancelDeletingProducts()}
        />
      );
    }
    if (onAddServicesPage) {
      return (
        <AddServicesNavContent
          isServiceOrder={Boolean(this.props.location?.state?.isServiceOrder)}
          onBackClick={() => this.showSelectProductsPage()}
        />
      );
    }

    return null;
  };

  private showSelectProductsPage = () => {
    this.setState({
      currentPage: ProductPages.SelectProducts,
      productsToDelete: 0,
      addServicesPageButtonBold: false,
    });
    this.forceUpdate();
  };

  private showAddServicesPage = () => {
    const noSelectedProducts = rootStore.productStore.getSelectedProducts.length === 0;
    if (noSelectedProducts) {
      return;
    }
    this.setState({
      currentPage: ProductPages.AddServices,
      addServicesPageButtonBold: true,
    });
    this.forceUpdate();
  };

  private updateProductsToDeleteNav = (productsToDelete: number) => {
    this.setState({ productsToDelete }, () => {
      this.DeleteProductsNav?.updateAmountToDelete(productsToDelete);
    });
  };

  private cancelDeletingProducts = () => {
    this.setState({ productsToDelete: 0 });
    this.addServicesPage?.unselectAllProductsForDeletion();
  };

  private deleteProductsFromSelection = () => {
    this.setState({ productsToDelete: 0 });
    this.addServicesPage?.deleteSelectedProducts();

    const numberOfSelectedProducts = rootStore.productStore.getSelectedProducts.length;
    if (numberOfSelectedProducts === 0) {
      this.showSelectProductsPage();
    }
  };

  private isDropOffOrder = () => {
    if (rootStore.productStore.getSelectedProducts.length === 0) {
      return false;
    }

    if (
      rootStore.productStore.getSelectedProducts.filter((product) => product.sku === 'scheduled-delivery').length ===
      rootStore.productStore.getSelectedProducts.length
    ) {
      return true;
    }

    return false;
  };

  private renderPage = () =>
    this.state.currentPage === ProductPages.SelectProducts ? (
      <SelectProducts
        ref={(page) => {
          if (page) this.selectProductsPage = page;
        }}
      />
    ) : (
      <AddServices
        ref={(page) => {
          if (page) this.addServicesPage = page;
        }}
        isGiftingOrder={Boolean(this.props.location?.state?.isGiftingOrder)}
        isDropOffOrder={this.isDropOffOrder()}
        onProductsToDeleteChange={(productsToDelete) => this.updateProductsToDeleteNav(productsToDelete)}
        isServiceOrder={Boolean(this.props.location?.state?.isServiceOrder)}
      />
    );

  public render(): JSX.Element {
    return (
      <div
        css={css`
          background-color: white;
        `}
      >
        {this.state.currentPage === ProductPages.SelectProducts ? (
          <H1 additionalStyles='margin-top: 48px; margin-bottom: 48px;'>SELECT PRODUCTS</H1>
        ) : (
          <H1 additionalStyles='margin-top: 48px;'>ENTER DETAILS</H1>
        )}
        <div>{this.renderPage()}</div>
        {this.renderBottomNav()}
      </div>
    );
  }
}

export { Products };
