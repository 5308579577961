import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ButtonProps {
  callback: any;
  ariaLabel?: string;
  disabled: boolean;
  iconClass: any;
  color?: string;
  height?: number;
  width?: number;
  additionalButtonStyles?: string;
  additionalIconStyles?: string;
  text?: string;
  additionalTextStyles?: string;
}

const IconButton = (props: ButtonProps) => (
  <button
    onClick={props.callback}
    aria-label={props.ariaLabel}
    disabled={props.disabled}
    css={css`
      appearance: none;
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      ${props.additionalButtonStyles}
    `}
    type='button'
  >
    <FontAwesomeIcon
      icon={props.iconClass}
      css={css`
        color: ${props.color};
        height: ${props.height}px!important;
        width: ${props.width}px!important;
        ${props.additionalIconStyles}
      `}
    />
    {props.text && (
      <span
        css={css`
          font-family: 'MarkOT';
          ${props.additionalTextStyles}
        `}
      >
        {props.text}
      </span>
    )}
  </button>
);

export { IconButton, ButtonProps };
