import { ProductMode } from '@isi/enums/product-mode.enum';

export const productModeToString = (productMode: ProductMode): string => {
  switch (productMode) {
    case ProductMode.Checkout:
      return 'Checkout';
    case ProductMode.TbybWizard:
      return 'Try Before You Buy';
    case ProductMode.TbybCheckout:
      return 'Try Before You Buy';
    case ProductMode.TbybButton:
      return 'Try Before You Buy';
    case ProductMode.InstoreInterface:
      return 'In-Store';
    case ProductMode.ManualOrder:
      return 'Manual Order';
    default:
      return '-';
  }
};
