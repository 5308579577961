import { observer } from 'mobx-react';
import { type FC } from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

import { LiveChatIntegration } from '@isi/enums/liveChatIntegration.enum';

import useStoreContext from '@isi/hooks/useStoreContext';

const ZENDESK_KEY = '1614c794-7ed0-4cab-ad65-1788f1d8909b';

const ZendeskSettings = {
  color: {
    theme: '#000',
  },
};

const LiveChat: FC = observer(() => {
  const {
    storeStore: { getStoreConfig },
  } = useStoreContext();

  const autoFillZendeskFields = (): void => {
    ZendeskAPI('webWidget', 'identify', { name: getStoreConfig?.fullBrandName, email: getStoreConfig?.brandUserEmail });
  };

  if (getStoreConfig?.liveChatIntegration === LiveChatIntegration.Zendesk)
    return <Zendesk defer zendeskKey={ZENDESK_KEY} {...ZendeskSettings} onLoaded={autoFillZendeskFields} />;

  return null;
});

export default LiveChat;
