import { css } from '@emotion/react';
import { type ReactNode } from 'react';

interface ContainerProps {
  id?: string;
  children: ReactNode;
  width: string;
  additionalStyles?: string;
}

const Container = ({ id, children, width, additionalStyles }: ContainerProps): JSX.Element => {
  const style: string = `
    max-width: 100%;
    margin: auto;
    width: ${width};
    ${additionalStyles};
  `;

  return (
    <div id={id} css={css(style)}>
      {children}
    </div>
  );
};
export { Container };
