import axios from 'axios';

import { apiUrl } from '@isi/config/Url';
import { type CountryCodes } from '@isi/enums/country-codes.enum';

export interface SearchPrediction {
  address_components: {
    address_line_1: string;
    address_line_2: string | undefined;
    town: string;
    province: string;
    postcode: string;
    country: CountryCodes | string;
    full_address_string: string;
  };
}

export interface AddressAutocompleteSearchResponse {
  data: {
    predictions: SearchPrediction[];
    total_results: number;
    more_values: boolean;
  };
}

export const addressAutocompleteOptions = (
  postcode: string,
  storeId: number,
): Promise<AddressAutocompleteSearchResponse> =>
  axios.get(`${apiUrl()}/v2/address_search/v2/autocomplete?query=${postcode}&store_id=${storeId}`);
