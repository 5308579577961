import { action, computed, makeObservable, observable } from 'mobx';

import { Service } from '@isi/enums/service.enum';
import { type IProductCatalogueItem } from '@isi/interfaces/IProductCatalogueItem.interface';
import { ProductRowValidationField } from '@isi/interfaces/product-validation-error.interface';
import { type ISelectedProduct } from '@isi/interfaces/selected-product.interface';
import { type IServicesValidationErrors } from '@isi/interfaces/services-validation-errors.interface';
import { mapKeysToCamel } from '@isi/network/helpers/params/map-keys-to-camel.function';
import { createProduct, type INewBrandProductData } from '@isi/network/products/create-product.function';
import { getProducts, type IGetProductResponse } from '@isi/network/products/get-products.function';
import type { RootStore } from '@isi/stores/root.store';

export class ProductStore {
  constructor(private readonly rootStore: RootStore) {
    makeObservable<ProductStore, 'products' | 'selectedProducts' | 'searchedProducts' | 'pageNumber' | 'search'>(this, {
      products: observable,
      allProducts: computed,
      addDropOffProductItem: action.bound,
      isDropOffOrder: computed,
      selectedProducts: observable,
      getSelectedProducts: computed,
      setSelectedProducts: action.bound,
      searchedProducts: observable,
      numberOfUniqueSelectedProducts: computed,
      getSearchedProducts: computed,
      sortedSelectedProductsByAdditionalSize: computed,
      areAllProductsValid: computed,
      getPageNumber: computed,
      getSearch: computed,
      pageNumber: observable,
      search: observable,
      clearValidation: action.bound,
      sortProductCatalogueBySelected: action.bound,
      loadProducts: action.bound,
      getProduct: action.bound,
      getProductSize: action.bound,
      getSelectedProduct: action.bound,
      addToSelectedProducts: action.bound,
      createBlankProductSize: action.bound,
      updateProductSize: action.bound,
      updateSelectedProductPrice: action.bound,
      updateProductQuantity: action.bound,
      getSelectedProductPrice: action.bound,
      getSelectedProductImageUrl: action.bound,
      checkIfProductHasABlankSize: action.bound,
      setProducts: action.bound,
      setSelectedProductsFromOrder: action.bound,
      removeFromSelectedProducts: action.bound,
      clearProductInfo: action.bound,
      clearSelectedProducts: action.bound,
      checkIfProductIsSelected: action.bound,
      selectServiceForProduct: action.bound,
      deselectServiceForProduct: action.bound,
      clearAllServicesForAllProducts: action.bound,
      selectServiceForAllProducts: action.bound,
      deselectServiceForAllProducts: action.bound,
      setValidationForProductRowField: action.bound,
      setValidationForAllProductRows: action.bound,
      getValidationErrorsForProduct: action.bound,
      removeAdditionalSelectedSizes: action.bound,
      hasSelectedAdditionalSizes: computed,
      hasSelectedServices: computed,
    });
  }

  private products: IProductCatalogueItem[] = [];

  get allProducts(): IProductCatalogueItem[] {
    return this.products;
  }

  private selectedProducts: ISelectedProduct[] = [];

  get getSelectedProducts(): ISelectedProduct[] {
    return this.selectedProducts;
  }
  setSelectedProducts(selected: ISelectedProduct[]): void {
    this.selectedProducts = selected;
  }

  get isDropOffOrder(): boolean {
    return this.selectedProducts.length === 1 && this.selectedProducts[0].sku === 'scheduled-delivery';
  }

  private searchedProducts: IProductCatalogueItem[] = [];

  get numberOfUniqueSelectedProducts(): number {
    return this.selectedProducts.filter((product) => product.mainSize).length;
  }

  get getSearchedProducts(): IProductCatalogueItem[] {
    return this.searchedProducts;
  }

  get sortedSelectedProductsByAdditionalSize(): ISelectedProduct[] {
    return [...this.selectedProducts].sort((product1, product2) => {
      if (product1.id > product2.id) {
        return 1;
      }
      if (product1.id < product2.id) {
        return -1;
      }
      if (product1.productSizeId > product2.productSizeId) {
        return 1;
      }
      if (product1.productSizeId < product2.productSizeId) {
        return -1;
      }
      return 1;
    });
  }

  get areAllProductsValid(): boolean {
    return !this.selectedProducts.some(({ validationErrors }) =>
      Object.values(validationErrors).some((message) => message !== ''),
    );
  }

  get getPageNumber(): number {
    return this.pageNumber;
  }

  get getSearch(): string | undefined {
    return this.search;
  }

  private pageNumber: number = 0;

  private search: string | undefined;

  sortProductCatalogueBySelected(products: IProductCatalogueItem[]): IProductCatalogueItem[] {
    const sortedProducts = [...products].sort((product) => {
      if (this.checkIfProductIsSelected(product.id)) {
        return -1;
      }
      return 1;
    });
    return sortedProducts;
  }

  loadProducts = async (pageNumber: number, search?: string): Promise<void> => {
    if (pageNumber === 0) {
      this.searchedProducts = [];
      this.products = [];
    }
    this.search = search;
    this.pageNumber = pageNumber;

    const params = search ? { query: search, pageNumber } : { pageNumber };
    const response = await getProducts(params);
    let mappedProducts = response.data.map(
      (product: IGetProductResponse): IProductCatalogueItem => ({
        id: product.id,
        sku: product.sku,
        name: product.name,
        colour: product.colour || '',
        imageUrl: decodeURIComponent(product.image_url),
        inputPrice: product.input_price ? parseFloat(product.input_price) : 0,
        taxRate: parseFloat(product.tax_rate),
      }),
    );
    this.products = [...this.products, ...mappedProducts];

    if (!search) {
      // Without search results are sorted with selected items first
      mappedProducts =
        pageNumber > 0 ? this.sortProductCatalogueBySelected([...this.searchedProducts, ...mappedProducts]) : [];
    }
    // Searched products not sorted by selected
    else if (pageNumber > 0) {
      // if not first page then concatenate mappedProducts to currently displayed products
      mappedProducts = [...this.searchedProducts, ...mappedProducts];
    }

    let searchedProducts = this.sortProductCatalogueBySelected(
      this.concatenateAndRemoveDups(this.selectedProducts, this.products),
    );

    if (search !== 'scheduled-delivery') {
      searchedProducts = searchedProducts.filter((item) => item.sku !== 'scheduled-delivery');
    }

    this.searchedProducts = searchedProducts;
  };

  getProduct(productId: number): IProductCatalogueItem | undefined {
    return this.products.find((product) => product.id === productId);
  }

  getProductSize(productId: number, productSizeId: number): string {
    for (let i = 0; i < this.selectedProducts.length; i++) {
      const matched =
        this.selectedProducts[i].id === productId && this.selectedProducts[i].productSizeId === productSizeId;
      if (matched) {
        return this.selectedProducts[i].size;
      }
    }
    return '';
  }

  getSelectedProduct(productId: number, productSizeId: number): ISelectedProduct | undefined {
    return this.selectedProducts.find((product) => product.id === productId && product.productSizeId === productSizeId);
  }

  clearValidation() {
    for (const product of this.selectedProducts) {
      Object.keys(product.validationErrors).forEach((key) => {
        product.validationErrors[key as keyof IServicesValidationErrors] = '';
      });
    }
  }

  async addDropOffProductItem() {
    await this.loadProducts(0, 'scheduled-delivery');
    const product = this.getSearchedProducts[0];

    this.selectedProducts = [
      {
        id: product.id,
        name: product.name,
        colour: product.colour,
        productSizeId: 1,
        size: '',
        imageUrl: product.imageUrl,
        sku: product.sku,
        services: {
          fittingPinning: false,
          inspireMe: false,
          perfectFit: false,
          paymentRequired: null,
        },
        mainSize: true,
        inputPrice: 0,
        quantity: 1,
        validationErrors: {
          priceMessage: '',
          quantityMessage: '',
          sizeMessage: '',
          paymentRequired: '',
        },
        upDownSizeIds: [],
        taxRate: product.taxRate,
      },
    ];

    this.clearValidation();
  }

  addToSelectedProducts(product: IProductCatalogueItem): void {
    this.selectedProducts.push({
      id: product.id,
      name: product.name,
      colour: product.colour,
      productSizeId: 1,
      size: '',
      imageUrl: product.imageUrl,
      sku: product.sku,
      services: {
        fittingPinning: false,
        inspireMe: false,
        perfectFit: false,
        paymentRequired: null,
      },
      mainSize: true,
      inputPrice: Number(product.inputPrice),
      quantity: 1,
      validationErrors: {
        priceMessage: '',
        quantityMessage: '',
        sizeMessage: '',
        paymentRequired: '',
      },
      upDownSizeIds: [],
      taxRate: product.taxRate,
    });
  }

  createBlankProductSize(product: ISelectedProduct): void {
    const sizeId = this.findHighestSizeIdForSelectedProduct(product.id);
    const price = this.getSelectedProductPrice(product.id);
    const imageUrl = this.getSelectedProductImageUrl(product.id);
    const name = this.getSelectedProductName(product.id);
    const colour = this.getSelectedProductColour(product.id);
    const duplicatedProduct = {
      isSizeUpSizeDownProduct: true,
      id: product.id,
      name,
      colour,
      productSizeId: sizeId + 1,
      size: '',
      sku: '',
      imageUrl,
      taxRate: product.taxRate,
      services: {
        fittingPinning: product.services.fittingPinning,
        inspireMe: product.services.inspireMe,
        perfectFit: product.services.perfectFit,
        paymentRequired: product.services.paymentRequired,
      },
      mainSize: false,
      inputPrice: Number(price),
      quantity: 1,
      validationErrors: {
        priceMessage: '',
        quantityMessage: '',
        sizeMessage: '',
        paymentRequired: '',
      },
      upDownSizeIds: [],
    };
    product.upDownSizeIds?.push(duplicatedProduct.productSizeId);
    this.selectedProducts.push(duplicatedProduct);
  }

  updateProductSize(productId: number, productSizeId: number, updatedSize: string): void {
    let indexOfSize = 0;

    for (let i = 0; i < this.selectedProducts.length; i++) {
      const currentProduct = this.selectedProducts[i];
      const matchesProduct = currentProduct.id === productId && currentProduct.productSizeId === productSizeId;
      if (matchesProduct) {
        indexOfSize = i;
      }
    }
    const price = this.getSelectedProductPrice(productId);
    const newProduct: ISelectedProduct = {
      id: productId,
      name: this.selectedProducts[indexOfSize].name,
      colour: this.selectedProducts[indexOfSize].colour,
      productSizeId,
      taxRate: this.selectedProducts[indexOfSize].taxRate,
      size: updatedSize,
      sku: this.selectedProducts[indexOfSize].sku,
      imageUrl: this.selectedProducts[indexOfSize].imageUrl,
      services: this.selectedProducts[indexOfSize].services,
      mainSize: this.selectedProducts[indexOfSize].mainSize,
      quantity: this.selectedProducts[indexOfSize].quantity,
      inputPrice: price,
      validationErrors: this.selectedProducts[indexOfSize].validationErrors,
      upDownSizeIds: this.selectedProducts[indexOfSize].upDownSizeIds,
    };
    this.selectedProducts.splice(indexOfSize, 1);
    this.selectedProducts.push(newProduct);
  }

  updateSelectedProductPrice(productId: number, updatedPrice: number): void {
    let selectedProducts = [...this.selectedProducts];
    const updatedProducts: ISelectedProduct[] = [];
    for (const product of selectedProducts) {
      if (product.id === productId) {
        const newProduct = product;
        newProduct.inputPrice = updatedPrice;
        updatedProducts.push(newProduct);
      }
    }
    selectedProducts = selectedProducts.filter((product) => product.id !== productId);
    this.selectedProducts = [...selectedProducts, ...updatedProducts];
  }

  updateProductQuantity(productId: number, productSizeId: number, updatedQuantity: number): void {
    let indexOfSize = 0;
    for (let i = 0; i < this.selectedProducts.length; i++) {
      const currentProduct = this.selectedProducts[i];
      const matchesProduct = currentProduct.id === productId && currentProduct.productSizeId === productSizeId;
      if (matchesProduct) {
        indexOfSize = i;
      }
    }
    const newProduct: ISelectedProduct = {
      id: productId,
      productSizeId,
      name: this.selectedProducts[indexOfSize].name,
      colour: this.selectedProducts[indexOfSize].colour,
      sku: this.selectedProducts[indexOfSize].sku,
      taxRate: this.selectedProducts[indexOfSize].taxRate,
      imageUrl: this.selectedProducts[indexOfSize].imageUrl,
      size: this.selectedProducts[indexOfSize].size,
      services: this.selectedProducts[indexOfSize].services,
      mainSize: this.selectedProducts[indexOfSize].mainSize,
      quantity: updatedQuantity,
      inputPrice: this.selectedProducts[indexOfSize].inputPrice,
      validationErrors: this.selectedProducts[indexOfSize].validationErrors,
      upDownSizeIds: this.selectedProducts[indexOfSize].upDownSizeIds,
    };
    this.selectedProducts.splice(indexOfSize, 1);
    this.selectedProducts.push(newProduct);
  }

  getSelectedProductPrice(productId: number): number {
    let price = 0;
    for (const product of this.selectedProducts) {
      const isMainProductSize = product.id === productId;
      if (isMainProductSize) {
        price = product.inputPrice;
      }
    }
    return price;
  }

  getSelectedProductImageUrl(productId: number): string {
    let imageUrl = '';
    for (const product of this.selectedProducts) {
      const isMainProductSize = product.id === productId;
      if (isMainProductSize) {
        imageUrl = product.imageUrl;
      }
    }
    return imageUrl;
  }

  checkIfProductHasABlankSize(productId: number): boolean {
    let hasBlankSize = false;
    for (const product of this.selectedProducts) {
      if (product.id === productId && product.size === '') {
        hasBlankSize = true;
      }
    }
    return hasBlankSize;
  }

  setProducts(products: IProductCatalogueItem[]): void {
    this.products = products;
  }

  setSelectedProductsFromOrder(): void {
    this.selectedProducts = this.rootStore.orderStore.orderProducts.map(
      ({
        name,
        colour,
        sku,
        imageUrl,
        mainSize,
        price,
        productId,
        productSizeId,
        quantity,
        services,
        size,
        taxRate,
      }) => ({
        name,
        colour,
        sku,
        imageUrl,
        mainSize,
        inputPrice: price,
        id: productId,
        productSizeId,
        quantity,
        services,
        size,
        validationErrors: {
          priceMessage: '',
          quantityMessage: '',
          sizeMessage: '',
          paymentRequired: '',
        },
        upDownSizeIds: [],
        taxRate,
      }),
    );
  }

  removeFromSelectedProducts(productId: number, productSizeId?: number, updatingService?: boolean): void {
    if (productSizeId) {
      let indexOfProduct = 0;
      for (let i = 0; i < this.selectedProducts.length; i++) {
        const matchesProduct =
          this.selectedProducts[i].id === productId && this.selectedProducts[i].productSizeId === productSizeId;
        if (matchesProduct) {
          indexOfProduct = i;
        }
      }
      this.selectedProducts.splice(indexOfProduct, 1);

      if (this.selectedProducts.length === 0) {
        return;
      }

      const productHasNoMainSize = !this.checkIfHasMainProductSize(productId);
      if (productHasNoMainSize && !updatingService) {
        this.resetProductMainSize(productId);
      }
    }

    if (!productSizeId) {
      this.selectedProducts = this.selectedProducts.filter((product) => product.id !== productId);
    }
  }

  clearProductInfo(): void {
    this.products = [];
  }

  clearSelectedProducts(): void {
    this.selectedProducts = [];
  }

  checkIfProductIsSelected = (productId: number): boolean => this.selectedProducts.some(({ id }) => id === productId);

  selectServiceForProduct(product: ISelectedProduct, productSizeId: number, service: Service): void {
    if (product) {
      const updatedProduct = { ...product };
      switch (service) {
        case Service.FittingPinning:
          updatedProduct.services.fittingPinning = true;
          break;
        case Service.InspireMe:
          updatedProduct.services.inspireMe = true;
          updatedProduct.services.paymentRequired = true;
          break;
        case Service.PerfectFit:
          updatedProduct.services.perfectFit = true;
          break;
        case Service.PaymentRequired:
          updatedProduct.services.paymentRequired = true;
          break;
        default:
          break;
      }

      this.removeFromSelectedProducts(updatedProduct.id, productSizeId, true);
      this.selectedProducts.push(product);
    }
  }

  deselectServiceForProduct(product: ISelectedProduct, productSizeId: number, service: Service): void {
    if (product) {
      const updatedProduct = { ...product };
      switch (service) {
        case Service.FittingPinning:
          updatedProduct.services.fittingPinning = false;
          break;
        case Service.InspireMe:
          updatedProduct.services.inspireMe = false;
          break;
        case Service.PerfectFit:
          updatedProduct.services.perfectFit = false;
          break;
        case Service.PaymentRequired:
          updatedProduct.services.paymentRequired = false;
          break;
        default:
          break;
      }

      this.removeFromSelectedProducts(updatedProduct.id, productSizeId, true);
      this.selectedProducts.push(updatedProduct);
    }
  }

  clearAllServicesForAllProducts(): void {
    for (let i = this.selectedProducts.length - 1; i >= 0; i--) {
      const product = this.selectedProducts[i];
      this.clearAllServicesForProduct(product.id, product.productSizeId);
    }
  }

  selectServiceForAllProducts(service: Service): void {
    for (let i = this.selectedProducts.length - 1; i >= 0; i--) {
      const product = this.selectedProducts[i];
      this.selectServiceForProduct(product, product.productSizeId, service);
    }
  }

  deselectServiceForAllProducts(service: Service): void {
    for (let i = this.selectedProducts.length - 1; i >= 0; i--) {
      const product = this.selectedProducts[i];
      this.deselectServiceForProduct(product, product.productSizeId, service);
    }
  }

  setValidationForProductRowField(
    productId: number,
    productSizeId: number,
    value: string | number,
    field: ProductRowValidationField,
  ): void {
    for (const product of this.selectedProducts) {
      if (product.id === productId && product.productSizeId === productSizeId) {
        this.setErrorMessage(value, field, product);
      }
    }
  }

  setValidationForAllProductRows(): void {
    for (let i = 0; i < this.selectedProducts.length; i++) {
      this.setValidationForProductRow(this.selectedProducts[i]);
    }
  }

  getValidationErrorsForProduct(productId: number, productSizeId: number): string[] {
    const errors: string[] = [];
    for (const product of this.selectedProducts) {
      const matched = product.id === productId && product.productSizeId === productSizeId;
      if (matched) {
        for (const error of Object.values(product.validationErrors)) {
          if (error !== '') {
            errors.push(error);
          }
        }
      }
    }
    return errors;
  }

  removeAdditionalSelectedSizes(): void {
    this.selectedProducts = this.selectedProducts.filter((product) => product.mainSize);
  }

  get hasSelectedAdditionalSizes(): boolean {
    return this.selectedProducts.some(({ mainSize }) => !mainSize);
  }

  get hasSelectedServices(): boolean {
    return this.selectedProducts.some(({ services }) => Object.values(services).some(Boolean));
  }

  async createProduct({
    name,
    sku,
    colour,
    inputPrice,
    imageUrl,
    productTaxCode,
  }: INewBrandProductData): Promise<IProductCatalogueItem | null> {
    const createParams = {
      brand_product: {
        name,
        sku: sku || 'n/a',
        colour,
        inputPrice,
        imageUrl,
      },
      productTaxCode,
    };

    const response = await createProduct(createParams);

    if (response.status === 200) {
      return mapKeysToCamel(response.data) as IProductCatalogueItem;
    }

    return null;
  }

  private setErrorMessage(value: string | number, field: ProductRowValidationField, selectedProduct: ISelectedProduct) {
    const currency = this.rootStore.storeStore.storeCurrency;
    switch (field) {
      case ProductRowValidationField.price:
        // eslint-disable-next-line no-param-reassign
        selectedProduct.validationErrors.priceMessage =
          Number(value) < 1 || Number.isNaN(value) ? `Product must have a unit price higher than ${currency}0.` : '';
        break;
      case ProductRowValidationField.quantity:
        // eslint-disable-next-line no-param-reassign
        selectedProduct.validationErrors.quantityMessage = Number(value) < 1 ? 'Product must have a quantity.' : '';
        break;
      case ProductRowValidationField.size:
        // eslint-disable-next-line no-param-reassign
        selectedProduct.validationErrors.sizeMessage = value === '' ? 'Product must have a size.' : '';
        break;
      default:
        break;
    }
  }

  private setValidationForProductRow(selectedProduct: ISelectedProduct) {
    const currency = this.rootStore.storeStore.storeCurrency;

    // eslint-disable-next-line no-param-reassign
    selectedProduct.validationErrors.priceMessage =
      Number(selectedProduct.inputPrice) < 1 || Number.isNaN(selectedProduct.inputPrice)
        ? `Product must have a unit price higher than ${currency}0.`
        : '';

    // eslint-disable-next-line no-param-reassign
    selectedProduct.validationErrors.quantityMessage =
      Number(selectedProduct.quantity) < 1 ? 'Product must have a quantity.' : '';

    // eslint-disable-next-line no-param-reassign
    selectedProduct.validationErrors.sizeMessage = selectedProduct.size === '' ? 'Product must have a size.' : '';
    // eslint-disable-next-line no-param-reassign
    selectedProduct.validationErrors.paymentRequired = this.validatePaymentRequired(selectedProduct)
      ? 'You must specify whether payment is required.'
      : '';

    // eslint-disable-next-line no-param-reassign
    selectedProduct.validationErrors.sizeMessage = selectedProduct.size === '' ? 'Product must have a size.' : '';
  }

  private validatePaymentRequired = (selectedProduct: ISelectedProduct): boolean => {
    const storeConfig = this.rootStore.storeStore.getStoreConfig;
    return (
      !this.rootStore.orderStore.dropOffOnly &&
      selectedProduct.services.paymentRequired === null &&
      Boolean(storeConfig?.paymentAtDoorEnabled)
    );
  };

  private clearAllServicesForProduct(productId: number, productSizeId: number) {
    const product = this.getSelectedProduct(productId, productSizeId);
    if (product) {
      product.services.fittingPinning = false;
      product.services.perfectFit = false;
      product.services.inspireMe = false;
      product.services.paymentRequired = false;
      this.removeFromSelectedProducts(productId, productSizeId);
      this.selectedProducts.push(product);
    }
  }

  private getProductPrice(productId: number): number {
    let price = 0;
    for (const product of this.products) {
      if (product.id === productId) {
        price = product.inputPrice;
      }
    }
    return price;
  }

  private findHighestSizeIdForSelectedProduct(productId: number): number {
    let highestId = 0;
    for (const product of this.selectedProducts) {
      const hasHigherSizeId = product.id === productId && highestId < product.productSizeId;
      if (hasHigherSizeId) {
        highestId = product.productSizeId;
      }
    }
    return highestId;
  }

  private checkIfHasMainProductSize(productId: number): boolean {
    let hasMainProductSize = false;
    for (const product of this.selectedProducts) {
      if (product.id === productId && product.mainSize) {
        hasMainProductSize = true;
      }
    }
    return hasMainProductSize;
  }

  private resetProductMainSize(productId: number) {
    let indexOfProduct: number = 0;
    for (let i = 0; i < this.selectedProducts.length; i++) {
      if (this.selectedProducts[i].id === productId) {
        indexOfProduct = i;
        break;
      }
    }
    const product: ISelectedProduct = this.selectedProducts[indexOfProduct];
    this.selectedProducts.splice(indexOfProduct, 1);
    product.mainSize = true;
    this.selectedProducts.push(product);
  }

  private concatenateAndRemoveDups(firstArr: any[], secondArr: any[]) {
    const concatenatedArr = [...firstArr, ...secondArr];
    return concatenatedArr.filter((item, index, self) => index === self.findIndex((i) => i.id === item.id));
  }

  getSelectedProductName = (productId: number): string =>
    this.selectedProducts.find(({ id }) => id === productId)?.name || '';

  getSelectedProductColour = (productId: number): string =>
    this.selectedProducts.find(({ id }) => id === productId)?.colour || '';
}
