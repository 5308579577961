import axios from 'axios';

import { apiUrl } from '@isi/config/Url';

export interface IGetOrderProductsParams {
  id: string;
}

export interface IGetProductResponse {
  tax_rate: number;
  id: number;
  brand_id: number;
  sku: string;
  name: string;
  size: string;
  colour: string;
  image_url: string;
  product_url: string;
  created_at: string;
  updated_at: string;
  input_price: number;
  quantity: number;
  inspire_me: boolean;
  parent_sku: string;
  kept: boolean;
  paid_at: string;
  pre_tax_price_cents?: number;
  post_tax_price_cents?: number;
}

export const getOrderItems = async (params: IGetOrderProductsParams) => {
  const url = `${apiUrl()}/v2/orders/${params?.id}/order_items`;

  return axios.get<IGetProductResponse[]>(url);
};
