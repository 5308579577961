import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Component } from 'react';
import { type RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import bag from '@isi/assets/icons/bag.svg';
import cross from '@isi/assets/icons/cross.svg';
import tick from '@isi/assets/icons/tick.svg';
import { type IOrderProduct } from '@isi/interfaces/order-product.interface';
import { rootStore } from '@isi/stores/root.store';

import { H3 } from '@isi/components/common/h3.component';
import { LinkButton } from '@isi/components/common/link-button.component';

import { excludingTaxCalculation, includingTaxCalculation } from '../../helpers/tax_calculation';

interface IProductCompleteProps {
  additionalStyles?: string;
  dashboard: boolean;
  isGiftingOrder?: boolean;
}

const Td = styled.td`
  height: 50px;
  vertical-align: middle;
  border-top: solid 1px #cecece;
`;

@observer
class ProductComplete extends Component<RouteComponentProps<{}> & IProductCompleteProps, {}> {
  public config = rootStore.storeStore.getStoreConfig;

  private isNotDropOff = !rootStore.orderStore.dropOffOnly;

  public centsFormatted = (cents: number): string => {
    const price = cents / 100;
    const currencyCode = rootStore.storeStore.storeCurrencyCode;

    return Intl.NumberFormat(rootStore.storeStore.storeLocale, { style: 'currency', currency: currencyCode }).format(
      price,
    );
  };

  public preTaxPrice = (product: IOrderProduct) => {
    const location = this.config?.storeLocation;
    const currencyCode = rootStore.storeStore.storeCurrencyCode;

    if (location === 'LDN') {
      return Intl.NumberFormat(rootStore.storeStore.storeLocale, { style: 'currency', currency: currencyCode }).format(
        Number(excludingTaxCalculation(product.price, product.taxRate)),
      );
    }
    return Intl.NumberFormat(rootStore.storeStore.storeLocale, { style: 'currency', currency: currencyCode }).format(
      product.price,
    );
  };

  public postTaxPrice = (product: IOrderProduct) => {
    const location = this.config?.storeLocation;
    const currencyCode = rootStore.storeStore.storeCurrencyCode;

    if (location === 'NYC') {
      return Intl.NumberFormat(rootStore.storeStore.storeLocale, { style: 'currency', currency: currencyCode }).format(
        Number(includingTaxCalculation(product.price, product.taxRate)),
      );
    }
    return Intl.NumberFormat(rootStore.storeStore.storeLocale, { style: 'currency', currency: currencyCode }).format(
      product.price,
    );
  };

  public preTaxHeader = (): JSX.Element => {
    const location = this.config?.storeLocation;
    return location === 'LDN' ? (
      <div>
        Price <br /> Exc VAT
      </div>
    ) : (
      <div>
        Pre Tax <br /> Price
      </div>
    );
  };

  public postTaxHeader = (): JSX.Element => {
    const location = this.config?.storeLocation;
    return location === 'LDN' ? (
      <div>
        Price <br /> Inc VAT
      </div>
    ) : (
      <div>
        Post Tax <br /> Price
      </div>
    );
  };

  public renderCompleteProductContent(): JSX.Element {
    const numberOfProducts = rootStore.orderStore.NumberOfProducts;

    return (
      <div>
        <div
          css={css`
            margin-left: 21px;
            display: inline-block;
            margin-top: 31px;
            position: relative;
            width: calc(100% - 82px);
          `}
        >
          {this.renderImg(bag, 33, 'vertical-align: top;')}
          <H3
            bold
            additionalStyles={`
                  display: inline-block;
                  margin-left: 34px;
                  margin-top: 15px;
                  font-size: 14px;
                `}
          >
            {`Products (${numberOfProducts})`}
          </H3>
          {this.props.dashboard ? null : (
            <LinkButton
              onClick={() =>
                this.props.history.push('/products', {
                  isGiftingOrder: this.props.isGiftingOrder,
                  edit: true,
                })
              }
              additionalStyles={`
                  color: black;
                  border: none;
                  padding: 0;
                  outline: inherit;
                  text-decoration: underline;
                  font-family: MarkOT, helvetica, arial, sans-serif;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.93;
                  letter-spacing: normal;
                  position: absolute;
                  right: 0px;
                `}
              disabled={false}
            >
              Edit
            </LinkButton>
          )}
        </div>
        <div
          css={css`
                width: calc(100% - 82px);
                margin-left: 41px;
                overflow-y: scroll;
                height 230px;
              `}
        >
          <table
            css={css`
              width: 100%;
              padding: 37px;
              border-collapse: collapse;
            `}
          >
            <thead
              css={css`
                font-family: MarkOT-Bold, helvetica, arial, sans-serif;
                font-size: 10px;
                vertical-align: top;
                text-align: center;
                text-transform: uppercase;
              `}
            >
              <tr
                css={css`
                  padding-bottom: 30px;
                `}
              >
                <th>Sku</th>
                <th
                  colSpan={3}
                  css={css`
                    text-align: left;
                  `}
                >
                  Product
                </th>
                <th>{this.preTaxHeader()}</th>
                <th>{this.postTaxHeader()}</th>
                <th>Colour</th>
                <th>Qty</th>
                <th>Size</th>
                {this.config?.perfectFitEnabled && this.isNotDropOff && (
                  <th>
                    Basic
                    <br />
                    Fitting
                    <br />
                    Needed?
                  </th>
                )}
                {this.config?.inspireMeEnabled && this.isNotDropOff && (
                  <th>
                    Inspire
                    <br />
                    Item?
                  </th>
                )}
                {this.config?.alterationsEnabled && this.isNotDropOff && (
                  <th>
                    Perfect
                    <br />
                    Fit?
                  </th>
                )}
                {this.isNotDropOff && (
                  <th>
                    Payment
                    <br />
                    Required?
                  </th>
                )}
                <th>Paid at</th>
                {(this.config?.consignmentDropoffEnabled || this.config?.consignmentServicedEnabled) && (
                  <th>Consignment</th>
                )}
              </tr>
            </thead>
            <tbody
              css={css`
                text-align: center;
                font-size: 12px;
              `}
            >
              {rootStore.orderStore.orderProducts.map((product) => {
                const {
                  productId,
                  productSizeId,
                  sku,
                  name,
                  preTaxPriceCents,
                  postTaxPriceCents,
                  colour,
                  quantity,
                  size,
                  services,
                  paidAt,
                } = product;
                const packageCount = rootStore.orderStore.getPackageCount;

                return (
                  <tr key={`product-row-${productId}-${productSizeId}`}>
                    <Td>{sku}</Td>
                    <td
                      colSpan={3}
                      css={css`
                        text-align: left;
                        border-top: solid 1px #cecece;
                      `}
                    >
                      {name}
                    </td>
                    <Td>
                      {preTaxPriceCents && preTaxPriceCents >= 0
                        ? this.centsFormatted(preTaxPriceCents)
                        : this.preTaxPrice(product)}
                    </Td>
                    <Td>
                      {postTaxPriceCents && postTaxPriceCents >= 0
                        ? this.centsFormatted(postTaxPriceCents)
                        : this.postTaxPrice(product)}
                    </Td>
                    <Td>{colour}</Td>
                    <Td>{this.isNotDropOff ? quantity : packageCount}</Td>
                    <Td>{size}</Td>
                    {this.config?.perfectFitEnabled && this.isNotDropOff && (
                      <Td>{services.fittingPinning ? this.renderImg(tick, 10) : this.renderImg(cross, 10)}</Td>
                    )}
                    {this.config?.inspireMeEnabled && this.isNotDropOff && (
                      <Td>{services.inspireMe ? this.renderImg(tick, 10) : this.renderImg(cross, 10)}</Td>
                    )}
                    {this.config?.alterationsEnabled && this.isNotDropOff && (
                      <Td>{services.perfectFit ? this.renderImg(tick, 10) : this.renderImg(cross, 10)}</Td>
                    )}
                    {this.isNotDropOff && (
                      <Td>{services.paymentRequired ? this.renderImg(tick, 10) : this.renderImg(cross, 10)}</Td>
                    )}
                    <Td>{paidAt && moment.utc(paidAt).format('dddd, Do MMMM YYYY')}</Td>
                    {(this.config?.consignmentDropoffEnabled || this.config?.consignmentServicedEnabled) && (
                      <Td>
                        {rootStore.orderStore.orderValue.services.consignment
                          ? this.renderImg(tick, 10)
                          : this.renderImg(cross, 10)}
                      </Td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div />
      </div>
    );
  }

  public renderImg = (img: NodeRequire, height?: number, additionalStyles?: string): JSX.Element => (
    <img
      src={img.toString()}
      css={css`
        height: ${height || '33'}px;
        display: inline;
        ${additionalStyles}
      `}
    />
  );

  public render(): JSX.Element {
    return this.renderCompleteProductContent();
  }
}

export default withRouter(ProductComplete);
