import { css } from '@emotion/react';
import { type ReactNode } from 'react';

interface H1Props {
  children: ReactNode;
  bold?: boolean;
  additionalStyles?: string;
}

const H1 = ({ children, bold, additionalStyles }: H1Props): JSX.Element => {
  const style: string = `
    font-family: MarkOT;
    font-size: 21px;
    font-weight: ${bold ? 'bold' : 'normal'};
    font-family: ${bold ? 'MarkOT-Bold' : 'MarkOT'}, helvetica, arial, sans-serif;
    text-transform: uppercase;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: ${bold ? '0.2px' : '1.3'}px;
    text-align: center;
    color: #000000;
    ${additionalStyles}
  `;

  return (
    <h1
      css={css`
        ${style}
      `}
    >
      {children}
    </h1>
  );
};

export { H1 };
