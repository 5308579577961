// https://stackoverflow.com/a/1349426
export const randomString = (length?: number, _prefix = '') => {
  let result = _prefix;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < (length || 6); i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
