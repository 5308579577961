import { css } from '@emotion/react';
import { Component } from 'react';

import upDownArrows from '@isi/assets/icons/up-down-arrows.svg';

interface ISizeUpDownButtonProps {
  onClick: () => void;
  disabled: boolean;
  additionalStyles?: string;
}

interface ISizeUpDownState {
  disabled: boolean;
}

class SizeUpDownButton extends Component<ISizeUpDownButtonProps, ISizeUpDownState> {
  constructor(props: ISizeUpDownButtonProps) {
    super(props);
    this.state = {
      disabled: props.disabled,
    };
  }

  public renderArrowsIcon = () => {
    const icon = upDownArrows;
    return (
      <img
        src={icon.toString()}
        css={css`
          height: 13px;
          margin-left: 7px;
          display: inline-block;
          vertical-align: sub;
        `}
        alt='up down arrows'
      />
    );
  };

  public render() {
    return (
      <button
        onClick={() => this.props.onClick()}
        css={css`
          background: none;
          text-decoration: none;
          font-family: MarkOT, helvetica, arial, sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.93;
          letter-spacing: normal;
          border-radius: 30px;
          border: solid 1px #000000;
          display: block;
          margin: auto;
          margin-top: 20px;
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 0;
          padding-bottom: 0;

          &:hover {
            cursor: pointer;
          }
          &:disabled {
            color: #9b9b9b;
          }
          ${this.props.additionalStyles}
        `}
        disabled={this.state.disabled}
        type='button'
      >
        Size
        {this.renderArrowsIcon()}
      </button>
    );
  }
}

export { ISizeUpDownButtonProps, SizeUpDownButton };
