export const getEnvironment = (subdomain: string): string => {
  switch (window.location.host) {
    case `${subdomain}.lvh.me:3000`:
      return 'development';
    case `${subdomain}.toshi.test`:
      return 'development';
    case `next.${subdomain}.toshi.co`:
      return 'next';
    case `staging.${subdomain}.toshi.co`:
      return 'staging';
    case `${subdomain}.toshi.co`:
      return 'production';
    default:
      return 'production';
  }
};
