import { css } from '@emotion/react';
import { type RouteComponentProps } from 'react-router';

import { Button, ButtonSize } from '@isi/components/common/button.component';

export interface CustomersItemProps extends RouteComponentProps {
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  houseName: string | null;
  addressLine1: string;
  onSelect: () => void;
  isGiftingRecipient: boolean;
}

export const CustomersItem = ({
  customerId,
  history,
  firstName,
  lastName,
  email,
  contactNumber,
  houseName,
  addressLine1,
  onSelect,
  isGiftingRecipient,
}: CustomersItemProps) => (
  <>
    <div
      css={css`
        margin-top: 18px;
        margin-bottom: 18px;
        display: grid;
        grid-template-columns: 60% 40%;
        position: relative;
      `}
    >
      <div
        css={css`
          width: 160px;
          overflow: hidden;
          word-break: break-word;
        `}
      >
        <div
          className='fullstorymask'
          css={css`
            font-size: 14px;
          `}
        >
          {lastName}, {firstName}
        </div>
        <div
          className='fullstorymask'
          css={css`
            font-size: 12px;
            color: #9b9b9b;
          `}
        >
          {houseName} {addressLine1}
        </div>
        <div
          className='fullstorymask'
          css={css`
            font-size: 12px;
            color: #9b9b9b;
          `}
        >
          {email}
        </div>
        <div
          className='fullstorymask'
          css={css`
            font-size: 12px;
            color: #9b9b9b;
          `}
        >
          {contactNumber}
        </div>
      </div>
      {!isGiftingRecipient && (
        <>
          <Button
            buttonSize={ButtonSize.small}
            onClick={() => onSelect()}
            disabled={false}
            linkTo='/'
            additionalStyles={`
                  height: 18px;
                  width: 36px;
                  font-size: 10px;
                  line-height: 1.6;
                  border: 1px solid black;
                  position: absolute;
                  right: 0;
                  top: 10px;
                `}
          >
            SELECT
          </Button>
          <Button
            buttonSize={ButtonSize.small}
            onClick={() => onSelect()}
            disabled={false}
            linkTo={`/customers/${customerId}/edit`}
            additionalStyles={`
                  height: 18px;
                  width: 36px;
                  font-size: 10px;
                  line-height: 1.6;
                  border: 1px solid black;
                  background-color: white;
                  color: black;
                  position: absolute;
                  right: 0;
                  top: 45px;
                `}
          >
            EDIT
          </Button>
        </>
      )}
      {isGiftingRecipient && (
        <Button
          buttonSize={ButtonSize.small}
          onClick={() => {
            onSelect();
            history.push(`/customers/gifting/${customerId}/edit`);
          }}
          disabled={false}
          additionalStyles={`
                height: fit-content;
                width: fit-content;
                font-size: 10px;
                line-height: 1.6;
                border: 1px solid black;
                align-self: center;
                box-sizing: border-box;
              `}
        >
          SELECT
        </Button>
      )}
    </div>
    <div
      css={css`
        color: #d2d2d2;
        background: #d2d2d2;
        width: 100%;
        height: 1px;
      `}
    />
  </>
);
