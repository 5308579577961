import { css } from '@emotion/react';
import axios from 'axios';
import { type FC, useEffect, useRef, useState } from 'react';
import ReactPhoneInput, { type CountryData } from 'react-phone-input-2';

import 'react-phone-input-2/lib/high-res.css';
import { apiUrl } from '@isi/config/Url';

import useOnClickOutside from '@isi/hooks/use-on-click-outside.function';

import { Label } from '@isi/components/common/label.component';
import { LinkButton } from '@isi/components/common/link-button.component';

interface IPhoneInputProps {
  country?: string;
  preferredCountries?: string[];
  value?: string;
  onChange: (value: string, country: string) => void;
  labelText?: string;
  error?: string;
}

const PhoneInput: FC<IPhoneInputProps> = ({
  country,
  preferredCountries,
  onChange: propsOnChange,
  value,
  labelText,
  error,
}) => {
  const [inputCountry, setInputCountry] = useState<Partial<CountryData>>({ countryCode: country?.toLowerCase() || '' });
  const [focused, setFocused] = useState(false);
  const [validPhoneNumberCountries, setValidPhoneNumberCountries] = useState();
  const [clearable, setClearable] = useState(false);
  const node = useRef(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = () => {
    setClearable(false);
  };

  useOnClickOutside(node, handleClickOutside);

  const borderColour = () => {
    if (error) {
      return '#d20303';
    }
    if (focused) {
      return '#000000';
    }
    return '#8c8c8c';
  };

  const toggleFocusState = () => setFocused((prev) => !prev);

  const clearText = (): void => {
    if (clearable) {
      // these will never be empty strings but we have to default since we're not initialising them in initial state
      propsOnChange(inputCountry.dialCode || '', inputCountry.countryCode || '');
      phoneInputRef?.current?.focus();
    }
  };

  const onChange = (value: string, data: CountryData) => {
    setInputCountry(data);
    propsOnChange(value, data.countryCode);
  };

  useEffect(() => {
    const getValidCountries = async () => {
      const resp = await axios.get(`${apiUrl()}/v2/customers/valid_phone_countries`);
      setValidPhoneNumberCountries(resp.data);
    };

    getValidCountries();
  }, []);

  useEffect(() => {
    if (country) {
      setInputCountry((prev) => ({ ...prev, countryCode: country.toLowerCase() }));
    }
  }, [country]);

  return (
    <div
      ref={node}
      css={css`
        position: relative;
      `}
    >
      {labelText ? <Label htmlFor='phone-input' text={labelText} /> : null}
      <ReactPhoneInput
        // the component does not self-reload when props change, use key to poke it
        key={`${validPhoneNumberCountries}`}
        onlyCountries={validPhoneNumberCountries}
        country={inputCountry.countryCode}
        preferredCountries={preferredCountries}
        value={value}
        onChange={onChange}
        inputProps={{
          id: 'phone-input',
          ref: phoneInputRef,
        }}
        onFocus={toggleFocusState}
        onBlur={toggleFocusState}
        onClick={() => setClearable(true)}
        containerStyle={{
          fontFamily: 'MarkOT',
          height: '40px',
          width: '100%',
          border: `solid 1px ${borderColour()}`,
        }}
        buttonStyle={{
          fontFamily: 'MarkOT',
          height: '40px',
          border: 'none',
        }}
        inputStyle={{
          fontFamily: 'MarkOT',
          width: '100%',
          height: '40px',
          border: 'none',
        }}
        dropdownStyle={{
          fontFamily: 'MarkOT',
        }}
      />
      <LinkButton
        disabled={false}
        onClick={clearText}
        additionalStyles={`
          ${clearable ? 'opacity: 1;' : 'opacity:0;'}
          cursor: ${clearable ? 'pointer;' : 'auto!important;'}
          position: absolute;
          ${labelText ? 'top: 39px;' : 'top: 8px;'}
          right: 10px;
          z-index: 100;
      `}
      >
        Clear
      </LinkButton>
      <div
        css={css`
          color: #d20303;
          margin-top: 5px;
          font-size: 13px;
          min-height: min-content;
        `}
      >
        {error}
      </div>
    </div>
  );
};

export { PhoneInput, IPhoneInputProps };
