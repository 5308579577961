import { css } from '@emotion/react';
import { Component } from 'react';

import logo from '@isi/assets/images/toshi_logo.svg';

interface IPoweredByToshiProps {
  additionalStyles?: string;
}

class PoweredByToshi extends Component<IPoweredByToshiProps, {}> {
  public style: string = `
    font-family: MarkOT;
    font-size: 12px;
    color: #aeaeae;
    display: inline;
  `;

  public render() {
    return (
      <div
        css={css`
          margin: auto;
          width: fit-content;
          ${this.props.additionalStyles}
        `}
      >
        <div
          css={css`
            ${this.style}
          `}
        >
          Powered by
        </div>
        <img
          src={logo.toString()}
          css={css`
            height: 19px;
            display: inline;
            margin-left: 6px;
            vertical-align: middle;
          `}
        />
      </div>
    );
  }
}

export { PoweredByToshi };
