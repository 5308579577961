import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { apiUrl } from '@isi/config/Url';

import { Button, ButtonSize } from '@isi/components/common/button.component';
import { H1 } from '@isi/components/common/h1.component';
import { Input } from '@isi/components/common/input.component';
import { PoweredByToshi } from '@isi/components/common/powered-by-toshi.component';

interface SSOLoginState {
  email: string;
}

@observer
export default class SSOLogin extends Component<{}, SSOLoginState> {
  state = {
    email: '',
  };

  render(): JSX.Element {
    const ssoLogIn = async (email: string) => {
      const ssoInitUrl = `${apiUrl()}/api/v3/isi/sso/saml/init?email=${email}`;
      window.location.href = ssoInitUrl;
    };

    return (
      <div>
        <H1 additionalStyles='margin-top: 172px;'>Hello</H1>
        <div
          css={css`
            font-family: MarkOT;
            font-size: 14px;
            text-align: center;
            color: #9b9b9b;
            margin-top: 16px;
            margin-bottom: 40px;
          `}
        >
          Please enter your SSO account email
        </div>
        <div
          css={css`
            margin: auto;
            width: fit-content;
          `}
        >
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              await ssoLogIn(this.state.email);
            }}
          >
            <Input
              id='emailInput'
              type='text'
              onClick={() => {}}
              value={this.state.email}
              onChange={(email) => {
                this.setState({ email });
              }}
              disabled={false}
              labelText='Email*'
              tabIndex={1}
              additionalStyles={`
                  margin-bottom: 35px;
                `}
              clearState={() => this.setState({ email: '' })}
            />
            <Button
              id='submitBtn'
              buttonSize={ButtonSize.large}
              fullWidth
              disabled={!this.state.email}
              additionalStyles={`
                  width: 322px;
                `}
              type='submit'
            >
              SUBMIT
            </Button>
            <PoweredByToshi additionalStyles='margin-top: 279px;' />
          </form>
        </div>
      </div>
    );
  }
}

export { SSOLogin as InStoreInterfaceSSOLogin };
