import axios, { type AxiosResponse } from 'axios';

import { apiUrl } from '@isi/config/Url';
import { mapKeysToSnake } from '@isi/network/helpers/params/map-keys-to-snake.function';

export interface ICreateCustomerParams {
  customer: INewCustomerData;
  address: INewAddressData;
}

export interface CreateGifteeParams extends INewCustomerData {
  giftingNote: string | undefined;
}

export interface INewCustomerData {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  guest: boolean;
  contactNumber: string;
  contactNumberCountry: string;
  contactPreferences: number;
}

export interface INewAddressData {
  [key: string]: string | undefined;
  houseName?: string;
  line1: string;
  line2?: string;
  cityTown: string;
  postcodeZipcode: string;
  country: string;
}

export interface CreateRecipientResponse {
  id: string;
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  guest: boolean;
  contact_number: string;
  contact_number_country: string;
  contact_preferences: number;
}

export type CreateGifteeResponse = CreateRecipientResponse;

export interface ICreateCustomerResponse extends CreateRecipientResponse {
  last_address: {
    id: number;
    house_name: string | null;
    line_1: string;
    line_2: string | null;
    city_town: string | null;
    postcode_zipcode: string;
    zone: string;
    country: string;
  };
}

export const createGiftingCustomer = async (
  params: INewCustomerData,
): Promise<AxiosResponse<CreateRecipientResponse>> => {
  const url = `${apiUrl()}/v2/customers`;
  return axios.post<CreateRecipientResponse>(
    url,
    mapKeysToSnake({
      customer: params,
    }),
  );
};

export const createCustomer = (params: ICreateCustomerParams): Promise<AxiosResponse<ICreateCustomerResponse>> => {
  const url = `${apiUrl()}/v2/customers`;
  return axios.post<ICreateCustomerResponse>(url, mapKeysToSnake(params));
};
