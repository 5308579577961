import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';

import giftIcon from '@isi/assets/icons/gift-icon.svg';
import { OrderStatus } from '@isi/enums/order-status.enum';
import { rootStore } from '@isi/stores/root.store';

import useStoreContext from '@isi/hooks/useStoreContext';

import Toggle from '@isi/components/abstractions/Toggle';
import { Container } from '@isi/components/common/container.component';
import { H3 } from '@isi/components/common/h3.component';
import { GiftingProvider } from '@isi/components/common/orderGiftingContext';
import { PrintButton } from '@isi/components/common/print-button.component';
import { ContentType } from '@isi/components/create_order/ContentType.enum';
import { BoxSize, OrderStepBox } from '@isi/components/create_order/order-step-box.component';
import { OrderStepContent } from '@isi/components/create_order/order-step-content.component';

interface IOrderDetailsProps {
  dashboard: boolean;
  orderCompleted: boolean;
}

interface OrderDetailsState {
  isGiftingOrder: boolean;
  isServiceOrder: boolean;
}

const OrderTitle = observer(() => {
  const {
    orderStore: { orderValue },
  } = useStoreContext();

  if (orderValue.id === '') return null;

  return (
    <h2>
      Order #{orderValue.id}
      {orderValue.storeOrderNumber && ` / ${orderValue.storeOrderNumber}`}
    </h2>
  );
});

const PrintLabelButton = observer(() => {
  const {
    orderStore: { orderValue },
    dashboardStore: { getCurrentOrder },
  } = useStoreContext();

  if (
    orderValue.id === '' ||
    getCurrentOrder?.externalState.includes('Return') ||
    (getCurrentOrder && [OrderStatus.Completed, OrderStatus.Cancelled].includes(getCurrentOrder.status))
  )
    return null;

  return (
    <PrintButton
      orderId={orderValue.id}
      height={20}
      width={20}
      additionalButtonStyles='border: 1px solid; border-radius: 4px; padding: 8px 16px;'
      text='Print Labels'
      additionalTextStyles='margin-left: 8px;'
    />
  );
});

const ReturnsLink = observer(() => {
  const {
    orderStore: { orderValue },
    storeStore: { getStoreConfig: config },
  } = useStoreContext();

  if (orderValue.id === '') return null;

  const url = `/returns?orderid=${orderValue.id}`;
  return config?.returnBookerEnabled && !rootStore.dashboardStore.getCurrentOrder?.returnOnly ? (
    <p>
      <a
        css={css`
          font-size: 1rem;
          background-color: #1a1919;
          color: white;
          padding: 0.5rem 0.7rem;
          text-decoration: none;
          border-radius: 4px;
          :hover {
            background-color: grey;
          }
        `}
        href={url}
      >
        Return this order
      </a>
    </p>
  ) : null;
});

@observer
class OrderDetails extends Component<IOrderDetailsProps, OrderDetailsState> {
  constructor(props: IOrderDetailsProps) {
    super(props);
    this.state = {
      isGiftingOrder: rootStore.orderStore.getIsGiftedOrder,
      // eslint-disable-next-line react/no-unused-state
      isServiceOrder: true,
    };
  }

  private renderGiftingToggle = (): JSX.Element => {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          background-color: #eeeeee;
          padding: 20px;

          @media (min-width: 1024px) {
            margin-bottom: 20px;
          }
        `}
      >
        <div>
          <img
            css={css`
              height: 1rem;
            `}
            src={giftIcon}
            alt='Gift icon'
          />
          <span
            css={css`
              margin-left: 5px;
              margin-right: 5px;
              font-family: MarkOT-Bold;
            `}
          >
            Gift delivery via a TOSHI Assistant
          </span>
          <img
            css={css`
              height: 1rem;
            `}
            src={giftIcon}
            alt='Gift icon'
          />
        </div>
        <div
          css={css`
            text-align: center;
          `}
        >
          Just add sender and recipient details and TOSHI will take care of the rest!
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            gap: 10px;
            border: 2px solid #cccccc;
            border-radius: 30px;
            background-color: white;
            padding: 10px 15px 10px 25px;
          `}
        >
          <label htmlFor='gifting-order-toggle'>Schedule a gift delivery via TOSHI</label>
          {/* Some style overrides have had to be added in in_store_interface_base_styles.css to set the background color to black */}
          <Toggle
            icons={{
              unchecked: null,
            }}
            id='gifting-order-toggle'
            defaultChecked={this.state.isGiftingOrder}
            onChange={() => this.setIsGifting(!this.state.isGiftingOrder)}
          />
        </div>
      </div>
    );
  };

  private setIsGifting = (isGiftingOrder: boolean) => {
    this.setState({ isGiftingOrder });
    rootStore.orderStore.setIsGiftedOrder(isGiftingOrder);
    if (isGiftingOrder) {
      rootStore.orderStore.clearDeliverySlot();
    }
    rootStore.orderStore.clearGiftingInfo();
    rootStore.customerStore.clearGifteeInfo();
  };

  public render(): JSX.Element {
    const { dashboard, orderCompleted } = this.props;
    const isCompletedGiftedOrder = dashboard && rootStore.dashboardStore.getCurrentOrder?.giftedOrder;
    const schedulingGiftingContent =
      this.state.isGiftingOrder || isCompletedGiftedOrder ? ContentType.gifting : ContentType.dateTime;

    const config = rootStore.storeStore.getStoreConfig;
    const isUsingAdditionalServices =
      config?.alterationsEnabled ||
      config?.inspireMeEnabled ||
      config?.sizingEnabled ||
      config?.perfectFitEnabled ||
      config?.waitAndTryEnabled;

    const clientInfoComplete = this.state.isGiftingOrder
      ? rootStore.customerStore.customerValue !== null
      : rootStore.customerStore.customerDetailsComplete;

    return (
      <GiftingProvider
        value={{
          isGiftingOrder: this.state.isGiftingOrder,
          setIsGifting: this.setIsGifting,
        }}
      >
        <Container
          width='100%'
          additionalStyles={`
            width: 100%;
            max-width: 1095px;
            margin: auto;
            margin-top: 63px; `}
        >
          {!dashboard && rootStore.storeStore.getStoreConfig?.enableGiftingFeature && this.renderGiftingToggle()}
          <Container
            width='100%'
            additionalStyles={`
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin: auto;`}
          >
            <OrderTitle />
            <Container
              width='auto'
              additionalStyles={`
                display: flex;
                gap: 16px;
                margin: 0;
                align-items: center;
                `}
            >
              <PrintLabelButton />
              <ReturnsLink />
            </Container>
          </Container>
          <OrderStepBox
            boxSize={BoxSize.small}
            onClick={() => null}
            complete={clientInfoComplete}
            disabled={false}
            css={css`
              width: 100%;
              display: inline-block;
              vertical-align: top;
              @media (min-width: 1024px) {
                width: calc(50% - 18.5px);
              }
            `}
          >
            <OrderStepContent complete={clientInfoComplete} contentType={ContentType.customer} dashboard={dashboard} />
          </OrderStepBox>

          <OrderStepBox
            boxSize={BoxSize.small}
            onClick={() => null}
            complete={dashboard ? true : rootStore.orderStore.dateTimeDetailsComplete}
            disabled={
              schedulingGiftingContent !== ContentType.gifting && !rootStore.customerStore.customerDetailsComplete
            }
            css={css`
              width: 100%;
              display: inline-block;
              vertical-align: top;
              @media (min-width: 1024px) {
                width: calc(50% - 18.5px);
                margin-left: 33px;
              }
            `}
          >
            {schedulingGiftingContent === ContentType.dateTime && (
              <OrderStepContent
                complete={dashboard ? true : rootStore.orderStore.dateTimeDetailsComplete}
                contentType={ContentType.dateTime}
                dashboard={dashboard}
              />
            )}
            {schedulingGiftingContent === ContentType.gifting && (
              <OrderStepContent
                complete={dashboard ? true : rootStore.customerStore.gifteeDetailsComplete}
                contentType={ContentType.gifting}
                dashboard={dashboard}
              />
            )}
          </OrderStepBox>

          {dashboard && isUsingAdditionalServices && (
            <div
              css={css`
                @media (min-width: 1024px) {
                  margin-top: 36px;
                }
              `}
            >
              <OrderStepBox boxSize={BoxSize.small} disabled={false} onClick={() => null} complete={dashboard}>
                <OrderStepContent contentType={ContentType.orderServices} dashboard={dashboard} complete={dashboard} />
              </OrderStepBox>
            </div>
          )}
          {dashboard && rootStore.dashboardStore.getCurrentOrder?.inspireMeCustomerNotes && (
            <OrderStepBox boxSize={BoxSize.small} disabled={false} onClick={() => null} complete={dashboard}>
              <OrderStepContent
                complete={rootStore.orderStore.atLeastOneProductSet}
                contentType={ContentType.inspireMeNotes}
                dashboard={dashboard}
              />
            </OrderStepBox>
          )}

          <div
            css={
              orderCompleted && !rootStore.orderStore.orderNotes
                ? css`
                    border-bottom: 1px solid black;
                  `
                : ''
            }
          >
            <div
              css={css`
                @media (min-width: 1024px) {
                  margin-top: 36px;
                  margin-bottom: 36px;
                }
              `}
            >
              <OrderStepBox
                boxSize={BoxSize.large}
                onClick={() => null}
                complete={rootStore.orderStore.atLeastOneProductSet}
                disabled={false}
              >
                <OrderStepContent
                  complete={rootStore.orderStore.atLeastOneProductSet}
                  contentType={ContentType.product}
                  dashboard={dashboard}
                  isGiftingOrder={this.state.isGiftingOrder}
                />
              </OrderStepBox>
            </div>
          </div>

          {dashboard && rootStore.orderStore.orderNotes ? (
            <div
              css={
                orderCompleted
                  ? css`
                      border-bottom: 1px solid black;
                    `
                  : ''
              }
            >
              <div
                css={css`
                  background-color: #f7f7f7;
                  padding-left: 21px;
                  padding-top: 18px;
                  padding-bottom: 18px;
                  margin-bottom: 36px;
                `}
              >
                <H3 bold>Notes</H3>
                <p
                  className='fullstorymask'
                  css={css`
                    font-size: 12px;
                    padding-left: 20px;
                  `}
                >
                  {rootStore.orderStore.orderNotes}
                </p>
              </div>
            </div>
          ) : null}

          {dashboard && !orderCompleted ? (
            <div
              css={css`
                width: 970px;
                text-align: center;
                padding-bottom: 25px;
                border-bottom: 1px solid black;
                @media (min-width: 1024px) {
                  margin-top: 36px;
                }
              `}
            >
              <H3 additionalStyles='margin: 4px;' bold>
                Want to cancel?
              </H3>
              <p
                css={css`
                  color: #9e9e9e;
                  font-size: 12px;
                  margin: 4px;
                `}
              >
                Please contact TOSHI to cancel your delivery
              </p>
            </div>
          ) : null}
        </Container>
      </GiftingProvider>
    );
  }
}

export { OrderDetails, IOrderDetailsProps as OrderDetailsProps };
