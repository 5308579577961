import axios, { type AxiosResponse } from 'axios';

import { apiUrl } from '@isi/config/Url';

interface IGetStoreTaxCodes {
  storeId: number;
}

interface IGetStoreTaxCodesResponse {
  code: string;
  name: string;
  description: string;
}

export const getStoreTaxCodes = async (
  params: IGetStoreTaxCodes,
): Promise<AxiosResponse<IGetStoreTaxCodesResponse[]>> => {
  const url = `${apiUrl()}/v2/stores/${params.storeId}/tax_codes`;

  return axios.get(url);
};
