import { ContactPreferences } from '@isi/enums/contact_preferences.enum';
import { type ICustomerFormState } from '@isi/interfaces/customer-form-state.interface';
import { type INewCustomerErrors } from '@isi/interfaces/new-customer-errors.interface';

export const blankState = (loading?: boolean): ICustomerFormState => {
  const defaultErrors: INewCustomerErrors = {
    cityTown: '',
    contactNumber: '',
    email: '',
    line1: '',
    firstName: '',
    postcodeZipcode: '',
    lastName: '',
    title: '',
    clientAwareOfServiceCoverage: '',
  };

  return {
    customer: {
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      guest: false,
      contactNumber: '',
      contactNumberCountry: '',
      contactPreferences: ContactPreferences.BOTH,
    },
    address: {
      houseName: '',
      line1: '',
      line2: '',
      cityTown: '',
      postcodeZipcode: '',
      country: '',
    },
    touched: {
      title: false,
      firstName: false,
      lastName: false,
      email: false,
      contactNumber: false,
      houseName: false,
      line1: false,
      line2: false,
      cityTown: false,
      postcodeZipcode: false,
      contactPreferences: false,
    },
    postcodeEligible: false,
    displayPostcodeEligibilityError: false,
    displayAllErrors: false,
    phoneNumberFocused: false,
    manualAddressEntry: false,
    loading: !!loading,
    errors: defaultErrors,
    isGiftRecipient: false,
    giftingNote: undefined,
  };
};
