import { css } from '@emotion/react';
import { type ReactNode } from 'react';

import { Container } from '@isi/components/common/container.component';

interface IBottomNavbarProps {
  children?: ReactNode;
}

const BottomNavbar = ({ children }: IBottomNavbarProps) => (
  <div
    css={css`
      bottom: 0;
      width: 100%;
      transition: all 0.3s ease;
      z-index: 2147483100;
      background-color: #e1e1e1;
      position: fixed;
      height: 150px;

      @media (min-width: 1024px) {
        height: 115px;
        position: sticky;
      }
    `}
  >
    <Container
      width='calc(100% - 40px)'
      additionalStyles={`
      margin-left: 20px;
      max-width: 726px;
      margin: auto;
      position: relative;
    `}
    >
      {children}
    </Container>
  </div>
);

export default BottomNavbar;

export { IBottomNavbarProps };
