import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { Button, ButtonSize } from '@isi/components/common/button.component';
import { LinkButton } from '@isi/components/common/link-button.component';

interface IDeleteProductsNavContentProps {
  onDeleteClick: () => void;
  onCancelClick: () => void;
}

interface IDeleteProductsNavContentState {
  amountToDelete: number;
}

@observer
class DeleteProductsNavContent extends Component<IDeleteProductsNavContentProps, IDeleteProductsNavContentState> {
  constructor(props: IDeleteProductsNavContentProps) {
    super(props);
    this.state = {
      amountToDelete: 0,
    };
  }

  public updateAmountToDelete = (amountToDelete: number): void => {
    this.setState({ amountToDelete });
  };

  public render(): JSX.Element {
    return (
      <div
        css={css`
          position: absolute;
          top: 35px;
        `}
      >
        <Button onClick={() => this.props.onDeleteClick()} disabled={false} buttonSize={ButtonSize.large}>
          Delete ({this.state.amountToDelete})
        </Button>
        <LinkButton onClick={() => this.props.onCancelClick()} disabled={false} additionalStyles='margin-left: 45px;'>
          Cancel
        </LinkButton>
      </div>
    );
  }
}

export { DeleteProductsNavContent, IDeleteProductsNavContentProps };
