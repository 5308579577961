import { css } from '@emotion/react';

const FiltersAppliedCount = ({ count }: { count: number }): JSX.Element => (
  <span
    css={css`
      font-size: 14px;
      background: white;
      color: black;
      width: 15px;
      border-radius: 50%;
      border: 1px solid;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  >
    {count}
  </span>
);

export default FiltersAppliedCount;
