/* eslint-disable no-console */
import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import { computed, flow, makeObservable, observable } from 'mobx';

import { CityCodes } from '@isi/enums/city-codes.enum';
import { type IStoreConfig } from '@isi/interfaces/store-config.interface';
import { storeConfig } from '@isi/network/config/store-config.function';
import { mapKeysToCamel } from '@isi/network/helpers/params/map-keys-to-camel.function';
import type { RootStore } from '@isi/stores/root.store';

export class StoreStore {
  constructor(private readonly rootStore: RootStore) {
    makeObservable<StoreStore, 'storeConfig'>(this, {
      storeConfig: observable,
      fetchStoreConfig: flow.bound,
      getStoreConfig: computed,
      storeCurrency: computed,
      storeCurrencyCode: computed,
      storeLocale: computed,
    });
  }

  private storeConfig: IStoreConfig | null = null;

  private setAccessHeaders(this: this, apiKey: string) {
    axios.defaults.headers.common['X-Toshi-Client-Api-Key'] = apiKey;
  }

  *fetchStoreConfig(): Generator<ReturnType<typeof storeConfig>, void, Awaited<ReturnType<typeof storeConfig>>> {
    try {
      const res = yield storeConfig();

      const data = res?.data;

      this.storeConfig = mapKeysToCamel({
        ...data,
        brandUserRoles: this.rootStore.authStore?.user?.roles || [],
      });

      this.setAccessHeaders(data.key);

      this.rootStore.orderStore.setUseSecurityCodes(data.security_code_toggle_default);
    } catch (error: any) {
      if (!error?.response) {
        if (error instanceof Error) {
          console.log((error as Error).message);
          console.log((error as Error).stack);
          console.log((error as Error).name);
        } else {
          console.log(JSON.stringify(error));
        }
        Bugsnag.leaveBreadcrumb('attempted to load store config');
        Bugsnag.notify(error);
      }
      if (error?.response?.status !== 401) Promise.reject(error);
    }
  }

  public get getStoreConfig(): IStoreConfig | null {
    return this.storeConfig;
  }

  public get storeCurrency(): string {
    switch (this.getStoreConfig?.storeLocation) {
      case CityCodes.NYC:
      case CityCodes.LA:
        return '$';
      default:
        return '£';
    }
  }

  public get storeCurrencyCode(): string {
    switch (this.getStoreConfig?.storeLocation) {
      case CityCodes.NYC:
      case CityCodes.LA:
        return 'usd';
      default:
        return 'gbp';
    }
  }

  public get storeLocale(): string {
    switch (this.getStoreConfig?.storeLocation) {
      case CityCodes.NYC:
      case CityCodes.LA:
        return 'en-US';
      default:
        return 'en-GB';
    }
  }
}
