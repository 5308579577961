import { css } from '@emotion/react';

import { Button, ButtonSize } from '@isi/components/common/button.component';
import { CloseButton } from '@isi/components/common/close-button.component';
import { H1 } from '@isi/components/common/h1.component';
import { LinkButton } from '@isi/components/common/link-button.component';

interface CancelOrderModalContentProps {
  closeClicked: () => void;
  cancelOrderClicked: () => void;
}

const CancelOrderModalContent = ({ cancelOrderClicked, closeClicked }: CancelOrderModalContentProps) => (
  <div>
    <CloseButton
      onClick={() => closeClicked()}
      additionalStyles={`
          position: absolute;
          top: 20px;
          right: 20px;
        `}
    />
    <div
      css={css`
        max-width: 240px;
        margin: 60px auto auto auto;
      `}
    >
      <H1 bold>are you sure?</H1>
      <p
        css={css`
          text-align: center;
          font-size: 14px;
          margin-top: 20px;
          line-height: 1.5;
        `}
      >
        {"This will erase all the information you've entered."}
      </p>
      <Button
        buttonSize={ButtonSize.large}
        disabled={false}
        onClick={() => cancelOrderClicked()}
        additionalStyles={`
              margin: 32px auto auto auto;
              display: block;
            `}
      >
        yes, cancel
      </Button>
      <LinkButton
        onClick={() => closeClicked()}
        disabled={false}
        additionalStyles={`
            margin: 22px auto auto auto;
            display: block;
          `}
      >
        No, keep
      </LinkButton>
    </div>
  </div>
);

export default CancelOrderModalContent;
