import styled from '@emotion/styled';

import useStoreContext from '@isi/hooks/useStoreContext';

const Wrapper = styled.div`
  height: 100%;
`;

export const Resources = () => {
  const {
    storeStore: { getStoreConfig },
  } = useStoreContext();

  switch (getStoreConfig?.storeLocation) {
    case 'LDN':
      return (
        <Wrapper>
          <object data='/documents/London_Resources.pdf' type='application/pdf' width='100%' height='100%' />
        </Wrapper>
      );
    case 'NYC':
      return (
        <Wrapper>
          <object data='/documents/New_York_Resources.pdf' type='application/pdf' width='100%' height='100%' />
        </Wrapper>
      );
    case 'LA':
      return (
        <Wrapper>
          <object data='/documents/Los_Angeles_Resources.pdf' type='application/pdf' width='100%' height='100%' />
        </Wrapper>
      );
    default:
      return <div>No service area found.</div>;
  }
};
