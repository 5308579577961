import { css, Global } from '@emotion/react';
import T, { type ToggleProps } from 'react-toggle';
import 'react-toggle/style.css';

const Toggle = (props: ToggleProps) => (
  <>
    <Global
      styles={css`
        .react-toggle .react-toggle-track {
          background-color: #cccccc;
        }
        .react-toggle.react-toggle--checked .react-toggle-track {
          background-color: black;
        }
        .react-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
          background-color: black;
        }
      `}
    />
    <T {...props} />
  </>
);

export default Toggle;
