import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { type ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import logoutIconWhite from '@isi/assets/icons/logout-white.svg';
import userIcon from '@isi/assets/icons/user.svg';
import { BrandUserRole } from '@isi/stores/models/brand-user.model';
import { rootStore } from '@isi/stores/root.store';

import useAuthContext from '@isi/hooks/useAuthContext';
import useToshiDetails from '@isi/hooks/useToshiDetails.function';

interface SideNavProps {
  children: ReactNode;
}

type OpenProp = {
  open: boolean;
};

const SideNavContainer = styled.nav<OpenProp>`
  vertical-align: top;
  display: inline-block;
  position: fixed;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: ${({ open }) => (open ? '80vw' : '0')};
  background-color: #414141;
  transition: width ease-out 0.5s;
  box-shadow: inset -12px 0px 11px -10px rgba(0, 0, 0, 0.49);
  z-index: 2;
`;

const AppWrapper = styled.div<OpenProp>`
  margin-left: ${({ open }) => (open ? '80vw' : '0')};
  transition: margin-left ease-out 0.5s;
  width: 100vw;
`;

const SideNav = ({ children }: SideNavProps): JSX.Element => {
  const [contactDropdownOpen, setContactDropdownOpen] = useState(false);
  const { user: brandUser, logOut: _logOut } = useAuthContext();
  const { contactEmail, contactPhone } = useToshiDetails();

  const sharedStyles = () => `
    color: white;
    font-family: MarkOT, Arial, Helvetica, sans-serif;
    text-decoration: none;
    display: block;
    font-size: 12px;
    opacity: ${rootStore.uiStore.isSideNavOpen ? '1' : '0'};
    transition: opacity ease-in 0.2s;
  `;

  const linkStyle = (delayMS: number) => css`
    ${sharedStyles()}
    transition-delay: ${delayMS}s;
    margin-bottom: 20px;
  `;

  const dropdownStyle = (delayMS: number) => css`
    ${sharedStyles()}
    transition-delay: ${delayMS}s;
    margin-bottom: 10px;
  `;

  const dropdownItemStyle = (delayMS: number) => css`
    ${sharedStyles()}
    transition-delay: ${delayMS}s;
    margin: 5px;
  `;

  const logoutLinkStyle = (delayMS: number) => css`
    ${linkStyle(delayMS)}
    font-family: MarkOT-Bold, Arial, Helvetica, sans-serif;
    opacity: ${rootStore.uiStore.isSideNavOpen ? '1' : '0'};
    transition: opacity ease-in 0.4s;
    transition-delay: ${delayMS}s;
  `;

  const logOut = (): void => {
    _logOut();
    rootStore.uiStore.toggleSideNav();
  };

  const logoutSection = () => (
    <div
      css={css`
        position: absolute;
        bottom: 20px;
        left: 30px;
      `}
    >
      <a onClick={logOut} css={logoutLinkStyle(1)}>
        Logout
        <img
          src={logoutIconWhite}
          css={css`
            height: 15px;
            position: absolute;
            left: 50px;
            bottom: 19px;
          `}
        />
      </a>
    </div>
  );

  const toggleContactDropdown = () => setContactDropdownOpen((prev) => !prev);

  const linkSection = () => (
    <div
      css={css`
        margin-top: 70px;
      `}
    >
      {brandUser?.hasRole(BrandUserRole.Booker) && (
        <Link to='/' onClick={rootStore.uiStore.toggleSideNav} css={linkStyle(0.4)}>
          Home
        </Link>
      )}
      <Link to='/dashboard' onClick={rootStore.uiStore.toggleSideNav} css={linkStyle(0.5)}>
        Dashboard
      </Link>
      <Link to='/resources' onClick={rootStore.uiStore.toggleSideNav} css={linkStyle(0.6)}>
        Resources
      </Link>
      {brandUser?.hasRole(BrandUserRole.Reporting) && (
        <Link to='/reports' onClick={rootStore.uiStore.toggleSideNav} css={linkStyle(0.7)}>
          Reports
        </Link>
      )}

      <button
        css={css`
          font-size: 12px;
          color: #fff;
          display: block;
          border: none;
          background: none;
          width: 100%;
          text-align: left;
          cursor: pointer;
          outline: none;
          padding: 0;
          ${dropdownStyle(0.6)}
        `}
        onClick={toggleContactDropdown}
        type='button'
      >
        Contact TOSHI
        <i
          className={`fa ${contactDropdownOpen ? 'fa-caret-up' : 'fa-caret-down'}`}
          css={css`
            float: right;
            padding-right: 8px;
          `}
        />
      </button>
      <div
        css={css`
          display: ${contactDropdownOpen ? 'flex' : 'none'};
          background-color: #2626267a;
          padding: 5px;
          flex-direction: column;
          border-radius: 2px;
        `}
      >
        <a
          css={css`
            ${dropdownItemStyle(0.5)}
            margin: 5px;
          `}
          href={`mailto:${contactEmail}`}
        >
          {contactEmail}
        </a>
        <a
          css={css`
            ${dropdownItemStyle(0.5)}
            margin: 5px;
          `}
          href={`tel:${contactPhone.number}`}
        >
          {contactPhone.formatted}
        </a>
      </div>
    </div>
  );

  const userSection = () => (
    <div
      css={css`
        margin-top: 20px;
      `}
    >
      <img
        src={userIcon}
        css={css`
          height: 15px;
          display: inline-block;
        `}
      />
      <div
        css={css`
          display: inline-block;
          font-family: MarkOT-Bold, Arial, Helvetica, sans-serif;
          color: white;
          margin-left: 10px;
          opacity: ${rootStore.uiStore.isSideNavOpen ? '1' : '0'};
          transition: opacity ease-in 0.2s;
          transition-delay: 0.4s;
          margin-left: 30px;
        `}
      >
        {rootStore.storeStore.getStoreConfig?.brandName}
        <span
          css={css`
            margin-left: 4px;
            font-family: MarkOT, Arial, Helvetica, sans-serif;
          `}
        >
          {rootStore.orderStore?.storeAssistantName}
        </span>
      </div>
    </div>
  );

  return (
    <>
      <SideNavContainer open={rootStore.uiStore.isSideNavOpen}>
        <div
          css={css`
            opacity: ${rootStore.uiStore.isSideNavOpen ? '1' : '0'};
            transition: opacity ease-in 0.2s;
            transition-delay: 0.4s;
            margin-left: 30px;
            z-index: 3;
          `}
        >
          {userSection()}
          {linkSection()}
          {logoutSection()}
        </div>
      </SideNavContainer>
      <AppWrapper open={rootStore.uiStore.isSideNavOpen}>{children}</AppWrapper>
    </>
  );
};

export default observer(SideNav);
