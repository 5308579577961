import { isEqual } from 'lodash';

export type FilterStatus = 'open' | 'closed';
export type FilterStatusType = 'delivery' | 'return';

export interface FilterPayload {
  excludeStatus: FilterStatus[];
  excludeStatusType: FilterStatusType[];
  orderValueRange: { minOrderValue: number; maxOrderValue: number };
  excludeZones: string[];
}

function isFilterPayload(payload: FilterPayload | string): payload is FilterPayload {
  return (payload as FilterPayload).excludeStatus !== undefined;
}

export enum SearchQueryReducerActionTypes {
  SetTerm = 'set_term',
  SetCurrentPage = 'set_current_page',
  SetPageRows = 'set_page_rows',
  SetFilter = 'set_filter',
  SortStorePickupTime = 'sort_store_pickup_time',
  SortStoreOrderNumber = 'sort_store_order_number',
  SortId = 'sort_id',
  SortProductMode = 'sort_product_mode',
  SortStatus = 'sort_status',
  SortOrderTotal = 'sort_order_total',
  SortDeliveryDate = 'sort_delivery_date',
}

type SortColumnsESIndexName =
  | 'start_datetime'
  | 'store_order_number'
  | 'hashed_id'
  | 'product_mode'
  | 'status'
  | 'post_tax_order_total_cents';

const mapActionTypeToSortColumnEsName = (item: SearchQueryReducerActionTypes): SortColumnsESIndexName | undefined => {
  switch (item) {
    case (SearchQueryReducerActionTypes.SortStorePickupTime, SearchQueryReducerActionTypes.SortDeliveryDate):
      return 'start_datetime';
    case SearchQueryReducerActionTypes.SortStoreOrderNumber:
      return 'store_order_number';
    case SearchQueryReducerActionTypes.SortId:
      return 'hashed_id';
    case SearchQueryReducerActionTypes.SortProductMode:
      return 'product_mode';
    case SearchQueryReducerActionTypes.SortStatus:
      return 'status';
    case SearchQueryReducerActionTypes.SortOrderTotal:
      return 'post_tax_order_total_cents';
    // no default
  }
  return undefined;
};

const sortableValues = new Set([
  SearchQueryReducerActionTypes.SortStorePickupTime,
  SearchQueryReducerActionTypes.SortStoreOrderNumber,
  SearchQueryReducerActionTypes.SortId,
  SearchQueryReducerActionTypes.SortProductMode,
  SearchQueryReducerActionTypes.SortStatus,
  SearchQueryReducerActionTypes.SortOrderTotal,
  SearchQueryReducerActionTypes.SortDeliveryDate,
]);

type SortCols = {
  [colType in SortColumnsESIndexName]?: 'asc' | 'desc';
};

export interface SearchQuery {
  term: any;
  sortCols: SortCols;
  cols: SortColumnsESIndexName[];
  page: number;
  per: number;
  excludeStates: FilterStatus[];
  minOrderValue: number;
  maxOrderValue: number;
  noChange: boolean;
  excludeZones: string[];
}

export type SearchQueryActions = { type: SearchQueryReducerActionTypes; payload?: FilterPayload | string };

export const searchQueryReducer = (state: SearchQuery, action: SearchQueryActions): SearchQuery => {
  if (action.type === SearchQueryReducerActionTypes.SetFilter) {
    if (action.payload && isFilterPayload(action.payload)) {
      const newState = {
        ...state,
        excludeStates: action.payload.excludeStatus,
        excludestatestype: action.payload.excludeStatusType,
        minOrderValue: action.payload.orderValueRange.minOrderValue,
        maxOrderValue: action.payload.orderValueRange.maxOrderValue,
        excludeZones: action.payload.excludeZones,
      };

      if (isEqual(newState, state)) {
        return { ...state, noChange: true };
      }

      return { ...newState, noChange: false, page: 0 };
    }

    return state;
  }

  if (action.type === SearchQueryReducerActionTypes.SetTerm) {
    return {
      ...state,
      term: action.payload || '',
      noChange: false,
      page: 0,
    };
  }

  if (action.type === SearchQueryReducerActionTypes.SetCurrentPage) {
    return {
      ...state,
      page: Number(action.payload),
      noChange: false,
    };
  }

  if (action.type === SearchQueryReducerActionTypes.SetPageRows) {
    return {
      ...state,
      per: Number(action.payload),
      page: 0,
      noChange: false,
    };
  }

  if (sortableValues.has(action.type)) {
    const type = mapActionTypeToSortColumnEsName(action.type);

    if (!type) {
      return state;
    }

    if (state.sortCols[type] === undefined) {
      const newCols = [...state.cols];
      newCols.push(type);

      return {
        term: state.term,
        sortCols: {
          ...state.sortCols,
          [type]: 'desc',
        },
        cols: newCols,
        page: state.page,
        per: state.per,
        excludeStates: state.excludeStates,
        noChange: false,
        minOrderValue: state.minOrderValue,
        maxOrderValue: state.maxOrderValue,
        excludeZones: state.excludeZones,
      };
    }
    if (state.sortCols[type] === 'desc') {
      return {
        term: state.term,
        cols: state.cols,
        page: state.page,
        per: state.per,
        sortCols: {
          ...state.sortCols,
          [type]: 'asc',
        },
        excludeStates: state.excludeStates,
        noChange: false,
        minOrderValue: state.minOrderValue,
        maxOrderValue: state.maxOrderValue,
        excludeZones: state.excludeZones,
      };
    }
    if (state.sortCols[type] === 'asc') {
      const newSortCols = {
        ...state.sortCols,
      };
      delete newSortCols[type];
      return {
        term: state.term,
        cols: state.cols.filter((col) => col !== type),
        sortCols: newSortCols,
        page: state.page,
        per: state.per,
        excludeStates: state.excludeStates,
        noChange: false,
        minOrderValue: state.minOrderValue,
        maxOrderValue: state.maxOrderValue,
        excludeZones: state.excludeZones,
      };
    }
  }
  return state;
};
