import { observer } from 'mobx-react';
import { Component } from 'react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

import { type IProductCatalogueItem } from '@isi/interfaces/IProductCatalogueItem.interface';
import { type ProductStore } from '@isi/stores/product.store';

import { Container } from '@isi/components/common/container.component';
import { ProductItem } from '@isi/components/products/select-products/product-item.component';

interface IProductGridProps {
  products: IProductCatalogueItem[];
  productStore: ProductStore;
  pageNumber: number;
  search?: string;
}

interface IProductItemRefs {
  id: number;
  ref: ProductItem | null;
}

@observer
class ProductGrid extends Component<IProductGridProps> {
  public productItemRefs: IProductItemRefs[] = [];

  public renderProductCatalogueItems = (): JSX.Element[] => {
    const productItemElements: JSX.Element[] = [];
    for (const product of this.props.products) {
      const selected = this.props.productStore.checkIfProductIsSelected(product.id);
      productItemElements.push(
        <ProductItem
          ref={(productItem) => {
            this.productItemRefs.push({
              id: product.id,
              ref: productItem,
            });
          }}
          key={`product-${product.id}`}
          onClick={(isSelected: boolean) => this.addOrRemoveFromSelectedProducts(product, isSelected)}
          imageUrl={product.imageUrl}
          name={product.name}
          price={product.inputPrice}
          sku={product.sku}
          colour={product.colour}
          selected={selected}
        />,
      );
    }
    return productItemElements;
  };

  public clearSelection = () => {
    const selectedProductIds = this.props.productStore.getSelectedProducts;
    for (const product of selectedProductIds) {
      for (const item of this.productItemRefs) {
        if (item.id === product.id) {
          item.ref?.forceUnselected();
        }
      }
    }
    this.props.productStore.clearSelectedProducts();
  };

  private addOrRemoveFromSelectedProducts = (product: IProductCatalogueItem, selected: boolean): void => {
    if (selected) {
      this.props.productStore.addToSelectedProducts(product);
      return;
    }
    this.props.productStore.removeFromSelectedProducts(product.id);
  };

  public render() {
    const nextPage = this.props.pageNumber + 1;
    return (
      <Container
        id='productContainer'
        width='100%'
        additionalStyles={`
              max-width: 766px;
              margin: auto;
              margin-bottom: 150px;
              display: grid;
              grid-template-columns: repeat(2, 50%);
              @media (min-width: 1024px) {
                grid-template-columns: repeat(4, 25%);
              }
            `}
      >
        {this.props.products.length > 0 ? (
          <BottomScrollListener
            triggerOnNoScroll={false}
            onBottom={() => this.props.productStore.loadProducts(nextPage, this.props.search)}
          >
            <>{this.renderProductCatalogueItems()}</>
          </BottomScrollListener>
        ) : null}
      </Container>
    );
  }
}

export { ProductGrid, IProductGridProps };
