import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import axios from 'axios';
import React, { Fragment, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { RootStoreContext } from '@isi/contexts/root-store.context';
import { rootStore } from '@isi/stores/root.store';
import { getEnvironment } from '@root/getEnvironment';
import InStoreInterface from '@root/InStoreInterface';

axios.interceptors.request.use((_config) => {
  const config = { ..._config };
  if (/amazonaws/.test(config.url)) delete config.headers.common.Authorization;

  return config;
});

axios.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) rootStore.authStore.setJwtExpired(true);
  else throw error;
});

// BugSnag wants to be used with the node plugin in tests
// defining it as a dumb component in test environment solves this

let ErrorBoundary = Fragment;
if (!TEST) {
  Bugsnag.start({
    apiKey: 'ca992bc62d8077433327c32a4f653000',
    plugins: [new BugsnagPluginReact()],
    releaseStage: getEnvironment('isi'),
  });
  const plugin = Bugsnag?.getPlugin('react');
  ErrorBoundary = plugin?.createErrorBoundary(React);
}

const App = () => (
  <StrictMode>
    <ErrorBoundary>
      <Router>
        <RootStoreContext.Provider value={rootStore}>
          <InStoreInterface />
        </RootStoreContext.Provider>
      </Router>
    </ErrorBoundary>
  </StrictMode>
);

const root = createRoot(document.querySelector('#root'));

root.render(<App />);
