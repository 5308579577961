import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';

import { OrderStatus } from '@isi/enums/order-status.enum';
import { type IDashboardOrder } from '@isi/interfaces/dashboard-order.interface';
import {
  type SearchQuery,
  type SearchQueryActions,
  SearchQueryReducerActionTypes,
} from '@isi/interfaces/search-query.interface';
import { rootStore } from '@isi/stores/root.store';

import useStoreContext from '@isi/hooks/useStoreContext';

import { H1 } from '@isi/components/common/h1.component';
import { OrderDetails } from '@isi/components/common/order-details.component';
import Pagination from '@isi/components/common/pagination.component';
import { theme } from '@isi/components/order_dashboard/mui-theme';
import OrderRow from '@isi/components/order_dashboard/orders/order-row.component';

export interface IOrders {
  orders: IDashboardOrder[];
  reloadOrders: (state: any) => void;
  queryState: SearchQuery;
  dispatch: React.Dispatch<SearchQueryActions>;
}

const sortIcon = (icon: IconProp) => <FontAwesomeIcon aria-hidden='false' icon={icon} />;

const sortedAsc = sortIcon(faChevronUp);
const sortedDesc = sortIcon(faChevronDown);

const orderComplete = (order: IDashboardOrder) => order.status === OrderStatus.Completed;

export const Orders = observer(({ orders, dispatch, queryState }: IOrders): JSX.Element => {
  const {
    dashboardStore: { getCurrentOrder, getTotalOrderHits, ordersLoading, anyOrders },
  } = useStoreContext();

  const {
    storeStore: { getStoreConfig },
  } = rootStore;

  const gridTemplateColumns = getStoreConfig?.warehouseToDoor ? 'repeat(7, 125px) 60px' : '160px repeat(8, 125px) 60px';

  const isCurrentOrder = (order: IDashboardOrder) => {
    if (!getCurrentOrder) {
      return false;
    }
    return getCurrentOrder.id === order.id;
  };

  return (
    <div
      role='table'
      css={css`
        width: 100%;
        text-align: left;
        height: 128px;
      `}
    >
      <div
        role='row'
        css={css`
          font-family: MarkOT-Bold, Arial, Helvetica, sans-serif;
          font-size: 12px;
          margin: auto;
          width: fit-content;
          display: grid;
          padding-top: 45px;
          padding-bottom: 15px;
          grid-template-columns: ${gridTemplateColumns};
          border-bottom: 1px solid #e1e1e1;
        `}
      >
        {!getStoreConfig?.warehouseToDoor && (
          <>
            <div
              role='button'
              tabIndex={0}
              onClick={() => dispatch({ type: SearchQueryReducerActionTypes.SortStorePickupTime })}
              css={css`
                display: flex;
                align-items: center;
                gap: 15px;
                &:hover {
                  cursor: pointer;
                }
              `}
            >
              <div>
                PICK UP <br />
                DATE
              </div>
              {queryState.sortCols.start_datetime === 'asc' && sortedAsc}{' '}
              {queryState.sortCols.start_datetime === 'desc' && sortedDesc}
            </div>
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 15px;
              `}
            >
              PICK UP <br />
              TIME
            </div>
          </>
        )}
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 15px;
            &:hover {
              cursor: pointer;
            }
          `}
          role='button'
          tabIndex={0}
          onClick={() => dispatch({ type: SearchQueryReducerActionTypes.SortId })}
        >
          <div className='order-number'>
            ORDER <br />
            NUMBER
          </div>{' '}
          {queryState.sortCols.hashed_id === 'asc' && sortedAsc}{' '}
          {queryState.sortCols.hashed_id === 'desc' && sortedDesc}
        </div>
        <div>
          PRINT <br />
          LABELS
        </div>
        {!getStoreConfig?.warehouseToDoor && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 15px;
            `}
          >
            PICKUP CODE
          </div>
        )}
        {getStoreConfig?.warehouseToDoor && (
          <div
            role='button'
            tabIndex={0}
            onClick={() => dispatch({ type: SearchQueryReducerActionTypes.SortDeliveryDate })}
            css={css`
              display: flex;
              align-items: center;
              gap: 15px;
              &:hover {
                cursor: pointer;
              }
            `}
          >
            DELIVERY DATE
          </div>
        )}
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 15px;
            &:hover {
              cursor: pointer;
            }
          `}
          role='button'
          tabIndex={0}
          onClick={() => dispatch({ type: SearchQueryReducerActionTypes.SortStoreOrderNumber })}
        >
          <div>
            STORE <br />
            ORDER NUMBER
          </div>{' '}
          {queryState.sortCols.store_order_number === 'asc' && sortedAsc}{' '}
          {queryState.sortCols.store_order_number === 'desc' && sortedDesc}
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 15px;
            &:hover {
              cursor: pointer;
            }
          `}
          role='button'
          tabIndex={0}
          onClick={() => dispatch({ type: SearchQueryReducerActionTypes.SortProductMode })}
        >
          SOURCE {queryState.sortCols.product_mode === 'asc' && sortedAsc}{' '}
          {queryState.sortCols.product_mode === 'desc' && sortedDesc}
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 15px;
            &:hover {
              cursor: pointer;
            }
          `}
          role='button'
          tabIndex={0}
          onClick={() => dispatch({ type: SearchQueryReducerActionTypes.SortStatus })}
        >
          STATUS {queryState.sortCols.status === 'asc' && sortedAsc}{' '}
          {queryState.sortCols.status === 'desc' && sortedDesc}
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 15px;
          `}
          role='button'
          tabIndex={0}
          onClick={() => dispatch({ type: SearchQueryReducerActionTypes.SortOrderTotal })}
        >
          TOTAL {queryState.sortCols.post_tax_order_total_cents === 'asc' && sortedAsc}{' '}
          {queryState.sortCols.post_tax_order_total_cents === 'desc' && sortedDesc}
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 15px;
          `}
        >
          DETAILS
        </div>
      </div>
      {!ordersLoading &&
        (anyOrders ? (
          orders.map((order) => {
            const currentOrder: boolean = isCurrentOrder(order);

            return (
              <div key={`order-row-${order.id}`}>
                <OrderRow order={order} expanded={currentOrder} />
                {currentOrder && <OrderDetails orderCompleted={orderComplete(order)} dashboard />}
              </div>
            );
          })
        ) : (
          <div
            css={css`
              width: 100%;
              margin: auto;
              margin-top: 58px;
            `}
          >
            <H1 additionalStyles='color: #9e9e9e;'>No orders placed</H1>
          </div>
        ))}

      <Pagination page={queryState.page} theme={theme} count={getTotalOrderHits} dispatch={dispatch} />
    </div>
  );
});
