import { css } from '@emotion/react';
import { Component } from 'react';

import { Button, ButtonSize } from '@isi/components/common/button.component';
import { CloseButton } from '@isi/components/common/close-button.component';
import { H1 } from '@isi/components/common/h1.component';
import { LinkButton } from '@isi/components/common/link-button.component';

interface IDropOffWarningModalContent {
  cancelClicked: () => void;
  dropOffClicked: () => void;
}

export default class DropOffWarningModalContent extends Component<IDropOffWarningModalContent, {}> {
  public renderCloseButton = (): JSX.Element => (
    <CloseButton
      onClick={() => this.props.cancelClicked()}
      additionalStyles={`
          position: absolute;
          top: 20px;
          right: 20px;
        `}
    />
  );

  public renderParagraph = (): JSX.Element => (
    <p
      css={css`
        text-align: center;
        font-size: 14px;
        margin-top: 20px;
        line-height: 1.5;
      `}
    >
      This will erase any additional sizes and services you have entered.
    </p>
  );

  public renderContinueButton = (): JSX.Element => (
    <Button
      buttonSize={ButtonSize.large}
      disabled={false}
      onClick={() => this.props.dropOffClicked()}
      additionalStyles={`
        margin: 32px auto auto auto;
        display: block;
      `}
    >
      Yes, continue
    </Button>
  );

  public renderCancelButton = (): JSX.Element => (
    <LinkButton
      onClick={() => this.props.cancelClicked()}
      disabled={false}
      additionalStyles={`
            margin: 22px auto;
            display: block;
          `}
    >
      No, keep additional sizes and services
    </LinkButton>
  );

  public render(): JSX.Element {
    return (
      <div>
        {this.renderCloseButton()}
        <div
          css={css`
            max-width: 240px;
            margin: 60px auto auto auto;
          `}
        >
          <H1
            bold
            additionalStyles={`
          width: 247px;
          margin-left: -3.5px;`}
          >
            change to scheduled delivery?
          </H1>
          {this.renderParagraph()}
          {this.renderContinueButton()}
          {this.renderCancelButton()}
        </div>
      </div>
    );
  }
}
