import { css } from '@emotion/react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import { type Theme, ThemeProvider } from '@mui/material/styles';
import type React from 'react';
import { type ReactNode } from 'react';
import { useReducer, useRef, useState } from 'react';

import FiltersAppliedCount from './filters-applied-count.component';
import filterIcon from '@isi/assets/icons/filter-icon.svg';
import {
  type FilterStatus,
  type FilterStatusType,
  type SearchQueryActions,
  SearchQueryReducerActionTypes,
} from '@isi/interfaces/search-query.interface';
import { rootStore } from '@isi/stores/root.store';

import useOnClickOutside from '@isi/hooks/use-on-click-outside.function';

import { H3 } from '@isi/components/common/h3.component';
import { ThinUnderlinedLink } from '@isi/components/thin-underlined-link.component';

interface FilterState {
  zones: {
    zone1: boolean;
    zone2: boolean;
    zone3: boolean;
    zone4: boolean;
    zone5: boolean;
    zone6: boolean;
  };
  minTotal: number;
  maxTotal: number;
  showClosedOrders: boolean;
  showOpenOrders: boolean;
  showDeliveries: boolean;
  showReturns: boolean;
}

interface FilterAction {
  type: string;
  payload: any;
}

const defaultMinTotal = 0;
const defaultMaxTotal = -1;

const defaultState = {
  minTotal: defaultMinTotal,
  maxTotal: defaultMaxTotal,
  showOpenOrders: true,
  showClosedOrders: true,
  showDeliveries: true,
  showReturns: true,
  zones: {
    zone1: true,
    zone2: true,
    zone3: true,
    zone4: true,
    zone5: true,
    zone6: true,
  },
};

const filterReducer = (currentState: FilterState, action: FilterAction) => {
  if (action.type === 'reset_filter') {
    return defaultState;
  }
  if (action.type === 'zone_changed') {
    return { ...currentState, zones: { ...currentState.zones, ...action.payload } };
  }

  if (action.type === 'min_total_changed') {
    return { ...currentState, minTotal: action.payload };
  }

  if (action.type === 'max_total_changed') {
    return { ...currentState, maxTotal: action.payload };
  }

  if (action.type === 'open_orders_changed') {
    return { ...currentState, showOpenOrders: action.payload };
  }

  if (action.type === 'closed_orders_changed') {
    return { ...currentState, showClosedOrders: action.payload };
  }

  if (action.type === 'deliveries_changed') {
    return { ...currentState, showDeliveries: action.payload };
  }

  if (action.type === 'returns_changed') {
    return { ...currentState, showReturns: action.payload };
  }

  return currentState;
};

interface FilterProps {
  theme: Theme;
  setSearchQuery: React.Dispatch<SearchQueryActions>;
}

interface AccordionProps {
  title: string;
  children?: ReactNode;
}

const FilterSection = ({ title, children }: AccordionProps): JSX.Element => (
  <div
    css={css`
      overflow: hidden;
    `}
  >
    <div
      css={css`
        background: black;
        border: none;
        text-transform: capitalize;
        width: 100%;
        text-align: left;
        padding: 13px;
        margin-bottom: 2px;
        font-family: 'MarkOT';
        font-size: 16px;
        color: white;
      `}
    >
      {title}
    </div>

    <div
      css={css`
        background: #f7f7f7;
        color: black;
        display: grid;
        grid-template-columns: auto auto;
      `}
    >
      {children}
    </div>
  </div>
);

const Filter = (props: FilterProps): JSX.Element => {
  const [active, setActive] = useState(false);
  const [filterState, dispatch] = useReducer(filterReducer, defaultState);
  const { setSearchQuery } = props;
  const currency = rootStore.storeStore.storeCurrency;
  const location = rootStore.storeStore.getStoreConfig?.storeLocation;

  const node = useRef(null);
  const excludedClickOutside = useRef(null);

  const handleClickOutside = () => {
    setActive(!active);

    const exclude: FilterStatus[] = [];
    const excludeType: FilterStatusType[] = [];

    if (!filterState.showOpenOrders) {
      exclude.push('open');
    }

    if (!filterState.showClosedOrders) {
      exclude.push('closed');
    }

    if (!filterState.showDeliveries) {
      excludeType.push('delivery');
    }

    if (!filterState.showReturns) {
      excludeType.push('return');
    }

    setSearchQuery({
      type: SearchQueryReducerActionTypes.SetFilter,
      payload: {
        excludeStatus: exclude,
        excludeStatusType: excludeType,
        orderValueRange: { minOrderValue: filterState.minTotal, maxOrderValue: filterState.maxTotal },
        excludeZones: Object.keys(filterState.zones)
          .filter((k) => !filterState.zones[k])
          .map((zone) => zone.replace('zone', 'ldn_zone_')),
      },
    });
  };

  const amountOfFiltersApplied = (): number => {
    let count = 0;

    if (
      defaultState.showClosedOrders !== filterState.showClosedOrders ||
      defaultState.showOpenOrders !== filterState.showOpenOrders
    ) {
      count += 1;
    }

    if (
      defaultState.showDeliveries !== filterState.showDeliveries ||
      defaultState.showReturns !== filterState.showReturns
    ) {
      count += 1;
    }

    if (defaultState.minTotal !== filterState.minTotal || defaultState.maxTotal !== filterState.maxTotal) {
      count += 1;
    }

    if (Object.values(filterState.zones).some((val) => !val)) {
      count += 1;
    }

    return count;
  };

  useOnClickOutside(node, handleClickOutside, ['MuiMenuItem-root', 'MuiBackdrop-root'], excludedClickOutside);

  const { theme } = props;

  interface SelectOrderTotalInterface {
    value: number;
    text: string;
  }

  const minimumSelectOrderTotalValues = (currency: string): SelectOrderTotalInterface[] => [
    { value: 0, text: `${currency}0` },
    { value: 100, text: `${currency}100` },
    { value: 500, text: `${currency}500` },
    { value: 1000, text: `${currency}1000` },
    { value: 5000, text: `${currency}5000` },
    { value: 10_000, text: `${currency}10000` },
    { value: 15_000, text: `${currency}15000` },
    { value: 25_000, text: `${currency}25000` },
    { value: 50_000, text: `${currency}50000` },
  ];

  const maximumSelectOrderTotalValues = (currency: string): SelectOrderTotalInterface[] => [
    { value: 100, text: `${currency}100` },
    { value: 500, text: `${currency}500` },
    { value: 1000, text: `${currency}1000` },
    { value: 5000, text: `${currency}5000` },
    { value: 10_000, text: `${currency}10000` },
    { value: 15_000, text: `${currency}15000` },
    { value: 25_000, text: `${currency}25000` },
    { value: 50_000, text: `${currency}50000` },
    { value: -1, text: 'Maximum' },
  ];

  const renderFilterBody = () => (
    <ThemeProvider theme={theme}>
      <div
        ref={node}
        css={css`
          background: #f7f7f7;
          position: absolute;
          padding: 0 25px 10px 25px;
          min-width: 400px;
          box-shadow: 0 7px 22px -4px rgb(0 0 0 / 28%);
          margin-top: 10px;
          z-index: 1;
          overflow: scroll;
          border: 1px solid #e1e1e1;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin: 20px 0;
          `}
        >
          <H3 additionalStyles='margin: 10px 0 0 0; color: black; font-size: 20px;' bold uppercase>
            Filters
          </H3>
          <ThinUnderlinedLink onClick={() => dispatch({ type: 'reset_filter', payload: null })}>
            Reset
          </ThinUnderlinedLink>
          <ThinUnderlinedLink onClick={handleClickOutside}>Apply</ThinUnderlinedLink>
        </div>
        <FilterSection title='order status'>
          <div
            css={css`
              padding: 10px 8px;
            `}
          >
            <FormControlLabel
              label='Open Orders'
              control={
                <Checkbox
                  checked={filterState.showOpenOrders}
                  onChange={() => dispatch({ type: 'open_orders_changed', payload: !filterState.showOpenOrders })}
                  {...{ inputProps: { 'aria-label': 'Open Orders' } }}
                />
              }
            />
          </div>
          <div
            css={css`
              padding: 10px 8px;
            `}
          >
            <FormControlLabel
              label='Closed Orders'
              control={
                <Checkbox
                  checked={filterState.showClosedOrders}
                  onChange={() => dispatch({ type: 'closed_orders_changed', payload: !filterState.showClosedOrders })}
                  {...{ inputProps: { 'aria-label': 'Closed Orders' } }}
                />
              }
            />
          </div>
        </FilterSection>
        <FilterSection title='order type'>
          <div
            css={css`
              padding: 10px 8px;
            `}
          >
            <FormControlLabel
              label='Deliveries'
              control={
                <Checkbox
                  checked={filterState.showDeliveries}
                  onChange={() => dispatch({ type: 'deliveries_changed', payload: !filterState.showDeliveries })}
                  {...{ inputProps: { 'aria-label': 'Deliveries' } }}
                />
              }
            />
          </div>
          <div
            css={css`
              padding: 10px 8px;
            `}
          >
            <FormControlLabel
              label='Returns'
              control={
                <Checkbox
                  checked={filterState.showReturns}
                  onChange={() => dispatch({ type: 'returns_changed', payload: !filterState.showReturns })}
                  {...{ inputProps: { 'aria-label': 'Returns' } }}
                />
              }
            />
          </div>
        </FilterSection>
        <FilterSection title='order total'>
          <div
            css={css`
              padding: 10px 8px;
            `}
          >
            <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id='min-total-label'>Min Total</InputLabel>
              <Select
                color='primary'
                labelId='min-total-label'
                value={filterState.minTotal}
                label='Order Total From'
                displayEmpty
                onChange={(event: SelectChangeEvent<number>) =>
                  dispatch({ type: 'min_total_changed', payload: event.target.value })
                }
              >
                {minimumSelectOrderTotalValues(currency).map((item: SelectOrderTotalInterface) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            css={css`
              padding: 10px 8px;
            `}
          >
            <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id='max-total-label'>Max Total</InputLabel>
              <Select
                color='primary'
                labelId='max-total-label'
                value={filterState.maxTotal}
                label='Order Total From'
                displayEmpty
                onChange={(event: SelectChangeEvent<number>) =>
                  dispatch({ type: 'max_total_changed', payload: event.target.value })
                }
              >
                {maximumSelectOrderTotalValues(currency).map((item: SelectOrderTotalInterface) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </FilterSection>

        {location === 'LDN' ? (
          <FilterSection title='London Zones'>
            {Object.keys(filterState.zones).map((zone) => (
              <div
                key={zone}
                css={css`
                  padding: 10px 8px;
                `}
              >
                <FormControlLabel
                  label={zone.replace('zone', 'Zone ')}
                  control={
                    <Checkbox
                      checked={filterState.zones[zone]}
                      onChange={() => dispatch({ type: 'zone_changed', payload: { [zone]: !filterState.zones[zone] } })}
                      {...{ inputProps: { 'aria-label': zone } }}
                    />
                  }
                />
              </div>
            ))}
          </FilterSection>
        ) : null}
      </div>
    </ThemeProvider>
  );

  return (
    <div
      css={css`
        max-width: 1095px;
        margin-bottom: 25px;
        margin-bottom: 20px;
        position: relative;
      `}
    >
      <button
        ref={excludedClickOutside}
        css={css`
          background: black;
          border: none;
          color: white;
          display: flex;
          align-items: center;
          padding: 10px;
          cursor: pointer;
        `}
        onClick={() => setActive(!active)}
        type='button'
      >
        <img src={filterIcon} css={{ height: '25px' }} alt='filter' />
        {amountOfFiltersApplied() === 0 ? null : <FiltersAppliedCount count={amountOfFiltersApplied()} />}
      </button>

      {active ? renderFilterBody() : null}
    </div>
  );
};

export default Filter;
