export enum OrderStatus {
  Scheduled = 'scheduled',
  ArrivedAtToshiDepot = 'arrived_at_toshi_depot',
  EnRouteToStore = 'en_route_to_store',
  ArrivedAtStore = 'arrived_at_store',
  CollectedItems = 'collected_items',
  EnRouteToClient = 'en_route_to_client',
  ArrivedAtClient = 'arrived_at_client',
  Serviced = 'serviced',
  Completed = 'completed',
  Cancelled = 'cancelled',
}
