import { css } from '@emotion/react';

interface LabelProps {
  text: string;
  htmlFor?: string;
  cssOverrides?: string;
}

const Label = ({ text, htmlFor, cssOverrides }: LabelProps): JSX.Element => (
  <label
    htmlFor={htmlFor}
    css={css`
      font-family: MarkOT;
      font-size: 14px;
      line-height: 1.93;
      color: #000000;
      margin-bottom: 4px;
      display: block;
      ${cssOverrides}
    `}
  >
    {text}
  </label>
);

export { Label };
