import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import bag from '@isi/assets/icons/bag.svg';
import { rootStore } from '@isi/stores/root.store';

import { H3 } from '@isi/components/common/h3.component';

interface IProductIncompleteProps {
  isGiftingOrder?: boolean;
}

const Image = styled.img<{ height?: string | number }>`
  height: ${(props) => props.height || '33'}px;
  display: inline;
`;

export default class ProductIncomplete extends Component<IProductIncompleteProps, {}> {
  public renderIncompleteProductContent = (): JSX.Element => {
    const linkToServiceOrder = {
      pathname: '/products',
      state: {
        isGiftingOrder: this.props.isGiftingOrder,
        isServiceOrder: true,
      },
    };
    const linkToNonServiceOrder = {
      pathname: '/products',
      state: {
        isGiftingOrder: this.props.isGiftingOrder,
        isServiceOrder: false,
      },
    };

    const showWaitAndTryButton: boolean =
      rootStore.storeStore.getStoreConfig?.sizingEnabled === true ||
      rootStore.storeStore.getStoreConfig?.waitAndTryEnabled === true ||
      rootStore.storeStore.getStoreConfig?.inspireMeEnabled === true;
    return (
      <div
        css={css`
          margin: auto;
          width: fit-content;
        `}
      >
        <div>
          <Image
            src={bag}
            height='33'
            css={css`
              display: inline-block;
              vertical-align: middle;
              margin-left: 46px;
              margin-right: 24px;
            `}
          />
          <H3
            bold
            additionalStyles={`
              letter-spacing: 1px;
              display: inline-block;
            `}
          >
            {rootStore.storeStore.getStoreConfig?.sizingEnabled === true
              ? 'SELECT DELIVERY TYPE'
              : 'ENTER ORDER DETAILS'}
          </H3>
        </div>
        <div
          css={css`
            margin: auto;
            width: fit-content;
          `}
        >
          <Link to={linkToNonServiceOrder} style={{ display: 'inline-block' }}>
            <H3
              additionalStyles={`
              display: inline-block;
              vertical-align: middle;
              padding: 10px 24px 10px 24px;
              margin: 14px 28px 40px 28px;
              border: 1px solid black;
              border-radius: 4px;
            `}
            >
              Drop off
            </H3>
          </Link>
          {showWaitAndTryButton ? (
            <Link to={linkToServiceOrder} style={{ display: 'inline-block' }}>
              <H3
                additionalStyles={`
              display: inline-block;
              vertical-align: middle;
              padding: 10px 24px 10px 24px;
              margin: 14px 28px 40px 28px;
              border: 1px solid black;
              border-radius: 4px;
            `}
              >
                Wait & Try
              </H3>
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  public render(): JSX.Element {
    return this.renderIncompleteProductContent();
  }
}
export { ProductIncomplete, IProductIncompleteProps };
