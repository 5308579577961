import { css } from '@emotion/react';
import { debounce } from 'lodash';
import { type ChangeEventHandler, type FC, useEffect, useRef, useState } from 'react';

import searchIcon from '@isi/assets/icons/search.svg';
import { type SearchQueryActions, SearchQueryReducerActionTypes } from '@isi/interfaces/search-query.interface';

interface SearchBarProps {
  setSearchQuery: React.Dispatch<SearchQueryActions>;
  propsActive?: boolean;
}

const SearchBar: FC<SearchBarProps> = ({ setSearchQuery, propsActive = false }): JSX.Element => {
  const [active, setActive] = useState(propsActive);

  const inputElement = useRef(null);

  useEffect(() => {
    const el: any = inputElement.current;

    if (inputElement.current && el !== null) {
      el.focus();
    }
  }, [active]);

  const debounceSetQuery = debounce(setSearchQuery, 800, { leading: false, trailing: true });

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const params: SearchQueryActions = { type: SearchQueryReducerActionTypes.SetTerm, payload: e.target.value };

    debounceSetQuery(params);
  };

  return (
    <div
      css={css`
        align-items: center;
        display: flex;
        max-width: 1095px;
        margin-bottom: 25px;
        margin-bottom: 20px;
        flex-grow: 1;
        flex-shrink: 100;
        flex-basis: 100%;
      `}
    >
      <button
        onClick={() => setActive(!active)}
        css={css`
          background: black;
          border: none;
          color: white;
          justify-content: center;
          display: flex;
          align-items: center;
          height: 45px;
          width: 45px;
          cursor: pointer;
          flex-shrink: 0;
        `}
        type='button'
      >
        <img src={searchIcon} css={{ height: '30px' }} alt='search' />
      </button>
      <input
        ref={inputElement}
        css={css`
          border: none;
          box-sizing: border-box;
          height: 45px;
          margin-left: 10px;
          padding: 8px;
          padding-right: ${active ? '10px' : '0'};
          padding-left: ${active ? '25px' : '0'};
          width: ${active ? '92%' : '0px'};
          box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.28);
          transition: all 0.3s cubic-bezier(0, 0.53, 0.34, 1.5);
          background: #f7f7f7;
          border: none;
          color: black;
          &:focus {
            outline: none;
          }
        `}
        placeholder={active ? 'Order no, customer name, customer email, customer address, product name' : ''}
        type='text'
        onChange={onChange}
      />
    </div>
  );
};

export default SearchBar;
