import axios from 'axios';
import type { CamelCasedProperties } from 'type-fest';

import { apiUrl } from '@isi/config/Url';

type AddressSnakeCase = {
  id: number;
  house_name: string | null;
  line_1: string;
  line_2: string | null;
  city_town: string | null;
  postcode_zipcode: string;
  country: string;
  latitude: string | null;
  longitude: string | null;
  notes: string | null;
  created_at: string;
  updated_at: string;
  customer_id: string | null;
};

export interface GetRecipientResponseSnakeCase {
  id: string;
  title: string | null;
  first_name: string;
  last_name: string;
  email: string;
  guest: boolean;
  contact_number: string;
  contact_preferences: number;
  contact_number_country: string;
}

export interface IGetCustomerResponseSnakeCase extends GetRecipientResponseSnakeCase {
  last_address: AddressSnakeCase;
}

export interface GetRecipientResponse {
  id: string;
  title: string | null;
  firstName: string;
  lastName: string;
  email: string;
  guest: boolean;
  contactNumber: string;
  contactNumberCountry: string;
  contactPreferences: number;
}

export interface IGetCustomerResponse extends GetRecipientResponse {
  lastAddress: CamelCasedProperties<AddressSnakeCase>;
}

export const getCustomer = (id: string | number) => {
  const url = `${apiUrl()}/v2/customers/${id}`;

  return axios.get<IGetCustomerResponseSnakeCase>(url);
};
