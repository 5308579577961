import { css } from '@emotion/react';
import { Component } from 'react';

interface IDropOffTableHeaderProps {
  // eslint-disable-next-line react/no-unused-prop-types
  onDeleteAllCheckboxClick: (checked: boolean) => void;
}

interface DropOffTableHeaderState {
  deleteCheckboxChecked: boolean;
}

class DropOffTableHeader extends Component<IDropOffTableHeaderProps, DropOffTableHeaderState> {
  constructor(props: IDropOffTableHeaderProps) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      deleteCheckboxChecked: false,
    };
  }

  public forceUnselectDeleteCheckbox = (): void => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ deleteCheckboxChecked: false });
  };

  public render(): JSX.Element {
    return (
      <tr
        css={css`
          font-family: MarkOT-Bold, Arial, Helvetica, sans-serif;
          background-color: #e1e1e1;
          height: 128px;
        `}
      >
        <th>PRODUCT</th>
        <th>
          TOTAL PRICE
          <br />
        </th>
        {/* <th>COLOUR</th> */}
        <th>
          QUANTITY
          <br />
          (NUMBER OF BAGS)
        </th>
        <th>SIZE</th>
      </tr>
    );
  }
}

export { DropOffTableHeader };
