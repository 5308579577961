import { css } from '@emotion/react';

import logo from '@isi/assets/images/toshi_logo.svg';

import useStoreContext from '@isi/hooks/useStoreContext';

export const BrandLogo = () => {
  const {
    storeStore: { getStoreConfig },
  } = useStoreContext();

  const logoUrl = getStoreConfig && getStoreConfig.logoUrl ? getStoreConfig.logoUrl : logo.toString();

  return (
    <div
      css={css`
        text-align: center;
      `}
    >
      <img
        src={logoUrl}
        css={css`
          margin-top: 72px;
          margin-bottom: 0;
          width: 350px;
        `}
      />
    </div>
  );
};
