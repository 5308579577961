import axios from 'axios';

import { apiUrl } from '@isi/config/Url';
import { encodeParams } from '@isi/network/helpers/params/encode-params.function';

export interface IGetProductParams {
  query?: string;
}

export interface IGetProductResponse {
  tax_rate: string;
  id: number;
  brand_id: number;
  sku: string;
  name: string;
  size: string;
  colour: string | null;
  image_url: string;
  product_url: string;
  created_at: string;
  updated_at: string;
  input_price: string;
  inventory_count: number;
  parent_sku: string;
}

export const getProducts = async (params?: IGetProductParams) => {
  let url = `${apiUrl()}/v2/brand_products`;

  if (params) {
    url = `${url}?${encodeParams(params)}`;
  }

  return axios.get<IGetProductResponse[]>(url);
};
