import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';

import { NotificationLevel } from '@isi/enums/notificationLevel.enum';

import useStoreContext from '@isi/hooks/useStoreContext';

import { CloseButton } from '@isi/components/common/close-button.component';
import { PrintButton } from '@isi/components/common/print-button.component';

function getNotificationColor(level: NotificationLevel) {
  switch (level) {
    case NotificationLevel.Success:
      return '#47bf54';
    case NotificationLevel.Info:
      return '#1f65fb';
    case NotificationLevel.Warn:
      return '#ffc42e';
    case NotificationLevel.Error:
      return '#C12034';

    default:
      return '#47bf54';
  }
}

function getNotificationTextColor(level: NotificationLevel) {
  if (level === NotificationLevel.Warn) {
    return 'black';
  }

  return 'white';
}

const NotificationContainer = styled.div<{
  show: boolean;
  level: NotificationLevel;
  orderId?: string;
}>`
  background-color: ${({ level }) => getNotificationColor(level)};
  color: ${({ level }) => getNotificationTextColor(level)};
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  height: ${({ show }) => (show ? 75 : 0)}px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition:
    height 0.3s ease-in-out,
    opacity 300ms;
`;

const Notification = () => {
  const {
    uiStore: {
      notification: { message, level, orderId },
      clearNotification,
    },
  } = useStoreContext();

  return (
    <NotificationContainer show={Boolean(message)} level={level} orderId={orderId}>
      <div
        css={css`
          line-height: 1.5;
        `}
      >
        <div
          css={css`
            font-size: 16px;
            font-family: MarkOT-Bold;
          `}
        >
          {level}
        </div>
        <div
          css={css`
            font-size: 14px;
          `}
        >
          {message}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          gap: 20px;
          align-items: center;
        `}
      >
        {orderId && (
          <PrintButton
            orderId={orderId}
            height={16}
            width={16}
            additionalButtonStyles='border: 1px solid; border-radius: 4px; padding: 6px 12px; background-color: white;'
            text='Print Labels'
            additionalTextStyles='margin-left: 8px;'
          />
        )}
        <CloseButton white={level !== NotificationLevel.Warn} disabled={false} onClick={clearNotification} />
      </div>
    </NotificationContainer>
  );
};

export default observer(Notification);
