import { css } from '@emotion/react';
import { Component } from 'react';

import { Service } from '@isi/enums/service.enum';
import { rootStore } from '@isi/stores/root.store';

import { Checkbox } from '@isi/components/common/checkbox.component';
import { LinkButton } from '@isi/components/common/link-button.component';

interface IWaitAndTryTableHeaderProps {
  onDeleteAllCheckboxClick: (checked: boolean) => void;
  onToggleServiceForAllProducts: (service: Service) => void;
}

interface WaitAndTryTableHeaderState {
  deleteCheckboxChecked: boolean;
}

class WaitAndTryTableHeader extends Component<IWaitAndTryTableHeaderProps, WaitAndTryTableHeaderState> {
  public config = rootStore.storeStore.getStoreConfig;

  constructor(props: IWaitAndTryTableHeaderProps) {
    super(props);

    this.state = {
      deleteCheckboxChecked: false,
    };
  }

  public forceUnselectDeleteCheckbox = (): void => {
    this.setState({ deleteCheckboxChecked: false });
  };

  private renderFittingAndPinning = (): JSX.Element | null =>
    this.config?.perfectFitEnabled ? (
      <th
        css={css`
          height: 50px;
          position: relative;
          min-width: 60px;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 25px;
            width: 100%;
          `}
        >
          BASIC
          <br />
          FITTING
          <br />
          NEEDED?
        </div>
        <LinkButton
          additionalStyles={`
            display: block;
            font-size: 10px;
            position: absolute;
            bottom: 30px;
            width: 100%;
            `}
          disabled={false}
          onClick={() => this.props.onToggleServiceForAllProducts(Service.FittingPinning)}
        >
          Select All
        </LinkButton>
      </th>
    ) : null;

  private renderPerfectFit = (): JSX.Element | null =>
    this.config?.alterationsEnabled ? (
      <th
        css={css`
          height: 50px;
          position: relative;
          min-width: 60px;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 25px;
            width: 100%;
          `}
        >
          PERFECT
          <br />
          FIT
        </div>
        <LinkButton
          additionalStyles={`
                display: block;
                font-size: 10px;
                position: absolute;
                bottom: 30px;
                width: 100%;
                `}
          disabled={false}
          onClick={() => this.props.onToggleServiceForAllProducts(Service.PerfectFit)}
        >
          Select All
        </LinkButton>
      </th>
    ) : null;

  private renderPaymentRequired = (): JSX.Element | undefined =>
    this.config?.paymentAtDoorEnabled && this.config?.waitAndTryEnabled ? (
      <th
        css={css`
          height: 50px;
          position: relative;
          min-width: 60px;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 25px;
            width: 100%;
          `}
        >
          PAYMENT
          <br />
          REQUIRED?
        </div>
        <LinkButton
          additionalStyles={`
              display: block;
              font-size: 10px;
              position: absolute;
              bottom: 30px;
              width: 100%;
              `}
          disabled={false}
          onClick={() => this.props.onToggleServiceForAllProducts(Service.PaymentRequired)}
        >
          Select All
        </LinkButton>
      </th>
    ) : undefined;

  private renderInspireMe = (): JSX.Element | undefined =>
    this.config?.inspireMeEnabled && this.config?.paymentAtDoorEnabled && this.config?.waitAndTryEnabled ? (
      <th
        css={css`
          height: 50px;
          position: relative;
          min-width: 60px;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 25px;
            width: 100%;
          `}
        >
          INSPIRE
          <br />
          ME ITEM?
        </div>
        <LinkButton
          additionalStyles={`
                display: block;
                font-size: 10px;
                position: absolute;
                bottom: 30px;
                width: 100%;
                `}
          disabled={false}
          onClick={() => this.props.onToggleServiceForAllProducts(Service.InspireMe)}
        >
          Select All
        </LinkButton>
      </th>
    ) : undefined;

  public render(): JSX.Element {
    return (
      <tr
        css={css`
          font-family: MarkOT-Bold, Arial, Helvetica, sans-serif;
          background-color: #e1e1e1;
          height: 128px;
        `}
      >
        <th>
          DELETE
          <div
            css={css`
              margin: auto;
              width: fit-content;
            `}
          >
            <Checkbox
              additionalStyles='margin-top: 12px;'
              checked={this.state.deleteCheckboxChecked}
              onClick={(checked) => this.props.onDeleteAllCheckboxClick(checked)}
            />
          </div>
        </th>
        <th>PRODUCT</th>
        <th>
          UNIT PRICE <br />
        </th>
        <th>COLOUR</th>
        <th>QUANTITY</th>
        <th>SIZE</th>
        {this.renderFittingAndPinning()}
        {this.renderInspireMe()}
        {this.renderPerfectFit()}
        {this.renderPaymentRequired()}
      </tr>
    );
  }
}

export { WaitAndTryTableHeader };
