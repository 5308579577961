import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

export interface ISelectValue {
  value?: string | number;
  display?: string;
  disabled: boolean;
  unscheduled?: boolean;
}

interface SelectProps {
  id?: string;
  values: ISelectValue[];
  label: string;
  disabled: boolean;
  placeholder?: string;
  defaultValue?: ISelectValue;
  onChange: (value: string | number) => void;
  resetValue?: boolean;
  error?: string;
  labelCSS?: string;
}

export const Select = ({
  id,
  values,
  label,
  disabled,
  placeholder,
  defaultValue,
  onChange: propsOnChange,
  resetValue,
  error,
  labelCSS,
}: SelectProps): JSX.Element => {
  const defaultOptionValue = values.find((value) => value.value === defaultValue?.value);
  const otherValues = defaultOptionValue ? values.filter((value) => value.value !== defaultOptionValue.value) : values;
  const options = [];
  const showDefaultValue = Boolean(defaultValue);
  const showPlaceholder = placeholder && !defaultValue;
  if (showDefaultValue && defaultValue !== undefined) {
    options.push(
      <option key={`default-${defaultValue.value}`} value={defaultValue.value}>
        {defaultValue.display}
      </option>,
    );
  }
  if (showPlaceholder) {
    options.push(
      <option
        css={css`
          color: #9b9b9b;
        `}
        disabled
        key='placeholder'
        value=''
      >
        {placeholder}
      </option>,
    );
  }
  for (const value of otherValues) {
    options.push(
      <option key={value.value} value={value.value} disabled={value.disabled}>
        {value.display}
      </option>,
    );
  }

  const [value, setValue] = useState(defaultValue ? defaultValue.value : '');
  const [focused, setFocused] = useState(false);
  const [showingPlaceholder, setShowingPlaceholder] = useState(Boolean(placeholder));

  const showPlaceholderColour = (): boolean => {
    if (defaultValue?.value) return false;
    if (showingPlaceholder) return true;

    return false;
  };

  const onChange = (selectedValue: any): void => {
    setValue(selectedValue);
    setShowingPlaceholder(false);
    propsOnChange(selectedValue);
  };

  const onFocus = (): void => {
    setFocused(true);
  };

  const onBlur = (): void => {
    setFocused(false);
  };

  useEffect(() => {
    if (resetValue) {
      setValue('');
      setShowingPlaceholder(true);
      propsOnChange('');
    }
  }, [propsOnChange, resetValue]);

  return (
    <label htmlFor={id} css={css(labelCSS)}>
      <div
        css={css`
          font-family: MarkOT;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin-bottom: 10px;
        `}
      >
        {label}
      </div>
      <div
        css={css`
          padding-right: 14px;
          border: solid 1px ${focused ? '#000000' : '#8c8c8c'};
          ${error ? 'border: solid 1px #d20303;' : ''}
          background-color: #ffffff;
          font-family: MarkOT;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          border-radius: 0px;
          width: 100%;
        `}
      >
        <select
          css={css`
            width: 100%;
            background-color: transparent;
            padding: 14px 16px;
            padding-right: 0;
            color: ${showPlaceholderColour() ? '#8c8c8c' : '#000000'};
            border: none;
          `}
          id={id}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          onFocus={() => onFocus()}
          onBlur={() => onBlur()}
          disabled={disabled}
        >
          {options}
        </select>
      </div>
      <div
        css={css`
          color: #d20303;
          margin-top: 5px;
          margin-bottom: 3px;
          font-size: 13px;
          height: 20px;
        `}
      >
        {error}
      </div>
    </label>
  );
};
