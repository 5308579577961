import { css } from '@emotion/react';
import { type CSSProperties, useState } from 'react';
import { formatFileSize, lightenDarkenColor, useCSVReader, usePapaParse } from 'react-papaparse';
import { withRouter } from 'react-router-dom';

import {
  createBrandProducts,
  type IJsonifiedCsv,
} from '@isi/network/brand_products/batches/create_brand_products.function';
import { getFileStoreUrl, type IGetFileStoreUrlResponse } from '@isi/network/files/get-file-upload-url.function';
import { uploadCsvFile } from '@isi/network/files/upload-csv.function';
import { mapKeysToCamel } from '@isi/network/helpers/params/map-keys-to-camel.function';
import { rootStore } from '@isi/stores/root.store';

import { Button } from '@isi/components/common/button.component';
import { Checkbox } from '@isi/components/common/checkbox.component';
import { Dropdown, type DropdownValue } from '@isi/components/common/dropdown.component';
import { H1 } from '@isi/components/common/h1.component';
import SpinnerWrapper from '@isi/components/common/SpinnerWrapper.component';

import { UK_STORE_TAX_CODE_CSV_URL, US_STORE_TAX_CODE_CSV_URL } from '../../constants/constants';
import { CityCodes } from '../../enums/city-codes.enum';

interface Header {
  value: string;
  index: number;
}

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
const GREY_DIM = '#686868';

const styles: Record<string, CSSProperties> = {
  zone: {
    alignItems: 'center',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: GREY,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

const UploadProductsComponent = (): JSX.Element => {
  const [csvJSONData, setCsvJSONData] = useState<string[][]>();
  const [csvHeaders, setCsvHeaders] = useState<Header[]>();
  const [nameRowIndex, setNameRowIndex] = useState<string | null>(null);
  const [taxCodeRowIndex, setTaxCodeRowIndex] = useState<string | null>(null);
  const [skuRowIndex, setSkuRowIndex] = useState<string | null>(null);
  const [imageUrlRowIndex, setImageUrlRowIndex] = useState<string | null>(null);
  const [inputPriceRowIndex, setInputPriceRowIndex] = useState<string | null>(null);
  const [colourRowIndex, setColourRowIndex] = useState<string | null>(null);
  const [deleteOldProducts, setDeleteOldProducts] = useState(false);
  const [successUploading, setSuccessUploading] = useState<boolean | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);
  const [uploading, setUploading] = useState(false);

  const { jsonToCSV } = usePapaParse();
  const { CSVReader } = useCSVReader();

  const mapCSVJSONToState = (data: IJsonifiedCsv<string[]>) => {
    if (data?.data) {
      const headers: Header[] = [];

      for (let i = 0; i < data.data[0].length; i++) {
        headers.push({ value: data.data[0][i], index: i });
      }
      setCsvHeaders(headers);
      setCsvJSONData(data.data);
    }
  };

  const handleOnDrop = (data: IJsonifiedCsv<string[]>) => {
    mapCSVJSONToState(data);
    setSuccessUploading(null);
    setMessage(null);
  };

  const handleOnError = (err: unknown) => {
    if (err instanceof Error) {
      throw err;
    } else if (typeof err === 'string') {
      throw new TypeError(err);
    } else {
      throw new TypeError(JSON.stringify(err));
    }
  };

  const handleOnRemoveFile = () => {
    setCsvJSONData([]);
    setCsvHeaders([]);
    setMessage(null);
  };

  const mapHeadersToDropdownValues = () => {
    if (!csvHeaders) {
      return [];
    }

    return csvHeaders.map((header: Header) => {
      const dropdownValue: DropdownValue = {
        display: header.value,
        value: header.index,
      };
      return dropdownValue;
    });
  };

  const duplicateHeadersSelected = (): boolean => {
    const headers = [nameRowIndex, skuRowIndex, taxCodeRowIndex, imageUrlRowIndex, inputPriceRowIndex, colourRowIndex];

    const strippedHeaders = headers.filter((header) => header !== null);

    return new Set(strippedHeaders).size !== strippedHeaders.length;
  };

  const isUploadButtonDisabled = (): boolean => {
    if (duplicateHeadersSelected()) return true;
    if (nameRowIndex && skuRowIndex) return false;

    return true;
  };

  const mapSelectedRowsToCSVJSON = (): string[][] => {
    if (!csvJSONData) {
      return [];
    }

    const firstItemData = [...csvJSONData[0]];
    firstItemData[Number(nameRowIndex)] = 'name';
    firstItemData[Number(skuRowIndex)] = 'sku';
    if (taxCodeRowIndex != null) {
      firstItemData[Number(taxCodeRowIndex)] = 'tax_code';
    }
    if (imageUrlRowIndex != null) {
      firstItemData[Number(imageUrlRowIndex)] = 'image_url';
    }
    if (inputPriceRowIndex != null) {
      firstItemData[Number(inputPriceRowIndex)] = 'input_price';
    }
    if (colourRowIndex != null) {
      firstItemData[Number(colourRowIndex)] = 'colour';
    }

    return [firstItemData, ...csvJSONData.slice(1)];
  };

  const hasEmptyValues = (jsonCSVData: string[][]): boolean => {
    const csvHeaderIndexes = {
      nameIndex: jsonCSVData[0].indexOf('name'),
      skuIndex: jsonCSVData[0].indexOf('sku'),
    };
    for (const jsonCSVDatum of jsonCSVData) {
      const hasEmptyNameValue = jsonCSVDatum[csvHeaderIndexes.nameIndex] === '';
      const hasEmptySkuValue = jsonCSVDatum[csvHeaderIndexes.skuIndex] === '';

      if (hasEmptyNameValue || hasEmptySkuValue) return true;
    }

    return false;
  };

  const removeEmptyRows = (data: string[][]): string[][] => data.filter((item) => item.length > 1);

  const uploadCSV = async () => {
    const data = mapSelectedRowsToCSVJSON().map((dataRow, idx) => {
      if (idx === 0) {
        return dataRow;
      }
      return dataRow.map((dataCell, cellIdx) => {
        if (cellIdx === Number(imageUrlRowIndex)) {
          return encodeURIComponent(dataCell);
        }
        return dataCell;
      });
    });
    const cleanedData = removeEmptyRows(data);

    if (hasEmptyValues(cleanedData)) {
      setMessage(
        'Rows cannot contain empty values for the columns that you have selected, please update the csv file.',
      );
      setSuccessUploading(false);
      return;
    }

    setUploading(true);
    const csvFile = jsonToCSV(cleanedData);

    await getFileStoreUrl()
      .then(async (res) => {
        const mappedRes = mapKeysToCamel(res) as IGetFileStoreUrlResponse;
        const { fileName } = mappedRes.data;

        setMessage('Uploading CSV');
        await uploadCsvFile(res.data.url, csvFile).then(async (res) => {
          if (res.status === 200) {
            setMessage('CSV file uploaded');
            await createBrandProducts({
              fileName,
              deleteCurrentProducts: deleteOldProducts,
            }).then((res) => {
              if (res.status === 200) {
                setSuccessUploading(true);
              } else {
                setSuccessUploading(false);
              }
            });
          } else {
            setSuccessUploading(false);
          }
        });
      })
      .finally(() => setUploading(false));
  };

  const inputPriceLabelName = () => {
    const store = rootStore.storeStore.getStoreConfig;

    switch (store?.storeLocation) {
      case CityCodes.LDN:
        return 'Unit Price (Post Tax)';
      case CityCodes.NYC:
      case CityCodes.LA:
        return 'Unit Price (Pre Tax)';
      default:
        return '';
    }
  };

  const renderDuplicateHeadersMessage = () => {
    if (!duplicateHeadersSelected()) {
      return null;
    }

    return (
      <div
        css={css`
          width: 100%;
          color: red;
          margin-bottom: 20px;
        `}
      >
        You must select unique headers before uploading.
      </div>
    );
  };

  const renderDropdowns = (): JSX.Element | null => {
    const hasMessageToDisplay = successUploading != null;

    if (!csvHeaders || hasMessageToDisplay || uploading) {
      return null;
    }

    return (
      <div>
        <H1 additionalStyles='margin: auto; margin-top: 40px;'> Select Your CSV headers</H1>
        <h2>Required</h2>
        Name
        <Dropdown
          values={mapHeadersToDropdownValues()}
          onChange={(selectedValue) => setNameRowIndex(selectedValue)}
          selectedValue=''
          error={false}
        />
        SKU
        <Dropdown
          values={mapHeadersToDropdownValues()}
          onChange={(selectedValue) => setSkuRowIndex(selectedValue)}
          selectedValue=''
          error={false}
        />
        <h2>Optional</h2>
        Image Url
        <Dropdown
          values={mapHeadersToDropdownValues()}
          onChange={(selectedValue) => setImageUrlRowIndex(selectedValue)}
          selectedValue=''
          error={false}
        />
        {inputPriceLabelName()}
        <Dropdown
          values={mapHeadersToDropdownValues()}
          onChange={(selectedValue) => setInputPriceRowIndex(selectedValue)}
          selectedValue=''
          error={false}
        />
        Taxcode
        <Dropdown
          values={mapHeadersToDropdownValues()}
          onChange={(selectedValue) => setTaxCodeRowIndex(selectedValue)}
          selectedValue=''
          error={false}
        />
        Colour
        <Dropdown
          values={mapHeadersToDropdownValues()}
          onChange={(selectedValue) => setColourRowIndex(selectedValue)}
          selectedValue=''
          error={false}
        />
        <div
          css={css`
            margin-top: 30px;
          `}
        >
          <Checkbox
            onClick={(checked: boolean) => setDeleteOldProducts(checked)}
            additionalStyles='margin-bottom: 40px;'
            fontSizePx='14'
          >
            Delete all current products on I.S.I
          </Checkbox>
        </div>
        {renderDuplicateHeadersMessage()}
        <Button
          buttonSize={2}
          onClick={() => uploadCSV()}
          disabled={isUploadButtonDisabled()}
          additionalStyles={`
            margin: auto;
            display: block;
          `}
        >
          Upload
        </Button>
      </div>
    );
  };

  const renderUploadStatusMessage = () => {
    let statusMessage;
    if (successUploading) {
      statusMessage = 'Your new products are currently processing in the background. You can leave this page.';
    }

    statusMessage ||= message;

    if (!statusMessage) {
      return null;
    }

    return (
      <div
        css={css`
          margin-top: 30px;
          margin-bottom: 10px;
          text-align: center;
          font-size: 14px;
          color: ${uploading ? '#343434' : 'red'};
        `}
      >
        {message}
      </div>
    );
  };

  const getTaxCodeSpreadsheetLink = (): string => {
    const store = rootStore.storeStore.getStoreConfig;

    switch (store?.storeLocation) {
      case CityCodes.LDN:
        return UK_STORE_TAX_CODE_CSV_URL;
      case CityCodes.NYC:
      case CityCodes.LA:
        return US_STORE_TAX_CODE_CSV_URL;
      default:
        return '';
    }
  };

  const renderTaxCodeGuide = (): JSX.Element => (
    <div
      css={css`
        padding: 10px 0;
        border: 1px dashed;
        display: inline-block;
        border-radius: 20px;
        margin-top: 20px;
      `}
    >
      <div
        css={css`
          padding: 0 20px;
        `}
      >
        <h3
          css={css`
            margin: 0;
            text-transform: uppercase;
          `}
        >
          Tax Code Guide
        </h3>
        <p>
          To see a list of tax code categories, please refer to the following{' '}
          <a
            css={css`
              color: black;
            `}
            href={getTaxCodeSpreadsheetLink()}
          >
            spreadsheet
          </a>
          . If you do not fill in a tax code, no tax exemptions will be applied when calculating the pre/post tax price.
        </p>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        />
      </div>
    </div>
  );

  return (
    <div
      css={css`
        max-width: 80%;
        margin: auto;
        width: 1000px;
        margin-top: 30px;
        margin-bottom: 30px;
      `}
    >
      <H1 additionalStyles='margin: auto; margin-bottom: 50px;'> Upload New Products</H1>
      <CSVReader
        onUploadAccepted={(results: IJsonifiedCsv<string[]>) => {
          setZoneHover(false);
          handleOnDrop(results);
        }}
        onDragOver={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(false);
        }}
        onDrop={handleOnDrop}
        onError={handleOnError}
        onRemoveFile={handleOnRemoveFile}
      >
        {({ getRootProps, acceptedFile, getRemoveFileProps, Remove }: any) => (
          <div {...getRootProps()} style={{ ...styles.zone, ...(zoneHover && styles.zoneHover) }}>
            {acceptedFile && !successUploading ? (
              <div style={styles.file}>
                <div style={styles.info}>
                  <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                  <span style={styles.name}>{acceptedFile.name}</span>
                </div>
                <div
                  {...getRemoveFileProps()}
                  style={styles.remove}
                  onMouseOver={(event: Event) => {
                    event.preventDefault();
                    setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                  }}
                  onMouseOut={(event: Event) => {
                    event.preventDefault();
                    setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                  }}
                >
                  <Remove color={removeHoverColor} />
                </div>
              </div>
            ) : (
              'Drop CSV file here or click to upload'
            )}
          </div>
        )}
      </CSVReader>
      {renderDropdowns()}
      {renderUploadStatusMessage()}
      {uploading && <SpinnerWrapper />}

      {renderTaxCodeGuide()}
    </div>
  );
};

export const UploadProducts = withRouter(UploadProductsComponent);
