import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { type AnchorHTMLAttributes, type ButtonHTMLAttributes, type FC } from 'react';
import { Link } from 'react-router-dom';

type AnchorButtonAttributes = ButtonHTMLAttributes<HTMLButtonElement> & AnchorHTMLAttributes<HTMLAnchorElement>;
interface LinkButtonPropsBase extends AnchorButtonAttributes {
  onClick?: () => void;
  disabled?: boolean;
  noUnderline?: boolean;
  additionalStyles?: string;
  bold?: boolean;
}
interface LinkButtonHref extends LinkButtonPropsBase {
  href: string;
  to?: never;
}
interface LinkButtonTo extends LinkButtonPropsBase {
  to: string;
  href?: never;
}
interface LinkButtonButton extends LinkButtonPropsBase {
  to?: never;
  href?: never;
}
type LinkButtonProps = LinkButtonHref | LinkButtonTo | LinkButtonButton;

const StyledButton = styled('button', { shouldForwardProp: isPropValid })<LinkButtonProps>`
  background: none;
  color: black;
  border: none;
  padding: 0;
  outline: inherit;
  ${({ noUnderline }) => (noUnderline ? 'text-decoration: none;' : 'text-decoration: underline;')}
  font-family: ${({ bold }) => (bold ? 'MarkOT-Bold' : 'MarkOT')}, helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    color: #9b9b9b;
  }
`;

const StyledLink = StyledButton.withComponent(Link);
const StyledAnchor = StyledButton.withComponent('a');

const LinkButton: FC<LinkButtonProps> = ({
  bold,
  disabled,
  href,
  noUnderline,
  onClick,
  additionalStyles,
  to,
  children,
  type,
  ...rest
}) => {
  const commonProps = {
    css: css(additionalStyles),
    onClick,
    bold,
    disabled,
    noUnderline,
    ...rest,
  };

  if (to) {
    return (
      <StyledLink aria-disabled={disabled} to={disabled ? '#' : to} {...commonProps}>
        {children}
      </StyledLink>
    );
  }

  if (href) {
    return (
      <StyledAnchor aria-disabled={disabled} href={disabled ? '#' : href} {...commonProps}>
        {children}
      </StyledAnchor>
    );
  }
  return (
    <StyledButton type={type} {...commonProps}>
      {children}
    </StyledButton>
  );
};

export { LinkButton };
