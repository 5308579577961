import moment from 'moment';

export const transformDateWithStore = (date: Date | string, storeUtcOffset: number | undefined): Date => {
  if (storeUtcOffset === undefined) {
    return moment.utc(date).toDate();
  }
  return moment.utc(date).add(storeUtcOffset, 'seconds').toDate();
};

export const transformFormatDateWithStore = (
  date: Date | string,
  storeUtcOffset: number | undefined,
  dateFormat?: string,
): string => {
  if (storeUtcOffset === undefined) {
    return `${moment.utc(date).format(dateFormat)} (UTC)`;
  }
  return moment.utc(date).add(storeUtcOffset, 'seconds').format(dateFormat);
};
