import { createTheme } from '@mui/material/styles';

import markOTBoldFont from '@isi/core/resources/fonts/MarkOT-Bold.ttf';
import markOTFont from '@isi/core/resources/fonts/MarkOT.ttf';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
      dark: 'black',
      contrastText: 'black',
    },
  },
  typography: {
    fontFamily: 'MarkOT',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'MarkOT-Bold';
          src: url('${markOTBoldFont}') format('truetype');
        }
        @font-face {
          font-family: 'MarkOT';
          src: url('${markOTFont}') format('truetype');
        }
      `,
    },
  },
});

export { theme };
