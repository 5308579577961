import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { rootStore } from '@isi/stores/root.store';

import { Button, ButtonSize } from '@isi/components/common/button.component';
import { H1 } from '@isi/components/common/h1.component';
import { Input } from '@isi/components/common/input.component';
import { LinkButton } from '@isi/components/common/link-button.component';
import { PoweredByToshi } from '@isi/components/common/powered-by-toshi.component';

interface LoginState {
  email: string;
  password: string;
}

@observer
export default class Login extends Component<{}, LoginState> {
  state = {
    email: '',
    password: '',
  };

  render(): JSX.Element {
    const { logIn, loginError } = rootStore.authStore;
    return (
      <div>
        <H1 additionalStyles='margin-top: 172px;'>Hello</H1>
        <div
          css={css`
            font-family: MarkOT;
            font-size: 14px;
            text-align: center;
            color: #9b9b9b;
            margin-top: 16px;
            margin-bottom: 40px;
          `}
        >
          Please enter your login details provided by TOSHI
        </div>
        <div
          css={css`
            margin: auto;
            width: fit-content;
          `}
        >
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              await logIn(this.state.email, this.state.password);
            }}
          >
            <Input
              id='emailInput'
              type='text'
              onClick={() => {}}
              value={this.state.email}
              onChange={(email) => {
                this.setState({ email });
              }}
              disabled={false}
              labelText='Email*'
              tabIndex={1}
              additionalStyles={`
                  margin-bottom: 35px;
                `}
              clearState={() => this.setState({ email: '' })}
            />
            <Input
              id='passwordInput'
              type='password'
              value={this.state.password}
              onChange={(password) => {
                this.setState({ password });
              }}
              disabled={false}
              labelText='Password*'
              tabIndex={2}
              additionalStyles={`
                  width: calc(320px - 65px);
                `}
              clearState={() => this.setState({ password: '' })}
            />
            <Button
              id='submitBtn'
              buttonSize={ButtonSize.large}
              fullWidth
              disabled={false}
              additionalStyles={`
                  width: 322px;
                  margin-top: 107px;
                `}
              type='submit'
            >
              SUBMIT
            </Button>
            {loginError && (
              <p
                css={css`
                  font-family: MarkOT;
                  font-size: 14px;
                  text-align: center;
                  color: red;
                  width: 320px;
                `}
              >
                {loginError}
              </p>
            )}
            <LinkButton to='/login/sso' additionalStyles='display: block; text-align: center; margin-top: 20px;'>
              Sign in with SSO
            </LinkButton>
            <PoweredByToshi additionalStyles='margin-top: 279px;' />
          </form>
        </div>
      </div>
    );
  }
}

export { Login as InStoreInterfaceLogin };
