import axios, { type AxiosResponse } from 'axios';

import { apiUrl } from '@isi/config/Url';
import { type OrderStatus } from '@isi/enums/order-status.enum';
import { type SearchQuery } from '@isi/interfaces/search-query.interface';
import { mapKeysToSnake } from '@isi/network/helpers/params/map-keys-to-snake.function';

export interface CreateStoreDashboardOrderQueryResponse {
  total_hits: number;
  items: DashboardRowsResponse[];
}

export type IProductModeResponse =
  | 'checkout'
  | 'tbyb_wizard'
  | 'tbyb_checkout'
  | 'tbyb_button'
  | 'instore_interface'
  | 'manual_order';

export interface DashboardRowsResponse {
  initial_journey_id: number;
  latest_journey_id: number;
  store_assistant_phone: string;
  store_assistant_name: string;
  return_only: boolean;
  store_id: number;
  paid: boolean;
  size_up_size_down: boolean | undefined;
  end_address_zone: string | null;
  alterations: boolean;
  inspire_me: boolean;
  pickup_code: string;
  perfect_fit: boolean;
  wait_and_try: boolean;
  consignment: boolean;
  client_guest: boolean;
  notes: string;
  inspire_me_customer_notes: string;
  gift_recipient_hashed_id: string | null;
  gift_recipient_guest: boolean | null;
  gift_recipient_email: string | null;
  gift_recipient_contact_number_country: string | null;
  client_hashed_id: string;
  client_email: string;
  client_contact_number_country: string;
  id: number;
  record_id: string;
  created_at: string;
  order_id: number;
  order_hashed_id: string;
  hashed_id: string;
  store_order_number_v2: string;
  product_mode: IProductModeResponse;
  start_datetime: string;
  end_datetime: string;
  status: OrderStatus;
  external_state: string;
  status_type: string;
  order_type: string;
  post_tax_order_total: number;
  post_tax_order_total_cents: number;
  pre_tax_order_total_cents: number;
  post_tax_order_total_formatted: string;
  pre_tax_order_total_formatted: string;
  journey_type: string;
  client_house_name: string | null;
  client_first_name: string;
  client_last_name: string;
  client_address_line_1: string;
  client_address_line_2: string;
  client_postcode: string;
  client_city: string;
  client_contact_number: string;
  gifted_order: boolean;
  gift_recipient_house_name: string | null;
  gift_recipient_first_name: string | null;
  gift_recipient_last_name: string | null;
  gift_recipient_address_line_1: string | null;
  gift_recipient_address_line_2: string | null;
  gift_recipient_postcode: string | null;
  gift_recipient_city: string | null;
  gift_recipient_contact_number: string | null;
  order_items: DashboardRowOrderItemResponse[];
  afternoon_collection: boolean;
  slot_times_as_string: string | null;
}

export interface DashboardRowOrderItemResponse {
  id: number;
  inspire_me: boolean;
  post_tax_price_formatted: string;
  pre_tax_price_formatted: string;
  post_tax_price_cents: number;
  pre_tax_price_cents: number;
  name: string;
  size: string;
  size_up_size_down: boolean;
  inspire_me_item: boolean;
  was_returned: boolean;
  return_reason: string;
  sku: string;
  kept: boolean;
  hashed_id: string;
  colour: string;
  image_url: string;
  input_price: number;
  quantity: number;
  applied_tax_rate: number;
  paid_at: string | null;
}

export const createStoreDashboardQuery = (
  params: SearchQuery,
): Promise<AxiosResponse<CreateStoreDashboardOrderQueryResponse>> => {
  const url = new URL(`${apiUrl()}/api/v3/isi/isi_dashboard_orders`);

  return axios.post<CreateStoreDashboardOrderQueryResponse>(url.toString(), mapKeysToSnake(params));
};
