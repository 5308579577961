import axios, { type AxiosResponse } from 'axios';

import { apiUrl } from '@isi/config/Url';
import { type LiveChatIntegration } from '@isi/enums/liveChatIntegration.enum';

export interface IStoreConfigResponse {
  id: number;
  wait_and_try_enabled: boolean;
  sizing_enabled: boolean;
  inspire_me_enabled: boolean;
  perfect_fit_enabled: boolean;
  alterations_enabled: boolean;
  consignment_dropoff_enabled: boolean;
  consignment_serviced_enabled: boolean;
  store_location: string;
  store_country: string;
  store_country_code: string;
  store_id: number;
  key: string;
  server_key: string;
  brand_id: number;
  full_brand_name: string;
  brand_name: string;
  brand_user_id: number;
  brand_user_email: string;
  sizes: Array<string>;
  logo_url: URL['href'];
  simple_drop_off_pricing: boolean;
  enable_security_code_feature: boolean;
  security_code_toggle_default: boolean;
  enable_gifting_feature: boolean;
  drop_off_sizes: Array<string>;
  wait_and_try_sizes: Array<string>;
  utc_offset_seconds: number;
  booker_enabled: boolean;
  warehouse_to_door: boolean;
  return_booker_enabled: boolean;
  non_toshi_return_booker_enabled: boolean;
  live_chat_integration: LiveChatIntegration;
  payment_at_door_enabled: boolean;
}

export const storeConfig = async (): Promise<AxiosResponse<IStoreConfigResponse>> => {
  return axios.get(`${apiUrl()}/api/v3/isi/integration`);
};
