import { css } from '@emotion/react';

import infoIcon from '@isi/assets/icons/info.svg';

type TToggleExpand = {
  type: string;
  payload: string;
};

interface TaxCategoryButtonProps {
  code: string;
  name: string;
  lastChild: boolean;
  expanded: boolean;
  onClick: () => void;
  description?: string;
  toggleExpandedCategory: (object: TToggleExpand) => void;
}

const DropDownButton = ({
  toggleExpandedCategory,
  onClick,
  lastChild,
  expanded,
  description,
  code,
  name,
}: TaxCategoryButtonProps): JSX.Element => (
  <div
    css={css`
      position: relative;
    `}
  >
    <div
      css={css`
        display: flex;
        min-height: 44px;
        background-color: #eee;
        border-bottom: 1px solid white;
        border-top: none;
        ${lastChild ? 'border-bottom: none;' : null}
        &:hover {
          background: black;
          color: white;
        }
      `}
    >
      <button
        css={css`
          width: 100%;
          background: none;
          display: flex;
          border: none;
          align-items: flex-start;
          justify-content: center;
          box-sizing: border-box;
          padding: 10px;
          padding-left: 17px;
          cursor: pointer;
          text-align: left;
          flex-direction: column;
          &:hover {
            color: white;
          }
        `}
        type='button'
        onClick={onClick}
        key={code}
      >
        <div
          css={css`
            display: flex;
            width: 90%;
            align-items: center;
          `}
        >
          {name}
        </div>

        {expanded ? (
          <div
            css={css`
              margin-top: 10px;
              color: #8c8c8c;
              width: 80%;
            `}
          >
            Description: {description}
          </div>
        ) : null}
      </button>
    </div>
    <button
      css={css`
        background: white;
        border: 1px solid #8c8c8c;
        border-radius: 3px;
        padding: 5px 10px;
        cursor: pointer;
        height: 30px;
        position: absolute;
        right: 8px;
        top: 7px;
        z-index: 9;
      `}
      onClick={() => {
        toggleExpandedCategory({ type: 'TOGGLE_TAX_CODE_INFO', payload: code });
      }}
      type='button'
    >
      <img
        css={css`
          height: 12px;
        `}
        src={infoIcon}
        alt='information'
      />
    </button>
  </div>
);
export default DropDownButton;
