import { css } from '@emotion/react';
import { Component } from 'react';
import { type RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import TaxCodeInput from './tax-code-input/tax-code-input.component';
import { type INewBrandProductData } from '@isi/network/products/create-product.function';
import { rootStore } from '@isi/stores/root.store';

import { Button, ButtonSize } from '@isi/components/common/button.component';
import { Container } from '@isi/components/common/container.component';
import { H1 } from '@isi/components/common/h1.component';
import { Input } from '@isi/components/common/input.component';

interface INewProductErrors {
  [key: string]: string | undefined;
  name: string;
  colour: string;
  inputPrice: string;
}

interface INewProductState {
  product: INewBrandProductData;
  errors: INewProductErrors;
}

class NewProduct extends Component<RouteComponentProps, INewProductState> {
  private static newProductErrorString(property: string) {
    switch (property) {
      case 'name':
        return 'Please enter a name.';
      case 'colour':
        return 'Please enter a colour';
      case 'inputPrice':
        return 'Please enter a price';

      default:
        return '';
    }
  }

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      product: {
        productTaxCode: '',
        name: '',
        colour: '',
        sku: '',
        imageUrl: '',
        inputPrice: -1,
      },
      errors: {
        name: '',
        colour: '',
        inputPrice: '',
      },
    };
  }

  private async handleSubmit() {
    this.addErrors();
    if (!this.checkForErrorMessages()) {
      const productCreated = await rootStore.productStore.createProduct(this.state.product);
      if (productCreated) {
        rootStore.productStore.addToSelectedProducts(productCreated);
        this.props.history.push({
          pathname: '/products',
          state: {
            isServiceOrder: true,
          },
        });
      }
    }
  }

  private handleProductFieldChange(property: string, value: string | number, required?: boolean) {
    this.clearError(property);
    if (required && !value) {
      this.addError(property);
    }
    this.setState((prevState) => ({
      product: {
        ...prevState.product,
        [property]: value,
      },
    }));
  }

  private addError(property: string) {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [property]: NewProduct.newProductErrorString(property),
      },
    }));
  }

  private clearError(property: string) {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [property]: '',
      },
    }));
  }

  private addErrors() {
    const { errors, product } = this.state;

    for (const property of Object.keys(errors)) {
      if (product[property] === '' || Number(product[property]) < 0)
        errors[property] = NewProduct.newProductErrorString(property);
    }
    this.setState({ errors });
  }

  private checkForErrorMessages() {
    return Object.values(this.state.errors).some((error) => error !== '');
  }

  public render() {
    const { errors, product } = this.state;

    return (
      <div>
        <Container width='320px' additionalStyles='margin-top: 46px;'>
          <H1 additionalStyles='margin-bottom: 41px;'>ADD NEW PRODUCT</H1>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <Input
              id='product_name_input'
              value={product.name}
              labelText='Product name*'
              onChange={(value: string) => this.handleProductFieldChange('name', value, true)}
              disabled={false}
              type='text'
              error={errors.name}
              clearState={() => this.handleProductFieldChange('name', '', true)}
            />
          </div>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <Input
              id='colour_input'
              value={product.colour}
              labelText='Colour*'
              onChange={(value: string) => this.handleProductFieldChange('colour', value, true)}
              disabled={false}
              type='text'
              error={errors.colour}
              clearState={() => this.handleProductFieldChange('colour', '', true)}
            />
          </div>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <Input
              id='sku_input'
              value={product.sku}
              labelText='SKU (optional)'
              onChange={(value: string) => this.handleProductFieldChange('sku', value)}
              disabled={false}
              type='text'
              clearState={() => this.handleProductFieldChange('sku', '', true)}
            />
          </div>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <Input
              id='image_url_input'
              value={product.imageUrl}
              labelText='Image URL (optional)'
              onChange={(value: string) => this.handleProductFieldChange('imageUrl', encodeURIComponent(value))}
              disabled={false}
              type='text'
              clearState={() => this.handleProductFieldChange('imageUrl', '', true)}
            />
          </div>
          <div
            css={css`
              margin-bottom: 30px;
            `}
          >
            <TaxCodeInput onChange={(value: string) => this.handleProductFieldChange('productTaxCode', value, true)} />
          </div>
          <div
            css={css`
              margin-bottom: 40px;
            `}
          >
            <Input
              id='price_input'
              value={product.inputPrice}
              labelText='Unit Price*'
              onChange={(value: string) => this.handleProductFieldChange('inputPrice', value, true)}
              disabled={false}
              type='number'
              error={errors.inputPrice}
              additionalStyles='width: 120px'
              clearState={() => this.handleProductFieldChange('inputPrice', '', true)}
            />
          </div>

          <Button buttonSize={ButtonSize.large} fullWidth onClick={() => this.handleSubmit()} disabled={false}>
            ADD PRODUCT
          </Button>
        </Container>
      </div>
    );
  }
}

export default withRouter(NewProduct);
