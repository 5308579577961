import axios from 'axios';

import { apiUrl } from '@isi/config/Url';
import { mapKeysToSnake } from '@isi/network/helpers/params/map-keys-to-snake.function';

export interface ICheckAddressEligibilityParams {
  postcode: string;
}

export interface IGetEligibilityResponse {
  eligible: boolean;
}

export const checkAddressEligibility = (params: ICheckAddressEligibilityParams) => {
  const url = `${apiUrl()}/v2/address/eligible`;
  return axios.post<IGetEligibilityResponse>(url, mapKeysToSnake(params));
};
