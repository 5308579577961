import { css } from '@emotion/react';
import { type ReactNode } from 'react';

interface H4Props {
  children: ReactNode;
  bold?: boolean;
  additionalStyles?: string;
}

const H4 = ({ children, bold, additionalStyles }: H4Props): JSX.Element => {
  const style: string = `
    font-family: MarkOT;
    font-size: 14px;
    font-weight: ${bold ? 'bold' : 'normal'};
    font-family: ${bold ? 'MarkOT-Bold' : 'MarkOT'}, helvetica, arial, sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #000000;
    ${additionalStyles}
  `;

  return (
    <h4
      css={css`
        ${style}
      `}
    >
      {children}
    </h4>
  );
};

export { H4 };
