import { css } from '@emotion/react';
import { useEffect, useReducer, useRef, useState } from 'react';

import DropDownButton from './drop-down-button.component';
import { mapKeysToCamel } from '@isi/network/helpers/params/map-keys-to-camel.function';
import { getStoreTaxCodes } from '@isi/network/store_tax_codes/get-store-tax-codes.function';
import { rootStore } from '@isi/stores/root.store';

import useOnClickOutside from '@isi/hooks/use-on-click-outside.function';

import { Label } from '@isi/components/common/label.component';

import { type ISelectValue, Select } from '../../common/select.component';

interface TaxCodeState {
  name: string;
  description: string;
  code: string;
  visable: boolean;
  expanded: boolean;
  pinned: boolean;
  relatedTerms: string[];
}

interface TaxCategoryData {
  term: string;
  categories: TaxCodeState[];
}

interface TaxCodeProps {
  onChange: (value: string) => void;
}

function taxCategoryDataReducer(state: TaxCategoryData, action: any) {
  if (action.type === 'DATA_READY') {
    return { term: '', categories: action.payload };
  }

  if (action.type === 'TOGGLE_TAX_CODE_INFO') {
    const code = action.payload;
    const newTaxCateogories = state.categories.map((cat: TaxCodeState) => {
      if (cat.code !== code) {
        return { ...cat, expanded: false };
      }

      return { ...cat, expanded: !cat.expanded };
    });

    return { ...state, categories: newTaxCateogories };
  }

  const term = action.payload;

  if (term === '') {
    const categories = state.categories.map((category: TaxCodeState) => ({ ...category, visable: false }));

    return { term, categories };
  }

  const categories = state.categories.map((category: TaxCodeState) => {
    const visable =
      category.name.toLowerCase().includes(term.toLowerCase()) ||
      category.relatedTerms.some((relatedTerm: string) => relatedTerm.toLowerCase().includes(term.toLowerCase()));

    return { ...category, visable };
  });

  return { term, categories };
}

const TaxCodeInput = (props: TaxCodeProps): JSX.Element => {
  const { onChange } = props;
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [taxCodesLoading, setTaxCodesLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<TaxCodeState | null>(null);
  const [taxCategoryData, dispatchTaxData] = useReducer(taxCategoryDataReducer, {
    term: '',
    categories: [],
  } as TaxCategoryData);
  const node = useRef(null);

  const handleClickOutside = () => {
    setDropDownOpen(false);
  };

  useOnClickOutside(node, handleClickOutside);

  const searchTermChange = (term: string): void => {
    dispatchTaxData({ type: 'SEARCH_UPDATE', payload: term });
  };

  useEffect(() => {
    const loadTaxCodes = async (): Promise<void> => {
      const storeConfig = rootStore.storeStore.getStoreConfig;
      const storeId = storeConfig?.storeId;
      if (!storeId) {
        return;
      }

      const taxCodeResponse = await getStoreTaxCodes({ storeId });
      const taxCodes = mapKeysToCamel(taxCodeResponse.data);
      const storeTaxCodes = taxCodes.map((code) => {
        return { ...code, visable: false, pinned: true, expanded: false };
      }) as TaxCodeState[];

      dispatchTaxData({ type: 'DATA_READY', payload: storeTaxCodes });
      setTaxCodesLoading(false);
    };
    loadTaxCodes();
  }, [dispatchTaxData, setTaxCodesLoading]);

  const changeCategory = (item: TaxCodeState | null) => {
    setSelectedCategory(item);
    onChange(item ? item.code : '');

    if (!item) {
      dispatchTaxData({ type: 'SEARCH_UPDATE', payload: '' });
    }
  };

  const renderCategories = (): JSX.Element[] =>
    taxCategoryData.categories
      .filter((item: TaxCodeState) => item.visable)
      .map((item: TaxCodeState, index: number) => (
        <DropDownButton
          toggleExpandedCategory={dispatchTaxData}
          key={item.code}
          code={item.code}
          expanded={item.expanded}
          description={item.description}
          name={item.name}
          lastChild={index === taxCategoryData.categories.length - 1}
          onClick={() => changeCategory(item)}
        />
      ));

  const optionsForSelect = (): ISelectValue[] => {
    const options = taxCategoryData.categories.map((cat: TaxCodeState) => ({ value: cat.code, display: cat.name }));
    return [{ display: 'Not VAT exempt', value: '' }, ...options];
  };

  const changeCategroryFromCode = (code: string | number) => {
    if (code === '') {
      changeCategory(null);
    }

    const taxcode = taxCategoryData.categories.find((item: TaxCodeState) => item.code === code);

    changeCategory(taxcode);
  };

  const renderComponent = (): JSX.Element => {
    if (taxCodesLoading) {
      return <div />;
    }
    const component =
      taxCategoryData.categories.length > 30 ? (
        <div
          css={css`
            margin-bottom: 30px;
          `}
          ref={node}
        >
          <Label htmlFor='code' text='Tax Exemption Category (optional)' />
          {selectedCategory ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <input
                css={css`
                  height: 44px;
                  padding-left: 15px;
                `}
                value={selectedCategory ? selectedCategory.name : ''}
                readOnly
              />

              <select
                css={css`
                  display: none;
                `}
                name='product_tax_code'
              >
                <option value={selectedCategory.code}>{selectedCategory.name}</option>
              </select>

              <button
                css={css`
                  background: none;
                  border: none;
                  padding: 10px 0 0;
                  cursor: pointer;
                  align-self: flex-start;
                  text-decoration: underline;
                `}
                onClick={() => changeCategory(null)}
                type='button'
              >
                Select a different category
              </button>
            </div>
          ) : (
            <input
              css={css`
                font-family: MarkOT;
                width: 100%;
                font-size: 14px;
                border: solid 1px #8c8c8c;
                padding-left: 15px;
                height: 44px;
                display: flex;
                align-items: flex-start;
                box-sizing: border-box;
                &:focus {
                  outline: 0;
                }
              `}
              placeholder="Describe the item...(e.g 'sweater')"
              value={taxCategoryData.term}
              onChange={(e) => searchTermChange(e.target.value)}
              onClick={() => setDropDownOpen(true)}
            />
          )}

          {dropDownOpen && !selectedCategory ? (
            <div
              css={css`
                box-sizing: border-box;
                overflow: scroll;
                max-height: 300px;
              `}
            >
              {renderCategories()}
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <Select
            label='Tax Exemption Category'
            values={optionsForSelect()}
            disabled={false}
            onChange={(item) => changeCategroryFromCode(item)}
          />
        </div>
      );

    return component;
  };

  return renderComponent();
};

export default TaxCodeInput;
