import PagyPagination from '@shared/stores/mixins/pagy-pagination.mixin';

import { type BrandJourneyReportSnakeCase } from '@isi/interfaces/brand-report.interface';
import { getBrandReports } from '@isi/network/brand_reports/get-brand-reports.function';
import BrandJourneyReport from '@isi/stores/models/brand-journey-report.model';

class BrandJourneyReportStore extends PagyPagination<BrandJourneyReportSnakeCase, BrandJourneyReport> {
  load = getBrandReports;

  transformResponse = (responseItems: BrandJourneyReportSnakeCase[]) =>
    responseItems.map((report) => new BrandJourneyReport(report));
}

export default BrandJourneyReportStore;
