import { useMemo } from 'react';

import {
  LA_OPS_EMAIL,
  LA_OPS_PHONE,
  LDN_OPS_EMAIL,
  LDN_OPS_PHONE,
  NY_OPS_EMAIL,
  NY_OPS_PHONE,
} from '@isi/constants/constants';
import { CityCodes } from '@isi/enums/city-codes.enum';

import useStoreContext from '@isi/hooks/useStoreContext';

type TuseToshiDetails = {
  contactEmail: string;
  contactPhone: { number: string; formatted: string };
};

const formatUKNumber = (number: string) => `${number.slice(0, 5)} ${number.slice(5)}`;

const formatUSNumber = (number: string) =>
  `${number.slice(0, 1)} ${number.slice(1, 4)} ${number.slice(4, 7)} ${number.slice(7)}`;

const useToshiDetails = (): TuseToshiDetails => {
  const {
    storeStore: { getStoreConfig },
  } = useStoreContext();

  const contactEmail = useMemo(() => {
    switch (getStoreConfig?.storeLocation) {
      case CityCodes.LDN:
        return LDN_OPS_EMAIL;
      case CityCodes.NYC:
        return NY_OPS_EMAIL;
      case CityCodes.LA:
        return LA_OPS_EMAIL;
      default:
        return '';
    }
  }, [getStoreConfig?.storeLocation]);

  const contactPhone = useMemo(() => {
    switch (getStoreConfig?.storeLocation) {
      case CityCodes.LDN:
        return { number: LDN_OPS_PHONE, formatted: formatUKNumber(LDN_OPS_PHONE) };
      case CityCodes.NYC:
        return { number: NY_OPS_PHONE, formatted: formatUSNumber(NY_OPS_PHONE) };
      case CityCodes.LA:
        return { number: LA_OPS_PHONE, formatted: formatUSNumber(LA_OPS_PHONE) };
      default:
        return { number: '', formatted: '' };
    }
  }, [getStoreConfig?.storeLocation]);

  return {
    contactEmail,
    contactPhone,
  };
};

export default useToshiDetails;
