import { observer } from 'mobx-react';
import { type ReactElement, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';

import { clearCookie } from '@shared/helpers/cookies/clearCookie';

import { CookieNames, GetCookie } from '@isi/helpers/cookies';

import useAuthContext from '@isi/hooks/useAuthContext';

type NoAuthProps = {
  children: ReactElement;
};

export const NoAuth = observer(({ children }: NoAuthProps) => {
  const { loggedIn } = useAuthContext();

  const redirect = useRef<string | null>(GetCookie.redirectedFrom());

  useEffect(() => {
    if (redirect.current && loggedIn) {
      clearCookie(CookieNames.RedirectedFrom);
    }
  }, [loggedIn]);

  if (!loggedIn) return children;

  if (redirect.current) return <Redirect to={new URL(redirect.current).pathname} />;

  return <Redirect to={{ pathname: '/' }} />;
});
