import { css } from '@emotion/react';
import { type ReactNode } from 'react';

interface H3Props {
  children: ReactNode;
  bold?: boolean;
  additionalStyles?: string;
  uppercase?: boolean;
}

const H3 = ({ children, bold, additionalStyles, uppercase }: H3Props): JSX.Element => {
  const style: string = `
    font-family: MarkOT;
    font-size: 16px;
    font-weight: ${bold ? 'bold' : 'normal'};
    font-family: ${bold ? 'MarkOT-Bold' : 'MarkOT'}, helvetica, arial, sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    ${uppercase ? 'text-transform: uppercase;' : ''}
    color: #000000;
    ${additionalStyles}
  `;
  return (
    <h3
      css={css`
        ${style}
      `}
    >
      {children}
    </h3>
  );
};

export { H3 };
