import { css } from '@emotion/react';
import { type MouseEvent, type ReactNode, type TouchEvent, useEffect, useState } from 'react';

import tickIcon from '@isi/assets/icons/tick.svg';

interface ICheckboxState {
  checked: boolean;
}

interface ICheckboxProps {
  onClick: (checked: boolean) => void;
  checked?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  additionalStyles?: string;
  fontSizePx?: string;
  id?: string;
}

const Checkbox = ({
  onClick,
  checked: propsChecked,
  children,
  disabled,
  additionalStyles,
  fontSizePx,
  id,
}: ICheckboxProps): JSX.Element => {
  const [checked, setChecked] = useState(Boolean(propsChecked));

  useEffect(() => {
    if (propsChecked !== undefined) {
      setChecked(propsChecked);
    }
  }, [propsChecked]);

  const toggleChecked = (e: MouseEvent | TouchEvent): void => {
    if (disabled) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    const newCheckedState = !checked;

    setChecked(newCheckedState);
    onClick(newCheckedState);
  };

  const renderTick = () =>
    checked ? (
      <img
        css={css`
          margin-left: 3px;
          margin-top: 5px;
          display: block;
        `}
        alt='Check mark icon'
        src={tickIcon.toString()}
      />
    ) : null;

  const renderLabel = () => {
    if (children) {
      return (
        <label
          css={css`
            margin-top: -20px;
            margin-left: 40px;
            display: block;
            font-family: MarkOT;
            font-size: ${fontSizePx || '12'}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
          `}
          htmlFor={id}
        >
          {children}
        </label>
      );
    }
    return null;
  };

  return (
    <div
      id={id}
      role='button'
      tabIndex={0}
      className='checkbox'
      css={css`
        ${additionalStyles}
      `}
      onClick={toggleChecked}
    >
      <div
        css={css`
          display: block;
          width: 21px;
          height: 21px;
          border: solid 1px ${disabled ? '#8c8c8c' : '#000000'};
          background-color: ${disabled ? 'rgb(170, 170, 170))' : '#ffffff'};

          &:hover {
            cursor: ${disabled ? 'auto' : 'pointer'};
          }
        `}
      >
        {renderTick()}
      </div>
      {renderLabel()}
    </div>
  );
};

export { Checkbox, ICheckboxState, ICheckboxProps };
