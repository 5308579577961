import { css } from '@emotion/react';
import { Component } from 'react';

import { type FieldTypePreservingOmit } from '@isi/helpers/fieldPreservingOmit';
import { type SearchPrediction } from '@isi/network/addresses/address-autocomplete-options.function';
import { type INewAddressData } from '@isi/network/customers/create-customer.function';
import { rootStore } from '@isi/stores/root.store';

import AddressSearchInput from '@isi/components/common/address-search-input.component';
import { Input } from '@isi/components/common/input.component';

interface AddressEntryInputProps {
  manualAddressEntry: boolean;
  handleAddressChange: (key: string, value: string) => void;
  handlePostcodeEligibilityCheck: (postcodeValue: string) => void;
  setAddressFromSearchPrediction: (prediction: SearchPrediction) => void;
  shouldDisplayError: (arg0: string) => string | undefined;
  toggleManualAddressEntry: () => void;
  address: FieldTypePreservingOmit<INewAddressData, 'country'>;
  isGiftRecipient: boolean;
}

class AddressEntryInput extends Component<AddressEntryInputProps> {
  private manualAddressEntryInstruction = this.props.isGiftRecipient
    ? 'Or enter their address manually'
    : 'Or enter your address manually';
  private searchAddressEntryInstruction = this.props.isGiftRecipient
    ? 'Or search for their address'
    : 'Or search for your address';

  private storeCountry = rootStore.storeStore.getStoreConfig?.storeCountry;

  private renderButton = () => (
    <button
      css={css`
        border: 0;
        cursor: pointer;
        text-decoration: underline;
        background: none;
        padding: 0;
        margin-top: ${this.props.manualAddressEntry ? '0' : '28px'};
      `}
      onClick={this.props.toggleManualAddressEntry}
      type='button'
    >
      {this.props.manualAddressEntry ? this.searchAddressEntryInstruction : this.manualAddressEntryInstruction}
    </button>
  );

  public render(): JSX.Element {
    const {
      address,
      handleAddressChange,
      manualAddressEntry,
      shouldDisplayError,
      handlePostcodeEligibilityCheck,
      setAddressFromSearchPrediction,
    } = this.props;

    if (manualAddressEntry) {
      return (
        <>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <Input
              id='inputHouseNumber'
              labelText='House name / Company name (optional)'
              value={address.houseName}
              onClick={() => null}
              onChange={(value: string) => handleAddressChange('houseName', value)}
              disabled={false}
              type='text'
              clearState={() => handleAddressChange('houseName', '')}
            />
          </div>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <Input
              id='inputAddress1'
              labelText='Address line 1*'
              value={address.line1}
              onClick={() => null}
              onChange={(value: string) => handleAddressChange('line1', value)}
              disabled={false}
              type='text'
              error={shouldDisplayError('line1')}
              clearState={() => handleAddressChange('line1', '')}
            />
          </div>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <Input
              id='inputAddress2'
              labelText='Address line 2 (optional)'
              value={address.line2}
              onClick={() => null}
              onChange={(value: string) => handleAddressChange('line2', value)}
              disabled={false}
              type='text'
              clearState={() => handleAddressChange('line2', '')}
            />
          </div>
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <Input
              id='inputCity'
              labelText='City*'
              value={address.cityTown}
              onClick={() => null}
              onChange={(value: string) => handleAddressChange('cityTown', value)}
              disabled={false}
              type='text'
              error={shouldDisplayError('cityTown')}
              clearState={() => handleAddressChange('cityTown', '')}
            />
          </div>
          <div>
            <Input
              id='inputPostcode'
              labelText='Postcode/Zipcode*'
              value={address.postcodeZipcode}
              onClick={() => null}
              onChange={(value: string) => handlePostcodeEligibilityCheck(value)}
              disabled={false}
              type='text'
              additionalStyles='width: 100px;'
              error={shouldDisplayError('postcodeZipcode')}
              clearState={() => handlePostcodeEligibilityCheck('')}
            />
          </div>
          {this.renderButton()}
        </>
      );
    }
    return (
      <div>
        <AddressSearchInput
          setAddressFromSearchPrediction={setAddressFromSearchPrediction}
          labelText='Please enter first line of address or full postcode/zipcode'
        />
        {this.renderButton()}
      </div>
    );
  }
}

export default AddressEntryInput;
