import axios from 'axios';

import { apiUrl } from '@isi/config/Url';
import { type BrandJourneyReportSnakeCase } from '@isi/interfaces/brand-report.interface';
import { type PagyNetworkFunction } from '@isi/interfaces/pagy-pagination.inteface';

export const getBrandReports: PagyNetworkFunction<BrandJourneyReportSnakeCase> = (params) =>
  axios.get(`${apiUrl()}/api/v3/isi/brand_reports`, {
    params,
  });
