/* tslint:disable */

export function encodeParams(obj: Record<string, any>): string {
  let str = '';

  for (const [key, value] of Object.entries(obj)) {
    if (str !== '') {
      str += '&';
    }
    str += `${key}=${encodeURIComponent(value)}`;
  }
  return str;
}
