import { css } from '@emotion/react';
import debounce from 'lodash/debounce';
import { Component, type InputHTMLAttributes } from 'react';

import {
  addressAutocompleteOptions,
  type SearchPrediction,
} from '@isi/network/addresses/address-autocomplete-options.function';
import { rootStore } from '@isi/stores/root.store';

import { Input } from '@isi/components/common/input.component';

interface IAddressSearchInputProps {
  setAddressFromSearchPrediction: (prediction: SearchPrediction) => void;
  labelText?: string;
}

interface IAddressSearchInputState {
  searchPredictions: SearchPrediction[];
  moreValues: boolean;
  inputValue: InputHTMLAttributes<HTMLInputElement>['value'];
  inputFocused: boolean;
}

class AddressSearchInput extends Component<IAddressSearchInputProps, IAddressSearchInputState> {
  state: IAddressSearchInputState = {
    searchPredictions: [],
    moreValues: false,
    inputValue: '',
    inputFocused: false,
  };

  searchAddresses = debounce(
    async (searchValue: string): Promise<void> => {
      const storeConfig = rootStore.storeStore.getStoreConfig;
      if (!storeConfig) {
        return;
      }
      const response = await addressAutocompleteOptions(searchValue, storeConfig.storeId);

      this.setState({
        searchPredictions: response.data.predictions,
        moreValues: response.data.more_values,
      });
    },
    500,
    { leading: false, trailing: true },
  );

  componentDidMount(): void {
    if (rootStore.customerStore.postcode !== '') {
      this.onAddressSearchChange(rootStore.customerStore.postcode);
    }
  }

  onAddressSearchChange = async (searchValue: string): Promise<void> => {
    this.setState({ inputValue: searchValue });
    if (searchValue === '') {
      this.setState({ searchPredictions: [] });
      return;
    }
    await this.searchAddresses(searchValue);
  };

  addressOutput = (vals: string[]): string => {
    const output = vals.filter(Boolean).join(', ');
    return output;
  };

  renderSeachPredictions = (): JSX.Element[] =>
    this.state.searchPredictions?.map((prediction: SearchPrediction, i: number) => {
      const address = prediction.address_components;
      const output = address.full_address_string;

      if (!['UNITED KINGDOM', 'UNITED STATES'].includes(address.country.toUpperCase()) || output === '') {
        return <span key={i} />;
      }

      return (
        <button
          css={css`
            background-color: #f5f5f5;
            border: none;
            padding: 10px 17px;
            cursor: pointer;
            width: 100%;
            text-align: left;

            &:hover {
              background-color: #000000;
              color: white !important;
            }
          `}
          onClick={() => this.props.setAddressFromSearchPrediction(prediction)}
          key={i}
          type='button'
        >
          {output}
        </button>
      );
    });

  storeInLdn = (): boolean => {
    const location = rootStore.storeStore.getStoreConfig?.storeLocation;

    return location === 'LDN';
  };

  clearInput = (): void => {
    this.setState({
      inputFocused: false,
      inputValue: '',
    });
  };

  render(): JSX.Element {
    const labelText = this.props.labelText || "Start typing customer's address";
    return (
      <div>
        {this.state.moreValues && (
          <div
            css={css`
              color: #d20303;
              margin-bottom: 10px;
              font-size: 13px;
            `}
          >
            Please refine your search - we found more addresses than we can show.
          </div>
        )}
        <Input
          id='inputCustomerAddress'
          labelText={labelText}
          value={this.state.inputValue}
          focused={this.state.inputFocused}
          onClick={() => {}}
          onChange={(value: string) => this.onAddressSearchChange(value)}
          disabled={false}
          autoComplete='off'
          type='text'
          additionalStyles={`
            box-sizing: border-box;
            width: 100%;
            &:focus {
              outline: none;
            }
          `}
          placeholderText={this.storeInLdn() ? 'E.g. 41 Foley St...' : 'E.g. 100 w 31st st...'}
          disableErrorText
          clearState={this.clearInput}
        />
        <div
          className='predictions fullstorymask'
          css={css`
            max-height: 50vh;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          `}
        >
          {this.renderSeachPredictions()}
        </div>
      </div>
    );
  }
}

export default AddressSearchInput;
