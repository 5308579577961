import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { Label } from '@isi/components/common/label.component';
import { LinkButton } from '@isi/components/common/link-button.component';

interface InputProps {
  value?: string | number | readonly string[];
  onClick?: () => void;
  onChange: (value: string) => void;
  disabled?: boolean;
  placeholderText?: string;
  type?: string;
  additionalStyles?: string;
  labelText?: string;
  error?: string;
  id?: string;
  disableErrorText?: boolean;
  tabIndex?: number;
  clearState: () => void;
  focused?: boolean;
  autoComplete?: string;
}

const StyledError = styled.span`
  color: #d20303;
  margin-top: 5px;
  font-size: 13px;
  height: 13px;
  display: block;
`;

const StyledInput = styled.input<{ focused: boolean; error?: string }>`
  &:focus {
    outline: none;
  }
  font-family: MarkOT;
  width: calc(100% - 65px);
  height: 40px;
  font-size: 14px;
  border: ${({ focused, error }) =>
    error ? 'solid 1px #d20303;' : focused ? 'solid 1px #000000;' : 'solid 1px #8c8c8c;'}
  padding-left: 15px;
  padding-right: 50px;
  display: block;

  &::placeholder {
    color: #a1a1a1;
  }

  &::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`;

const Input = forwardRef<Partial<HTMLInputElement>, InputProps>(
  (
    {
      onChange: propsOnChange = () => {},
      onClick = () => {},
      disabled,
      type: propsType,
      value,
      placeholderText,
      additionalStyles,
      labelText,
      error,
      id,
      disableErrorText,
      tabIndex,
      clearState,
      focused: propsFocused,
      autoComplete,
    },
    ref,
  ): JSX.Element => {
    const passwordField = propsType === 'password';

    const [type, setType] = useState(propsType);
    const [linkButtonText, setLinkButtonText] = useState<'Show' | 'Hide' | 'Clear'>(passwordField ? 'Show' : 'Clear');
    const [focused, setFocused] = useState(!!propsFocused);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setFocused(!!propsFocused);
    }, [propsFocused]);

    const inputClick = (): void => {
      setFocused(true);
      onClick();
    };

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          inputRef?.current?.focus();
        },
      }),
      [],
    );

    const showHidePassword = (): void => {
      if (type === 'password') {
        setType('text');
        setLinkButtonText('Hide');
      } else {
        setType('password');
        setLinkButtonText('Show');
      }
    };

    const setFocusState = () => setFocused((focused) => !focused);

    const handleLinkButtonClick = () => {
      if (passwordField) showHidePassword();
      else clearState();

      inputRef?.current?.focus();
    };

    return (
      <div
        css={css`
          position: relative;
        `}
      >
        {labelText ? <Label htmlFor={id} text={labelText} /> : null}
        <div
          css={css`
            position: relative;
          `}
        >
          <StyledInput
            id={id}
            ref={inputRef}
            value={value}
            focused={focused}
            onClick={inputClick}
            onChange={(event) => propsOnChange(event.target.value)}
            onFocus={setFocusState}
            onBlur={setFocusState}
            css={css(additionalStyles)}
            disabled={disabled}
            placeholder={placeholderText}
            type={type}
            tabIndex={tabIndex}
            autoComplete={autoComplete}
            error={error}
          />
          {(passwordField || focused || !!value) && (
            <LinkButton
              onClick={handleLinkButtonClick}
              additionalStyles={`
                position: absolute;
                cursor: pointer;
                top: 8px;
                right: 10px;
              `}
              type='button'
            >
              {linkButtonText}
            </LinkButton>
          )}
        </div>
        {!disableErrorText && <StyledError>{error}</StyledError>}
      </div>
    );
  },
);

Input.displayName = 'React.forwardRef(Input)';

export { Input };
