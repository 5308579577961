import { css } from '@emotion/react';
import { Component } from 'react';
import Spinner from 'react-spinner-material';

import { ContentType } from './ContentType.enum';

import { ClientDateTimeComplete } from '@isi/components/create_order/client-date-time-complete.component';
import { ClientDateTimeIncomplete } from '@isi/components/create_order/client-date-time-incomplete.component';
import InspireMeNotes from '@isi/components/create_order/inspire-me-notes.component';
import OrderServices from '@isi/components/create_order/order-services.component';
import ProductComplete from '@isi/components/create_order/product-complete.component';
import { ProductIncomplete } from '@isi/components/create_order/product-incomplete.component';

interface IOrderStepContentProps {
  contentType: ContentType;
  complete: boolean;
  additionalStyles?: string;
  dashboard: boolean;
  isGiftingOrder?: boolean;
}

class OrderStepContent extends Component<IOrderStepContentProps, {}> {
  /*
   * Render the correct content for the type, in complete or incomplete state
   */
  public renderContent = (type: ContentType): JSX.Element => {
    if (type === ContentType.customer || type === ContentType.dateTime || type === ContentType.gifting) {
      if (this.props.complete) {
        return <ClientDateTimeComplete contentType={this.props.contentType} dashboard={this.props.dashboard} />;
      }
      return <ClientDateTimeIncomplete contentType={this.props.contentType} />;
    }

    if (type === ContentType.orderServices) {
      return <OrderServices />;
    }

    if (type === ContentType.inspireMeNotes) {
      return <InspireMeNotes />;
    }

    if (this.props.complete) {
      return <ProductComplete isGiftingOrder={this.props.isGiftingOrder} dashboard={this.props.dashboard} />;
    }
    if (this.props.dashboard) {
      return (
        <div>
          <div
            css={css`
              margin-left: 41px;
              display: inline-block;
              margin-top: 31px;
              position: relative;
              width: calc(100% - 82px);
            `}
          >
            <div
              css={css`
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
              `}
            >
              <Spinner radius={70} color='#979797' stroke={5} visible />
            </div>
          </div>
        </div>
      );
    }
    return <ProductIncomplete isGiftingOrder={this.props.isGiftingOrder} />;
  };

  public render(): JSX.Element {
    const styles = css`
      ${this.props.contentType === ContentType.product
        ? 'padding-top: 24px; border-top: 1px dashed #ccc; @media (min-width: 1024px) { padding-top: 0; margin-top: 36px; border-top-width: 0; }'
        : ''}
      ${this.props.additionalStyles}
    `;

    return <div css={styles}>{this.renderContent(this.props.contentType)}</div>;
  }
}

export { OrderStepContent, IOrderStepContentProps };
