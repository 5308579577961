import { css } from '@emotion/react';
import { Route, Switch } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

import { DateTime } from './date_time/DateTime';
import { InStoreInterfaceLogin } from '@isi/containers/Login';
import Reports from '@isi/containers/reports/reports.container';
import { InStoreInterfaceSSOLogin } from '@isi/containers/sso-login';
import { BrandUserRole } from '@isi/stores/models/brand-user.model';

import useAuthContext from '@isi/hooks/useAuthContext';

import { NoAuth } from '@isi/components/common/routing/NoAuth.component';
import { RequireAuth } from '@isi/components/common/routing/RequireAuth.component';
import { CreateOrder } from '@isi/components/create_order/create-order.component';
import CustomerForm from '@isi/components/customers/customer-form.component';
import Customers from '@isi/components/customers/customers.component';
import { OrderDashboard } from '@isi/components/order_dashboard/order-dashboard.component';
import NewProduct from '@isi/components/products/new-product.component';
import { Products } from '@isi/components/products/products.component';
import { UploadProducts } from '@isi/components/uploadProducts/upload-products.component';
import { Resources } from '@isi/containers/resources/components/resources.component';

const Logout = () => {
  const { logOut } = useAuthContext();

  useEffectOnce(() => {
    logOut();
  });

  return null;
};

const Routes = (): JSX.Element => (
  <main
    css={css`
      grid-area: main;
      overflow: auto;
    `}
  >
    <Switch>
      <Route
        exact
        path='/login'
        render={() => (
          <NoAuth>
            <InStoreInterfaceLogin />
          </NoAuth>
        )}
      />
      <Route
        exact
        path='/login/sso'
        render={() => (
          <NoAuth>
            <InStoreInterfaceSSOLogin />
          </NoAuth>
        )}
      />
      <Route
        exact
        path='/'
        render={(props: any) => (
          <RequireAuth role={BrandUserRole.Booker}>
            <CreateOrder {...props} />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path='/products'
        render={(props: any) => (
          <RequireAuth>
            <Products {...props} />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path='/products/new'
        render={(props: any) => (
          <RequireAuth>
            <NewProduct {...props} />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path={['/customers', '/customers/gifting']}
        render={(props: any) => (
          <RequireAuth>
            <Customers {...props} />
          </RequireAuth>
        )}
      />

      <Route
        exact
        path={['/customers/new', '/customers/gifting/new']}
        render={(props: any) => (
          <RequireAuth>
            <CustomerForm {...props} />
          </RequireAuth>
        )}
      />

      <Route
        exact
        path={['/customers/:customer_id/edit', '/customers/gifting/:customer_id/edit']}
        render={() => (
          <RequireAuth>
            <CustomerForm edit />
          </RequireAuth>
        )}
      />

      <Route
        exact
        path='/date_time'
        render={(props: any) => (
          <RequireAuth>
            <DateTime {...props} />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path='/dashboard'
        render={(props: any) => (
          <RequireAuth>
            <OrderDashboard {...props} />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path='/upload_products'
        render={(props: any) => (
          <RequireAuth>
            <UploadProducts {...props} />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path='/reports'
        render={(props: any) => (
          <RequireAuth role={BrandUserRole.Reporting}>
            <Reports {...props} />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path='/resources'
        render={(props: any) => (
          <RequireAuth>
            <Resources {...props} />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path='/logout'
        render={() => (
          <RequireAuth redirectOnLogin={false}>
            <Logout />
          </RequireAuth>
        )}
      />
    </Switch>
  </main>
);

export default Routes;
