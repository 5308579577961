import axios from 'axios';

import { apiUrl } from '@isi/config/Url';

export interface IGetLabelsParams {
  orderId: string;
}

export type IGetLabelsResponse = Blob;

export const getLabels = async (params: IGetLabelsParams) => {
  const url = `${apiUrl()}/api/v3/isi/orders/${params?.orderId}/labels`;

  return axios.get<IGetLabelsResponse>(url, { responseType: 'blob' });
};
