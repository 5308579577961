import { css } from '@emotion/react';
import $ from 'jquery';
import { type ChangeEvent, Component } from 'react';

import { Label } from '@isi/components/common/label.component';
import { LinkButton } from '@isi/components/common/link-button.component';

interface ITextAreaProps {
  value?: string;
  onChange: (value: string) => void;
  disabled: boolean;
  placeholderText?: string;
  additionalStyles?: string;
  labelText?: string;
  id?: string;
}

interface ITextAreaState {
  value: string;
  focused: boolean;
}

class TextArea extends Component<ITextAreaProps, ITextAreaState> {
  public static getDerivedStateFromProps(props: ITextAreaProps, state: ITextAreaState) {
    if (props.value !== state.value) {
      return {
        value: props.value,
      };
    }
    return state;
  }

  constructor(props: ITextAreaProps) {
    super(props);
    this.state = {
      value: this.props.value || '',
      focused: false,
    };
  }

  public render() {
    const styles = css`
      font-family: MarkOT;
      width: calc(100% - 65px);
      height: 60px;
      font-size: 14px;
      border: solid 1px #000000;
      padding: 15px;
      padding-right: 50px;
      display: block;
      resize: none;

      &::placeholder {
        color: #a1a1a1;
      }

      ${this.props.additionalStyles}
    `;

    return (
      <div
        css={css`
          position: relative;
        `}
      >
        {this.props.labelText ? <Label text={this.props.labelText} htmlFor={this.props.id} /> : null}

        <textarea
          id={this.props.id}
          value={this.state.value}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => this.onChange(event)}
          onFocus={this.setFocusState}
          onBlur={this.setFocusState}
          css={styles}
          disabled={this.props.disabled}
          placeholder={this.props.placeholderText}
        />
        {this.state.focused ? (
          <LinkButton
            disabled={false}
            onClick={this.clearText}
            additionalStyles={`
          position: absolute;
          ${this.props.labelText ? 'top: 39px;' : 'top: 8px;'}
          right: 10px;
        `}
          >
            Clear
          </LinkButton>
        ) : null}
      </div>
    );
  }

  public setFocusState = (event: any) => {
    if ($(event.relatedTarget).is('button') && event.relatedTarget?.textContent === 'Clear') {
      event.preventDefault();
      return;
    }

    const state = this.state.focused;
    this.setState({ focused: !state });
  };

  public clearText = () => {
    const focused = false;
    this.setState({ value: '', focused });

    this.props.onChange('');
  };

  public onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ value: event.target.value });
    this.props.onChange(event.target.value);
  };
}

export { TextArea, ITextAreaProps };
