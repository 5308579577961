import { css } from '@emotion/react';
import { type ChangeEvent, type ChangeEventHandler, Component, type FocusEventHandler } from 'react';

interface ISmallInputProps {
  selector?: string;
  value?: string;
  onClick: () => void;
  onChange: (value: string) => void;
  disabled: boolean;
  placeholderText?: string;
  additionalStyles?: string;
  type?: string;
  min?: string;
  max?: string;
  step?: string;
}

interface ISmallInputState {
  value: string;
  focused: boolean;
}

class SmallInput extends Component<ISmallInputProps, ISmallInputState> {
  public static getDerivedStateFromProps(props: ISmallInputProps, state: ISmallInputState) {
    if (props.value !== state.value) {
      return {
        value: props.value,
      };
    }
    return state;
  }
  constructor(props: ISmallInputProps) {
    super(props);
    this.state = {
      value: this.props.value || '',
      focused: false,
    };
  }

  public onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ value: event.target.value });
    this.props.onChange(event.target.value);
  };

  public setFocusState: FocusEventHandler<HTMLInputElement> = () => {
    const state = this.state.focused;
    this.setState({ focused: !state });
  };

  public render() {
    const { focused, value } = this.state;
    const { additionalStyles, onClick, placeholderText, type, min, max, step, disabled, selector } = this.props;
    return (
      <input
        css={css`
          font-size: 12px;
          line-height: 1.75;
          height: 24px;
          padding-left: 5px;
          ${focused ? 'border: solid 1px #000000;' : 'border: solid 1px #8c8c8c;'}
          ${additionalStyles}
        `}
        onClick={() => onClick()}
        onChange={(event: ChangeEvent<HTMLInputElement>) => this.onChange(event)}
        onFocus={this.setFocusState}
        onBlur={this.setFocusState}
        placeholder={placeholderText}
        value={value}
        type={type}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        data-selector={selector}
      />
    );
  }
}

export { SmallInput, ISmallInputProps };
