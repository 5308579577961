import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { PLACEHOLDER_IMAGE } from '@isi/constants/constants';
import { Service } from '@isi/enums/service.enum';
import { getImageOrientation } from '@isi/helpers/image-orientation';
import { isUrlSecure } from '@isi/helpers/is-url-secure.function';
import { shortenStringForRender } from '@isi/helpers/shorten-string-for-render.function';
import { type ISelectedProduct } from '@isi/interfaces/selected-product.interface';
import { type IServicesCheckboxes } from '@isi/interfaces/services-checkboxes.interface';
import { rootStore } from '@isi/stores/root.store';

import { Checkbox } from '@isi/components/common/checkbox.component';
import { Dropdown, type DropdownValue } from '@isi/components/common/dropdown.component';
import { SizeUpDownButton } from '@isi/components/products/add-services/add-services-table/size-up-down-button.component';
import { SmallInput } from '@isi/components/products/add-services/add-services-table/small-input.component';

import { excludingTaxCalculation, includingTaxCalculation } from '../../../../helpers/tax_calculation';

interface IWaitAndTryProductRowProps {
  product: ISelectedProduct | undefined;
  name: string;
  sku?: string;
  colour: string;
  sizeId: number;
  onItemToDeleteSelectChange: () => void;
  onSizeChange: (size: string) => void;
  onPriceChange: (price: number) => void;
  onQuantityChange: (quantity: number) => void;
  onDuplicateClick: () => void;
  onServiceChange: (selected: boolean, service: Service) => void;
  isMainRow: boolean;
  size: string;
  price: number;
  quantity: number;
  services: IServicesCheckboxes;
  errors: string[];
  taxRate: number;
}

interface WaitAndTryProductRowState {
  deleteCheckboxForceChecked: boolean | undefined;
  fittingAndPinningCheckboxForceChecked: boolean | undefined;
  perfectFitCheckboxForceChecked: boolean | undefined;
  inspireMeCheckboxForceChecked: boolean | undefined;
}

@observer
class WaitAndTryProductRow extends Component<IWaitAndTryProductRowProps, WaitAndTryProductRowState> {
  public product: ISelectedProduct | undefined = this.props.product;
  public config = rootStore.storeStore.getStoreConfig;

  constructor(props: IWaitAndTryProductRowProps) {
    super(props);

    this.state = {
      deleteCheckboxForceChecked: undefined,
      fittingAndPinningCheckboxForceChecked: this.props.services.fittingPinning,
      perfectFitCheckboxForceChecked: this.props.services.perfectFit,
      inspireMeCheckboxForceChecked: this.props.services.inspireMe,
    };
  }

  public forceDeleteSelect = (): void => {
    this.setState({ deleteCheckboxForceChecked: true });
  };

  public forceDeleteDeselect = (): void => {
    this.setState({ deleteCheckboxForceChecked: false });
  };

  public forceFittingAndPinningSelect = (): void => {
    this.setState({ fittingAndPinningCheckboxForceChecked: true });
  };

  public forceFittingAndPinningDeselect = (): void => {
    this.setState({ fittingAndPinningCheckboxForceChecked: false });
  };

  public forceInspireMeDeselect = (): void => {
    this.setState({ inspireMeCheckboxForceChecked: false });
  };

  public forcePaymentRequiredSelect = (): void => {
    if (this.props.product) {
      this.props.product.validationErrors.paymentRequired = '';
    }
  };

  public forcePaymentRequiredDeselect = (): void => {
    if (this.props.product) {
      this.props.product.validationErrors.paymentRequired = '';
    }
  };

  public forceInspireMeSelect = (): void => {
    this.setState({ inspireMeCheckboxForceChecked: true });
  };

  public forcePerfectFitDeselect = (): void => {
    this.setState({ perfectFitCheckboxForceChecked: false });
  };

  public forcePerfectFitSelect = (): void => {
    this.setState({ perfectFitCheckboxForceChecked: true });
  };

  private getErrorsRowColSpan = (): number => {
    let span = 7;
    const config = rootStore.storeStore.getStoreConfig;
    if (config?.inspireMeEnabled) {
      span += 1;
    }
    if (config?.perfectFitEnabled) {
      span += 1;
    }
    if (config?.alterationsEnabled) {
      span += 1;
    }
    return span;
  };

  private updatePaymentRequired(isRequired: boolean): void {
    this.props.onServiceChange(isRequired, Service.PaymentRequired);
  }

  private renderTaxBreakdown = () => {
    const location = this.config?.storeLocation;
    const currency = rootStore.storeStore.storeCurrency;
    let taxDescription: string;
    let taxCalculationFunction: (p: number, r: number) => string;

    if (location === 'LDN') {
      taxDescription = 'Excluding VAT';
      taxCalculationFunction = excludingTaxCalculation;
    } else {
      taxDescription = 'Including Tax';
      taxCalculationFunction = includingTaxCalculation;
    }

    return (
      <div
        css={css`
          font-size: 10px;
          text-align: left;
          margin-top: 10px;
        `}
      >
        {taxDescription} ({this.props.taxRate}
        %):
        {currency}
        {taxCalculationFunction(this.props.price, this.props.taxRate)}
      </div>
    );
  };

  private productPriceControl(): JSX.Element {
    const currency = rootStore.storeStore.storeCurrency;
    const currencyCode = rootStore.storeStore.storeCurrencyCode;
    const canEditPrice = rootStore.storeStore.getStoreConfig?.brandUserRoles.includes('change_prices');

    if (canEditPrice) {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            text-align: left;
            height: 28px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {currency}
            <CurrencyInput
              id='waitAndTryCurrencyInput'
              name='wait-and-try-currency-input'
              placeholder='0.00'
              decimalsLimit={2}
              step={0.01}
              onValueChange={(value) => this.props.onPriceChange(Number(value))}
              css={css`
                max-width: 70px;
                padding: 0;
                padding-left: 5px;
                margin: auto;
                margin-left: 5px;
              `}
            />
          </div>

          {this.renderTaxBreakdown()}
        </div>
      );
    }
    return (
      <div
        css={css`
          height: 15px;
          display: flex;
          flex-direction: column;
          padding-left: 10px;
          text-align: left;
        `}
      >
        {Intl.NumberFormat('en-GB', { style: 'currency', currency: currencyCode }).format(this.props.price)}
        {this.renderTaxBreakdown()}
      </div>
    );
  }

  private sizeDropdownValues(): DropdownValue[] {
    return this.config ? this.config.waitAndTrySizes.map((size) => ({ value: size, display: size })) : [];
  }

  private updateInspireMeAndPaymentRequired(isRequired: boolean): void {
    this.props.onServiceChange(isRequired, Service.InspireMe);
    if (isRequired) this.updatePaymentRequired(isRequired);
  }

  private renderSizes(): JSX.Element {
    return !this.config || this.config.waitAndTrySizes.length === 0 ? (
      <SmallInput
        selector='inputProductSize'
        disabled={false}
        onClick={() => {}}
        value={this.props.size}
        onChange={(size) => this.props.onSizeChange(size)}
        additionalStyles={`
        max-width: 70px;
        padding: 0;
        padding-left: 5px;
        margin: auto;
      `}
      />
    ) : (
      <Dropdown
        values={this.sizeDropdownValues()}
        onChange={(size) => this.props.onSizeChange(size)}
        selectedValue={this.props.size}
        error={false}
        hidePleaseChoose={false}
        selectStyleOverride={`
          font-size: 12px;
          height: 25px;
          border: solid 1px #8c8c8c;
        `}
      />
    );
  }

  private renderImage = (): JSX.Element => {
    let orientation;
    const productUrl = this.product?.imageUrl || '';
    const secure = isUrlSecure(productUrl);
    if (productUrl && secure) {
      orientation = getImageOrientation(productUrl);
    }
    return (
      <div
        css={css`
          width: 63px;
          height: 85px;
          background-color: #ffffff;
          background-image: url('${productUrl && secure ? productUrl : PLACEHOLDER_IMAGE}');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          ${orientation === 'landscape' ? 'background-size: contain;' : 'background-size: cover;'}
        `}
      />
    );
  };

  private renderFittingAndPinning = (): JSX.Element | undefined => {
    if (this.config?.perfectFitEnabled) {
      return (
        <td>
          <div
            css={css`
              margin: auto;
              width: fit-content;
            `}
          >
            <Checkbox
              disabled={!this.props.isMainRow}
              checked={this.state.fittingAndPinningCheckboxForceChecked}
              onClick={(selected) => this.props.onServiceChange(selected, Service.FittingPinning)}
            />
          </div>
        </td>
      );
    }
    return undefined;
  };

  private renderPerfectFit = (): JSX.Element | undefined => {
    if (this.config?.alterationsEnabled) {
      return (
        <td>
          <div
            css={css`
              margin: auto;
              width: fit-content;
            `}
          >
            <Checkbox
              disabled={!this.props.isMainRow}
              checked={this.state.perfectFitCheckboxForceChecked}
              onClick={(selected) => this.props.onServiceChange(selected, Service.PerfectFit)}
            />
          </div>
        </td>
      );
    }
    return undefined;
  };

  private renderPaymentRequired = (): JSX.Element | undefined => {
    if (this.config?.paymentAtDoorEnabled && this.config?.waitAndTryEnabled) {
      return (
        <td>
          <div
            css={css`
              margin: auto;
              width: fit-content;
            `}
          >
            <div css={{ display: 'flex', gap: 15 }}>
              <label>
                <span css={{ display: 'block' }}>Yes</span>
                <input
                  style={{ marginLeft: 0 }}
                  type='radio'
                  name={`paymentRequired_${this.product?.id}_${this.product?.productSizeId}`}
                  checked={this.props.services.paymentRequired === true}
                  disabled={!this.props.isMainRow}
                  onChange={() => this.updatePaymentRequired(true)}
                />
              </label>
              <label>
                <span css={{ display: 'block' }}>No</span>
                <input
                  style={{ marginLeft: 0 }}
                  type='radio'
                  name={`paymentRequired_${this.product?.id}_${this.product?.productSizeId}`}
                  checked={this.props.services.paymentRequired === false}
                  disabled={!this.props.isMainRow}
                  onChange={() => this.updatePaymentRequired(false)}
                />
              </label>
            </div>
          </div>
        </td>
      );
    }
    return undefined;
  };

  private renderInspireMe = (): JSX.Element | undefined => {
    if (this.config?.inspireMeEnabled && this.config?.paymentAtDoorEnabled && this.config?.waitAndTryEnabled) {
      return (
        <td>
          <div
            css={css`
              margin: auto;
              width: fit-content;
            `}
          >
            <Checkbox
              disabled={!this.props.isMainRow}
              checked={this.state.inspireMeCheckboxForceChecked}
              onClick={(selected) => this.updateInspireMeAndPaymentRequired(selected)}
            />
          </div>
        </td>
      );
    }
    return undefined;
  };

  private renderSizingButton = (): JSX.Element | undefined => {
    if (this.config?.sizingEnabled && this.config?.waitAndTryEnabled && this.props.isMainRow) {
      return <SizeUpDownButton onClick={() => this.props.onDuplicateClick()} disabled={false} />;
    }
    return undefined;
  };

  private renderErrors = (): JSX.Element | undefined => {
    const pId = this.props.product?.id;
    const sId = this.props.sizeId;
    if (!this.props.errors) {
      return undefined;
    }
    const errors: any = [];
    let i = 1;
    for (const error of this.props.errors) {
      i += 1;
      errors.push(
        <div
          key={`${pId}-${sId}-${i}-validation-error`}
          css={css`
            color: #c12034;
            margin-bottom: 5px;
          `}
        >
          {error}
        </div>,
      );
    }
    return errors;
  };

  public render(): JSX.Element {
    const { onItemToDeleteSelectChange, name, sku, colour, isMainRow, onQuantityChange, quantity } = this.props;
    return (
      <>
        <tr
          css={css`
            height: 190px;
            font-size: 12px;
            background-color: #f7f7f7;
          `}
        >
          <td
            css={css`
              min-width: 50px;
            `}
          >
            <div
              css={css`
                margin: auto;
                width: fit-content;
              `}
            >
              <Checkbox checked={this.state.deleteCheckboxForceChecked} onClick={() => onItemToDeleteSelectChange()} />
            </div>
          </td>
          <td
            css={css`
              max-width: 120px;
              line-height: 1.25;
            `}
          >
            {this.renderImage()}
            <div
              css={css`
                width: 126px;
                margin: auto;
                margin-top: 10px;
                text-align: left;
                word-break: break-word;
              `}
            >
              <div>
                <span
                  css={css`
                    font-family: MarkOT-Bold;
                  `}
                >
                  Name:{' '}
                </span>
                {shortenStringForRender(name)}
              </div>
              <div>
                <span
                  css={css`
                    font-family: MarkOT-Bold;
                  `}
                >
                  SKU:{' '}
                </span>
                {sku}
              </div>
            </div>
          </td>
          <td>{this.productPriceControl()}</td>
          <td>{colour}</td>
          <td>
            <SmallInput
              disabled={!isMainRow}
              onClick={() => {}}
              onChange={(quantity) => onQuantityChange(Number(quantity))}
              value={quantity.toString()}
              min='1'
              max='999'
              type='number'
              additionalStyles={`
                max-width: 35px;
                text-align: center;
                padding: 0;
                margin: auto;
              `}
            />
          </td>
          <td
            css={css`
              position: relative;
              min-width: 75px;
            `}
          >
            <div
              css={
                this.config?.sizingEnabled && this.config?.waitAndTryEnabled
                  ? css`
                      margin-top: 45px;
                    `
                  : undefined
              }
            >
              {this.renderSizes()}
              {this.renderSizingButton()}
            </div>
          </td>

          {this.renderFittingAndPinning()}
          {this.renderInspireMe()}
          {this.renderPerfectFit()}
          {this.renderPaymentRequired()}
        </tr>
        <tr
          css={css`
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #cecece;
            font-size: 12px;
            background-color: #f7f7f7;
          `}
        >
          <td
            css={css`
              padding-bottom: 20px;
            `}
            colSpan={this.getErrorsRowColSpan()}
          >
            {this.renderErrors()}
          </td>
        </tr>
      </>
    );
  }
}

export { WaitAndTryProductRow };
