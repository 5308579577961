import { css } from '@emotion/react';
import { Component } from 'react';

import { PLACEHOLDER_IMAGE } from '@isi/constants/constants';
import { getImageOrientation } from '@isi/helpers/image-orientation';
import { isUrlSecure } from '@isi/helpers/is-url-secure.function';
import { shortenStringForRender } from '@isi/helpers/shorten-string-for-render.function';
import { rootStore } from '@isi/stores/root.store';

import { Checkbox } from '@isi/components/common/checkbox.component';

interface IProductItemProps {
  imageUrl?: string;
  name: string;
  price: number;
  sku: string;
  colour?: string;
  selected?: boolean;
  onClick: (selected: boolean) => void;
}

interface IProductItemState {
  selected: boolean;
}

class ProductItem extends Component<IProductItemProps, IProductItemState> {
  constructor(props: IProductItemProps) {
    super(props);
    this.state = {
      selected: Boolean(props.selected),
    };
  }

  public forceUnselected = () => {
    this.setState({
      selected: false,
    });
  };

  private renderImage = () => {
    let orientation;
    const imageUrl = this.props.imageUrl || '';
    const secure = isUrlSecure(imageUrl);
    if (imageUrl && secure) {
      orientation = getImageOrientation(imageUrl);
    }
    return (
      <div
        css={css`
          height: 227px;
          width: 100%;
          background-image: url('${imageUrl && secure ? imageUrl : PLACEHOLDER_IMAGE}');
          background-color: #ffffff;
          background-position: center;
          background-repeat: no-repeat;
          ${orientation === 'landscape' ? 'background-size: contain;' : 'background-size: cover;'}
        `}
      />
    );
  };

  private toggleSelected = () => {
    this.setState(
      (prev) => ({ selected: !prev.selected }),
      () => {
        this.props.onClick(this.state.selected);
      },
    );
  };

  public render() {
    const currency = rootStore.storeStore.storeCurrency;
    return (
      <div
        css={css`
          padding: 20px;
          position: relative;

          &:hover {
            cursor: pointer;
          }
        `}
        onClick={() => this.toggleSelected()}
      >
        <div
          css={css`
            position: absolute;
            top: 29px;
            left: 29px;
          `}
        >
          <Checkbox onClick={() => {}} checked={this.state.selected} />
        </div>
        {this.renderImage()}
        <div
          css={css`
            width: 136px;
            font-family: MarkOT;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #8c8c8c;
            word-wrap: break-word;
            min-height: 48px;

            @media (min-width: 1024px) {
              height: 130px;
            }
          `}
        >
          <div>
            <span
              css={css`
                font-family: MarkOT-Bold;
              `}
            >
              Name:{' '}
            </span>
            {shortenStringForRender(this.props.name)}
          </div>
          <div>
            <span
              css={css`
                font-family: MarkOT-Bold;
              `}
            >
              SKU:{' '}
            </span>
            {shortenStringForRender(this.props.sku)}
          </div>
          <div>
            <span
              css={css`
                font-family: MarkOT-Bold;
              `}
            >
              Colour:{' '}
            </span>
            {this.props.colour}
          </div>
          <div>
            <span
              css={css`
                font-family: MarkOT-Bold;
              `}
            >
              Price:{' '}
            </span>
            {`${currency}${this.props.price}`}
          </div>
        </div>
      </div>
    );
  }
}

export { ProductItem, IProductItemProps };
