import { css } from '@emotion/react';
import { type Theme, ThemeProvider } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import type React from 'react';
import { useState } from 'react';

import { type SearchQueryActions, SearchQueryReducerActionTypes } from '@isi/interfaces/search-query.interface';

interface PaginationProps {
  dispatch: React.Dispatch<SearchQueryActions>;
  count: number;
  theme: Theme;
  page: number;
}

const Pagination = (props: PaginationProps) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const { dispatch, count, theme, page } = props;

  const handlePageChange = (value: number) => {
    dispatch({ type: SearchQueryReducerActionTypes.SetCurrentPage, payload: value.toString() });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(Number(event.target.value));
    dispatch({ type: SearchQueryReducerActionTypes.SetPageRows, payload: event.target.value });
  };

  return (
    <div
      css={css`
        display: flex;
        max-width: 1135px;
        margin: auto;
        margin-bottom: 25px;
      `}
    >
      <ThemeProvider theme={theme}>
        <TablePagination
          component='div'
          count={count}
          page={page}
          onPageChange={(_params, value) => handlePageChange(value)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
        />
      </ThemeProvider>
    </div>
  );
};

export default Pagination;
