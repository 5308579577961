import { makeObservable, observable } from 'mobx';

export type TLoadingState = 'pending' | 'done' | 'error';
export default class LoadableMixin {
  state: TLoadingState = 'pending';

  loadingError = '';

  constructor() {
    makeObservable(this, {
      state: observable,
      loadingError: observable,
    });
  }
}
