import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component, createRef, type InputHTMLAttributes } from 'react';
import { type RouteChildrenProps } from 'react-router';

import { rootStore } from '@isi/stores/root.store';

import { BrandLogo } from '@isi/components/common/brandLogo.component';
import { Button, ButtonSize } from '@isi/components/common/button.component';
import { H1 } from '@isi/components/common/h1.component';
import { H2 } from '@isi/components/common/h2.component';
import { H4 } from '@isi/components/common/h4.component';
import { Input } from '@isi/components/common/input.component';
import { LinkButton } from '@isi/components/common/link-button.component';
import Modal from '@isi/components/common/modal.component';
import { OrderDetails } from '@isi/components/common/order-details.component';
import { PoweredByToshi } from '@isi/components/common/powered-by-toshi.component';
import SpinnerWrapper from '@isi/components/common/SpinnerWrapper.component';
import { TextArea } from '@isi/components/common/textarea.component';
import CancelOrderModalContent from '@isi/components/create_order/cancel-order-modal-content.component';

interface CreateOrderState {
  inputValueAssistantName: InputHTMLAttributes<HTMLInputElement>['value'];
  inputFocusedAssistantName: boolean;
  inputValueAssistantPhone: InputHTMLAttributes<HTMLInputElement>['value'];
  inputFocusedAssistantPhone: boolean;
  inputValueStoreOrderNumber: InputHTMLAttributes<HTMLInputElement>['value'];
  inputFocusedStoreOrderNumber: boolean;
}

@observer
class CreateOrder extends Component<RouteChildrenProps, CreateOrderState> {
  private showCancelOrderModal: boolean = false;
  private storeAssistantNameInput = createRef<HTMLInputElement>();
  private storeAssistantPhoneInput = createRef<HTMLInputElement>();
  private orderNotesTextArea: TextArea | null = null;
  private isDropOff = rootStore.orderStore.dropOffOnly;

  state: CreateOrderState = {
    inputValueAssistantName: '',
    inputFocusedAssistantName: false,
    inputValueAssistantPhone: '',
    inputFocusedAssistantPhone: false,
    inputValueStoreOrderNumber: '',
    inputFocusedStoreOrderNumber: false,
  };

  componentDidMount(): void {
    if (rootStore?.orderStore?.storeAssistantName) {
      this.setState({ inputValueAssistantName: rootStore.orderStore.storeAssistantName });
    }
    if (rootStore?.orderStore?.storeAssistantPhone) {
      this.setState({ inputValueAssistantPhone: rootStore.orderStore.storeAssistantPhone });
    }
    if (rootStore?.orderStore?.storeOrderNumber) {
      this.setState({ inputValueStoreOrderNumber: rootStore.orderStore.storeOrderNumber });
    }
  }

  setAssistantName = (name: string): void => {
    this.setState({ inputValueAssistantName: name });
    rootStore.orderStore.setStoreAssistantName(name);
  };

  setAssistantPhone = (phone: string): void => {
    this.setState({ inputValueAssistantPhone: phone });
    rootStore.orderStore.setStoreAssistantPhone(phone);
  };

  setStoreOrderNumber = (storeOrderNumber: string): void => {
    this.setState({ inputValueStoreOrderNumber: storeOrderNumber });
    rootStore.orderStore.setStoreOrderNumber(storeOrderNumber);
  };

  clearAssistantName = (): void => {
    this.setState({
      inputValueAssistantName: '',
      inputFocusedAssistantName: false,
    });
  };

  clearAssistantPhone = (): void => {
    this.setState({
      inputValueAssistantPhone: '',
      inputFocusedAssistantPhone: false,
    });
  };

  clearStoreOrderNumber = (): void => {
    this.setState({
      inputValueStoreOrderNumber: '',
      inputFocusedStoreOrderNumber: false,
    });
  };

  public renderModal = (): JSX.Element | null =>
    this.showCancelOrderModal ? (
      <Modal backgroundClicked={() => this.toggleCancelModal()}>
        <CancelOrderModalContent
          closeClicked={() => this.toggleCancelModal()}
          cancelOrderClicked={() => this.cancelOrder()}
        />
      </Modal>
    ) : null;

  /** Clear the required parts of the order */
  public cancelOrder = (): void => {
    this.orderNotesTextArea?.clearText();
    this.clearAssistantName();
    rootStore.orderStore.clearOrderInfo();

    this.toggleCancelModal();
  };

  public createOrder = async (): Promise<void> => {
    const unscheduledOrder = rootStore.orderStore.getUnscheduledOrder;

    const orderCreated = unscheduledOrder
      ? await rootStore.orderStore.createUnscheduledOrder()
      : await rootStore.orderStore.createSetItemsAndConfirmOrder(rootStore.orderStore.getIsGiftedOrder);

    if (orderCreated) {
      this.orderNotesTextArea?.clearText();
      this.clearAssistantName();
      rootStore.productStore.clearSelectedProducts();
      rootStore.customerStore.clearGifteeInfo();
    }
    rootStore.orderStore.setIsServicedOrder(false);
  };

  public toggleCancelModal = (): void => {
    this.showCancelOrderModal = !this.showCancelOrderModal;
    this.forceUpdate();
  };

  private renderSpinner = (): JSX.Element | null =>
    rootStore.storeStore.getStoreConfig === null || rootStore.orderStore.getisCreatingOrder ? <SpinnerWrapper /> : null;

  public render(): JSX.Element {
    return (
      <>
        {this.renderSpinner()}
        <div
          css={css`
            width: 100%;
            margin: auto;
          `}
        >
          <BrandLogo />
          <H2 additionalStyles='margin-top: 48px;'>{rootStore.storeStore.getStoreConfig?.fullBrandName}</H2>
          <H1 additionalStyles='margin-top: 24px;'>Create Order</H1>
          <H4 additionalStyles='text-align: center;'>Sales Associate</H4>
          <div
            css={css`
              margin: auto;
              width: fit-content;
            `}
          >
            <Input
              onClick={() => {}}
              value={this.state.inputValueAssistantName}
              focused={this.state.inputFocusedAssistantName}
              onChange={(s) => this.setAssistantName(s)}
              disabled={false}
              placeholderText='Name'
              type='text'
              ref={this.storeAssistantNameInput}
              additionalStyles='width: 255px;'
              id='assistantName'
              clearState={this.clearAssistantName}
            />
            <Input
              onClick={() => {}}
              value={this.state.inputValueAssistantPhone}
              focused={this.state.inputFocusedAssistantPhone}
              onChange={(s) => this.setAssistantPhone(s)}
              disabled={false}
              placeholderText='Phone number'
              type='text'
              ref={this.storeAssistantPhoneInput}
              additionalStyles='width: 255px;'
              id='storeAssistantPhone'
              clearState={this.clearAssistantPhone}
            />
          </div>
          <OrderDetails orderCompleted={false} dashboard={false} />

          <div
            css={css`
              margin: auto;
              width: 350px;
              margin-top: 45px;
            `}
          >
            <Input
              onClick={() => {}}
              labelText={`${rootStore.storeStore.getStoreConfig?.brandName} order reference (visible to clients)`}
              value={this.state.inputValueStoreOrderNumber}
              focused={this.state.inputFocusedStoreOrderNumber}
              onChange={(s) => this.setStoreOrderNumber(s)}
              disabled={false}
              type='text'
              id='storeOrderNumber'
              clearState={this.clearStoreOrderNumber}
            />
            <TextArea
              value={rootStore.orderStore.orderNotes}
              labelText='Add any notes (recommended)'
              onChange={(n) => rootStore.orderStore.setOrderNotes(n)}
              disabled={false}
              placeholderText={
                this.isDropOff
                  ? 'Please add any information relevant to this delivery.'
                  : 'Please add any additional information to enhance the customer experience.'
              }
              ref={(textArea) => {
                this.orderNotesTextArea = textArea;
              }}
            />
            {this.isDropOff && (
              <small css={css('font-size: 12px; display: inline-block; line-height: normal;')}>
                e.g. can the package be left with a family member, etc.
              </small>
            )}
            {!this.isDropOff && (
              <small css={css('font-size: 12px; display: inline-block; line-height: normal;')}>
                e.g. any additional product information, does the sizing run true, etc.
              </small>
            )}

            <Button
              fullWidth
              onClick={() => {
                this.createOrder();
              }}
              disabled={!rootStore.orderStore.canPlaceOrder}
              buttonSize={ButtonSize.large}
              additionalStyles='margin-top: 37px;'
            >
              BOOK TOSHI
            </Button>
            <div
              css={css`
                margin: auto;
                width: fit-content;
                margin-top: 26px;
              `}
            >
              <LinkButton onClick={() => this.toggleCancelModal()} disabled={false}>
                Cancel
              </LinkButton>
              {this.renderModal()}
            </div>
            <div
              css={css`
                margin-top: 77px;
                margin-bottom: 29px;
              `}
            >
              <PoweredByToshi />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export { CreateOrder };
