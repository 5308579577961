import styled from '@emotion/styled';
import { type ReactNode } from 'react';

const StyledThinLink = styled.button`
  background: none;
  color: black;
  border: none;
  padding: 0;
  outline: inherit;
  text-decoration: underline;
  font-family: 'MarkOT';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: -2px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
`;

interface ThinLinkProps {
  onClick: () => void;
  children: ReactNode;
}

const ThinUnderlinedLink = (props: ThinLinkProps) => {
  const { onClick, children } = props;
  return <StyledThinLink onClick={onClick}>{children}</StyledThinLink>;
};

export { ThinUnderlinedLink };
