import Bugsnag from '@bugsnag/js';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { type FC, useCallback, useState } from 'react';

import { NotificationLevel } from '@isi/enums/notificationLevel.enum';
import { getLabels } from '@isi/network/labels/get-labels.function';

import useStoreContext from '@isi/hooks/useStoreContext';

import { IconButton } from '@isi/components/common/icon-button.component';
import SpinnerWrapper from '@isi/components/common/SpinnerWrapper.component';

interface IPrintButtonProps {
  orderId: string;
  height?: number;
  width?: number;
  additionalButtonStyles?: string;
  additionalIconStyles?: string;
  text?: string;
  additionalTextStyles?: string;
}

const PrintButton: FC<IPrintButtonProps> = ({
  orderId,
  height,
  width,
  additionalButtonStyles,
  additionalIconStyles,
  text,
  additionalTextStyles,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    uiStore: { setNotification },
  } = useStoreContext();

  const openLabelsPdf = useCallback(
    async (orderId: string) => {
      try {
        setLoading(true);
        const { data: blob } = await getLabels({ orderId });
        const url = URL.createObjectURL(blob);

        window.open(url);

        setTimeout(() => URL.revokeObjectURL(url), 1000);
      } catch (error: any) {
        Bugsnag.notify(error);

        setNotification(
          {
            level: NotificationLevel.Error,
            message: 'There was an error generating labels, please try again or contact TOSHI',
          },
          5000,
        );
      } finally {
        setLoading(false);
      }
    },
    [setNotification],
  );

  return (
    <>
      <IconButton
        ariaLabel='print-label'
        callback={() => {
          openLabelsPdf(orderId);
        }}
        iconClass={faPrint}
        disabled={false}
        height={height}
        width={width}
        additionalButtonStyles={additionalButtonStyles}
        additionalIconStyles={additionalIconStyles}
        text={text}
        additionalTextStyles={additionalTextStyles}
      />
      {loading && <SpinnerWrapper />}
    </>
  );
};

export { IPrintButtonProps, PrintButton };
