import { addMinutes } from 'date-fns';
import { observer } from 'mobx-react';
import { type ReactElement, useMemo } from 'react';
import { Cookies } from 'react-cookie';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

import { originUrl } from '@isi/config/Url';
import { CookieNames } from '@isi/helpers/cookies';
import { BrandUserRole } from '@isi/stores/models/brand-user.model';

import useAuthContext from '@isi/hooks/useAuthContext';

type RequireAuthProps = {
  children: ReactElement;
  role?: BrandUserRole;
  redirectOnLogin?: boolean;
};

// for role based pages:
// path to redirect to if user is missing role
function handleRoleRedirect(role: BrandUserRole): string {
  switch (role) {
    case BrandUserRole.Booker:
      return '/dashboard';

    default:
      return '/';
  }
}

export const RequireAuth = observer(({ children, role, redirectOnLogin = true }: RequireAuthProps) => {
  const { loggedIn, user } = useAuthContext();
  const { createHref } = useHistory();
  const location = useLocation();

  const showChildren = useMemo(() => (loggedIn ? !role || user?.hasRole(role) : false), [loggedIn, role, user]);

  useEffectOnce(() => {
    if (!loggedIn && redirectOnLogin) {
      new Cookies().set(CookieNames.RedirectedFrom, originUrl() + createHref(location), {
        expires: addMinutes(new Date(), 5),
        domain: window.location.host.split('isi')[1],
      });
    }
  });

  if (showChildren) return children;

  return (
    <Redirect
      to={{
        pathname: loggedIn ? (role ? handleRoleRedirect(role) : '/') : '/login',
      }}
    />
  );
});
