import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { originUrl } from '@isi/config/Url';
import type BrandJourneyReport from '@isi/stores/models/brand-journey-report.model';

interface ReportProps {
  report: BrandJourneyReport;
}

export const StyledReport = styled.article`
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  border: 1px solid #e1e1e1;
  border-left: 0;
  border-right: 0;
  height: 50px;

  &:not(:first-of-type) {
    border-top: 0;
  }
`;

const ReportSkeleton = styled(StyledReport)`
  p,
  button {
    color: #eee;
    background: #eee;
    color: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    border-radius: 5px;
  }
  p {
    height: 40px;
    width: 220px;
  }
  button {
    height: 35px;
    width: 30px;
    border: none;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

export const ReportsSkeleton = ({ skeletonCount = 10 }: { skeletonCount?: number }) => (
  <>
    {Array.from({ length: skeletonCount }).map((_, i) => (
      <ReportSkeleton key={i}>
        <p />
        <button type='button' disabled />
      </ReportSkeleton>
    ))}
  </>
);

export const Report = ({ report }: ReportProps) => (
  <StyledReport>
    <p>{report.titleString}</p>
    <a
      key={report.filename}
      download
      href={originUrl() + report.downloadPath}
      css={css`
        text-decoration: none;
        color: black;
        &:hover {
          svg {
            color: grey;
          }
        }
      `}
    >
      <FontAwesomeIcon icon={faFileDownload} size='2x' />
    </a>
  </StyledReport>
);
