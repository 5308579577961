import { makeAutoObservable } from 'mobx';
import moment from 'moment';

import { OrderStatus } from '@isi/enums/order-status.enum';
import { ProductMode } from '@isi/enums/product-mode.enum';
import { type IAddress } from '@isi/interfaces/address.interface';
import { type ICustomer } from '@isi/interfaces/customer.interface';
import { type IDashboardOrder } from '@isi/interfaces/dashboard-order.interface';
import { type IDeliverySlot } from '@isi/interfaces/delivery-slot.interface';
import { type IJourney } from '@isi/interfaces/journey.interface';
import { type IOrderProduct } from '@isi/interfaces/order-product.interface';
import { type SearchQuery } from '@isi/interfaces/search-query.interface';
import { mapKeysToCamel } from '@isi/network/helpers/params/map-keys-to-camel.function';
import { getJourney } from '@isi/network/journeys/get-journey.function';
import {
  createStoreDashboardQuery,
  type DashboardRowOrderItemResponse,
  type DashboardRowsResponse,
  type IProductModeResponse,
} from '@isi/network/orders/get-store-dashboard-orders';
import type { RootStore } from '@isi/stores/root.store';

export class DashboardStore {
  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable<
      DashboardStore,
      | 'currentJourney'
      | 'currentOrder'
      | 'deliverySlot'
      | 'isLoading'
      | 'orderProducts'
      | 'orderTimeoutTimeStamp'
      | 'orderTotalHits'
      | 'orders'
      | 'setIsLoading'
      | 'setOrderAddress'
      | 'setOrderCustomer'
    >(this, undefined, { autoBind: true });
  }

  get anyOrders(): boolean {
    return this.orders.length > 0;
  }

  get completedOrders(): IDashboardOrder[] {
    return this.orders.filter((order: { status: OrderStatus }) =>
      [OrderStatus.Completed, OrderStatus.Cancelled].includes(order.status),
    );
  }

  get activeOrders(): IDashboardOrder[] {
    return this.orders;
  }

  get getProducts(): IOrderProduct[] {
    return this.orderProducts;
  }

  get getCurrentOrder(): IDashboardOrder | undefined {
    return this.currentOrder;
  }

  public get getTotalOrderHits(): number {
    return this.orderTotalHits;
  }

  public get getCurrentJourney(): IJourney | undefined {
    return this.currentJourney;
  }

  get ordersLoading(): boolean {
    return this.isLoading;
  }

  get getOrderTimeoutTimeStamp(): Date | undefined {
    return this.orderTimeoutTimeStamp;
  }

  get currentOrderDeliverySlotString(): string | undefined {
    const order = this.currentOrder;
    const slot = this.deliverySlot;

    if (!order || !slot) return '';

    const startTime = moment.utc(slot.startTime).format('h:mm');
    const endTime = moment.utc(slot.endTime).format('h:mm a');

    return `${startTime} - ${endTime}`;
  }

  private isLoading: boolean = true;
  private orderTimeoutTimeStamp?: Date;
  private orders: IDashboardOrder[] = [];
  private deliverySlot: IDeliverySlot | null = null;
  private orderProducts: IOrderProduct[] = [];
  private currentOrder: IDashboardOrder | undefined;
  private currentJourney: IJourney | undefined;
  private orderTotalHits: number = 0;

  *loadjourneyFromCurrentOrder(): Generator<
    ReturnType<typeof getJourney>,
    void,
    Awaited<ReturnType<typeof getJourney>>
  > {
    if (!this.currentOrder?.id) return;
    const journeyResp = yield getJourney({ journeyId: this.currentOrder.latestJourneyId });
    this.deliverySlot = mapKeysToCamel(journeyResp.data.delivery_slot) as IDeliverySlot;
    this.currentJourney = mapKeysToCamel(journeyResp.data) as IJourney;
  }

  *loadDashboardOrders(
    query: SearchQuery,
  ): Generator<
    ReturnType<typeof createStoreDashboardQuery>,
    void,
    Awaited<ReturnType<typeof createStoreDashboardQuery>>
  > {
    if (query.noChange) {
      return;
    }

    this.setIsLoading(true);
    const response = yield createStoreDashboardQuery(query);

    this.orderTotalHits = response.data.total_hits;

    const mapped: IDashboardOrder[] = response.data.items.map(
      (item: DashboardRowsResponse): IDashboardOrder => ({
        id: item.hashed_id,
        pickupCode: item.pickup_code,
        deliverySlotId: -1,
        pickUpTime: item.start_datetime,
        orderNumber: item.order_hashed_id,
        endAddressZone: item.end_address_zone,
        storeOrderNumber: item.store_order_number_v2.includes('TOSHI:') ? '' : item.store_order_number_v2,
        productMode: this.productModeFromResponse(item.product_mode),
        perfectFit: item.perfect_fit,
        storeId: item.store_id,
        paid: item.paid,
        status: item.status,
        externalState: item.external_state,
        statusType: item.status_type,
        orderType: item.order_type,
        initialJourneyId: item.initial_journey_id,
        latestJourneyId: item.latest_journey_id,
        notes: item.notes,
        inspireMeCustomerNotes: item.inspire_me_customer_notes,
        giftedOrder: item.gifted_order,
        afternoonCollection: item.afternoon_collection,
        slotTimesAsString: item.slot_times_as_string,
        startDatetime: item.start_datetime,
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        giftRecipient: item.gifted_order
          ? {
              id: item.gift_recipient_hashed_id!,
              firstName: item.gift_recipient_first_name!,
              lastName: item.gift_recipient_last_name!,
              email: item.gift_recipient_email!,
              guest: item.gift_recipient_guest!,
              contactNumber: item.gift_recipient_contact_number!,
              contactNumberCountry: item.gift_recipient_contact_number_country!,
              contactPreferences: 1!,
            }
          : undefined,
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
        customer: {
          id: item.client_hashed_id,
          firstName: item.client_first_name,
          lastName: item.client_last_name,
          email: item.client_email,
          guest: item.client_guest,
          contactNumber: item.client_contact_number,
          contactNumberCountry: item.client_contact_number_country,
          contactPreferences: 1,
        },
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        address: {
          id: 1,
          houseName: item.gifted_order ? item.gift_recipient_first_name! : item.client_first_name,
          line1: item.gifted_order ? item.gift_recipient_address_line_1! : item.client_address_line_1,
          line2: item.gifted_order ? item.gift_recipient_address_line_2! : item.client_address_line_2,
          cityTown: item.gifted_order ? item.gift_recipient_city! : item.client_city,
          postcodeZipcode: item.gifted_order ? item.gift_recipient_postcode! : item.client_postcode,
          zone: item.end_address_zone || '',
          country: this.rootStore.storeStore.getStoreConfig?.storeCountry as string,
        },
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
        storeAssistantName: item.store_assistant_name,
        storeAssistantPhone: item.store_assistant_phone,
        services: {
          waitAndTry: item.wait_and_try,
          perfectFit: item.perfect_fit,
          inspireMe: item.inspire_me,
          alterations: item.alterations,
          sizeUpSizeDown: item.size_up_size_down,
          consignment: item.consignment,
        },
        orderTotal: item.post_tax_order_total,
        returnOnly: item.return_only,
        orderItems: item.order_items.map((order_item: DashboardRowOrderItemResponse) => ({
          id: order_item.id,
          postTaxPriceFormatted: order_item.post_tax_price_formatted,
          preTaxPriceFormatted: order_item.pre_tax_price_formatted,
          postTaxPriceCents: order_item.post_tax_price_cents,
          preTaxPriceCents: order_item.pre_tax_price_cents,
          name: order_item.name,
          size: order_item.size,
          sizeUpSizeDown: order_item.size_up_size_down,
          inspireMeItem: order_item.inspire_me,
          wasReturned: order_item.was_returned,
          returnReason: order_item.return_reason,
          sku: order_item.sku,
          kept: order_item.kept,
          hashedId: order_item.hashed_id,
          colour: order_item.colour,
          imageUrl: order_item.image_url,
          inputPrice: order_item.input_price,
          quantity: order_item.quantity,
          taxRate: order_item.applied_tax_rate || 0,
          paidAt: order_item.paid_at,
        })),
      }),
    );

    this.setIsLoading(false);

    this.orders = mapped;
  }

  setCurrentOrder(order: IDashboardOrder) {
    this.currentOrder = order;

    this.rootStore.orderStore.setOrder(order);
    this.rootStore.orderStore.setOrderProducts(order);

    this.setOrderCustomer(order.customer);
    this.setOrderAddress(order.address);
    if (order.giftRecipient) {
      this.rootStore.customerStore.setGiftee(order.giftRecipient);
    }
  }

  setCurrentJourney(journey: IJourney): void {
    this.currentJourney = journey;
  }

  clearCurrentOrder() {
    this.currentOrder = undefined;
    this.orderProducts = [];
    this.currentJourney = undefined;
  }

  clearCurrentDeliverySlot() {
    this.deliverySlot = null;
  }

  private setOrderAddress(orderAddress: IAddress) {
    this.rootStore.customerStore.setAddress(orderAddress);
  }

  private setOrderCustomer(orderCustomer: ICustomer) {
    this.rootStore.customerStore.setCustomer(orderCustomer);
  }

  private setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  public setOrderTimeoutTimeStamp(timestamp?: Date) {
    this.orderTimeoutTimeStamp = timestamp;
  }

  private productModeFromResponse(response: IProductModeResponse): ProductMode {
    switch (response) {
      case 'checkout':
        return ProductMode.Checkout;
      case 'tbyb_button':
        return ProductMode.TbybButton;
      case 'tbyb_checkout':
        return ProductMode.TbybCheckout;
      case 'tbyb_wizard':
        return ProductMode.TbybWizard;
      case 'instore_interface':
        return ProductMode.InstoreInterface;
      case 'manual_order':
        return ProductMode.ManualOrder;

      default:
        return ProductMode.Checkout;
    }
  }
}
