const breakpoints = [576, 768, 992, 1200];

// Access the breakpoints by using mediaQuery[1]..etc.
const mediaQueryMin: string[] = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);
const mediaQueryMax: string[] = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const mqMinXs = mediaQueryMin[0];
export const mqMinSm = mediaQueryMin[1];
export const mqMinMd = mediaQueryMin[2];
export const mqMinLg = mediaQueryMin[3];

export const mqMaxXs = mediaQueryMax[0];
export const mqMaxSm = mediaQueryMax[1];
export const mqMaxMd = mediaQueryMax[2];
export const mqMaxLg = mediaQueryMax[3];
