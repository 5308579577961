import { css } from '@emotion/react';

import blackCrossIcon from '@isi/assets/icons/black-close-button.svg';
import whiteCrossIcon from '@isi/assets/icons/white-close-button.svg';

interface CloseButtonProps {
  white?: boolean;
  onClick: () => void;
  disabled?: boolean;
  additionalStyles?: string;
}

const CloseButton = ({ white, onClick, disabled, additionalStyles }: CloseButtonProps): JSX.Element => {
  const style: string = `
    background: none;
    border: none;
    padding: 0;
    outline: inherit;

    &:hover {
      cursor: pointer;
    }
    ${additionalStyles}
  `;

  const icon = white ? whiteCrossIcon : blackCrossIcon;

  return (
    <button onClick={onClick} css={css(style)} disabled={disabled} type='button'>
      <img
        src={icon}
        alt='Close button icon'
        css={css`
          height: 17px;
        `}
      />
    </button>
  );
};

export { CloseButton };
