import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useReducer } from 'react';

import { useParams } from '@shared/hooks/useParams';

import Filter from './filter/filter.component';
import SearchBar from './search-bar.component';
import { BrandLogo } from '../common/brandLogo.component';
import { H2 } from '../common/h2.component';
import { rootStore } from '@isi/stores/root.store';

import useStoreContext from '@isi/hooks/useStoreContext';

import { Container } from '@isi/components/common/container.component';
import { H1 } from '@isi/components/common/h1.component';
import SpinnerWrapper from '@isi/components/common/SpinnerWrapper.component';
import { theme } from '@isi/components/order_dashboard/mui-theme';
import { Orders } from '@isi/components/order_dashboard/orders/orders.component';

import { type SearchQuery, searchQueryReducer } from '../../interfaces/search-query.interface';

export const OrderDashboard = observer((): JSX.Element => {
  const urlOrderID = useParams().get('orderID');

  const {
    dashboardStore: { clearCurrentOrder, loadDashboardOrders: _loadDashboardOrders, ordersLoading, activeOrders },
  } = useStoreContext();

  const [searchQuery, dispatch] = useReducer(searchQueryReducer, {
    term: urlOrderID || '',
    sortCols: {},
    cols: [],
    page: 0,
    per: 10,
    excludeStates: [],
    noChange: false,
    minOrderValue: 0,
    maxOrderValue: -1,
    excludeZones: [],
  });

  const ReturnLink = observer(() => {
    const {
      storeStore: { getStoreConfig: config },
    } = useStoreContext();

    const url = '/returns';
    return config?.nonToshiReturnBookerEnabled ? (
      <p
        css={css`
          padding: 0;
          margin: 0;
          margin-top: 0.8rem;
        `}
      >
        <a
          css={css`
            white-space: nowrap;
            font-size: 1rem;
            background-color: #1a1919;
            color: white;
            padding: 0.7rem 0.7rem;
            text-decoration: none;
            border-radius: 4px;
            :hover {
              background-color: grey;
            }
          `}
          href={url}
        >
          Book a return
        </a>
      </p>
    ) : null;
  });

  const loadDashboardOrders = useCallback(
    (searchQuery: SearchQuery): void => {
      clearCurrentOrder();
      _loadDashboardOrders(searchQuery);
    },
    [clearCurrentOrder, _loadDashboardOrders],
  );

  useEffect(() => {
    _loadDashboardOrders(searchQuery);
  }, [_loadDashboardOrders, searchQuery]);

  useEffect(
    () => () => {
      rootStore.orderStore.clearOrderInfo();
    },
    [],
  );

  const renderSpinner = () => ordersLoading && <SpinnerWrapper />;

  const maxWidth = rootStore.storeStore.getStoreConfig?.warehouseToDoor ? '1095px;' : '1220px;';

  return (
    <div
      css={css`
        margin-bottom: 25px;
      `}
    >
      {renderSpinner()}
      <div
        css={css`
          width: 100%;
          top: 10%;
          margin: auto;
          margin-bottom: 25px;
        `}
      >
        <BrandLogo />
        <H2 additionalStyles='margin-top: 48px;'>{rootStore.storeStore.getStoreConfig?.fullBrandName}</H2>
        <H1 additionalStyles='margin-top: 58px; text-size: 58px; margin-bottom: 63px;'>Order Dashboard</H1>

        <div
          css={css`
            align-items: center;
            display: flex;
            align-items: flex-start;
            max-width: ${maxWidth}
            margin: auto;
          `}
        >
          <div
            css={css`
              margin-right: 10px;
            `}
          >
            <Filter setSearchQuery={dispatch} theme={theme} />
          </div>
          <SearchBar setSearchQuery={dispatch} propsActive={Boolean(urlOrderID)} />
          <ReturnLink />
        </div>

        <Container width='100%' additionalStyles=' background-color: #f7f7f7;'>
          <Orders
            dispatch={dispatch}
            queryState={searchQuery}
            reloadOrders={loadDashboardOrders}
            orders={activeOrders}
          />
        </Container>
      </div>
    </div>
  );
});
