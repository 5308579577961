import axios from 'axios';
import { type ParseError, type ParseResult } from 'papaparse';

import { apiUrl } from '@isi/config/Url';
import { mapKeysToSnake } from '@isi/network/helpers/params/map-keys-to-snake.function';

export interface ICreateBrandProductsParams {
  fileName: string;
  deleteCurrentProducts: boolean;
}
export interface IJsonifiedCsv<T = string> extends ParseResult<T> {
  data: T[];
  errors: ParseError[];
  meta: {
    delimiter: string;
    linebreak: string;
    aborted: boolean;
    truncated: boolean;
    cursor: number;
  };
}

export interface ICreateBrandProductsResponseSnakeCase {
  data: {};
  status: number;
}
export interface ICreateBrandProductsResponse {
  data: {};
  status: number;
}

export const createBrandProducts = async (
  params: ICreateBrandProductsParams,
): Promise<ICreateBrandProductsResponseSnakeCase> => {
  const url = `${apiUrl()}/v2/brand_products/batch`;
  return axios.post(url, mapKeysToSnake(params));
};
