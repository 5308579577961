import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { rootStore } from '@isi/stores/root.store';

import { Dropdown, type DropdownValue } from '@isi/components/common/dropdown.component';
import { SmallInput } from '@isi/components/products/add-services/add-services-table/small-input.component';

interface INumberOfBagsProps {
  packageCount: number;
  packageSize: string;

  onPackageSizeChange: (packageSize: string) => void;
  onPackageCountChange: (packageCount: number) => void;

  errors: string[];
}

interface NumberOfBagsState {
  deleteCheckboxChecked: boolean;
}

@observer
class NumberOfBags extends Component<INumberOfBagsProps, NumberOfBagsState> {
  public config = rootStore.storeStore.getStoreConfig;

  private renderQuantity(): JSX.Element {
    return (
      <div
        css={css`
          display: flex;
          gap: 16px;
        `}
      >
        <p
          css={css`
            margin: auto;
            text-align: center;
            min-width: 200px;
            line-height: 1.25;
            padding-left: 24px;
          `}
        >
          QUANTITY (NUMBER OF BAGS)
        </p>
        <SmallInput
          disabled={false}
          onClick={() => {}}
          onChange={(packageCount) => this.props.onPackageCountChange(Number(packageCount))}
          value={this.props.packageCount.toString()}
          min='1'
          max='999'
          type='number'
          additionalStyles={`
                  max-width: 35px;
                  text-align: center;
                  padding: 0;
                  margin: auto;
                `}
        />
      </div>
    );
  }

  private sizeDropdownValues(): DropdownValue[] {
    return this.config ? this.config.dropOffSizes.map((size) => ({ value: size, display: size })) : [];
  }

  private renderSizes(): JSX.Element {
    return (
      <div
        css={css`
          display: flex;
          gap: 16px;
          padding-right: 24px;
        `}
      >
        <p
          css={css`
            text-align: center;
            max-width: 70px;
            margin: auto;
            line-height: 1.25;
          `}
        >
          SIZE
        </p>
        {this.sizeDropdownValues().length === 0 ? (
          <SmallInput
            selector='inputPackageSize'
            disabled={false}
            onClick={() => {}}
            value={this.props.packageSize}
            onChange={(size) => this.props.onPackageSizeChange(size)}
            additionalStyles={`
        max-width: 70px;
        padding: 0;
        padding-left: 5px;
        margin: auto;
      `}
          />
        ) : (
          <div
            css={css`
              margin: auto;
            `}
          >
            <Dropdown
              values={this.sizeDropdownValues()}
              onChange={(size) => this.props.onPackageSizeChange(size)}
              selectedValue=''
              error={false}
              hidePleaseChoose={false}
              selectStyleOverride={`
            margin: auto;
            font-size: 12px;
            height: 25px;
            border: solid 1px #8c8c8c;
          `}
            />
          </div>
        )}
      </div>
    );
  }

  private renderErrors = () => {
    return (
      this.props.errors &&
      this.props.errors.map((error: string, index) => (
        <div
          key={`${index}-validation-error`}
          css={css`
            color: #c12034;
            margin-bottom: 5px;
          `}
        >
          {error}
        </div>
      ))
    );
  };

  public render(): JSX.Element {
    return (
      <div
        css={css`
          font-size: 12px;
          background-color: #f7f7f7;
          width: 100%;
        `}
      >
        <div
          css={css`
            font-size: 12px;
            background-color: #f7f7f7;
            height: 100px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            border-top: 1px solid black;
          `}
        >
          {this.renderQuantity()}
          {this.renderSizes()}
        </div>

        <div
          css={css`
            padding-bottom: 20px;
          `}
        >
          {this.renderErrors()}
        </div>
      </div>
    );
  }
}

export { NumberOfBags };
