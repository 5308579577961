import { css } from '@emotion/react';
import Spinner from 'react-spinner-material';

const SpinnerWrapper = () => (
  <div
    css={css`
      height: 100%;
      width: 100%;
      z-index: 20;
      background-color: rgba(0, 0, 0, 0.41);
      position: fixed;
      top: 0;
      left: 0;
    `}
  >
    <div
      css={css`
        width: fit-content;
        position: absolute;
        left: 0;
        right: 0;
        top: 47%;
        margin-left: auto;
        margin-right: auto;
      `}
    >
      <Spinner radius={70} color='black' stroke={5} visible />
    </div>
  </div>
);

export default SpinnerWrapper;
