/* eslint-disable react/no-unused-state */
import { css } from '@emotion/react';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import giftLargeIcon from '@isi/assets/icons/gift-large-icon.svg';
import { type ICustomerFieldsTouched, type ICustomerFormState } from '@isi/interfaces/customer-form-state.interface';
import { type ICustomer } from '@isi/interfaces/customer.interface';
import { type INewCustomerErrors } from '@isi/interfaces/new-customer-errors.interface';
import { type SearchPrediction } from '@isi/network/addresses/address-autocomplete-options.function';
import { type INewAddressData, type INewCustomerData } from '@isi/network/customers/create-customer.function';
import {
  type GetCustomerResponseLastAddress,
  type IGetCustomerResponse,
} from '@isi/network/customers/get-customers.function';
import { mapKeysToCamel } from '@isi/network/helpers/params/map-keys-to-camel.function';
import { rootStore } from '@isi/stores/root.store';

import AddressEntryInput from '@isi/components/common/address-entry-input.component';
import { Button, ButtonSize } from '@isi/components/common/button.component';
import { Container } from '@isi/components/common/container.component';
import { H1 } from '@isi/components/common/h1.component';
import { H4 } from '@isi/components/common/h4.component';
import { Input } from '@isi/components/common/input.component';
import { blankState } from '@isi/components/customers/blank-slate.function';
import { CustomersItem } from '@isi/components/customers/customers-item.component';

interface ICustomersState {
  customers: IGetCustomerResponse[];
  displayCustomerCount: boolean;
  postcodeEligible: boolean;
  renderPostcodeError: boolean;
  customerSearchValue: string;
  postcodeZipcodeSearchValue: string;
  isGiftingRecipient: boolean;

  customerId?: string | null;
  address: INewAddressData;
  customer: INewCustomerData;
  touched: ICustomerFieldsTouched;
  displayPostcodeEligibilityError: boolean;
  displayAllErrors: boolean;
  phoneNumberFocused: boolean;
  manualAddressEntry: boolean;
  loading: boolean;
  errors: INewCustomerErrors;
  giftingNote?: string;
  isGiftRecipient: boolean;
  clientAwareOfServiceCoverage?: boolean;
}

@observer
class Customers extends Component<RouteComponentProps, ICustomersState> {
  private doSearch = debounce(
    async (searchValue: string) => {
      this.setState({
        customers: [],
        displayCustomerCount: false,
      });
      if (!searchValue) {
        return;
      }
      const customers: IGetCustomerResponse[] = await rootStore.customerStore.searchCustomersRequest(searchValue);
      this.setState({
        customers,
        displayCustomerCount: true,
      });
    },
    500,
    { leading: false, trailing: true },
  );

  private debouncedHandleCheck = debounce(
    async (postcodeValue: string) => {
      if (postcodeValue.trim() === '') {
        return;
      }
      const postcodeEligible = await rootStore.customerStore.checkPostcodeEligibility(postcodeValue);
      this.setState({
        postcodeEligible,
        displayPostcodeEligibilityError: !postcodeEligible,
      });
    },
    500,
    { leading: false, trailing: true },
  );

  private doPostcodeEligibilityCheck = debounce(
    async (postcodeValue: string) => {
      this.setState({
        postcodeEligible: false,
        renderPostcodeError: false,
      });
      if (!postcodeValue) {
        return;
      }
      const postcodeEligible: boolean = await rootStore.customerStore.checkPostcodeEligibility(postcodeValue);

      this.setState({
        postcodeEligible,
        renderPostcodeError: !postcodeEligible,
      });
    },
    500,
    { leading: false, trailing: true },
  );

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      customers: [],
      displayCustomerCount: false,
      renderPostcodeError: false,
      customerSearchValue: '',
      postcodeZipcodeSearchValue: '',
      isGiftingRecipient: props.history.location?.pathname?.includes('/gifting'),
      ...this.generateBlankState(false),
    };
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    if (prevProps.history.location?.pathname !== this.props.history.location?.pathname) {
      this.setState({
        isGiftingRecipient: this.props.history.location?.pathname?.includes('/gifting'),
      });
    }
  }

  private handleSearchCustomers = async (searchValue: string): Promise<void> => {
    this.setState({
      customerSearchValue: searchValue,
    });
    await this.doSearch(searchValue);
  };

  private generateBlankState = (loading?: boolean): ICustomerFormState => blankState(loading);

  shouldDisplayError = (field: string) => {
    const hasError = this.state.errors[field];
    const shouldShow = this.state.touched[field] || this.state.displayAllErrors;
    return hasError && shouldShow ? this.state.errors[field] : '';
  };

  public setAddressFromSearchPrediction = (prediction: SearchPrediction): void => {
    const address = prediction.address_components;
    this.setState(
      (prev) => ({
        address: {
          ...prev.address,
          houseName: '',
          line1: address.address_line_1 || '',
          line2: address.address_line_2 || '',
          cityTown: address.town || '',
          postcodeZipcode: address.postcode || '',
        },
        touched: {
          ...prev.touched,
          line1: true,
          line2: true,
          cityTown: true,
          postcodeZipcode: true,
        },
      }),
      () => {
        this.handlePostcodeEligibilityCheck(this.state.address.postcodeZipcode);
      },
    );

    this.toggleManualAddressEntry();
    this.debouncedHandleCheck(address.postcode || '');
  };

  toggleManualAddressEntry = () => {
    this.setState((prev) => ({ manualAddressEntry: !prev.manualAddressEntry }));
  };

  private handleAddressChange = (key: string, value: string): void => {
    this.setState(
      (prev) => ({
        address: {
          ...prev.address,
          [key]: value,
        },
        touched: { ...prev.touched, [key]: true },
      }),
      this.validate,
    );
  };

  private handlePostcodeEligibilityCheck = async (postcodeValue: string) => {
    this.setState((prev) => ({
      touched: { ...prev.touched, postcodeZipcode: true },
      postcodeEligible: false,
      displayPostcodeEligibilityError: false,
      address: { ...prev.address, postcodeZipcode: postcodeValue },
    }));

    await this.debouncedHandleCheck(postcodeValue);
  };

  private storeCustomer = (): void => {
    rootStore.customerStore.setAddress({
      key: this.state.address.id || 0,
      id: parseInt(this.state.address.id || '0', 10),
      houseName: this.state.address.houseName || '',
      line1: this.state.address.line1 || '',
      line2: this.state.address.line2 || '',
      cityTown: this.state.address.cityTown || '',
      postcodeZipcode: this.state.address.postcodeZipcode || '',
      zone: this.state.address.zone || '',
      country: this.state.address.country || '',
    });
  };

  private handleSelectCustomer = (customer: IGetCustomerResponse): void => {
    const selectedCustomer = mapKeysToCamel(customer) as ICustomer;

    const { lastAddress: selectedAddress } = selectedCustomer;

    if (this.state.isGiftingRecipient) {
      rootStore.customerStore.setGiftee({
        ...selectedCustomer,
        giftingNote: '',
      });
    } else {
      rootStore.customerStore.setCustomer(selectedCustomer);
      if (selectedAddress) rootStore.customerStore.setAddress(selectedAddress);
    }
  };

  private validate(): INewCustomerErrors {
    const errors = {
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
      contactNumberCountry: '',
      line1: this.state.address.line1.length === 0 ? 'Please enter an address.' : '',
      cityTown: this.state.address.cityTown.length === 0 ? 'Please enter a city.' : '',
      postcodeZipcode: this.validatePostcode(false),
      clientAwareOfServiceCoverage: '',
    };
    if (JSON.stringify(errors) !== JSON.stringify(this.state.errors)) {
      this.setState({ errors });
    }

    return errors;
  }

  private validatePostcode(skipAddressValidation: boolean): string {
    if (skipAddressValidation) {
      return '';
    }
    if (this.state.address.postcodeZipcode.length === 0) {
      return 'Please enter a postcode/zipcode.';
    }
    if (this.state.displayPostcodeEligibilityError) {
      return 'Postcode/Zipcode not eligible.';
    }
    return '';
  }

  private customers() {
    return this.state.customers.filter((customer) => customer.last_address !== null);
  }
  private renderCustomersCount() {
    return this.state.displayCustomerCount ? (
      <div>
        <H4 bold additionalStyles='margin-top: 0; margin-bottom: 18px;'>
          {`${this.customers().length} results`}
        </H4>
      </div>
    ) : null;
  }

  private renderCustomersList() {
    return this.customers().length > 0 ? (
      <>
        <div
          css={css`
            color: #000;
            background: #000;
            width: 100%;
            height: 1px;
          `}
        />
        <div
          css={css`
            overflow-y: scroll;
            max-height: 35vh;
          `}
        >
          {this.customers().map((customer) => {
            const address = customer.last_address as GetCustomerResponseLastAddress;
            return (
              <CustomersItem
                isGiftingRecipient={this.state.isGiftingRecipient}
                key={customer.id}
                customerId={customer.id}
                firstName={customer.first_name}
                lastName={customer.last_name}
                email={customer.email}
                contactNumber={customer.contact_number}
                houseName={address.house_name}
                addressLine1={address.line_1}
                onSelect={() => this.handleSelectCustomer(customer)}
                history={this.props.history}
                location={this.props.location}
                match={this.props.match}
              />
            );
          })}
        </div>
      </>
    ) : null;
  }

  public render(): JSX.Element {
    const orderIsGifting =
      new URLSearchParams(this.props.history.location.search).get('isGiftingOrder') === 'true' ||
      this.state.isGiftingRecipient;
    let targetCustomerType = 'Client';
    if (this.state.isGiftingRecipient) {
      targetCustomerType = 'Recipient';
    }
    if (orderIsGifting && !this.state.isGiftingRecipient) {
      targetCustomerType = 'Sender';
    }
    return (
      <div
        css={css`
          @media (min-width: 1024px) {
            overflow: hidden;
            display: grid;
            grid-template-columns: repeat(2, 50vw);
            height: 100%;
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            background-color: #f7f7f7;
            display: flex;
            justify-content: center;
            @media (min-width: 1024px) {
              height: 100%;
            }
          `}
        >
          <Container width='310px' additionalStyles='margin-top: 25%;'>
            {this.state.isGiftingRecipient && (
              <H1
                additionalStyles={`
              text-transform: uppercase;
              display: flex;
              justify-content: center;
              align-items: center;
              white-space: nowrap;
            `}
              >
                <img
                  css={css`
                    height: 1.5rem;
                    margin-right: 5px;
                  `}
                  src={giftLargeIcon}
                  alt='Large gift icon'
                />
                Existing Recipient
              </H1>
            )}
            {!orderIsGifting && <H1 additionalStyles='margin-bottom: 38px'>Existing Client</H1>}
            {orderIsGifting && !this.state.isGiftingRecipient && (
              <H1 additionalStyles='margin-bottom: 38px;'>
                <img
                  css={css`
                    height: 1.5rem;
                    margin-right: 5px;
                    vertical-align: text-top;
                  `}
                  src={giftLargeIcon}
                  alt='Large gift icon'
                />
                Existing Sender
              </H1>
            )}
            <div
              css={css`
                margin-bottom: 0;
              `}
            >
              <Input
                onClick={() => {}}
                id='searchExistingRecipient'
                value={this.state.customerSearchValue}
                onChange={(value: string) => this.handleSearchCustomers(value)}
                disabled={false}
                placeholderText='Enter name, phone or email'
                type='text'
                labelText={`Search Existing ${targetCustomerType}s`}
                clearState={() => this.handleSearchCustomers('')}
                autoComplete='off'
              />
            </div>
            {this.renderCustomersCount()}
            {this.renderCustomersList()}
          </Container>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            overflow: scroll;
          `}
        >
          <Container width='310px' additionalStyles='margin-top: 25%;'>
            {this.state.isGiftingRecipient && (
              <H1
                additionalStyles={`
              text-transform: uppercase;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 38px;
            `}
              >
                <img
                  css={css`
                    height: 1.5rem;
                    margin-right: 5px;
                  `}
                  src={giftLargeIcon}
                  alt='Large gift icon'
                />
                New Recipient
              </H1>
            )}
            {!orderIsGifting && <H1 additionalStyles='margin-bottom: 38px;'>New Client</H1>}
            {orderIsGifting && !this.state.isGiftingRecipient && (
              <H1 additionalStyles='margin-bottom: 60px;'>
                <img
                  css={css`
                    height: 1.5rem;
                    margin-right: 5px;
                    vertical-align: text-top;
                  `}
                  src={giftLargeIcon}
                  alt='Large gift icon'
                />
                New Sender
              </H1>
            )}
            {!orderIsGifting && (
              <div
                css={css`
                  margin-bottom: 25px;
                `}
              >
                {this.state.displayPostcodeEligibilityError && (
                  <div
                    css={css`
                      color: red;
                    `}
                  >
                    {"Postcode/Zipcode not eligible: please enter an address within TOSHI's service area"}
                  </div>
                )}
                <AddressEntryInput
                  manualAddressEntry={this.state.manualAddressEntry}
                  handleAddressChange={this.handleAddressChange}
                  handlePostcodeEligibilityCheck={this.handlePostcodeEligibilityCheck}
                  setAddressFromSearchPrediction={this.setAddressFromSearchPrediction}
                  shouldDisplayError={this.shouldDisplayError}
                  toggleManualAddressEntry={this.toggleManualAddressEntry}
                  address={this.state.address}
                  isGiftRecipient={this.state.isGiftingRecipient}
                />
              </div>
            )}
            <Button
              id='addNewClient'
              buttonSize={ButtonSize.large}
              fullWidth
              onClick={this.storeCustomer}
              linkTo={
                this.state.isGiftingRecipient
                  ? '/customers/gifting/new'
                  : orderIsGifting
                    ? '/customers/new?isGiftingOrder=true'
                    : '/customers/new'
              }
              disabled={!orderIsGifting && !this.state.postcodeEligible}
              additionalStyles='margin-bottom: 20px;'
            >
              {`Add New ${targetCustomerType}`}
            </Button>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(Customers);
