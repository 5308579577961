import styled from '@emotion/styled';

enum BoxSize {
  small = 1,
  large,
}

interface IOrderStepBoxProps {
  onClick: () => void;
  boxSize: BoxSize;
  complete: boolean;
  disabled: boolean;
  additionalStyles?: string;
}

const OrderStepBox = styled.div<IOrderStepBoxProps>`
  background-color: ${(props) => (props.complete ? ' #f7f7f7' : ' white')};
  opacity: ${(props) => (props.disabled ? ' 0.4' : ' 1')};
  width: 100%;
  min-height: ${(props) => (props.boxSize === BoxSize.small ? ' 130px' : ' 180px')};
  ${(props) => props.boxSize === BoxSize.large && props.complete && 'min-height: 333px;'}
  border-bottom: dashed 1px #979797;

  @media (min-width: 1024px) {
    border: dashed 1px #979797;
  }

  ${(props) => props.complete && 'border: none;'};
`;

export { OrderStepBox, IOrderStepBoxProps, BoxSize };
