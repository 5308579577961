import { type RefObject, useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void,
  excludedClass?: string[],
  excludedNode?: RefObject<T>,
): void {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current;
      const excludedEl = excludedNode?.current;
      const target = event.target as HTMLElement;

      if (excludedEl && excludedEl.contains(target as Node)) {
        return;
      }

      if (!el || el.contains(target as Node)) {
        return;
      }

      if (excludedClass && excludedClass.some((string) => target.classList.contains(string))) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, excludedClass, excludedNode]);
}

export default useOnClickOutside;
