import axios from 'axios';

import { apiUrl } from '@isi/config/Url';
import { mapKeysToSnake } from '@isi/network/helpers/params/map-keys-to-snake.function';

export interface ICreateProductParams {
  brand_product: {
    sku: string;
    name: string;
    colour: string;
    inputPrice: number;
    imageUrl: string;
  };
  productTaxCode: string;
}

export interface INewBrandProductData {
  [key: string]: string | number | undefined;
  sku: string;
  name: string;
  colour: string;
  inputPrice: number;
  imageUrl: string;
  productTaxCode: string;
}

interface ICreateProductResponse {
  id: number;
  name: string;
  size: string;
  image_url: string;
  product_url: string;
  created_at: string;
  updated_at: string;
  input_price: number;
  input_price_currency: string | null;
  inventory_count: number;
  parent_sku: string | null;
  brand_id: number;
  colour: string;
  display_order: number;
  tax_code_id: string | null;
  tax_rate: number;
  unit_price: number;
  sku: string | 'n/a';
}

export const createProduct = (params: ICreateProductParams) => {
  const url = `${apiUrl()}/v2/brand_products`;
  return axios.post<ICreateProductResponse>(url, mapKeysToSnake(params));
};
