import { getCookie } from '@shared/helpers/cookies/getCookie';

import { type Environments } from '@isi/enums/environments';

export const CookieNames = {
  Environment: 'app_environment',
  SlotData: (deliverySlotId: string | number) => `slot_data_${deliverySlotId}`,
  RedirectedFrom: 'redirectedFrom',
};

type TSlotDataCookie = {
  delivery_slot_id: number;
  booked_count: number;
  block_slot: boolean;
};

export const GetCookie = {
  environment: () => getCookie<Environments>(CookieNames.Environment),
  slotData: (deliverySlotId: string | number) => getCookie<TSlotDataCookie>(CookieNames.SlotData(deliverySlotId)),
  redirectedFrom: () => getCookie<string>(CookieNames.RedirectedFrom),
};
