import { action, computed, makeObservable, observable } from 'mobx';

import { NotificationLevel } from '@isi/enums/notificationLevel.enum';

type TNotification = { level: NotificationLevel; message: string; orderId?: string; blackText?: boolean };

export class UIStore {
  private sideNavOpen: boolean = false;

  constructor() {
    makeObservable<UIStore, 'sideNavOpen' | '_notification'>(this, {
      sideNavOpen: observable,
      isSideNavOpen: computed,
      page: observable,
      _notification: observable,
      notification: computed,
      toggleSideNav: action.bound,
      stopPageScroll: action.bound,
      startPageScroll: action.bound,
      setNotification: action.bound,
      clearNotification: action.bound,
      clearNotificationByText: action.bound,
    });
  }

  get isSideNavOpen(): boolean {
    return this.sideNavOpen;
  }

  page: string = 'CreateOrder';

  private _notification: TNotification = {
    level: NotificationLevel.Info,
    message: '',
    orderId: undefined,
  };

  get notification(): TNotification {
    return this._notification;
  }

  toggleSideNav() {
    this.sideNavOpen = !this.sideNavOpen;
  }

  stopPageScroll() {
    document.body.classList.add('overflow-hidden');
  }

  startPageScroll() {
    document.body.classList.remove('overflow-hidden');
  }

  setNotification(notification: TNotification, autoTimeout?: number) {
    this._notification = notification;
    if (autoTimeout && autoTimeout > 0) {
      setTimeout(this.clearNotification, autoTimeout);
    }
  }

  clearNotification() {
    this.setNotification({
      level: this.notification.level,
      message: '',
    });
  }

  clearNotificationByText(message?: string) {
    if (message === this.notification.message) this.clearNotification();
  }
}
