import { css } from '@emotion/react';
import { action, computed, type IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { rootStore } from '@isi/stores/root.store';

import { Container } from '@isi/components/common/container.component';
import { Input } from '@isi/components/common/input.component';
import { LinkButton } from '@isi/components/common/link-button.component';
import { ProductGrid } from '@isi/components/products/select-products/product-grid.component';

@observer
class SelectProducts extends Component {
  public productGrid: ProductGrid | null = null;
  private disposer!: IReactionDisposer;

  _productSearchValue: string = '';

  get productSearchValue(): string {
    return this._productSearchValue;
  }

  constructor(props: {}) {
    super(props);

    makeObservable(this, {
      _productSearchValue: observable,
      productSearchValue: computed,
      productSearchOnChange: action.bound,
    });
  }

  componentDidMount() {
    this.disposer = reaction(
      () => this.productSearchValue,
      (search) => rootStore.productStore.loadProducts(0, search),
      { delay: 500 },
    );

    rootStore.productStore.loadProducts(0);
    if (rootStore.productStore.getSelectedProducts.length === 0) {
      rootStore.productStore.setSelectedProductsFromOrder();
    }
  }

  componentWillUnmount() {
    this.disposer();
  }

  public clearProducts = (): void => {
    this.productGrid?.clearSelection();
  };

  productSearchOnChange(productSearchValue: string) {
    this._productSearchValue = productSearchValue;
  }

  private canAddNewProduct = () => {
    const storeConfig = rootStore.storeStore.getStoreConfig;

    const additionalServicesEnabled =
      storeConfig?.waitAndTryEnabled ||
      storeConfig?.sizingEnabled ||
      storeConfig?.inspireMeEnabled ||
      storeConfig?.perfectFitEnabled ||
      storeConfig?.alterationsEnabled;
    return additionalServicesEnabled;
  };

  public render(): JSX.Element {
    return (
      <div
        css={css`
          background-color: #f7f7f7;
        `}
      >
        <Container
          width='calc(100% - 40px)'
          additionalStyles={`
          margin-left: 20px;
          max-width: 726px;
          margin: auto;
          padding-top: 26px;
          padding-bottom: 36px;
          position: relative;
        `}
        >
          <div
            css={css`
              display: inline-block;
            `}
          >
            <Input
              onClick={() => {}}
              value={this.productSearchValue}
              onChange={this.productSearchOnChange}
              disabled={false}
              placeholderText='Search by product name or SKU'
              type='text'
              labelText='Search product catalogue'
              additionalStyles={`
                font-size: 12px;

                @media (min-width: 1024px) {
                  width: 60%;
                  min-width: 300px;
                }
              `}
              clearState={() => this.productSearchOnChange('')}
            />
          </div>

          <div
            css={css`
              display: inline-block;
              position: absolute;
              right: 0;

              @media (min-width: 1024px) {
                bottom: 45px;
              }
            `}
          >
            {this.canAddNewProduct() && (
              <LinkButton onClick={() => {}} disabled={false} bold to='/products/new'>
                ADD NEW PRODUCT
              </LinkButton>
            )}
          </div>
        </Container>

        <ProductGrid
          search={rootStore.productStore.getSearch}
          pageNumber={rootStore.productStore.getPageNumber}
          products={rootStore.productStore.getSearchedProducts}
          ref={(grid) => {
            if (grid) this.productGrid = grid;
          }}
          productStore={rootStore.productStore}
        />
      </div>
    );
  }
}

export { SelectProducts };
