import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { type FC } from 'react';

import services from '@isi/assets/icons/hangar.svg';
import tick from '@isi/assets/icons/tick.svg';

import useStoreContext from '@isi/hooks/useStoreContext';

import { H4 } from '@isi/components/common/h4.component';

const OrderServices: FC = () => {
  const {
    dashboardStore: { getCurrentOrder },
    orderStore: { orderProducts },
    storeStore: { getStoreConfig: config },
  } = useStoreContext();

  const orderServices = getCurrentOrder?.services;
  const areAnyOrderItemsInspireMe = orderProducts.some((product) => product.services.inspireMe);

  return (
    <div
      css={css`
        padding: 25px 20px 0px 20px;
      `}
    >
      <div
        css={css`
          padding-bottom: 10px;
        `}
      >
        <img
          src={services}
          alt=''
          aria-label='Hangar image depicting services - by Gregor Cresnar from the Noun Project'
          css={css`
            height: 33px;
            display: inline;
            padding-right: 10px;
          `}
        />
        <H4
          bold
          additionalStyles={`
            display: inline;
            vertical-align: super;
          `}
        >
          Services
        </H4>
      </div>
      <table
        css={css`
          border-collapse: collapse;
          width: 100%;
          text-align: center;
        `}
      >
        <thead
          css={css`
            font-weight: bold;
            font-family: MarkOT-Bold, helvetica, arial, sans-serif;
            font-size: 10px;
            text-transform: uppercase;
          `}
        >
          <tr>
            {config?.alterationsEnabled && (
              <th
                css={css`
                  width: 20%;
                `}
              >
                Basic fitting
              </th>
            )}
            {config?.inspireMeEnabled && (
              <th
                css={css`
                  width: 20%;
                `}
              >
                Inspire me
              </th>
            )}
            {config?.perfectFitEnabled && (
              <th
                css={css`
                  width: 20%;
                `}
              >
                Perfect fit
              </th>
            )}
            {config?.sizingEnabled && (
              <th
                css={css`
                  width: 20%;
                `}
              >
                Size up/size down
              </th>
            )}
            {config?.waitAndTryEnabled && (
              <th
                css={css`
                  width: 20%;
                `}
              >
                Wait and try
              </th>
            )}
            {(config?.consignmentDropoffEnabled || config?.consignmentServicedEnabled) && (
              <th
                css={css`
                  width: 20%;
                `}
              >
                Consignment
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {config?.alterationsEnabled && (
              <td>
                {orderServices?.alterations && (
                  <img
                    aria-label='alterations service selected'
                    src={tick.toString()}
                    css={css`
                      height: 10px;
                      display: inline;
                    `}
                  />
                )}
                {!orderServices?.alterations && 'No'}
              </td>
            )}
            {config?.inspireMeEnabled && (
              <td>
                {(orderServices?.inspireMe || areAnyOrderItemsInspireMe) && (
                  <img
                    aria-label='inspire me service selected'
                    src={tick.toString()}
                    css={css`
                      height: 10px;
                      display: inline;
                    `}
                  />
                )}
                {!orderServices?.inspireMe && 'No'}
              </td>
            )}
            {config?.perfectFitEnabled && (
              <td>
                {orderServices?.perfectFit && (
                  <img
                    aria-label='perfect fit service selected'
                    src={tick.toString()}
                    css={css`
                      height: 10px;
                      display: inline;
                    `}
                  />
                )}
                {!orderServices?.perfectFit && 'No'}
              </td>
            )}
            {config?.sizingEnabled && (
              <td>
                {orderServices?.sizeUpSizeDown && (
                  <img
                    aria-label='size up/size down service selected'
                    src={tick.toString()}
                    css={css`
                      height: 10px;
                      display: inline;
                    `}
                  />
                )}
                {!orderServices?.sizeUpSizeDown && 'No'}
              </td>
            )}
            {config?.waitAndTryEnabled && (
              <td>
                {orderServices?.waitAndTry && (
                  <img
                    aria-label='wait and try service selected'
                    src={tick.toString()}
                    css={css`
                      height: 10px;
                      display: inline;
                    `}
                  />
                )}
                {!orderServices?.waitAndTry && 'No'}
              </td>
            )}
            {(config?.consignmentDropoffEnabled || config?.consignmentServicedEnabled) && (
              <td>
                {orderServices?.consignment && (
                  <img
                    aria-label='consignment service selected'
                    src={tick.toString()}
                    css={css`
                      height: 10px;
                      display: inline;
                    `}
                  />
                )}
                {!orderServices?.consignment && 'No'}
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default observer(OrderServices);
