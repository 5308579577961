import axios from 'axios';

import { apiUrl } from '@isi/config/Url';

export interface ISetOrderItemsParams {
  store_id: number;
  order_id: string | number;
  lineItems: ILineItem[];
}

export interface UnscheduledOrderItem {
  id: number;
  sku: string;
  quantity: number;
  input_price: number;
  name: string;
  image_url: string;
  product_url: string;
  bespoke: boolean;
  size: string;
  brand_product_id: number;
  colour: string;
  inspire_me: boolean;
  payment_required: boolean;
  additional_sizes: IAdditionalSize[];
}

export interface ILineItem {
  id: number;
  sku: string;
  quantity: number;
  inputPrice: number;
  name: string;
  imageUrl: string;
  productUrl: string;
  bespoke: boolean;
  brandProductId: number;
  inspireMe: boolean;
  size: string;
  colour: string;
  payment_required: boolean;
  additional_sizes: IAdditionalSize[];
}

export interface IAdditionalSize {
  sku: string;
  size: string;
}

interface ISetOrderItemsResponse {
  result: boolean;
}

export const setOrderItems = async (params: ISetOrderItemsParams) => {
  const url = `${apiUrl()}/v2/orders/${params?.order_id}/order_items/create_many`;
  return axios.post<ISetOrderItemsResponse>(url, params);
};
