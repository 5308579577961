import { action, autorun, makeObservable } from 'mobx';

import { AuthMixin } from '@shared/stores/mixins/auth.mixin';

import BrandUser, { type BrandUserType } from './models/brand-user.model';
import type { RootStore } from './root.store';
import { apiUrl } from '@isi/config/Url';
import { NotificationLevel } from '@isi/enums/notificationLevel.enum';

export class AuthStore extends AuthMixin<BrandUserType, BrandUser> {
  static jwtExpireCopy = 'Your previous session has expired. Please log in again';

  constructor(private readonly rootStore: RootStore) {
    super('ISI');

    makeObservable(this, {
      onLogIn: action.bound,
      onLogOut: action.bound,
      onExpiry: action.bound,
    });

    autorun(() => {
      if (this.loggedIn) this.rootStore.storeStore.fetchStoreConfig();
    });

    this.handleSSOLogin();
  }

  UserClass = BrandUser;

  authUrl = `${apiUrl()}/api/v3/isi/sessions`;

  onLogIn() {
    this.rootStore.uiStore.clearNotificationByText(AuthStore.jwtExpireCopy);
  }

  onLogOut() {
    this.rootStore.resetState();
  }

  onExpiry(): void {
    this.rootStore.uiStore.setNotification({
      message: AuthStore.jwtExpireCopy,
      level: NotificationLevel.Info,
    });
  }
}
