import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import clock from '@isi/assets/icons/clock.svg';
import giftIconLarge from '@isi/assets/icons/gift-large-icon.svg';
import person from '@isi/assets/icons/person.svg';
import arrow from '@isi/assets/icons/right-arrow.svg';
import { rootStore } from '@isi/stores/root.store';

import { H3 } from '@isi/components/common/h3.component';
import { GiftingConsumer } from '@isi/components/common/orderGiftingContext';
import { ContentType } from '@isi/components/create_order/ContentType.enum';

interface IClientDateTimeIncompleteProps {
  contentType: ContentType;
}

@observer
export default class ClientDateTimeIncomplete extends Component<IClientDateTimeIncompleteProps, {}> {
  public mainBoxImage = (contentType: ContentType): any => {
    if (contentType === ContentType.gifting) {
      return giftIconLarge;
    }

    if (contentType === ContentType.customer) {
      return person;
    }

    return clock;
  };

  public renderIncompleteClientDateTimeContent = (type: ContentType): JSX.Element => {
    const customerMode = type === ContentType.customer || type === ContentType.gifting;

    const giftingOrderGiftingContentText = 'ADD GIFT RECIPIENT DETAILS';
    const giftingOrderCustomerContentText = 'SENDER DETAILS';
    const normalOrderCustomerContentText = 'SELECT CLIENT';

    const mainImage = this.mainBoxImage(type);

    return (
      <GiftingConsumer>
        {({ isGiftingOrder: isGifting }) => (
          <div
            css={css`
              padding-left: 38px;
              padding-top: 25px;
              padding-right: 38px;
            `}
          >
            <div
              css={css`
                position: relative;
                display: inline-block;
              `}
            >
              {this.renderImg(mainImage, 33, 'vertical-align: middle;')}
              <div
                css={css`
                  font-family: MarkOT;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #8f8f8f;

                  display: inline-block;
                  margin-left: 22px;
                  margin-top: 10px;
                `}
              >
                <Link className='selectClientTime' style={{ display: 'block' }} to={this.linkToLocation(isGifting)}>
                  <div>
                    <H3
                      bold
                      additionalStyles={`
                    letter-spacing: 1px;
                    display: inline-block;
                    ${customerMode ? 'margin-left: 12px;' : 'margin-left: 26px;'}
                  `}
                    >
                      {!customerMode && 'SELECT WHEN'}
                      {customerMode &&
                        isGifting &&
                        this.props.contentType === ContentType.gifting &&
                        giftingOrderGiftingContentText}
                      {customerMode &&
                        isGifting &&
                        this.props.contentType === ContentType.customer &&
                        giftingOrderCustomerContentText}
                      {customerMode &&
                        !isGifting &&
                        this.props.contentType === ContentType.customer &&
                        normalOrderCustomerContentText}
                    </H3>
                    {this.renderImg(arrow, 12, 'margin-left: 6px;')}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )}
      </GiftingConsumer>
    );
  };
  public renderImg = (img: any, height?: number, additionalStyles?: string): JSX.Element => (
    <img
      src={img.toString()}
      css={css`
        height: ${height || '33'}px;
        display: inline;
        ${additionalStyles}
      `}
    />
  );

  private linkToLocation = (isGiftingOrder: boolean): string => {
    if (this.props.contentType === ContentType.customer) {
      return isGiftingOrder ? '/customers?isGiftingOrder=true' : '/customers';
    }

    if (this.props.contentType === ContentType.gifting) {
      return '/customers/gifting';
    }

    if (this.props.contentType === ContentType.dateTime && !rootStore.customerStore.customerDetailsComplete) {
      return '/';
    }

    return '/date_time';
  };

  public render(): JSX.Element {
    return this.renderIncompleteClientDateTimeContent(this.props.contentType);
  }
}
export { ClientDateTimeIncomplete, IClientDateTimeIncompleteProps };
