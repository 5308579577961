export const PLACEHOLDER_IMAGE: string =
  'https://toshi-images.s3.eu-west-2.amazonaws.com/marketing/ISI/product-placeholder.png';

export const LDN_OPS_EMAIL: string = 'ldn-liveops@toshi.co';
export const LDN_OPS_PHONE: string = '07445090650';

export const NY_OPS_EMAIL: string = 'ny-liveops@toshi.co';
export const NY_OPS_PHONE: string = '16465420921';

export const LA_OPS_EMAIL: string = 'la-liveops@toshi.co';
export const LA_OPS_PHONE: string = '12132143221';

export const US_STORE_TAX_CODE_CSV_URL: string =
  'https://public-toshi-assets.s3.eu-west-2.amazonaws.com/TOSHI_TaxCodes+_US.csv';
export const UK_STORE_TAX_CODE_CSV_URL: string =
  'https://public-toshi-assets.s3.eu-west-2.amazonaws.com/TOSHI_TaxCodes+_UK.csv';
