import { css } from '@emotion/react';
import { addMinutes } from 'date-fns';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { type Availability, mapAvailabilityDTO } from '@shared/models/Availability';

import { NotificationLevel } from '@isi/enums/notificationLevel.enum';
import {
  checkAvailability,
  type ICheckAvailabilityParams,
} from '@isi/network/availability/check-availability.function';
import { rootStore } from '@isi/stores/root.store';

import useStoreContext from '@isi/hooks/useStoreContext';

import { Button, ButtonSize } from '@isi/components/common/button.component';
import { Checkbox } from '@isi/components/common/checkbox.component';
import { Label } from '@isi/components/common/label.component';
import { Select } from '@isi/components/common/select.component';
import SpinnerWrapper from '@isi/components/common/SpinnerWrapper.component';

export const DateTime = observer(() => {
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const [selectedDeliverySlotId, setSelectedDeliverySlotId] = useState<string | undefined>();
  const [unscheduledOrderSelected, setUnscheduledOrderSelected] = useState<boolean>(false);
  const [availability, setAvailability] = useState<Availability>();
  const [fetching, setFetching] = useState<boolean>();
  const [reset, setReset] = useState<boolean>(false);

  const {
    customerStore: { customerValue, addressValue },
    dashboardStore: { setOrderTimeoutTimeStamp },
    storeStore: { getStoreConfig },
    orderStore: {
      setOrderDeliverySlotId,
      setSelectedDeliverySlotOption,
      setOrderScheduledDate,
      setUnscheduledOrder,
      unsetUnscheduledOrder,
    },
    uiStore: { setNotification, clearNotification },
  } = useStoreContext();

  const bookerEnabled = Boolean(rootStore.storeStore.getStoreConfig?.bookerEnabled);

  const availabilityParams = useMemo<Partial<ICheckAvailabilityParams>>(
    () => ({
      addressLine1: [addressValue?.houseName, addressValue?.line1].filter(Boolean).join(', '),
      addressLine2: addressValue?.line2,
      town: addressValue?.cityTown,
      postcode: addressValue?.postcodeZipcode,
      email: customerValue?.email,
      storeId: getStoreConfig?.storeId,
      interface: 'in_store_interface',
      selectedDate,
    }),
    [addressValue, customerValue?.email, getStoreConfig?.storeId, selectedDate],
  );

  const slotsPresentForSelectedDate = useMemo(
    () => Boolean(selectedDate && availability?.slotsPresentForDate(selectedDate)),
    [availability, selectedDate],
  );

  useEffect(() => {
    async function fetchAvailability() {
      setFetching(true);
      try {
        const { data } = await checkAvailability(availabilityParams as ICheckAvailabilityParams);
        const availability = mapAvailabilityDTO(data);

        setAvailability(availability);
        setFetching(false);
        setReset(false);
      } catch {
        setNotification(
          {
            level: NotificationLevel.Error,
            message: 'There was an error fetching available times, please try again or contact TOSHI',
          },
          5000,
        );
        setFetching(false);
        setReset(false);
      }
    }

    if ((customerValue && getStoreConfig && addressValue && !slotsPresentForSelectedDate) || reset) {
      fetchAvailability();
    }
  }, [
    addressValue,
    availabilityParams,
    customerValue,
    getStoreConfig,
    reset,
    setNotification,
    slotsPresentForSelectedDate,
  ]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (selectedDeliverySlotId) {
      setNotification(
        {
          level: NotificationLevel.Warn,
          message: 'Please click CONFIRM within the next 2 minutes, otherwise we will reset your timeslot selection',
        },
        5000,
      );
      const timeout = setTimeout(
        () => {
          setReset(true);
          setSelectedDate(undefined);
          setSelectedDeliverySlotId(undefined);
          setNotification(
            {
              level: NotificationLevel.Info,
              message: 'Your delivery date and time selection has been reset',
            },
            5000,
          );
        },
        2 * 60 * 1000,
      );

      return () => clearTimeout(timeout);
    }
  }, [selectedDeliverySlotId, setNotification]);

  const canConfirmDateTime = Boolean(selectedDeliverySlotId);

  const confirmDateTime = useCallback(() => {
    setUnscheduledOrderSelected(false);
    unsetUnscheduledOrder();
    if (selectedDeliverySlotId && availability && selectedDate) {
      setOrderDeliverySlotId(Number(selectedDeliverySlotId));

      const selectedSlot = availability
        .selectSlotsForDate(selectedDate)
        .find(({ value }) => value === selectedDeliverySlotId);
      setSelectedDeliverySlotOption({
        disabled: false,
        display: selectedSlot?.content,
        value: selectedSlot?.value,
      });

      const momentDate = moment.utc(selectedDate);
      setOrderScheduledDate(momentDate.toString());
      setOrderTimeoutTimeStamp(addMinutes(Date.now(), 5));
    }
  }, [
    availability,
    selectedDate,
    selectedDeliverySlotId,
    setOrderDeliverySlotId,
    setOrderScheduledDate,
    setOrderTimeoutTimeStamp,
    setSelectedDeliverySlotOption,
    unsetUnscheduledOrder,
  ]);

  const renderUnscheduledBooker = (): JSX.Element => (
    <div
      css={css`
        margin: auto;
        width: 100%;
        background: #fff;
        padding: 16px;

        @media (min-width: 1024px) {
          margin-top: 221px;
          width: 50%;
          max-width: 360px;
          margin-top: 32px;
        }
      `}
    >
      <div
        css={css`
          width: fit-content;
          display: flex;
          width: 100%;
          justify-content: center;
        `}
      >
        <h2
          css={css`
            font-size: 1.4rem;
          `}
        >
          SELECT DELIVERY SLOT LATER
        </h2>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <Checkbox
          checked={unscheduledOrderSelected}
          onClick={() => setUnscheduledOrderSelected(!unscheduledOrderSelected)}
        />
        <div
          css={css`
            margin-left: 20px;
          `}
        >
          <Label
            cssOverrides='line-height: 1;'
            text='The customer wishes to schedule the delivery themselves at a later time.'
          />
        </div>
      </div>
      <p
        css={css`
          font-size: 14px;
        `}
      >
        <span
          css={css`
            font-family: 'MarkOT-Bold';
            margin-right: 5px;
          `}
        >
          Note:
        </span>
        The customer will be able to schedule this delivery via the TOSHI Booker which will be sent to their email
        address.
      </p>
      <div
        css={css`
          margin-top: 20px;
        `}
      >
        <Button
          onClick={setUnscheduledOrder}
          disabled={!unscheduledOrderSelected}
          buttonSize={ButtonSize.large}
          fullWidth
          linkTo='/'
        >
          confirm
        </Button>
      </div>
    </div>
  );

  const dateDropdown = (
    <Select
      id='dateSelect'
      values={
        availability?.selectDates().map(({ value, content }) => ({ value, display: content, disabled: false })) ?? []
      }
      label=''
      placeholder='Please select a delivery date'
      disabled={false}
      labelCSS={`
          width: 100%;
        `}
      onChange={(date: string | number) => setSelectedDate(String(date))}
      resetValue={reset}
    />
  );

  const slotDropdown = (
    <Select
      id='timeSelect'
      values={
        (selectedDate &&
          availability
            ?.selectSlotsForDate(selectedDate)
            .map(({ value, content }) => ({ value, display: content, disabled: false }))) ||
        []
      }
      label=''
      placeholder='Please select a delivery window'
      disabled={!slotsPresentForSelectedDate}
      onChange={(value) => setSelectedDeliverySlotId(`${value}`)}
      resetValue={reset}
    />
  );

  return (
    <div
      css={css`
        height: 100%;
      `}
    >
      {(!availability || fetching) && <SpinnerWrapper />}
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          height: 100%;

          @media (min-width: 1024px) {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            background: linear-gradient(to left, #fff 50%, #f7f7f7 50%);
            ${bookerEnabled ? null : 'display: flex;'};
            ${bookerEnabled ? null : 'background: #fff;'};
          }
        `}
      >
        <div
          css={css`
            margin: auto;
            width: 100%;
            ${bookerEnabled ? 'background: #f7f7f7;' : 'background: #fff;'};
            padding: 16px;

            @media (min-width: 1024px) {
              margin-top: 221px;
              width: 50%;
              max-width: 360px;
              margin-top: 32px;
            }
          `}
        >
          <div
            css={css`
              width: fit-content;
              display: flex;
              width: 100%;
              justify-content: center;
            `}
          >
            <h2
              css={css`
                font-size: 1.4rem;
              `}
            >
              {bookerEnabled ? 'SELECT DELIVERY SLOT NOW' : 'SELECT DELIVERY SLOT'}
            </h2>
          </div>
          <div
            css={css`
              margin-top: 47px;
            `}
          >
            <p>Delivery Date</p>
            <div
              css={css`
                display: flex;
              `}
            >
              {dateDropdown}
            </div>
            <div
              css={css`
                margin-top: 49px;
              `}
            >
              <p>Delivery Time</p>
              {slotDropdown}
            </div>
          </div>
          <div
            css={css`
              margin-top: 76px;
            `}
          >
            <Button
              onClick={() => {
                confirmDateTime();
                clearNotification();
              }}
              disabled={!canConfirmDateTime}
              buttonSize={ButtonSize.large}
              fullWidth
              linkTo='/'
            >
              confirm
            </Button>
          </div>
        </div>
        {bookerEnabled ? renderUnscheduledBooker() : null}
      </div>
    </div>
  );
});
