import axios, { type AxiosResponse } from 'axios';

import { type UnscheduledOrderItem } from './set-order-items.function';
import { apiUrl } from '@isi/config/Url';

interface SharedCreateOrderParams {
  customer_id: string;
  first_name: string;
  last_name: string;
  contact_number: string;
  email: string;
  store_id: number;
  wait_and_try: boolean;
  up_down_size: boolean;
  alteration: boolean;
  perfect_fit: boolean;
  inspire_me: boolean;
  inspire_me_customer_notes?: string;
  consignment: boolean;
  store_order_number: string;
  total_due: number;
  paid_to_date: number;
  order_total: number;
  total_less_discount: number;
  product_mode: string;
  notes: string;
  store_assistant_name: string;
  store_assistant_phone: string;
  use_security_codes: boolean;
}

export interface ICreateUnscheduledOrderParams extends SharedCreateOrderParams {
  address_id: number;
  house_name: string;
  address_line_1: string;
  address_line_2?: string;
  town: string;
  postcode: string;
  country: string;
  order_items: UnscheduledOrderItem[];
  package_count: number;
  package_size: string;
}

export interface ICreateOrderParams extends SharedCreateOrderParams {
  address_id: number;
  house_name: string;
  address_line_1: string;
  address_line_2?: string;
  town: string;
  postcode: string;
  country: string;
  delivery_slot_id: number;
  scheduled_date: string;
  package_count: number;
  package_size: string;
}

export interface ICreateOrderResponse {
  order_id: string;
}

export const createOrder = async (params: ICreateOrderParams): Promise<AxiosResponse<ICreateOrderResponse>> => {
  const url = `${apiUrl()}/v2/orders`;
  return axios.post<ICreateOrderResponse>(url, params);
};

export const createUnscheduledOrder = async (
  params: ICreateUnscheduledOrderParams,
): Promise<AxiosResponse<ICreateOrderResponse>> => {
  const url = `${apiUrl()}/api/v3/isi/unscheduled_orders`;
  return axios.post<ICreateOrderResponse>(url, params);
};

export const createGiftedOrder = async (
  params: SharedCreateOrderParams,
): Promise<AxiosResponse<ICreateOrderResponse>> => {
  const url = `${apiUrl()}/v2/gifts/orders`;
  return axios.post<ICreateOrderResponse>(url, params);
};
