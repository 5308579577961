import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { TablePagination, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { mqMaxXs } from '@shared/helpers/styling/emotion-media-query.function';

import useStoreContext from '@isi/hooks/useStoreContext';

import { H1 } from '@isi/components/common/h1.component';
import { theme } from '@isi/components/order_dashboard/mui-theme';
import { Report, ReportsSkeleton } from '@isi/containers/reports/components/report.component';

const ReportsContainer = styled.section`
  margin: 0 10vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'reports'
    'pagination';
  height: 90vh;
`;

const Reports = () => {
  const {
    brandJourneyReportStore: { count, page, fetchItems, per, items, state },
  } = useStoreContext();

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <ReportsContainer>
      <H1
        additionalStyles={`
          margin: 2em 0;
          ${mqMaxXs} {
            margin: 1em;
            font-size: 16px;
          }
      `}
      >
        Reports
      </H1>
      <div css={{ gridArea: 'reports', overflowY: 'scroll' }}>
        {state === 'pending' && <ReportsSkeleton skeletonCount={per} />}
        {state === 'done' && items.get(page)?.map((report, i) => <Report report={report} key={report.filename + i} />)}
      </div>
      <Global
        styles={css`
          .MuiTablePagination-spacer {
            display: none;
          }

          .MuiToolbar-root {
            justify-content: center;
            ${mqMaxXs} {
              flex-direction: column;

              * {
                align-self: center;
                margin-left: 0 !important;
                margin-right: 0 !important;
              }
            }
          }
        `}
      />
      <ThemeProvider theme={theme}>
        <TablePagination
          component='div'
          // backend responses are 1 index while this component expects 0 index
          count={count ?? -1}
          page={page - 1}
          onPageChange={(_, value) => {
            fetchItems(value + 1);
          }}
          rowsPerPage={per}
          onRowsPerPageChange={(e) => fetchItems(undefined, parseInt(e.target.value, 10))}
          showFirstButton
          showLastButton
        />
      </ThemeProvider>
    </ReportsContainer>
  );
};

export default observer(Reports);
