import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { type ButtonHTMLAttributes, type FC, type ReactNode } from 'react';
import { Link } from 'react-router-dom';

enum ButtonSize {
  small = 1,
  large = 2,
}

interface ButtonProps {
  id?: string;
  children?: ReactNode;
  buttonSize: ButtonSize;
  fullWidth?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  linkTo?: string;
  additionalStyles?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

const calculateWidth = (fullWidth?: boolean, link?: string): string => {
  if (fullWidth && link) {
    return 'width: calc(100% - 94px);';
  }
  if (fullWidth) {
    return 'width: 100%;';
  }
  return '';
};

const sizeStyle = (size: ButtonSize) => {
  switch (size) {
    case ButtonSize.large:
      return `
          font-size: 16px;
          padding: 11px 47px;
          line-height: 1.69;
        `;
    case ButtonSize.small:
      return `
          font-size: 14px;
          line-height: 1.93;
          padding: 3px 18px;
        `;
    default:
      return '';
  }
};

const StyledButton = styled('button', { shouldForwardProp: isPropValid })<{
  to?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  size: ButtonSize;
}>`
  font-weight: bold;
  appearance: none;
  border: none;
  background-color: #000000;
  color: #ffffff;
  font-family: MarkOT, helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  letter-spacing: normal;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  ${({ to }) =>
    to
      ? 'display: block; text-decoration: none;'
      : `&:disabled {
    opacity: 0.41;
  }`}

  ${({ to, fullWidth }) => calculateWidth(fullWidth, to)}
  ${({ size }) => sizeStyle(size)}
`;

const StyledLink = StyledButton.withComponent(Link);

const Button: FC<ButtonProps> = ({
  additionalStyles,
  buttonSize,
  children,
  disabled,
  fullWidth,
  id,
  linkTo,
  onClick,
  type,
}) => {
  const commonProps = {
    css: css(additionalStyles),
    disabled,
    fullWidth,
    onClick,
    size: buttonSize,
  };

  const buttonProps = {
    ...commonProps,
    id,
    type,
    onClick,
  };

  if (linkTo) {
    const linkProps = {
      ...commonProps,
      to: disabled ? '#' : linkTo,
      'aria-disabled': disabled,
    };

    return <StyledLink {...linkProps}>{children}</StyledLink>;
  }

  return <StyledButton {...buttonProps}>{children}</StyledButton>;
};

export { Button, ButtonProps, ButtonSize };
