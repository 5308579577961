import axios from 'axios';
import { action, makeObservable } from 'mobx';
import { clearPersistedStore, stopPersisting } from 'mobx-persist-store';

import { AuthStore } from './auth.store';
import BrandJourneyReportStore from '@isi/stores/brand-journey-report.store';
import { CustomerStore } from '@isi/stores/customer.store';
import { DashboardStore } from '@isi/stores/dashboard.store';
import { OrderStore } from '@isi/stores/order.store';
import { ProductStore } from '@isi/stores/product.store';
import { StoreStore } from '@isi/stores/store.store';
import { UIStore } from '@isi/stores/ui.store';

class RootStore {
  public authStore!: AuthStore;
  public uiStore!: UIStore;
  public dashboardStore!: DashboardStore;
  public customerStore!: CustomerStore;
  public orderStore!: OrderStore;
  public productStore!: ProductStore;
  public storeStore!: StoreStore;
  public brandJourneyReportStore!: BrandJourneyReportStore;

  constructor() {
    makeObservable(this, {
      resetState: action.bound,
    });

    this.setupInitialState();
  }

  public setCSRFToken = (): void => {
    axios.defaults.headers.common['x-csrf-token'] = (
      document?.querySelector('meta[name="csrf-token"]') as HTMLMetaElement
    )?.content;
    axios.defaults.withCredentials = true;
  };

  public resetState(clearAuth: boolean = false): void {
    if (clearAuth) {
      stopPersisting(this.authStore);
      clearPersistedStore(this.authStore);
    }

    this.setCSRFToken();
    this.authStore = new AuthStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.customerStore = new CustomerStore(this);
    this.orderStore = new OrderStore(this);
    this.productStore = new ProductStore(this);
    this.storeStore = new StoreStore(this);
    this.brandJourneyReportStore = new BrandJourneyReportStore();
  }

  private setupInitialState(): void {
    this.setCSRFToken();
    this.uiStore = new UIStore();
    this.storeStore = new StoreStore(this);
    this.authStore = new AuthStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.customerStore = new CustomerStore(this);
    this.orderStore = new OrderStore(this);
    this.productStore = new ProductStore(this);
    this.brandJourneyReportStore = new BrandJourneyReportStore();
  }
}
const rootStore = new RootStore();

export { rootStore, RootStore };
