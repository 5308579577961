import { css } from '@emotion/react';
import { Component } from 'react';

import { RadioButtonBlock } from '@isi/components/products/add-services/radio-button-block.component';

interface IServicesToggleProps {
  onChange: () => void;
  showWaitAndTry: boolean;
}

enum ServicesToggles {
  DropOff = 'DropOff',
  WaitAndTry = 'WaitAndTry',
}

class ServicesToggle extends Component<IServicesToggleProps, {}> {
  public WaitAndTryButton: RadioButtonBlock | null = null;
  public DropOffButton: RadioButtonBlock | null = null;

  public forceWaitAndTrySelection = (): void => {
    this.WaitAndTryButton?.forceSelected();
    this.DropOffButton?.forceUnselected();
  };

  public forceDropOffSelection = (): void => {
    this.DropOffButton?.forceSelected();
    this.WaitAndTryButton?.forceUnselected();
  };

  private toggleDropOffButton = (): void => {
    this.DropOffButton?.forceSelectionToggle();
    this.props.onChange();
  };

  private toggleWaitAndTryButton = (): void => {
    this.WaitAndTryButton?.forceSelectionToggle();
    this.props.onChange();
  };

  public render(): JSX.Element {
    return (
      <div
        css={css`
          width: fit-content;
          margin: auto;
        `}
      >
        {this.props.showWaitAndTry && (
          <RadioButtonBlock
            ref={(button) => {
              if (button) this.WaitAndTryButton = button;
            }}
            selected={this.props.showWaitAndTry}
            onClick={() => {
              this.toggleDropOffButton();
            }}
          >
            Wait &amp; Try
          </RadioButtonBlock>
        )}
        {this.props.showWaitAndTry && (
          <RadioButtonBlock
            ref={(button) => {
              if (button) this.DropOffButton = button;
            }}
            selected={!this.props.showWaitAndTry}
            onClick={this.props.showWaitAndTry ? this.toggleWaitAndTryButton : undefined}
            additionalStyles={`
            margin-left: 10px;
          `}
          >
            Scheduled delivery
          </RadioButtonBlock>
        )}
      </div>
    );
  }
}

export { ServicesToggle, IServicesToggleProps, ServicesToggles };
