export interface IProductValidationError {
  productId: number;
  productSizeId: number;
  priceErrorMessage: string;
  quantityErrorMessage: string;
  sizeErrorMessage: string;
}

export enum ProductRowValidationField {
  price = 'price',
  quantity = 'quantity',
  size = 'size',
}
