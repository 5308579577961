import axios, { type AxiosResponse } from 'axios';

import { apiUrl } from '@isi/config/Url';
import { encodeParams } from '@isi/network/helpers/params/encode-params.function';

export interface IGetCustomerParams {
  query?: string;
}

export interface GetCustomerResponseLastAddress {
  id: number;
  house_name: string | null;
  line_1: string;
  line_2: string | null;
  city_town: string | null;
  postcode_zipcode: string;
  country: string;
  latitude: string | null;
  zone: string | null;
  longitude: string | null;
  notes: string | null;
  created_at: string;
  updated_at: string;
  customer_id: string | null;
}

export interface IGetCustomerResponse {
  id: string;
  title: string | null;
  first_name: string;
  last_name: string;
  email: string;
  guest: boolean;
  contact_number: string;
  contact_number_country: string;
  last_address: GetCustomerResponseLastAddress | undefined;
  contact_preferences: number;
}

export const getCustomers = (params?: IGetCustomerParams): Promise<AxiosResponse<IGetCustomerResponse[]>> => {
  let url = `${apiUrl()}/v2/customers`;

  if (params?.query) {
    url = `${url}?${encodeParams(params)}`;
  }

  return axios.get<IGetCustomerResponse[]>(url);
};
