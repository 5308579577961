import axios from 'axios';

import { mapKeysToSnake } from '@shared/helpers/mapKeys/mapKeysToSnake';
import { type AvailabilityDTO } from '@shared/models/Availability';

import { apiUrl } from '@isi/config/Url';

export interface ICheckAvailabilityParams {
  addressLine1: string;
  addressLine2?: string;
  town: string;
  postcode: string;
  email: string;
  storeId: number;
  currentSkus?: string[];
  upDownSkus?: string[];
  availabilityDate?: string | null;
  interface: string;
  slotsForDates?: string[];
  selectedDate?: string;
}

export const checkAvailability = async (params: ICheckAvailabilityParams) => {
  const url = `${apiUrl()}/v2/availability/check_available`;
  return axios.post<AvailabilityDTO>(url, mapKeysToSnake(params));
};
