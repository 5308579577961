import axios from 'axios';

import { apiUrl } from '@isi/config/Url';

export interface IGetJourneyParams {
  journeyId: number;
}

export interface IGetJourneyResponse {
  id: number;
  order_id: number;
  delivery_slot: {
    start_time: string;
    end_time: string;
    id: number;
  };
}

export const getJourney = async (params: IGetJourneyParams) => {
  const url = `${apiUrl()}/v2/journeys/${params?.journeyId}`;

  return axios.get<IGetJourneyResponse>(url);
};
