import axios from 'axios';
import { action, autorun, computed, makeObservable, observable, runInAction, when } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';
import type { CamelCasedProperties } from 'type-fest';

import { mapKeysToSnake } from '@shared/helpers/mapKeys/mapKeysToSnake';
import { login } from '@shared/network/auth/login';
import { logout } from '@shared/network/auth/logout';

import { GetCookie } from '@isi/helpers/cookies';

export interface WithJwt {
  jwt: string;
}

declare global {
  interface Window {
    logInFromJSON: AuthMixin<WithJwt, WithJwt>['logInFromJSON'];
  }
}

export abstract class AuthMixin<TUserResponse extends WithJwt, TUserModel extends CamelCasedProperties<TUserResponse>> {
  abstract UserClass: new (userResponse: TUserResponse) => TUserModel;
  abstract authUrl: URL['href'];

  // optional side effects
  onExpiry?(): void;
  onLogIn?(): void;
  onLogOut?(): void;

  protected cacheKey: string;
  protected _loggedIn: boolean = false;

  constructor(cacheKey: string) {
    makeObservable<AuthMixin<TUserResponse, TUserModel>, '_loginError' | '_user' | '_jwtExpired' | 'jwtExpired'>(this, {
      loggedIn: computed,
      logInFromJSON: action.bound,
      _loginError: observable,
      loginError: computed,
      setLoginError: action.bound,
      logIn: action.bound,
      logOut: action.bound,
      _user: observable,
      user: computed,
      setUser: action.bound,
      _jwtExpired: observable,
      jwtExpired: computed,
      setJwtExpired: action.bound,
      handleSSOLogin: action.bound,
    });

    this.cacheKey = `TOSHI_${GetCookie.environment()}_${cacheKey}_AUTH`;
    window.logInFromJSON = this.logInFromJSON;

    makePersistable(this, {
      name: this.cacheKey,
      properties: [
        {
          key: '_user' as keyof this,
          serialize: JSON.stringify,
          deserialize: (value: string) => {
            const json = mapKeysToSnake(JSON.parse(value)) as TUserResponse;
            this.setAuthorizationHeaders(json.jwt);
            return new this.UserClass(json) as unknown as this[keyof this];
          },
        },
      ],
      storage: window.localStorage,
      expireIn: 604_800_000, // 1 week
      removeOnExpiration: !TEST,
      stringify: true,
    }).then(() => {
      when(
        () => this.jwtExpired,
        () => {
          this.logOut();

          this.onExpiry?.();
        },
        { name: 'logout when jwt expire' },
      );
    });
  }

  get loggedIn() {
    return Boolean(this.user);
  }

  protected _loginError: string = '';

  get loginError() {
    return this._loginError;
  }

  setLoginError(value: string) {
    this._loginError = value;
  }

  protected _user?: TUserModel;

  get user() {
    return this._user;
  }

  setUser(value?: TUserModel) {
    this._user = value;
    if (value) this.setAuthorizationHeaders(value.jwt);
  }

  handleStorage = autorun(
    () => {
      if (this.user) {
        this.setAuthorizationHeaders(this.user.jwt);
      } else {
        this.clearAuthorizationHeaders();
      }
    },
    { name: 'Reaction: store user and authorisation' },
  );

  private setAuthorizationHeaders(this: this, token: string) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  private clearAuthorizationHeaders(this: this) {
    delete axios.defaults.headers.common.Authorization;
  }

  async logIn(email: string, password: string) {
    try {
      const { data } = await login<TUserResponse>(this.authUrl, email, password);

      runInAction(() => {
        this.setUser(new this.UserClass(data));
        this.setLoginError('');
        this.setJwtExpired(false);

        this.onLogIn?.();
      });
    } catch {
      this.setLoginError('You have entered an invalid username or password');
    }
  }

  handleSSOLogin(): boolean {
    const params = new URLSearchParams(window.location.search);
    const userParam = params.get('sso_token');

    if (userParam) {
      this.logInFromJSON(userParam);
      params.delete('sso_token');
      const newURL = window.location.origin + window.location.pathname + params.toString();
      window.history.replaceState({ path: newURL }, '', newURL);
      this.onLogIn?.();
      return true;
    }
    return false;
  }

  logInFromJSON(userJSON: string) {
    this.setUser(new this.UserClass(JSON.parse(userJSON)));
    this.setLoginError('');
  }

  async logOut() {
    await logout(this.authUrl);
    this.setUser();
    stopPersisting(this);

    this.onLogOut?.();
  }

  private _jwtExpired: boolean = false;

  protected get jwtExpired(): boolean {
    return this._jwtExpired;
  }

  setJwtExpired(value: boolean) {
    this._jwtExpired = value;
  }
}
