import { css } from '@emotion/react';
import { type FC } from 'react';

import notes from '@isi/assets/icons/notes.svg';
import { rootStore } from '@isi/stores/root.store';

import { H4 } from '@isi/components/common/h4.component';

const InspireMeNotes: FC = () => {
  const currentOrder = rootStore.dashboardStore.getCurrentOrder;
  const inspireMeNotes = currentOrder?.inspireMeCustomerNotes;
  return (
    <div
      css={css`
        padding: 20px;
      `}
    >
      <div
        css={css`
          padding-bottom: 10px;
        `}
      >
        <img
          src={notes}
          alt=''
          aria-label='Notes image - by Xinh Studio from the Noun Project'
          css={css`
            height: 33px;
            display: inline;
            padding-right: 10px;
          `}
        />
        <H4
          bold
          additionalStyles={`
          display: inline;
          vertical-align: super;
        `}
        >
          Inspire me customer notes
        </H4>
      </div>
      <div>{inspireMeNotes}</div>
    </div>
  );
};

export default InspireMeNotes;
