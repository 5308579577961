import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { type IProductCatalogueItem } from '@isi/interfaces/IProductCatalogueItem.interface';
import { rootStore } from '@isi/stores/root.store';

import { Dropdown, type DropdownValue } from '@isi/components/common/dropdown.component';
import { SmallInput } from '@isi/components/products/add-services/add-services-table/small-input.component';

interface IDropOffProductRowProps {
  product: IProductCatalogueItem | undefined;
  quantity: number;
  // eslint-disable-next-line react/no-unused-prop-types
  colour: string;
  onSizeChange: (size: string) => void;
  onPriceChange: (price: number) => void;
  onQuantityChange: (quantity: number) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onItemToDeleteSelectChange: (size: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  price: number;
  size: string;
  errors: string[];
  // eslint-disable-next-line react/no-unused-prop-types
  name: string;
}

interface DropOffProductRowState {
  deleteCheckboxChecked: boolean;
}

@observer
class DropOffProductRow extends Component<IDropOffProductRowProps, DropOffProductRowState> {
  public product: IProductCatalogueItem | undefined = this.props.product;
  public config = rootStore.storeStore.getStoreConfig;
  public storeCurrency = rootStore.storeStore.storeCurrency;

  constructor(props: IDropOffProductRowProps) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      deleteCheckboxChecked: false,
    };
  }

  private priceDropdownValues = (): DropdownValue[] => [
    { value: 10_000, display: `Up to ${this.storeCurrency}10,000` },
    { value: 50_000, display: `${this.storeCurrency}10,000 to ${this.storeCurrency}50,000` },
    { value: 100_000, display: `${this.storeCurrency}50,000+` },
  ];

  private renderPrice = (): JSX.Element => {
    const simpleDropOffPricing = rootStore.storeStore.getStoreConfig?.simpleDropOffPricing;

    if (simpleDropOffPricing) {
      return (
        <Dropdown
          values={this.priceDropdownValues()}
          onChange={(price) => this.props.onPriceChange(Number(price))}
          selectedValue=''
          error={false}
          hidePleaseChoose={false}
          selectStyleOverride={`
            font-size: 12px;
            height: 25px;
            border: solid 1px #8c8c8c;
          `}
        />
      );
    }

    return (
      <span>
        {this.storeCurrency}
        <CurrencyInput
          id='dropOffCurrencyInput'
          name='drop-off-currency-input'
          placeholder='0.00'
          decimalsLimit={2}
          step={0.01}
          onValueChange={(value) => this.props.onPriceChange(Number(value))}
          css={css`
            max-width: 70px;
            padding: 0;
            padding-left: 5px;
            margin: auto;
            margin-left: 5px;
          `}
        />
      </span>
    );
  };

  private sizeDropdownValues(): DropdownValue[] {
    return this.config ? this.config.dropOffSizes.map((size) => ({ value: size, display: size })) : [];
  }

  private renderSizes(): JSX.Element {
    return this.sizeDropdownValues().length === 0 ? (
      <SmallInput
        selector='inputProductSize'
        disabled={false}
        onClick={() => {}}
        value={this.props.size}
        onChange={(size) => this.props.onSizeChange(size)}
        additionalStyles={`
        max-width: 70px;
        padding: 0;
        padding-left: 5px;
        margin: auto;
      `}
      />
    ) : (
      <Dropdown
        values={this.sizeDropdownValues()}
        onChange={(size) => this.props.onSizeChange(size)}
        selectedValue={this.props.size}
        error={false}
        hidePleaseChoose={false}
        selectStyleOverride={`
          font-size: 12px;
          height: 25px;
          border: solid 1px #8c8c8c;
        `}
      />
    );
  }

  public forceDeleteSelect = (): void => {};

  public forceDeleteDeselect = (): void => {};

  private renderErrors = () => {
    const pId = this.props.product?.id;

    return (
      this.props.errors &&
      this.props.errors.map((error: string, index) => (
        <div
          key={`${pId}-${index}-validation-error`}
          css={css`
            color: #c12034;
            margin-bottom: 5px;
          `}
        >
          {error}
        </div>
      ))
    );
  };

  public render(): JSX.Element {
    return (
      <>
        <tr
          css={css`
            height: 100px;
            font-size: 12px;
            background-color: #f7f7f7;
          `}
        >
          <td
            css={css`
              max-width: 70px;
              line-height: 1.25;
            `}
          >
            <p>Drop off</p>
          </td>
          <td>{this.renderPrice()}</td>
          <td>
            <SmallInput
              disabled={false}
              onClick={() => {}}
              onChange={(quantity) => this.props.onQuantityChange(Number(quantity))}
              value={this.props.quantity.toString()}
              min='1'
              max='999'
              type='number'
              additionalStyles={`
                max-width: 35px;
                text-align: center;
                padding: 0;
                margin: auto;
              `}
            />
          </td>
          <td
            css={css`
              margin-top: 45px;
            `}
          >
            <div>{this.renderSizes()}</div>
          </td>
        </tr>
        <tr
          css={css`
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #cecece;
            font-size: 12px;
            background-color: #f7f7f7;
          `}
        >
          <td
            css={css`
              padding-bottom: 20px;
            `}
            colSpan={7}
          >
            {this.renderErrors()}
          </td>
        </tr>
      </>
    );
  }
}

export { DropOffProductRow };
