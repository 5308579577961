import axios from 'axios';

export interface IUploadCsvFileResponse {
  data: string;
  status: number;
}

export const uploadCsvFile = (url: string, file: string): Promise<IUploadCsvFileResponse> =>
  axios.put(url, file, {
    headers: {
      'Content-Type': 'text/csv',
    },
  });
