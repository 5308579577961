import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { rootStore } from '@isi/stores/root.store';

import { Button, ButtonSize } from '@isi/components/common/button.component';
import { LinkButton } from '@isi/components/common/link-button.component';

interface ISelectProductsNavContentProps {
  onClear: () => void;
  onAddServices: () => void;
}

@observer
export default class SelectProductsNavContent extends Component<ISelectProductsNavContentProps, {}> {
  private renderProductsSelectedTextEl = (): JSX.Element => {
    const productsSelected: number = rootStore.productStore.numberOfUniqueSelectedProducts;
    const plural: string = productsSelected > 1 ? 's' : '';
    const text = `${productsSelected} product${plural} selected`;
    return (
      <div
        css={css`
          position: absolute;
          left: 0;
          top: 6px;
          font-family: MarkOT-Bold, helvetica, arial, sans-serif;
          font-size: 19px;

          @media (min-width: 1024px) {
            top: 40px;
          }
        `}
      >
        {text}
      </div>
    );
  };

  public render(): JSX.Element {
    return (
      <div>
        <div>{this.renderProductsSelectedTextEl()}</div>
        <div
          css={css`
            position: absolute;
            right: 0;
            top: 30px;
          `}
        >
          <LinkButton onClick={() => this.props.onClear()} disabled={false}>
            Clear selection
          </LinkButton>
          <Button
            onClick={() => this.props.onAddServices()}
            disabled={false}
            buttonSize={ButtonSize.large}
            additionalStyles={`
              width: 100%;
              margin-top: 24px;
              @media (min-width: 1024px) {
                width: auto;
                margin-left: 36px;
                margin-top: 0;
              }
            `}
          >
            Select Services
          </Button>
        </div>
      </div>
    );
  }
}

export { ISelectProductsNavContentProps };
