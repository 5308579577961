import { type AxiosResponse } from 'axios';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';

import { NotificationLevel } from '@isi/enums/notificationLevel.enum';
import { type IAddress } from '@isi/interfaces/address.interface';
import { type Giftee, type ICustomer } from '@isi/interfaces/customer.interface';
import {
  createCustomer,
  type CreateGifteeResponse,
  createGiftingCustomer,
  type CreateRecipientResponse,
  type ICreateCustomerResponse,
  type INewAddressData,
  type INewCustomerData,
} from '@isi/network/customers/create-customer.function';
import { getCustomers, type IGetCustomerResponse } from '@isi/network/customers/get-customers.function';
import { checkAddressEligibility } from '@isi/network/helpers/check-address-eligibility.function';
import type { RootStore } from '@isi/stores/root.store';

export class CustomerStore {
  private customer: ICustomer | null = null;

  private address: IAddress | null = null;

  private giftee: Giftee | null = null;

  postcode: string;

  constructor(private readonly rootStore: RootStore) {
    makeObservable<
      CustomerStore,
      | 'customer'
      | 'address'
      | 'giftee'
      | '_addressEligible'
      | 'mapNonAddressCustomerResponseData'
      | 'mapCustomerResponseData'
    >(this, {
      customer: observable,
      address: observable,
      giftee: observable,
      postcode: observable,
      customerValue: computed,
      customerAddressValue: computed,
      addressValue: computed,
      customerDetailsComplete: computed,
      gifteeValue: computed,
      gifteeDetailsComplete: computed,
      _addressEligible: observable,
      addressEligible: computed,
      setAddressEligible: action.bound,
      clearAddressNotEligibleMessage: action.bound,
      setCustomer: action.bound,
      setAddress: action.bound,
      setPostcode: action.bound,
      setGiftee: action.bound,
      clearCustomerInfo: action.bound,
      clearGifteeInfo: action.bound,
      createGiftingCustomer: action.bound,
      createCustomer: action.bound,
      checkPostcodeEligibility: action.bound,
      searchCustomersRequest: action.bound,
      mapNonAddressCustomerResponseData: action.bound,
      mapCustomerResponseData: action.bound,
    });

    this.customer = null;
    this.giftee = null;
    this.address = null;
    this.postcode = '';

    reaction(
      () => this.address?.postcodeZipcode,
      async (postcodeZipcode: IAddress['postcodeZipcode'] | undefined) => {
        if (postcodeZipcode) {
          const eligible = await this.checkPostcodeEligibility(postcodeZipcode);

          runInAction(() => {
            this.setAddressEligible(eligible);
            if (postcodeZipcode && !eligible) {
              this.rootStore.uiStore.setNotification({
                level: NotificationLevel.Error,
                message: this.addressNotEligibleMessage,
              });
            } else {
              this.clearAddressNotEligibleMessage();
            }
          });
        } else {
          this.clearAddressNotEligibleMessage();
          this.setAddressEligible(false);
        }
      },
    );
  }

  get customerValue(): ICustomer | null {
    return this.customer;
  }

  get customerAddressValue(): IAddress | null {
    return this.address;
  }

  get addressValue(): IAddress | null {
    return this.address;
  }

  get customerDetailsComplete(): boolean {
    return this.customer !== null && this.address !== null;
  }

  get gifteeValue(): Giftee | null {
    return this.giftee;
  }

  get gifteeDetailsComplete(): boolean {
    return this.giftee !== null;
  }

  private _addressEligible: boolean = false;

  get addressEligible(): boolean {
    return this._addressEligible;
  }

  setAddressEligible = (eligible: boolean) => {
    this._addressEligible = eligible;
  };

  private addressNotEligibleMessage: string =
    "This customer's address is not eligible for TOSHI's services: please change the address to one within TOSHI's service area, or contact TOSHI support";

  clearAddressNotEligibleMessage = (): void => {
    this.rootStore.uiStore.clearNotificationByText(this.addressNotEligibleMessage);
  };

  setCustomer = (customer: ICustomer): void => {
    this.customer = customer;
  };

  setAddress = (address: IAddress): void => {
    this.address = address;
  };

  setPostcode = (postcode: string): void => {
    this.postcode = postcode;
  };

  setGiftee = (giftee: Giftee): void => {
    this.giftee = giftee;
  };

  clearCustomerInfo = (): void => {
    this.customer = null;
    this.address = null;
    this.rootStore.orderStore.clearDeliverySlot();
  };

  clearGifteeInfo = (): void => {
    this.giftee = null;
  };

  createGiftingCustomer = async (
    customer: INewCustomerData,
    isGiftRecipient: boolean,
  ): Promise<{ success: boolean; error?: string }> => {
    let error: string | undefined;
    let response: AxiosResponse<CreateRecipientResponse> | undefined;
    await createGiftingCustomer(customer)
      .then((resp) => {
        response = resp;
      })
      .catch((networkError) => {
        error = networkError.response.data.reason;
      });

    if (!response) {
      return {
        success: false,
        error,
      };
    }

    const mappedData = this.mapNonAddressCustomerResponseData(response.data);
    if (isGiftRecipient) {
      this.setGiftee(mappedData);
    } else {
      this.setCustomer(mappedData);
    }

    return { success: true };
  };

  createCustomer = async (
    customer: INewCustomerData,
    address: INewAddressData,
  ): Promise<{ success: boolean; error?: string }> => {
    try {
      const { data } = await createCustomer({
        customer,
        address,
      });

      const mappedData = this.mapCustomerResponseData(data);
      if (mappedData) {
        this.setCustomer(mappedData.newCustomer);
        this.setAddress(mappedData.newCustomerAddress);
      }

      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        error: error.response.data.reason,
      };
    }
  };

  checkPostcodeEligibility = async (postcode: string): Promise<boolean> => {
    const response = await checkAddressEligibility({ postcode });

    if (response.data.eligible) return true;

    return false;
  };

  searchCustomersRequest = async (query: string): Promise<IGetCustomerResponse[]> => {
    const response = await getCustomers({ query });
    return response.data;
  };

  private mapNonAddressCustomerResponseData = (responseData: CreateGifteeResponse): Giftee => ({
    id: responseData.id,
    title: responseData.title,
    firstName: responseData.first_name,
    lastName: responseData.last_name,
    email: responseData.email,
    guest: responseData.guest,
    contactNumber: responseData.contact_number,
    contactNumberCountry: responseData.contact_number_country,
    contactPreferences: responseData.contact_preferences,
  });

  private mapCustomerResponseData = (
    responseData: ICreateCustomerResponse,
  ): {
    newCustomer: ICustomer;
    newCustomerAddress: IAddress;
  } => {
    const newCustomer: ICustomer = {
      id: responseData.id,
      title: responseData.title,
      firstName: responseData.first_name,
      lastName: responseData.last_name,
      email: responseData.email,
      guest: responseData.guest,
      contactNumber: responseData.contact_number,
      contactNumberCountry: responseData.contact_number_country,
      contactPreferences: responseData.contact_preferences,
    };
    const newCustomerAddress: IAddress = {
      id: responseData.last_address.id,
      houseName: responseData.last_address.house_name || '',
      line1: responseData.last_address.line_1,
      line2: responseData.last_address.line_2 || '',
      cityTown: responseData.last_address.city_town || '',
      postcodeZipcode: responseData.last_address.postcode_zipcode,
      zone: responseData.last_address.zone,
      country: responseData.last_address.country,
    };

    return {
      newCustomer,
      newCustomerAddress,
    };
  };
}
